// JS
import "../../../global.js"

// COMPONENT
// import MenuComponent from "../SideMenu/SideMenu";
import BreadcrumbComponent from "../Breadcrumb/Breadcrumb"

// LIBRARY
import Swal from "sweetalert2"
import { toast } from "react-toastify"
import Cookies from "universal-cookie"
import React, { Component } from "react"
import Pagination from "react-js-pagination"
import { Modal } from "react-bootstrap"
import { Menu, MenuItem } from "@szhsin/react-menu"
import pdfMake from "pdfmake/build/pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts"
import "pdfmake/build/vfs_fonts"

// CSS
import "./style.css"
import "../../../bootstrap.css"
import "react-slideshow-image/dist/styles.css"
import "bootstrap-daterangepicker/daterangepicker.css"
import "@szhsin/react-menu/dist/index.css"
import "@szhsin/react-menu/dist/transitions/slide.css"


import {
  tokens,
  user_profile,
  alert,
  alert_url,
  GET,
  POST,
  PUT,
  DELETE,
  LOGOUT,
  validate,
  gen_Password,
  format_date,
  success_url,
  success,
  baht,
  ModalDetail,
  ModalSaleHistory,
  print_slip
} from "../../Custom/CustomComponent.js"

const cookies = new Cookies()
toast.configure()

pdfMake.vfs = pdfFonts.pdfMake.vfs
pdfMake.fonts = {
  FCIconic: {
    normal: "FCIconic-Regular.ttf",
    bold: "FCIconic-Bold.ttf",
    italics: "FCIconic-Italic.ttf",
    bolditalics: "FCIconic-Italic.ttf",
  },
  THSarabunNew: {
    normal: "THSarabunNew.ttf",
    bold: "THSarabunNew-Bold.ttf",
    italics: "THSarabunNew-Italic.ttf",
    bolditalics: "THSarabunNew-BoldItalic.ttf",
  },
  Roboto: {
    normal: "Roboto-Regular.ttf",
    bold: "Roboto-Medium.ttf",
    italics: "Roboto-Italic.ttf",
    bolditalics: "Roboto-MediumItalic.ttf",
  },
}
const array_month = [
  "ม.ค.",
  "ก.พ.",
  "มี.ค.",
  "เม.ย.",
  "พ.ค.",
  "มิ.ย.",
  "ก.ค.",
  "ส.ค.",
  "ก.ย.",
  "ต.ค.",
  "พ.ย.",
  "ธ.ค.",
]
export default class ListTransactionScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      token: cookies.get("token_aip_shop") ? "token " + cookies.get("token_aip_shop") : "",
      modal_create: false,
      modal_cancel: false,

      modal_detail: false,
      modal_detail_print: false,

      calculate_type: "เงินสด",
      page: 1,
      loading: false,
      data: null,
      data_detail: "",
      user_member_name: "",
      user_member_address: "",
      user_member_phone: "",
      user_member_email: null,
      user_member_tax_invoice: "",
      order_main_id: "",
      order_main: "",
      text_cancle: "",
      type: "",

      start_date: "",
      end_date: "",
      status_order_main: "",
      search: "",
      category_name: "",
      category_type: "",

      order_item: "",
      modal_history: false,
      history: "",

      type_print: ""
    }
  }
  async componentDidMount() {
    await this.Get_All()
  }

  Get_All = async () => {
    let status = this.state.status_order_main
    if (Number(this.state.status_order_main)) {
      status = Number(this.state.status_order_main)
    }


    this.setState({ loading: true })
    let body = {
      category_name: this.state.category_type,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      status_order_main: status,
      data_search: this.state.search,
    }
    let result = await POST(tokens, "v1/pos-mgt/transaction/filter/page/" + this.state.page, body);
    if (result && result.status) {
      this.setState({ data: result.data })
    }
    this.setState({ loading: false })

  }

  Get_Detail = async (type) => {

    this.setState({ loading: true })
    let result = await GET(tokens, "v1/pos-mgt/order/detail/" + this.state.order_item.order_main_id, null);
    if (result && result.status) {
      let member_data = {
        address: this.state.user_member_address,
        company_code: result.data.data.member_data.company_code,
        company_name: result.data.data.member_data.company_name,
        credit_day: result.data.data.member_data.credit_day,
        email: this.state.user_member_email === null ? "-" : this.state.user_member_email,
        full_name: this.state.user_member_name,
        line_id: result.data.data.member_data.line_id,
        phone: this.state.user_member_phone,
        tax_invoice: this.state.user_member_tax_invoice,
        user_code: result.data.data.member_data.user_code,
        user_id: result.data.data.member_data.user_id,
        user_type: result.data.data.member_data.user_type
      }

      let setdata = result.data.data
      setdata.member_data = member_data

      let calculate_type =
        result.data.data.payment_type_layer_1 === 1 ? "เงินสด" :
          result.data.data.payment_type_layer_1 === 3 ? "บัตรเครดิต" :
            result.data.data.payment_type_layer_1 === 4 ? "โอน" : ""

      let data = {
        detail: setdata,
        calculate_type: calculate_type,
        payment_detail: "",
        general: 0.00,
      }



      if (type === "ใบกำกับภาษี") {
        print_slip(data, "ใบกำกับภาษี")
      } else if (type === "ใบส่งของ/แจ้งหนี้") {
        print_slip(data, "ใบแจ้งหนี้")

      } else if (type === "ใบแจ้งซ่อม") {
        print_slip(data, "ใบแจ้งซ่อม")
      }
      this.setState({ modal_detail_print: false })
    }
    this.setState({ loading: false, })
  }

  History = async (order_main_number) => {
    this.setState({ loading: true })
    let result = await POST(tokens, "/v1/transaction_payment/detail/" + order_main_number, null);
    if (result && result.status) {

      this.setState({ history: result.data.data })

      setTimeout(() => {
        this.setState({ modal_history: true, loading: false })
      }, 20)

    }
    this.setState({ loading: false })

  }

  Get_Modal_Detail = async (id) => {
    this.setState({ loading: true })
    let result = await GET(tokens, "v1/pos-mgt/order/detail/" + id, null);
    if (result && result.status) {
      this.setState({ data_detail: result.data.data })
      setTimeout(() => {
        this.setState({ modal_detail: true, loading: false })
      }, 20)
    }
    this.setState({ loading: false })
  }

  Cancle = () => {
    this.setState({ loading: true })
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
      body: JSON.stringify({
        order_main_id: this.state.order_main_id,
      }),
    }
    fetch(global.api + "v1/pos-mgt/order/cancel", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ loading: false, page: 1, modal_cancel: false })
          this.alert(
            "success",
            "ยกเลิกรายการเรียบร้อยแล้ว",
            "ท่านยังสามารถสั่งรายการแบบเดิมได้โดยกดที่ปุ่มเมนู “สั่งอีกครั้ง”"
          )
          setTimeout(() => {
            this.Get_All()
          }, 10)
        } else {
          switch (result.data.message) {
            case "company name is none":
              this.alert("info", "แจ้งเตือน", "กรุณากรอกชื่อบริษัท")
              break
            case "This Page is not available":
              break
            case "Token is not found":
              this.alert("info", "แจ้งเตือน", "เซสชั่นหมดอายุ")
              this.Logout()
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")

              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
        this.setState({ loading: false })
      })
  }
  clear_form() {
    this.setState({
      type_print: "",
      loading: false,
      modal_detail: false,
      category_name: "",
      calculate_type: "เงินสด",
      data_detail: null,
      user_member_name: "",
      user_member_address: "",
      user_member_phone: "",
      user_member_email: null,
      user_member_tax_invoice: "",
      order_main_id: "",
      text_cancle: "",
      type: "",
      order_main: ""
    })
  }
  date = (date) => {
    let datetime = ""
    try {
      datetime =
        date.getDate() +
        " " +
        array_month[date.getMonth()] +
        " " +
        (date.getFullYear() + 543) +
        ", " +
        date.getHours() +
        ":" +
        date.getMinutes() +
        " น."
    } catch (e) {
      datetime =
        date.split(" ")[0].split("-")[2] +
        " " +
        array_month[Number(date.split(" ")[0].split("-")[1]) - 1] +
        " " +
        (Number(date.split(" ")[0].split("-")[0]) + 543) +
        ", " +
        date.split(" ")[1].slice(0, 5) +
        " น."
    }
    return datetime
  }
  date_print = (date) => {
    let datetime = ""
    try {
      datetime =
        date.getDate() +
        " " +
        array_month[date.getMonth()] +
        " " +
        (date.getFullYear() + 543)
    } catch (e) {
      datetime =
        date.split("T")[0].split("-")[2] +
        " " +
        array_month[Number(date.split("T")[0].split("-")[1]) - 1] +
        " " +
        (Number(date.split("T")[0].split("-")[0]) + 543)
    }
    return datetime
  }
  status = (status) => {
    switch (status) {
      case 1:
        return "รอชำระ"
      case 2:
        return "ชำระเงินแล้ว"
      case 3:
        return "ยกเลิกสินค้า"
      case 4:
        return "พักออเดอร์"
      case 5:
        return "มัดจำ"

      default:
        return ""
    }
  }
  status_color = (status) => {
    switch (status) {
      case 1:
        return "status status-blue"
      case 2:
        return "status status-green"
      case 3:
        return "status status-black"
      case 4:
        return "status status-black"
      case 5:
        return "status status-yellow"
      default:
        return ""
    }
  }
  handlePageChange(pageNumber) {

    this.setState({ page: pageNumber })
    setTimeout(() => {

      this.Get_All()
    }, 20)
  }

  retry = async (id, type) => {
    window.location.href = "/main?retry_id=" + id
  }
  alert(icon, title, detail) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    })
  }
  alert_url(icon, title, detail, url) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    }).then(() => {
      window.location.href = url
    })
  }
  Logout = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
    }
    fetch(global.api + "v1/logout", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        cookies.remove("token_aip_shop")
        cookies.remove("role_data")
        cookies.remove("user_profile")
        cookies.remove("first_page")
        window.location.href = "/login"
      })
      .catch((e) => {
        this.alert_url("info", "แจ้งเตือน", "เกิดข้อผิดพลาด", "/login")
      })
  }
  date_in_detail = (date) => {
    let datetime =
      date.split("T")[0].split("-")[2] +
      " " +
      array_month[Number(date.split("T")[0].split("-")[1]) - 1] +
      " " +
      (Number(date.split("T")[0].split("-")[0]) + 543) +
      ", " +
      date.split("T")[1].slice(0, 5) +
      " น."
    return datetime
  }
  validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  }
  validatePhone = (phone) => {
    return phone.match(/^^[0][0-9]*$/)
  }

  clear_form_print() {
    this.setState({

      user_member_name: "",
      user_member_address: "",
      user_member_phone: "",
      user_member_email: null,
      user_member_tax_invoice: "",

    })

  }
  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="w-100">
          <BreadcrumbComponent />
          <div style={{ height: 60 }}></div>
          <div className="row mt-4 mx-0">
            <div className="col-12 mb-4">
              <div className="card p-3">
                <div className="w-100 d-flex justify-content-between align-items-center">
                  <h2>
                    <b>รายการธุรกรรม</b>
                  </h2>
                  <div>
                    <button
                      type="button"
                      className="btn btn-outline-primary w-100 px-4"
                      onClick={() => {
                        window.location.href = "/main"
                      }}
                    >
                      <span className="icon-brand mr-2">{"\uf060"}</span>
                      ย้อนกลับ
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mb-4">
              <div className="card p-3">
                <div className="row">
                  <div className="col-12 col-sm-6 col-md-7 mb-3">
                    <div className="w-100 d-flex align-items-center">
                      <div className="w-100">
                        <input
                          type="date"
                          className="form-control"
                          value={this.state.start_date}
                          onChange={(e) => {
                            this.setState({
                              start_date: e.target.value,
                              page: 1,
                            })
                            setTimeout(() => {
                              this.Get_All()
                            }, 20)
                          }}
                        ></input>
                      </div>
                      <label className="mx-3">ถึง</label>
                      <div className="w-100">
                        <input
                          type="date"
                          className="form-control"
                          value={this.state.end_date}
                          onChange={(e) => {
                            this.setState({ end_date: e.target.value, page: 1 })
                            setTimeout(() => {
                              this.Get_All()
                            }, 20)
                          }}
                        ></input>
                      </div>
                      <div className="w-100 ml-2">
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            this.setState({
                              start_date: "",
                              end_date: "",
                              status_order_main: "",
                              search: "",
                              page: 1,
                            })
                            setTimeout(() => {
                              this.Get_All()
                            }, 20)
                          }}
                        >
                          ล้างค่า
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-5 ml-auto mb-3">
                    <div className="w-100 d-flex align-items-center">

                      <div className="w-100 mr-1">
                        <select
                          className="form-control"
                          value={this.state.status_order_main}
                          onChange={(e) => {
                            this.setState({
                              status_order_main: e.target.value,
                              page: 1,
                            })
                            setTimeout(() => {
                              this.Get_All()
                            }, 20)
                          }}
                        >
                          <option value="all" selected>
                            ทุกสถานะ
                          </option>
                          <option value="2">ชำระเงินแล้ว</option>
                          <option value="3">ยกเลิก (คืนสินค้า)</option>
                        </select>
                      </div>
                      <div className="w-100 ml-1">
                        <input
                          className="form-control bg-secondary-light"
                          type="search"
                          placeholder="ค้นหาด้วยเลขที่รายการ ชื่อ"
                          value={this.state.search}
                          onChange={(e) => {
                            this.setState({ search: e.target.value })
                            if (e.target.value === "") {
                              this.setState({ page: 1, search: "" })
                              setTimeout(() => {
                                this.Get_All()
                              }, 20)
                            }
                          }}
                          onKeyPress={(event) => {
                            if (event.key === "Enter") {
                              this.setState({
                                page: 1,
                              })
                              setTimeout(() => {
                                this.Get_All()
                              }, 20)
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-100">
                  <div className="table-responsive">
                    <table className="table design-table table-striped design-table-sell">
                      <thead>
                        <tr>
                          <th className="text-left">เลขที่รายการ</th>
                          <th className="text-left" style={{ minWidth: 140 }}>วันที่</th>
                          <th className="text-left">รหัสลูกค้า</th>
                          <th className="text-left" style={{ minWidth: 100 }}>ชื่อลูกค้า</th>
                          <th className="text-left">เลขที่ POS</th>
                          <th className="text-right" style={{ minWidth: 130 }}>ค่ามัดจำ</th>
                          <th className="text-right">ยอดรวม</th>
                          <th className="text-center" style={{ minWidth: 150 }}>สถานะ</th>
                          <th className="text-center">จัดการ</th>
                        </tr>
                      </thead>
                      <tbody>
                        {((this.state.data &&
                          this.state.data.data.length === 0) ||
                          !this.state.data) && (
                            <tr>
                              <td colSpan={10} className="text-center">
                                -- ไม่มีข้อมูล --
                              </td>
                            </tr>
                          )}
                        {this.state.data &&
                          this.state.data.data.map((item, index) => (
                            <tr key={index}>
                              <td className="text-left">
                                {item.order_main_number}
                              </td>
                              <td className="text-left">
                                {this.date(item.datetime_create)}
                              </td>
                              <td className="text-left">{item.member_data.user_code}</td>
                              <td className="text-left">{item.member_data.user_type === "corporation" ? item.member_data.company_name
                                : item.member_data.full_name
                              }</td>
                              <td className="text-left">{item.machine_number}</td>
                              <td className="text-right">
                                {baht(item.deposit)}
                              </td>
                              <td className="text-right">
                                {baht(item.total_price_final)}
                              </td>
                              <td className="text-center">
                                <span
                                  className={this.status_color(
                                    item.status_order_main
                                  )}
                                >
                                  {this.status(item.status_order_main)}
                                </span>
                              </td>
                              <td className="text-center">
                                <Menu
                                  menuButton={
                                    <button className="btn btn-link text-secondary ">
                                      <h2 className="icon h2">{"\uf141"}</h2>
                                    </button>
                                  }
                                  transition
                                >
                                  {item.status_order_main !== 3 && (
                                    <>
                                      {item.status_order_main === 2 && (
                                        <MenuItem
                                          onClick={async () => {
                                            this.setState({
                                              order_item: item,
                                              type_print: "ใบกำกับภาษี",
                                              user_member_name: item.member_data.user_type === "corporation"
                                                ? item.member_data.company_name
                                                : item.member_data.full_name,
                                              user_member_tax_invoice: item.member_data.tax_invoice,
                                              user_member_phone: item.member_data.phone,
                                              user_member_address: item.member_data.address,
                                              user_member_email: item.member_data.email === "" ? null : item.member_data.email,
                                            })




                                            this.setState({ modal_detail_print: true })

                                          }}
                                        >
                                          <label className="icon mr-2">
                                            {"\uf319"}
                                          </label>
                                          ใบกำกับภาษี
                                        </MenuItem>
                                      )}

                                      <MenuItem
                                        onClick={async () => {
                                          this.setState({
                                            order_item: item,
                                            type_print: "ใบส่งของ/แจ้งหนี้",
                                            user_member_name: item.member_data.user_type === "corporation"
                                              ? item.member_data.company_name
                                              : item.member_data.full_name,
                                            user_member_tax_invoice: item.member_data.tax_invoice,
                                            user_member_phone: item.member_data.phone,
                                            user_member_address: item.member_data.address,
                                            user_member_email: item.member_data.email === "" ? null : item.member_data.email,
                                          })


                                          this.setState({ modal_detail_print: true })
                                        }}
                                      >
                                        <label className="icon mr-2">
                                          {"\uf15c"}
                                        </label>
                                        ใบส่งของ/แจ้งหนี้
                                      </MenuItem>

                                      {item.technician_job === true && (


                                        <MenuItem
                                          onClick={async () => {
                                            this.setState({
                                              order_item: item,
                                              type_print: "ใบแจ้งซ่อม",
                                              user_member_name: item.member_data.user_type === "corporation"
                                                ? item.member_data.company_name
                                                : item.member_data.full_name,
                                              user_member_tax_invoice: item.member_data.tax_invoice,
                                              user_member_phone: item.member_data.phone,
                                              user_member_address: item.member_data.address,
                                              user_member_email: item.member_data.email === "" ? null : item.member_data.email,
                                            })


                                            this.setState({ modal_detail_print: true })
                                          }}
                                        >
                                          <label className="icon mr-2">
                                            {"\uf15c"}
                                          </label>
                                          ใบแจ้งซ่อม
                                        </MenuItem>
                                      )}
                                    </>
                                  )}

                                  <MenuItem
                                    onClick={async () => {
                                      this.setState({ index: index })
                                      this.Get_Modal_Detail(item.order_main_id)
                                    }}
                                  >
                                    <label className="icon mr-2">
                                      {"\uf15c"}
                                    </label>
                                    รายละเอียด
                                  </MenuItem>

                                  {item.payment_type_layer_1 === 5 && (
                                    <MenuItem
                                      onClick={async () => {



                                        this.History(item.order_main_number)



                                      }}
                                    >
                                      <label className="icon mr-2">
                                        {"\uf1da"}
                                      </label>
                                      ประวัติการผ่อนชำระ
                                    </MenuItem>
                                  )}

                                  <MenuItem
                                    onClick={() => {
                                      this.retry(
                                        item.order_main_id,
                                        item.category_name
                                      )
                                    }}
                                  >
                                    <label className="icon mr-2">
                                      {"\uf01e"}
                                    </label>
                                    สั่งอีกครั้ง
                                  </MenuItem>


                                  {item.status_order_main !== 3 && (
                                    <MenuItem
                                      onClick={() => {
                                        this.setState({
                                          modal_cancel: true,
                                          text_cancle: "",
                                          order_main_id: item.order_main_id,
                                          order_main: item.order_main_number
                                        })
                                      }}
                                    >
                                      <label className="icon mr-2">
                                        {"\uf057"}
                                      </label>
                                      ยกเลิกรายการ
                                    </MenuItem>)}
                                </Menu>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {/* pagination */}
              {this.state.data && this.state.data.data.length !== 0 && (
                <div className="d-flex justify-content-between row mx-0 w-100 px-3 my-4">
                  <div>
                    <label>
                      แสดง {this.state.data.end_index} รายการจาก{" "}
                      {this.state.data.count} รายการ
                    </label>
                  </div>
                  <div className="d-flex">
                    <button
                      className="btn-circle-white mx-1"
                      onClick={() => {
                        this.handlePageChange(1)
                      }}
                    >
                      <label className="icon">{"\uf100"}</label>
                    </button>
                    <Pagination
                      activePage={this.state.page}
                      itemsCountPerPage={10}
                      totalItemsCount={this.state.data.count}
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChange.bind(this)}
                      prevPageText={<label className="icon">{"\uf104"}</label>}
                      nextPageText={<label className="icon">{"\uf105"}</label>}
                      hideFirstLastPages={true}
                    />
                    <button
                      className="btn-circle-white mx-1"
                      onClick={() => {
                        this.handlePageChange(this.state.data.page_number)
                      }}
                    >
                      <label className="icon">{"\uf101"}</label>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* modal */}
        {/* ยกเลิกรายการ modal */}
        <Modal
          show={this.state.modal_cancel}
          onHide={() => {
            this.setState({ modal_cancel: false })
            this.clear_form()
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b>ยกเลิกรายการ</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 d-flex mb-3">
              <label>
                ท่านแน่ในว่าต้องการยกเลิกรายการ "{this.state.order_main}
                " หลังจากท่านยืนยันการยกแล้วจะไม่สามารถย้อนคืนข้อมูลได้
              </label>
            </div>

            {/* ที่อยู่ */}
            <div className="w-100 mb-3">
              <textarea
                className="form-control"
                placeholder="สาเหตุในการยกเลิก"
                value={this.state.text_cancle}
                onChange={(e) => {
                  this.setState({ text_cancle: e.target.value })
                }}
              ></textarea>
            </div>

            <div className="w-100 mb-3">
              <label className="w-100">
                หมายเหตุ: การค้างจ่ายจะไม่มีการคืนเงิน
              </label>
            </div>

            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_cancel: false })
                    this.clear_form()
                  }}
                >
                  ย้อนกลับ
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-danger w-100"
                  onClick={() => {
                    this.Cancle()
                  }}
                >
                  ยืนยัน
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>


        {/* รายละเอียด modal */}
        < ModalDetail
          value={this.state.data_detail}
          show={this.state.modal_detail}
          onHide={() => {
            this.setState({ modal_detail: false })
          }}
        />

        {/* Detail print */}
        <Modal
          show={this.state.modal_detail_print}
          onHide={() => {
            this.setState({ modal_detail_print: false })

            this.clear_form_print()
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b>รายละเอียดเพิ่มเติม</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label className="mb-3 text-secondary">กรอก/ตรวจสอบรายละเอียดเพิ่มเติมในการพิมพ์เอกสาร</label>
            {/* ชื่อลูกค้า */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ชื่อลูกค้า:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control"
                  type="text"
                  placeholder="กรอกชื่อ"
                  onChange={(e) => {
                    this.setState({ user_member_name: e.target.value })
                  }}
                  value={this.state.user_member_name}
                />
              </div>
            </div>

            {/* เลขที่ผู้เสียภาษี */}
            <div className="mb-3 w-100 d-flex">
              <div className="wpx-120">
                <label>เลขที่ผู้เสียภาษี:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control"
                  maxLength={13}
                  type="text"
                  placeholder="กรอกเลขที่ผู้เสียภาษี"
                  onChange={(e) => {
                    let user_member_tax_invoice = this.state.user_member_tax_invoice
                    user_member_tax_invoice = e.target.value.replace(/\D/g, "")
                    this.setState({
                      user_member_tax_invoice: user_member_tax_invoice,
                    })
                  }}
                  value={this.state.user_member_tax_invoice}
                />
                <small className="text-dark">กรุณากรอกข้อมูล หากต้องการออกใบกำกับภาษีหรือใบแจ้งหนี้</small>
              </div>
            </div>


            {/* เบอร์โทร */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>เบอร์โทร:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control"
                  type="tel"
                  maxLength={10}
                  placeholder="กรอกเบอร์โทร"
                  onChange={(e) => {
                    let phone = this.state.user_member_phone
                    phone = e.target.value.replace(/\D/g, "")
                    this.setState({
                      user_member_phone: phone,
                    })
                  }}
                  value={this.state.user_member_phone}
                />
              </div>
            </div>
            {/* ที่อยู่ */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ที่อยู่:</label>
              </div>
              <div className="w-100">
                <textarea
                  className="form-control"
                  placeholder="กรอกที่อยู่"
                  onChange={(e) => {
                    this.setState({ user_member_address: e.target.value })
                  }}
                  value={this.state.user_member_address}
                ></textarea>
              </div>
            </div>
            {/* อีเมล */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>อีเมล:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control"
                  type="email"
                  placeholder="กรอกอีเมล"
                  onChange={(e) => {
                    if (e.target.value !== "") {
                      this.setState({ user_member_email: e.target.value })
                    } else {
                      this.setState({ user_member_email: null })
                    }
                  }}
                  value={this.state.user_member_email}
                />
              </div>
            </div>

            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_detail_print: false })
                    this.clear_form_print()

                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {

                    if (this.state.user_member_tax_invoice !== "" && this.state.user_member_tax_invoice.length !== 13) {
                      this.alert(
                        "info",
                        "แจ้งเตือน",
                        "กรุณากรอกเลขกำกับภาษีให้ครบ 13 หลัก"
                      )
                      return
                    } else if (this.state.user_member_phone.trim() !== "" && !this.validatePhone(this.state.user_member_phone)) {
                      this.alert(
                        "info",
                        "แจ้งเตือน",
                        "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง"
                      )

                      return
                    } else if (this.state.user_member_phone.trim() !== "" && this.state.user_member_phone.length !== 10) {
                      this.alert(
                        "info",
                        "แจ้งเตือน",
                        "กรุณากรอกเบอร์โทรศัพท์ให้ครบ 10 หลัก"
                      )

                      return
                    } else if (this.state.user_member_email !== null && !this.validateEmail(this.state.user_member_email)) {
                      this.alert(
                        "info",
                        "แจ้งเตือน",
                        "รูปแบบอีเมลไม่ถูกต้อง"
                      )

                      return
                    } if (this.state.type === "ใบกำกับภาษี" && this.state.user_member_tax_invoice.trim().length !== 13) {
                      this.alert(
                        "info",
                        "แจ้งเตือน",
                        "กรุณากรอกเลขที่ผู้เสียภาษี"
                      )
                      return
                    }

                    this.Get_Detail(this.state.type_print)

                  }}
                >
                  ถัดไป
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>


        {/* ประวัติการชำระเงิน */}
        < ModalSaleHistory
          value={this.state.history}
          show={this.state.modal_history}
          onHide={() => {
            this.setState({ modal_history: false })
          }}
        />
      </div>
    )
  }
}
