
// JS
import "../../../global.js"
import {
  tokens,
  user_profile,
  alert,
  alert_url,
  GET,
  POST,
  PUT,
  DELETE,
  LOGOUT,
  validate,
  gen_Password,
  format_date,
  success_url,
  success,
  baht,
  ModalPrint,
  print_slip,
  date_overdue,
  total
} from "../../Custom/CustomComponent.js"

// COMPONENT
// import MenuComponent from "../SideMenu/SideMenu";
import BreadcrumbComponent from "../Breadcrumb/Breadcrumb"
import NONE from "./none.png"
import CLOCK from "./clock.png"
import WARNING from "./warning.png"
import CREDIT1 from "./credit1.png"
import CREDIT2 from "./credit2.png"
import CREDIT3 from "./credit3.png"
import CREDIT4 from "./credit4.png"
import CREDIT5 from "./credit5.png"
import SOLDOUT from "./soldout.png"
import IMGAIR from "./imgair.png"
import IMGSPAREPART from "./imgsparepart.png"
import DEBIT002 from "./bank_icon/002.png"
import DEBIT004 from "./bank_icon/004.png"
import DEBIT006 from "./bank_icon/006.png"
import DEBIT011 from "./bank_icon/011.png"
import DEBIT014 from "./bank_icon/014.png"
import DEBIT022 from "./bank_icon/022.png"
import DEBIT024 from "./bank_icon/024.png"
import DEBIT025 from "./bank_icon/025.png"
import DEBIT030 from "./bank_icon/030.png"
import DEBIT033 from "./bank_icon/033.png"
import DEBIT034 from "./bank_icon/034.png"
import DEBIT066 from "./bank_icon/066.png"
import DEBIT067 from "./bank_icon/067.png"
import DEBIT069 from "./bank_icon/069.png"
import DEBIT070 from "./bank_icon/070.png"
import DEBIT071 from "./bank_icon/071.png"
import DEBIT073 from "./bank_icon/073.png"

// LIBRARY
import Swal from "sweetalert2"
import { toast } from "react-toastify"
import Cookies from "universal-cookie"
import React, { Component } from "react"
import { Modal } from "react-bootstrap"
import Select, { components } from "react-select"
import pdfMake from "pdfmake/build/pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts"
import "pdfmake/build/vfs_fonts"

// CSS
import "./style.css"
import "../../../bootstrap.css"
import "react-slideshow-image/dist/styles.css"
import "bootstrap-daterangepicker/daterangepicker.css"

const cookies = new Cookies()
toast.configure()
pdfMake.vfs = pdfFonts.pdfMake.vfs
pdfMake.fonts = {
  FCIconic: {
    normal: "FCIconic-Regular.ttf",
    bold: "FCIconic-Bold.ttf",
    italics: "FCIconic-Italic.ttf",
    bolditalics: "FCIconic-Italic.ttf",
  },
  THSarabunNew: {
    normal: "THSarabunNew.ttf",
    bold: "THSarabunNew-Bold.ttf",
    italics: "THSarabunNew-Italic.ttf",
    bolditalics: "THSarabunNew-BoldItalic.ttf",
  },
  Roboto: {
    normal: "Roboto-Regular.ttf",
    bold: "Roboto-Medium.ttf",
    italics: "Roboto-Italic.ttf",
    bolditalics: "Roboto-MediumItalic.ttf",
  },
}
const array_month = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"]
const { Option } = components
const customStyles = { option: (provided, state) => ({ ...provided, height: 75, padding: 8 }) }
const IconOption = (props) => (
  <Option {...props}> <img src={props.data.icon} style={{ width: 24, marginRight: 16 }} alt={props.data.label} /> {props.data.label} </Option>
)
const UserOption = (props) => (
  <Option {...props}>
    <h5>{props.data.full_name}</h5>
    <div className="w-100 d-flex justify-content-between">
      <label className="w-50">เบอร์โทร : {props.data.phone}</label>
      <label className="w-50">เลขบัตร : {props.data.id}</label>
    </div>
  </Option>
)
export default class MainScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      role: cookies.get("role_data"),

      modal_search_member: false,
      modal_pause_order: false,
      modal_cancel: false,
      modal_select_stock: false,
      modal_payment: false,
      modal_edit_price: false,
      modal_edit_discount: false,
      modal_print: false,
      modal_PauseDate: false,
      modal_alert: false,
      modal_address: false,

      modal_add: false,
      modal_edit_delivery_price: false,
      modal_remark: false,
      modal_detail_print: false,
      checked_other_address: false,

      calculate_type: "เงินสด",
      price_type: "แก้ไข",
      page: 1,
      loading: false,
      data: null,
      data_member: null,
      data_brand: null,
      data_product: null,
      data_stock: null,
      data_hole: null,
      data_supplier: null,

      date: new Date(),
      check_date: new Date().toISOString().slice(0, new Date().toISOString().lastIndexOf(":")),

      brand_id: "all",
      data_search: "",

      edit_status: false,
      pause_status: false,
      print: "ใบเสร็จ",
      stock_location_id: "",
      stock_main_name: "",
      stock_main_qty: 0,
      product: null,
      index: -1,
      product_index: -1,
      price: "00",
      card_type: "บัตรเครดิต",
      hour_transfer: "",
      minute_transfer: "",
      user_member_name: "",
      user_member_code: "",
      user_member_credit_day: "",
      user_member_address: "",
      user_member_phone: "",
      user_member_email: null,
      user_member_line: "",
      user_member_tax_invoice: "",
      user_member: null,

      send_payment: 0,
      data_address: "",
      general: 0.0,
      count_qty_order_list: 0,
      status_price: 1,

      // MEMBER
      member_type: "name",
      company_name: "",
      user_type: "",
      credit_day: 0,
      full_name: "",
      company_id: "",
      tax_invoice: "",
      address_MEMBER: "",
      phone: "",
      email: "",

      // ALL VALUE
      order_main_id: "",
      category_name: "all",
      user_member_id: "",
      order_main_number: "",
      machine_number: cookies.get("user_profile") ? cookies.get("user_profile").user_code : "",
      count_order: 0,
      total_price: 0.0,
      delivery_price: 0.0,
      delivery_address: "",
      remark: "",
      input_remark: "",
      vat: 0.0,
      discount: 0.0,
      deposit: 0.0,
      total_price_final: 0.0,
      status_order_main: 1,
      payment_type_layer_1: 0,
      product_main: {},
      payment_detail: {
        credit_card_type: "",
        bank_name: "",
        card_number: "",
        slip_number: "",
        remark_payment_s2: "",
        remark_payment_s3: "",
        full_name_transfer: "",
        datetime_transfer: "",
        reference_number: "",
        remark_payment_s4: "",
        remark_payment_s5: "",
        total_pay: 0.0,
        installment: "",
      },
      repair_detail: {
        customer_name: "",
        customer_address: "",
        customer_phone: "",
        date_time_repair: null,
        remark_repair: "",
      },
      order_list: [],
      tax_order: {},

      //งานซ่อม
      technician_list: [],
      data_user: null,
      technician: null,
      modal_detail_repair: false,

      //modal type: 
      modal_type: false,
      txt_type: "",

      new_OM: false,
      message: { title: "", description: "", tax_order: "", detail: "", general: "", calculate_type: "", payment_detail: "", screen: "" },

      modal_detail_repair_save: false,
      modal_pause_clear: false,
      page_name: ""
    }
  }
  async componentDidMount() {
    this.setState({ loading: true })

    await this.GetProductAll()

    await this.GetOMNumber()
    await this.GetMemberAll()

    await this.GetBrandAll()
    await this.GetSupplier()
    // await this.GetHold()
    await this.GetUserAll()
    let POS = localStorage.getItem("POS");
    if (POS) {
      this.setState({ pause_status: true })
    }

    this.setState({ loading: false })

    if (this.props.location.query.retry_id) {
      await this.GetRetryDetail(this.props.location.query.retry_id, "retry")
    }
    if (this.props.location.query.edit_id) {
      await this.GetRetryDetail(this.props.location.query.edit_id, "edit")
    }
  }
  GetProductAll = async () => {
    this.setState({ loading: true })
    let body = {
      category_name: this.state.category_name,
      brand_id: this.state.brand_id,
      data_search: this.state.data_search,
    }
    let result = await POST(tokens, "v1/pos-mgt/order/filter", body);
    if (result && result.status) {
      this.setState({ data_product: result.data, loading: false })
    }
  }
  GetUserAll = async () => {
    let result = await GET(tokens, "v1/user-admin/all", null);
    if (result && result.status) {
      let user = []
      result.data.data.find((e, index) => {
        if (e.user_type === "emp_technician") {
          user.push(e)
        }
      })
      this.setState({ data_user: user })
    }
    this.setState({ loading: false })
  }
  GetOMNumber = async () => {
    let result = await GET(tokens, "v1/pos-mgt/order/generate-om-number", null);
    if (result && result.status) {
      this.setState({ order_main_number: result.data.data, })
      if (this.state.new_OM === true) {
        this.Create()
      }
    }
  }
  GetMemberAll = async () => {
    let result = await GET(tokens, "v1/user-member/all", null);
    if (result && result.status) {
      this.setState({ data_member: result.data })
      if (result.data.data.length > 0) {
        this.setState({
          user_member_name: result.data.data[0].full_name,
          user_member_code: result.data.data[0].user_code,
          user_member_credit_day: result.data.data[0].credit_day,
          user_member_phone: result.data.data[0].phone,
          user_member: result.data.data[0],
          user_member_id: result.data.data[0].user_id,
          user_member_address: result.data.data[0].address,
          user_member_email: result.data.data[0].email,
          user_member_line: result.data.data[0].line_id,
        })
      }
    }
  }
  GetBrandAll = async () => {
    let result = await GET(tokens, "v1/product-mgt/brand/all", null);
    if (result && result.status) {
      this.setState({ data_brand: result.data })
    }
  }
  GetSupplier = async () => {
    let result = await GET(tokens, "v1/user-member/supplier/all/supplier", null);
    if (result && result.status) {
      this.setState({ data_supplier: result.data })
    }
  }
  GetStockAll = async (id) => {
    this.setState({ loading: true })
    let result = await GET(tokens, "v1/pos-mgt/order/stock-location-all/" + id, null);
    if (result && result.status) {
      this.setState({ data_stock: result.data, loading: false, stock_location_id: "", modal_select_stock: true })
    }
  }
  GetRetryDetail = async (id, type) => {
    let result = await GET(tokens, "v1/pos-mgt/order/detail/" + id, null);
    if (result && result.status) {
      let data = result.data.data
      console.log(data);
      let order_list_data = data.order_list_data
      for (let order of order_list_data) {
        order.product_main_id = order.product_id
        this.state.data_product.data.find((e, index) => {
          if (e.product_id === order.product_id) {
            order.product_main = e
            order.stock_qty = e.stock_qty
            e.qty = order.qty
          }
        })
        for (let i = 0; i < order.technician_list.length; i++) {
          this.state.data_user.find((e) => {
            if (e.user_id === order.technician_list[i]) {
              order.technician_list[i] = e
            }
          })
        }
      }
      this.setState({
        calculate_type: "เงินสด",
        page: 1,
        loading: false,
        date: new Date(),
        brand_id: "all",
        data_search: "",
        user_member_name: data.member_data.user_type === "general" ? data.member_data.full_name : data.member_data.company_name,
        user_member_code: data.member_data.user_code,
        user_member_credit_day: data.member_data.credit_day,
        user_member_address: data.member_data.address,
        user_member_phone: data.member_data.phone,
        user_member_email: data.member_data.email ? data.member_data.email : null,
        user_member_line: data.member_data.line_id,
        user_member: data.member_data,
        user_member_tax_invoice: data.member_data.tax_invoice,
        address: data.member_data.address,
        send_payment: 0,
        status_price: 1,
        // ALL VALUE 
        category_name: data.category_name,
        user_member_id: data.member_data.user_id,
        machine_number: this.state.machine_number,
        count_order: data.count_order,
        delivery_address: data.delivery_address,
        total_price: data.total_price,
        delivery_price: data.delivery_price,
        vat: data.vat,
        discount: data.discount,
        deposit: data.deposit,
        remark: "",
        input_remark: "",
        total_price_final: data.total_price_final,
        total_deposit: 0.0,
        status_order_main: 1,
        payment_type_layer_1: 0,
        payment_detail: {
          credit_card_type: "",
          bank_name: "",
          card_number: "",
          slip_number: "",
          remark_payment_s2: "",
          remark_payment_s3: "",
          full_name_transfer: "",
          datetime_transfer: "",
          reference_number: "",
          remark_payment_s4: "",
          remark_payment_s5: "",
          total_pay: data.order_list_data[0].total_pay,
          installment: "",
        },
        repair_detail: {
          customer_name: "",
          customer_address: "",
          customer_phone: "",
          date_time_repair: null,
          remark_repair: "",
        },
        order_list: order_list_data ? order_list_data : [],
        tax_order: {},
      })
      if (type === "edit") {
        this.setState({
          edit_status: true,
          order_main_id: data.order_main_id,
          order_main_number: data.order_main_number
        })
        let technician_list = [];
        for (let d of data.order_list_data) {
          if (d.product_main.product_type === "technician") {
            technician_list = d.technician_list;
          }
        }
        this.setState({ technician_list: technician_list })
      }
      setTimeout(() => { this.TotalPrice(); }, 10);
      this.setState({ loading: false })
    }
  }
  CreateMember = async () => {
    this.setState({ loading: true })
    let body = {
      user_type: this.state.user_type,
      credit_day: Number(this.state.credit_day),
      full_name: this.state.full_name,
      company_name: this.state.company_name,
      tax_invoice: this.state.tax_invoice,
      address: this.state.address_MEMBER,
      phone: this.state.phone,
      email: this.state.email,
      line_id: "",
    }
    let result = await POST(tokens, "v1/user-member/create", body);
    if (result && result.status) {
      alert("success", "เพิ่มสมาชิกเรียบร้อยแล้ว", "สมาชิก ' " + this.state.full_name + " ' ถูกเพิ่มในระบบแล้ว")
      this.setState({ loading: false, page: 1, modal_add: false })
      this.clear_form()
      this.GetMemberAll()
    }
  }
  TotalPrice = () => {
    let summary = total(this.state.order_list, "total_price") - Number(this.state.discount) + Number(this.state.delivery_price);

    this.setState({
      vat: (summary * 7) / 107,
      total_price: total(this.state.order_list, "total_price"),
      total_price_final: summary,
    });

    if (this.state.order_list.length === 0) {
      this.setState({
        count_order: 0,
        total_price: 0.0,
        delivery_price: 0.0,
        delivery_address: "",
        vat: 0.0,
        discount: 0.0,
        deposit: 0.0,
        total_price_final: 0.0,
        remark: "",
        input_remark: "",
      })
    } else if (this.state.order_list.filter(e => { return e.product_main.product_type === "technician" }).length === 0) {
      this.setState({ deposit: 0.0 })
    }
  }
  Create = async () => {
    this.setState({ loading: true })
    let order_list = []

    if (this.state.order_list.filter(e => { return e.product_main.product_type === "technician" }).length === 0) {
      if ((this.state.calculate_type === "เงินโอน" || this.state.calculate_type === "เงินสด") && this.state.status_order_main !== 4 && this.state.status_order_main !== 1) {
        if (Number(this.state.price) === 0) {
          alert("info", "แจ้งเตือน", "กรุณากรอกจำนวนเงิน")
          this.setState({ loading: false })
          return
        }
        if (Number(this.state.price) >= this.state.total_price_final) {
          let general = Number(
            Number(this.state.price) - this.state.total_price_final
          )
          this.setState({ general: general })
        } else {
          this.setState({ loading: false })
          this.setState({ general: 0.0, price: Number(this.state.total_price_final) })
          this.alert("info", "แจ้งเตือน", "กรุณาชำระเงินเต็มจำนวน")
          return
        }
      }
    } else {
      if (this.state.status_order_main !== 4 && this.state.status_order_main !== 1) {
        if (Number(this.state.price) === 0 && this.state.total_price_final !== 0) {
          this.alert("info", "แจ้งเตือน", "กรุณากรอกจำนวนเงิน")
          this.setState({ loading: false })
          return
        }
        if (Number(this.state.deposit) === 0) {
          if (Number(this.state.price) >= this.state.total_price_final) {
            let general = Number(this.state.price - this.state.total_price_final)
            this.setState({ general: general })
          } else {
            this.alert("info", "แจ้งเตือน", " กรุณาชำระเงินเต็มจำนวน")
            this.setState({ loading: false, price: this.state.total_price_final.toString() })
            return
          }
        } else {
          if (Number(this.state.price) < Number(this.state.deposit)) {
            this.alert("info", "แจ้งเตือน", "กรุณากรอกจำนวนเงินมากกว่าค่ามัดจำ")
            this.setState({ price: this.state.deposit.toString(), loading: false })
            return
          } else {
            let payment_detail = this.state.payment_detail
            payment_detail.total_pay = this.state.deposit
            this.setState({ status_order_main: 5, payment_detail: payment_detail })
            let general = this.state.deposit
            this.setState({ general: general, total_deposit: Number(this.state.total_price_final - this.state.deposit) })
          }
        }
      }
    }


    let count = 0
    for (let o of this.state.order_list) {
      let technician_list = []
      for (let t of o.technician_list) {
        technician_list.push(t.user_id)
      }
      count += o.qty
      order_list.push({
        product_main_id: o.product_main_id ? o.product_main_id : "",
        stock_location_id: o.stock_location_id ? o.stock_location_id : "",
        price_by_item: o.price_by_item ? o.price_by_item : 0.0,
        qty: o.qty ? o.qty : 0,
        total_price: o.total_price ? o.total_price : 0.0,
        technician_list: technician_list,
        status_price: o.status_price ? o.status_price : 1,
      })
    }
    this.setState({ count_qty_order_list: count })


    let body = {
      category_name: this.state.category_name === "all" ? "air" : this.state.category_name,
      user_member_id: this.state.user_member_id,
      order_main_number: this.state.order_main_number,
      machine_number: this.state.machine_number,
      count_order: this.state.count_order,
      total_price: this.state.total_price,
      delivery_price: this.state.delivery_price,
      delivery_address: this.state.delivery_address,
      vat: Number(this.state.vat.toFixed(2)),
      discount: this.state.discount,
      deposit: this.state.deposit,
      total_price_final: this.state.total_price_final,
      status_order_main: this.state.status_order_main,
      payment_type_layer_1: this.state.payment_type_layer_1,
      payment_detail: this.state.payment_detail,
      repair_detail: this.state.repair_detail,
      remark: this.state.remark,
      order_list: order_list,
    }


    setTimeout(async () => {
      let result = await POST(tokens, "v1/pos-mgt/order/create", body);
      if (result && result.status) {
        if (this.state.data_hole) {
          localStorage.removeItem("POS")
        }
        this.setState({ tax_order: result.data.data })

        let result_get = await GET(tokens, "v1/pos-mgt/order/detail/" + this.state.tax_order.order_main_id, null);
        if (result_get && result_get.status) {
          let data = result_get.data.data
          this.setState({ page: 1, modal_payment: false, loading: false })

          switch (data.status_order_main) {
            case 1:
              if (this.state.calculate_type === "เงินเชื่อ") {
                // this.alert_url("success", "บันทึกข้อมูลเรียบร้อยแล้ว", "คุณสามารถออกบิลและชำระเงินย้อนหลังได้ที่รายการธุรกรรมหรือค้างจ่าย", "/main")
                this.setState({
                  modal_print: true,
                  message: {
                    title: "บันทึกข้อมูลเรียบร้อยแล้ว",
                    description: "คุณสามารถออกบิลและชำระเงินย้อนหลังได้ที่รายการธุรกรรมหรือค้างจ่าย",
                    tax_order: this.state.tax_order,
                    detail: data,
                    general: this.state.general,
                    calculate_type: this.state.calculate_type,
                    payment_detail: this.state.payment_detail,
                    screen: "main"
                  },
                })
              } else if (this.state.calculate_type === "เป็นงวด") {
                // this.alert_url("success", "บันทึกข้อมูลเรียบร้อยแล้ว", "คุณสามารถออกบิลและชำระเงินย้อนหลังได้ที่รายการธุรกรรมหรือค้างจ่าย", "/main")
                this.setState({
                  modal_print: true,
                  message: {
                    title: "บันทึกข้อมูลเรียบร้อยแล้ว",
                    description: "คุณสามารถออกบิลและชำระเงินย้อนหลังได้ที่รายการธุรกรรมหรือค้างจ่าย",
                    tax_order: this.state.tax_order,
                    detail: data,
                    general: this.state.general,
                    calculate_type: this.state.calculate_type,
                    payment_detail: this.state.payment_detail,
                    screen: "main"
                  },
                })
              } else {
                // this.alert_url("success", "บันทึกเพื่อรอชำระแล้ว", "คุณสามารถออกบิลและชำระเงินย้อนหลังได้ที่รายการธุรกรรมหรือค้างจ่าย", "/main")
                this.setState({
                  modal_print: true,
                  message: {
                    title: "บันทึกเพื่อรอชำระแล้ว",
                    description: "คุณสามารถออกบิลและชำระเงินย้อนหลังได้ที่รายการธุรกรรมหรือค้างจ่าย",
                    tax_order: this.state.tax_order,
                    detail: data,
                    general: this.state.general,
                    calculate_type: this.state.calculate_type,
                    payment_detail: this.state.payment_detail,
                    screen: "main"
                  },

                })
              }
              break
            case 2:
              if (this.state.calculate_type === "เงินสด") {
                // this.alert_url("success", "ชำระเงินแล้ว", "คุณสามารถออกบิลและชำระเงินย้อนหลังได้ที่รายการธุรกรรมหรือค้างจ่าย", "/main")
                this.setState({
                  modal_print: true,
                  message: {
                    title: "ชำระเงินแล้ว",
                    description: "คุณสามารถออกบิลและชำระเงินย้อนหลังได้ที่รายการธุรกรรมหรือค้างจ่าย",
                    tax_order: this.state.tax_order,
                    detail: data,
                    general: this.state.general,
                    calculate_type: this.state.calculate_type,
                    payment_detail: this.state.payment_detail,
                    screen: "main"
                  },
                })
              } else if (this.state.calculate_type === "บัตรเครดิต") {
                // this.alert_url("success", "ชำระเงินแล้ว", "คุณสามารถออกบิลและชำระเงินย้อนหลังได้ที่รายการธุรกรรมหรือค้างจ่าย", "/main")
                this.setState({
                  modal_print: true,
                  message: {
                    title: "ชำระเงินแล้ว",
                    description: "คุณสามารถออกบิลและชำระเงินย้อนหลังได้ที่รายการธุรกรรมหรือค้างจ่าย",
                    tax_order: this.state.tax_order,
                    detail: data,
                    general: this.state.general,
                    calculate_type: this.state.calculate_type,
                    payment_detail: this.state.payment_detail,
                    screen: "main"
                  },
                })
              } else if (this.state.calculate_type === "เงินโอน") {
                // this.alert_url("success", "ชำระเงินแล้ว", "คุณสามารถออกบิลและชำระเงินย้อนหลังได้ที่รายการธุรกรรมหรือค้างจ่าย", "/main")
                this.setState({
                  modal_print: true,
                  message: {
                    title: "ชำระเงินแล้ว",
                    description: "คุณสามารถออกบิลและชำระเงินย้อนหลังได้ที่รายการธุรกรรมหรือค้างจ่าย",
                    tax_order: this.state.tax_order,
                    detail: data,
                    general: this.state.general,
                    calculate_type: this.state.calculate_type,
                    payment_detail: this.state.payment_detail,
                    screen: "main"
                  },
                })
              }
              break
            case 3:
              this.alert_url(
                "success",
                "แจ้งเตือน",
                "ยกเลิกรายการสำเร็จ",
                "/main"
              )
              break
            case 4:
              this.alert_url(
                "success",
                "พักออเดอร์เรียบร้อยแล้ว",
                "คุณสามารถกลับมาจัดการออเดอร์ที่พักไว้ ในเมนูพักออเดอร์",
                "/main"
              )
              break
            case 5:
              // this.alert_url("success", "ชำระค่ามัดจำ", "คุณสามารถออกบิลและชำระเงินย้อนหลังได้ที่รายการธุรกรรมหรือค้างจ่าย", "/main")
              this.setState({
                modal_print: true,
                message: {
                  title: "ชำระค่ามัดจำ",
                  description: "คุณสามารถออกบิลและชำระเงินย้อนหลังได้ที่รายการธุรกรรมหรือค้างจ่าย",
                  tax_order: this.state.tax_order,
                  detail: data,
                  general: this.state.general,
                  calculate_type: this.state.calculate_type,
                  payment_detail: this.state.payment_detail,
                  screen: "main"
                },
              })

              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              this.setState({ loading: false })
              break
          }

        }
      } else if (result === "getOM") {
        this.setState({ order_main_number: "", new_OM: true })
        this.GetOMMumber()
      }
      this.setState({ loading: false })
    }, 20);

  }
  Edit = () => {
    //มาจากการพักออเดอร์
    this.setState({ loading: true })
    let order_list = []
    if (this.state.order_list.filter(e => { return e.product_main.product_type === "technician" }).length === 0) {
      if ((this.state.calculate_type === "เงินโอน" || this.state.calculate_type === "เงินสด") && this.state.status_order_main !== 4 && this.state.status_order_main !== 1) {
        if (Number(this.state.price) === 0) {
          alert("info", "แจ้งเตือน", "กรุณากรอกจำนวนเงิน")
          this.setState({ loading: false })
          return
        }
        if (Number(this.state.price) >= this.state.total_price_final) {
          let general = Number(
            Number(this.state.price) - this.state.total_price_final
          )
          this.setState({ general: general })
        } else {
          this.setState({ loading: false })
          this.setState({ general: 0.0, price: Number(this.state.total_price_final) })
          this.alert("info", "แจ้งเตือน", "กรุณาชำระเงินเต็มจำนวน")
          return
        }
      }
    } else {
      if (this.state.status_order_main !== 4 && this.state.status_order_main !== 1) {
        if (Number(this.state.price) === 0 && this.state.total_price_final !== 0) {
          this.alert("info", "แจ้งเตือน", "กรุณากรอกจำนวนเงิน")
          this.setState({ loading: false })
          return
        }
        if (Number(this.state.deposit) === 0) {
          if (Number(this.state.price) >= this.state.total_price_final) {
            let general = Number(this.state.price - this.state.total_price_final)
            this.setState({ general: general })
          } else {
            this.alert("info", "แจ้งเตือน", " กรุณาชำระเงินเต็มจำนวน")
            this.setState({ loading: false, price: this.state.total_price_final.toString() })
            return
          }
        } else {
          if (Number(this.state.price) < this.state.deposit) {
            this.alert("info", "แจ้งเตือน", "กรุณากรอกจำนวนเงินมากกว่าค่ามัดจำ")
            this.setState({ price: this.state.deposit.toString(), loading: false })
            return
          } else {
            let payment_detail = this.state.payment_detail
            payment_detail.total_pay = this.state.deposit
            this.setState({ status_order_main: 5, payment_detail: payment_detail })
            let general = this.state.deposit
            this.setState({ general: general, total_deposit: Number(this.state.total_price_final - this.state.deposit) })
          }
        }
      }
    }


    let count = 0
    for (let o of this.state.order_list) {
      let technician_list = []
      for (let t of o.technician_list) {
        technician_list.push(t.user_id)
      }
      count += o.qty
      order_list.push({
        product_main_id: o.product_main_id ? o.product_main_id : "",
        stock_location_id: o.stock_location_id ? o.stock_location_id : "",
        price_by_item: o.price_by_item ? o.price_by_item : 0.0,
        qty: o.qty ? o.qty : 0,
        total_price: o.total_price ? o.total_price : 0.0,
        technician_list: technician_list,
        status_price: o.status_price ? o.status_price : 1,
      })
    }
    this.setState({ count_qty_order_list: count })

    let body = {
      order_main_id: this.state.order_main_id,
      category_name: this.state.category_name === "all" ? "air" : this.state.category_name,
      user_member_id: this.state.user_member_id,
      order_main_number: this.state.order_main_number,
      machine_number: this.state.machine_number,
      count_order: this.state.count_order,
      total_price: this.state.total_price,
      delivery_price: this.state.delivery_price,
      delivery_address: this.state.delivery_address,
      vat: Number(this.state.vat.toFixed(2)),
      discount: this.state.discount,
      deposit: this.state.deposit,
      total_price_final: this.state.total_price_final,
      status_order_main: this.state.status_order_main,
      payment_type_layer_1: this.state.payment_type_layer_1,
      payment_detail: this.state.payment_detail,
      repair_detail: this.state.repair_detail,
      remark: this.state.remark,
      order_list: order_list,
    }
    setTimeout(async () => {
      let result = await PUT(tokens, "v1/pos-mgt/order/update", body);
      if (result && result.status) {

        if (this.state.data_hole) {
          localStorage.removeItem("POS")
        }
        this.setState({ tax_order: result.data.data })

        let result_get = await GET(tokens, "v1/pos-mgt/order/detail/" + this.state.tax_order.order_main_id, null);
        if (result_get && result_get.status) {
          let data = result_get.data.data
          this.setState({ page: 1, modal_payment: false, loading: false })

          switch (data.status_order_main) {
            case 1:
              if (this.state.calculate_type === "เงินเชื่อ") {
                // this.alert_url("success", "บันทึกข้อมูลเรียบร้อยแล้ว", "คุณสามารถออกบิลและชำระเงินย้อนหลังได้ที่รายการธุรกรรมหรือค้างจ่าย", "/main")
                this.setState({
                  modal_print: true,
                  message: {
                    title: "บันทึกข้อมูลเรียบร้อยแล้ว",
                    description: "คุณสามารถออกบิลและชำระเงินย้อนหลังได้ที่รายการธุรกรรมหรือค้างจ่าย",
                    tax_order: this.state.tax_order,
                    detail: data,
                    general: this.state.general,
                    calculate_type: this.state.calculate_type,
                    payment_detail: this.state.payment_detail,
                    screen: "main"
                  },
                })
              } else if (this.state.calculate_type === "เป็นงวด") {
                // this.alert_url("success", "บันทึกข้อมูลเรียบร้อยแล้ว", "คุณสามารถออกบิลและชำระเงินย้อนหลังได้ที่รายการธุรกรรมหรือค้างจ่าย", "/main")
                this.setState({
                  modal_print: true,
                  message: {
                    title: "บันทึกข้อมูลเรียบร้อยแล้ว",
                    description: "คุณสามารถออกบิลและชำระเงินย้อนหลังได้ที่รายการธุรกรรมหรือค้างจ่าย",
                    tax_order: this.state.tax_order,
                    detail: data,
                    general: this.state.general,
                    calculate_type: this.state.calculate_type,
                    payment_detail: this.state.payment_detail,
                    screen: "main"
                  },
                })
              } else {
                // this.alert_url("success", "บันทึกเพื่อรอชำระแล้ว", "คุณสามารถออกบิลและชำระเงินย้อนหลังได้ที่รายการธุรกรรมหรือค้างจ่าย", "/main")
                this.setState({
                  modal_print: true,
                  message: {
                    title: "บันทึกเพื่อรอชำระแล้ว",
                    description: "คุณสามารถออกบิลและชำระเงินย้อนหลังได้ที่รายการธุรกรรมหรือค้างจ่าย",
                    tax_order: this.state.tax_order,
                    detail: data,
                    general: this.state.general,
                    calculate_type: this.state.calculate_type,
                    payment_detail: this.state.payment_detail,
                    screen: "main"
                  },

                })
              }
              break
            case 2:
              if (this.state.calculate_type === "เงินสด") {
                // this.alert_url("success", "ชำระเงินแล้ว", "คุณสามารถออกบิลและชำระเงินย้อนหลังได้ที่รายการธุรกรรมหรือค้างจ่าย", "/main")
                this.setState({
                  modal_print: true,
                  message: {
                    title: "ชำระเงินแล้ว",
                    description: "คุณสามารถออกบิลและชำระเงินย้อนหลังได้ที่รายการธุรกรรมหรือค้างจ่าย",
                    tax_order: this.state.tax_order,
                    detail: data,
                    general: this.state.general,
                    calculate_type: this.state.calculate_type,
                    payment_detail: this.state.payment_detail,
                    screen: "main"
                  },
                })
              } else if (this.state.calculate_type === "บัตรเครดิต") {

                // this.alert_url("success", "ชำระเงินแล้ว", "คุณสามารถออกบิลและชำระเงินย้อนหลังได้ที่รายการธุรกรรมหรือค้างจ่าย", "/main")
                this.setState({
                  modal_print: true,
                  message: {
                    title: "ชำระเงินแล้ว",
                    description: "คุณสามารถออกบิลและชำระเงินย้อนหลังได้ที่รายการธุรกรรมหรือค้างจ่าย",
                    tax_order: this.state.tax_order,
                    detail: data,
                    general: this.state.general,
                    calculate_type: this.state.calculate_type,
                    payment_detail: this.state.payment_detail,
                    screen: "main"
                  },
                })
              } else if (this.state.calculate_type === "เงินโอน") {

                // this.alert_url("success", "ชำระเงินแล้ว", "คุณสามารถออกบิลและชำระเงินย้อนหลังได้ที่รายการธุรกรรมหรือค้างจ่าย", "/main")
                this.setState({
                  modal_print: true,
                  message: {
                    title: "ชำระเงินแล้ว",
                    description: "คุณสามารถออกบิลและชำระเงินย้อนหลังได้ที่รายการธุรกรรมหรือค้างจ่าย",
                    tax_order: this.state.tax_order,
                    detail: data,
                    general: this.state.general,
                    calculate_type: this.state.calculate_type,
                    payment_detail: this.state.payment_detail,
                    screen: "main"
                  },
                })
              }
              break
            case 3:
              this.alert_url(
                "success",
                "แจ้งเตือน",
                "ยกเลิกรายการสำเร็จ",
                "/main"
              )
              break
            case 4:
              this.alert_url(
                "success",
                "พักออเดอร์เรียบร้อยแล้ว",
                "คุณสามารถกลับมาจัดการออเดอร์ที่พักไว้ ในเมนูพักออเดอร์",
                "/main"
              )
              break
            case 5:

              // this.alert_url("success", "ชำระค่ามัดจำ", "คุณสามารถออกบิลและชำระเงินย้อนหลังได้ที่รายการธุรกรรมหรือค้างจ่าย", "/main")
              this.setState({
                modal_print: true,
                message: {
                  title: "ชำระค่ามัดจำ",
                  description: "คุณสามารถออกบิลและชำระเงินย้อนหลังได้ที่รายการธุรกรรมหรือค้างจ่าย",
                  tax_order: this.state.tax_order,
                  detail: data,
                  general: this.state.general,
                  calculate_type: this.state.calculate_type,
                  payment_detail: this.state.payment_detail,
                  screen: "main"
                },
              })

              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              this.setState({ loading: false })
              break
          }

        }
      } else if (result === "getOM") {
        this.setState({ order_main_number: "", new_OM: true })
        this.GetOMMumber()
      }
      this.setState({ loading: false })
    }, 20);



  }
  alert(icon, title, detail) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    })
  }
  alert_url(icon, title, detail, url) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    }).then(() => {
      window.location.href = url
    })
  }
  date = (date) => {
    let datetime = ""
    try {
      datetime = date.getDate() + " " + array_month[date.getMonth()] + " " + (date.getFullYear() + 543);
    } catch (e) {
      datetime = date.split("T")[0].split("-")[2] + " " + array_month[Number(date.split("T")[0].split("-")[1]) - 1] + " " + (Number(date.split("T")[0].split("-")[0]) + 543)
    }
    return datetime
  }
  payment_clear = () => {
    let data = this.state.payment_detail
    data.credit_card_type = ""
    data.bank_name = ""
    data.card_number = ""
    data.slip_number = ""
    data.remark_payment_s2 = ""
    data.remark_payment_s3 = ""
    data.full_name_transfer = ""
    data.datetime_transfer = ""
    data.reference_number = ""
    data.remark_payment_s4 = ""
    data.remark_payment_s5 = ""
    data.installment = ""
    this.setState({
      payment_detail: data,
    })
  }
  validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  }
  validatePhone = (phone) => {
    return phone.match(/^^[0][0-9]*$/)
  }
  calculate = (number) => {
    const input = document.getElementById('price');
    input.focus();
    if (number === "delete") {
      const currentPosition = input.selectionStart;
      const textBeforeCursor = input.value.slice(0, currentPosition);
      const textAfterCursor = input.value.slice(currentPosition);
      input.value = textBeforeCursor.slice(0, -1) + textAfterCursor;
      input.selectionStart = currentPosition - 1;
      input.selectionEnd = currentPosition - 1;
      if (/^\d*\.?\d{0,2}$/.test(input.value) && Number(input.value) < 999999999) {
        this.setState({ price: input.value });
      } else {
        this.setState({ price: this.state.price });
      }
    } else if (number === 100) {
      if (Number(this.state.price) + 100 < 999999999) {
        this.setState({ price: Number(this.state.price) + 100 });
      }
    } else if (number === 500) {
      if (Number(this.state.price) + 500 < 999999999) {
        this.setState({ price: Number(this.state.price) + 500 });
      }
    } else if (number === 1000) {
      if (Number(this.state.price) + 1000 < 999999999) {
        this.setState({ price: Number(this.state.price) + 1000 });
      }
    } else if (number === "full") {
      this.setState({ price: this.state.total_price_final.toString(), });
    } else {
      input.setRangeText(number, input.selectionStart, input.selectionEnd, 'end');
      if (/^\d*\.?\d{0,2}$/.test(input.value) && Number(input.value) < 999999999) {
        this.setState({ price: input.value });
      } else {
        this.setState({ price: this.state.price });
      }
    }
  }
  clear_form() {
    this.setState({
      loading: false,
      modal_detail: false,
      modal_detail_print: false,
      company_name: "",
      user_type: "",
      credit_day: 0,
      full_name: "",
      company_id: "",
      tax_invoice: "",
      address_MEMBER: "",
      phone: "",
      email: "",
      input_remark: "",
      status_price: 1,
      installment: ""
    })
  }
  clear_form_data_check() {
    this.setState({
      checked_other_address: false,
      check_print: false,
      repair_detail: {
        customer_name: "",
        customer_address: "",
        customer_phone: "",
        date_time_repair: null,
        remark_repair: "",
      },
    })

  }
  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (<div className="spinner-container"> <div className="loading-spinner"></div> </div>)}
        <div className="w-100">
          <BreadcrumbComponent page1="หน้าหลัก" page2="รายการขาย" />
          <div style={{ height: 60 }}></div>
          <div className="row mt-4 mx-0">
            {/* ซ้าย */}
            <div className="col-12 col-sm-12 col-md-6 col-lg-8 col-xl-8 mb-4">
              {/* ช่องที่ 1 รายละเอียดบุคคล */}
              <div className="card p-3 mb-4">
                <div className="row">
                  {/* เลขที่ลูกค้า */}
                  <div className="col text-center" style={{ minWidth: 160 }} >
                    <div className="w-100 d-flex align-items-cennter">
                      <div className="w-100">
                        <label className="text-secondary">เลขที่ลูกค้า</label>
                        <br />
                        <div
                          onClick={() => {
                            let data = this.state.user_member

                            data.full_name = this.state.user_member_name
                            data.user_code = this.state.user_member_code
                            data.credit_day = this.state.user_member_credit_day
                            data.phone = this.state.user_member_phone
                            data.user_id = this.state.user_member_id
                            data.address = this.state.user_member_address
                            data.email = this.state.user_member_email ? this.state.user_member_email : null
                            data.line_id = this.state.user_member_line
                            data.tax_invoice = this.state.user_member_tax_invoice

                            this.setState({
                              user_member: data,
                              modal_search_member: true,
                            })
                          }}
                        >
                          <label className="pointer">
                            {this.state.user_member_name ? this.state.user_member_code.slice(-5) + "," + this.state.user_member_name : "+ เพิ่มลูกค้าใหม่"}
                          </label>
                          <label className="icon ml-1 pointer"> {"\uf304"} </label>
                        </div>
                      </div>
                      <div className="hpx-32 border-right ml-3 d-none d-sm-block my-auto"></div>
                    </div>
                    <hr className="d-block d-sm-none d-lg-none" />
                  </div>
                  <div className="col-12 d-none d-sm-block d-lg-none">
                    <hr />
                  </div>
                  {/* เลขที่รายการ */}
                  <div className="col text-center" style={{ minWidth: 160 }} >
                    <div className="w-100 d-flex align-items-cennter">
                      <div className="w-100">
                        <label className="text-secondary">เลขที่รายการ</label>
                        <br />
                        <label>{this.state.order_main_number}</label>
                      </div>
                      <div className="hpx-32 border-right ml-3 d-none d-lg-block my-auto"></div>
                    </div>
                    <hr className="d-block d-sm-none d-lg-none" />
                  </div>
                  <div className="col-12 d-none d-sm-block d-lg-none">
                    <hr />
                  </div>
                  {/* เลขที่เครื่อง POS */}
                  <div className="col text-center" style={{ minWidth: 160 }} >
                    <div className="w-100 d-flex align-items-cennter">
                      <div className="w-100">
                        <label className="text-secondary"> เลขที่เครื่อง POS </label>
                        <br />
                        <label> {user_profile && user_profile.user_code} </label>
                      </div>
                      <div className="hpx-32 border-right ml-3 d-none d-sm-block d-md-block d-lg-none d-xl-block my-auto"></div>
                    </div>
                    <hr className="d-block d-sm-none d-lg-none" />
                  </div>
                  <div className="col-12 d-none d-lg-block d-xl-none">
                    <hr />
                  </div>
                  {/* วัน เวลา ทำรายการ */}
                  <div className="col text-center" style={{ minWidth: 160 }} >
                    <div className="w-100 d-flex align-items-cennter">
                      <div className="w-100">
                        <label className="text-secondary"> วัน เวลา ทำรายการ </label>
                        <br />
                        <label>{this.date(this.state.date)}</label>
                      </div>
                      <div className="hpx-32 border-right ml-3 d-none d-lg-block my-auto"></div>
                    </div>
                    <hr className="d-block d-sm-none d-lg-none" />
                  </div>
                  <div className="col-12 d-none d-sm-block d-lg-none">
                    <hr />
                  </div>
                  {/* พนักงาน */}
                  <div className="col text-center" style={{ minWidth: 160 }} >
                    <div className="w-100 d-flex align-items-cennter">
                      <div className="w-100">
                        <label className="text-secondary">พนักงาน</label>
                        <br />
                        <label> {user_profile && user_profile.full_name} </label>
                      </div>
                      <div className="hpx-32 border-right ml-3 d-none d-sm-block my-auto"></div>
                    </div>
                    <hr className="d-block d-sm-none d-lg-none" />
                  </div>
                  <div className="col-12 d-none d-sm-block d-lg-none">
                    <hr />
                  </div>
                  {/* พักออเดอร์ */}
                  <div className="col text-center d-flex align-items-center" style={{ minWidth: 197 }} >
                    <button
                      className={this.state.pause_status ? "btn btn-primary w-100 position-relative" : "btn btn-outline-primary w-100 position-relative"}
                      onClick={() => {
                        if (this.state.pause_status) {
                          this.setState({ modal_PauseDate: true })
                        } else {
                          if (this.state.order_list.length === 0) {
                            alert("info", "แจ้งเตือน", "ไม่มีออเดอร์")
                          } else {
                            this.setState({
                              modal_pause_order: true,
                              status_order_main: 4,
                              payment_type_layer_1: 0
                            })
                          }
                        }
                      }}
                    >
                      <label className="icon mr-2">{"\uf017"}</label>พักออเดอร์
                      {this.state.pause_status && (
                        <div className="run-number bg-danger position-absolute" style={{ top: -10, right: -10 }}>
                          <label className="icon">{"\uf0f3"}</label>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              </div>
              {/* ช่องที่ 2 ตาราง */}
              <div className="card p-3 mb-4">
                {/* <button className="btn btn-primary" onClick={() => { console.log(this.state.order_list) }}>aaaa</button> */}
                {this.state.order_list && this.state.order_list.length > 0 ? (
                  <div className="table-responsive">
                    <table className="table table-striped design-table-sell">
                      <thead>
                        <tr>
                          <th className="text-left" style={{ minWidth: 80 }}>ลำดับที่</th>
                          <th className="text-left" style={{ minWidth: 110 }}>ชื่อสินค้า</th>
                          <th className="text-left" style={{ minWidth: 100 }}>คลังสินค้า</th>
                          <th className="text-right" style={{ minWidth: 140 }}>ราคา/หน่วย</th>
                          <th className="text-center" style={{ minWidth: 145 }}>จำนวน</th>
                          <th className="text-right" style={{ minWidth: 125 }}>ราคารวมสุทธิ</th>
                          <th className="text-center">ลบ</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.order_list.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.product_main ? item.product_main.product_name : item.product_name ? item.product_name : ""}</td>
                            <td className="text-left">
                              {this.state.order_list && item.product_main && item.product_main.product_type !== "technician" && item.stock_location_name}
                            </td>
                            <td className="text-right">
                              <label
                                className="icon mr-2 text-secondary pointer"
                                onClick={() => {
                                  this.setState({
                                    modal_edit_price: true,
                                    index: index,
                                    price: item.price_by_item === 0 ? "0.00" : item.price_by_item.toString(),
                                    product_main: item.product_main,
                                    price_type: "แก้ไข",
                                  })

                                }}
                              >
                                {"\uf304"}
                              </label>
                              {baht(item.price_by_item)}
                            </td>
                            <td className="text-center">
                              {this.state.order_list && item.product_main && item.product_main.product_type === "technician" ?
                                <>
                                  <button
                                    className="btn btn-link"
                                    onMouseDown={() => {
                                      this.interval = setInterval(() => {
                                        if (item.qty > 1) {
                                          let data = this.state.order_list;
                                          if (data[index].qty >= 1) {
                                            data[index].qty -= 1;
                                            data[index].total_price = data[index].qty * data[index].price_by_item;
                                            this.TotalPrice();
                                            this.setState({ order_list: data });
                                          }
                                        } else {
                                          clearInterval(this.interval);
                                        }
                                      }, 300)
                                    }}
                                    onMouseUp={() => { clearInterval(this.interval); }}
                                    onClick={() => {
                                      if (item.qty > 1) {
                                        let data = this.state.order_list
                                        if (data[index].qty >= 1) {
                                          data[index].qty -= 1;
                                          data[index].total_price = data[index].qty * data[index].price_by_item;
                                          this.TotalPrice();
                                          this.setState({ order_list: data });
                                        }
                                      }
                                    }}
                                  >
                                    <label className="bg-secondary text-18 text-white pointer" style={{ width: 24, height: 24, borderRadius: 12 }}>
                                      -
                                    </label>
                                  </button>
                                  {item.qty}
                                  <button
                                    className="btn btn-link"
                                    onMouseDown={() => {
                                      this.interval = setInterval(() => {
                                        let data = this.state.order_list
                                        data[index].qty += 1;
                                        data[index].total_price = data[index].qty * data[index].price_by_item;
                                        this.TotalPrice();
                                        this.setState({ order_list: data });
                                      }, 300)
                                    }}
                                    onMouseUp={() => { clearInterval(this.interval); }}
                                    onClick={() => {
                                      let data = this.state.order_list
                                      data[index].qty += 1;
                                      data[index].total_price = data[index].qty * data[index].price_by_item;
                                      this.TotalPrice();
                                      this.setState({ order_list: data });
                                    }}
                                  >
                                    <label className="bg-secondary text-18 text-white pointer" style={{ width: 24, height: 24, borderRadius: 12 }}>
                                      +
                                    </label>
                                  </button>
                                </>
                                :
                                <>
                                  <button
                                    className="btn btn-link"
                                    onMouseDown={() => {
                                      this.interval = setInterval(() => {
                                        if (item.qty > 1) {
                                          let data = this.state.order_list;
                                          if (data[index].qty >= 1) {
                                            data[index].qty -= 1;
                                            data[index].total_price = data[index].qty * data[index].price_by_item;
                                            this.TotalPrice();
                                            this.setState({ order_list: data });
                                          }
                                        } else {
                                          clearInterval(this.interval);
                                        }
                                      }, 300)
                                    }}
                                    onMouseUp={() => { clearInterval(this.interval); }}
                                    onClick={() => {
                                      if (item.qty > 1) {
                                        let data = this.state.order_list
                                        if (data[index].qty >= 1) {
                                          data[index].qty -= 1;
                                          data[index].total_price = data[index].qty * data[index].price_by_item;
                                          this.TotalPrice();
                                          this.setState({ order_list: data });
                                        }
                                      }
                                    }}
                                  >
                                    <label className="bg-secondary text-18 text-white pointer" style={{ width: 24, height: 24, borderRadius: 12 }}>
                                      -
                                    </label>
                                  </button>
                                  {item.qty}
                                  <button
                                    className="btn btn-link"
                                    onMouseDown={() => {
                                      this.interval = setInterval(() => {
                                        let data = this.state.order_list
                                        if (data[index].qty < data[index].stock_qty) {
                                          data[index].qty += 1;
                                          data[index].total_price = data[index].qty * data[index].price_by_item;
                                          this.TotalPrice();
                                          this.setState({ order_list: data });
                                        } else {
                                          clearInterval(this.interval);
                                        }
                                      }, 300)
                                    }}
                                    onMouseUp={() => { clearInterval(this.interval); }}
                                    onClick={() => {
                                      let data = this.state.order_list
                                      if (data[index].qty < data[index].stock_qty) {
                                        data[index].qty += 1;
                                        data[index].total_price = data[index].qty * data[index].price_by_item;
                                        this.TotalPrice();
                                        this.setState({ order_list: data });
                                      }
                                    }}
                                  >
                                    <label className="bg-secondary text-18 text-white pointer" style={{ width: 24, height: 24, borderRadius: 12 }}>
                                      +
                                    </label>
                                  </button>
                                </>
                              }
                            </td>
                            <td className="text-right">{baht(item.total_price)}</td>
                            <td className="text-center">
                              <button
                                className="btn btn-link text-secondary"
                                onClick={() => {
                                  let data = this.state.order_list;
                                  data.splice(index, 10);
                                  this.setState({
                                    order_list: data,
                                    count_order: data.length,
                                  });
                                  setTimeout(() => {
                                    this.TotalPrice()
                                  }, 10)
                                }}
                              >
                                <label
                                  className="icon bg-danger text-14 text-white"
                                  style={{
                                    width: 24,
                                    height: 24,
                                    borderRadius: 12,
                                  }}
                                >
                                  {"\uf00d"}
                                </label>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="my-5 p-5 w-100 center">
                    <div>
                      <img
                        className="none-product mt-5 mx-5 mb-2"
                        alt="logo"
                        src={NONE}
                      ></img>
                      <div className="w-100">
                        <label className="w-100 text-center">รอการขาย</label>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* ช่องที่ 3 รายละเอียด */}
              <div className="card p-3 mb-4">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 border-right-md">
                    <div className="d-flex justify-content-between">
                      <label className="text-secondary">รายการ:</label>
                      <label className="text-dark">
                        {this.state.count_order}
                      </label>
                    </div>

                    <div className="d-flex justify-content-between">
                      <label className="wpx-120 text-secondary">
                        ที่อยู่จัดส่ง:
                      </label>
                      {this.state.delivery_address ? (
                        <label className="text-dark text-right">
                          <label
                            className="icon mr-2 text-primary pointer"
                            onClick={() => {

                              if (this.state.order_list.length === 0) {
                                alert("info", "แจ้งเตือน", "ไม่มีออเดอร์")
                                return
                              }
                              this.setState({
                                modal_address: true,
                                address: this.state.delivery_address,
                              })
                            }}
                          >
                            {"\uf304"}
                          </label>
                          <label
                            className="icon mr-2 text-danger pointer"
                            onClick={() => {
                              this.setState({
                                address: "",
                                delivery_address: "",
                                // send_payment: this.state.delivery_price,
                              })
                            }}
                          >
                            {"\uf00d"}
                          </label>
                          {this.state.delivery_address}
                        </label>
                      ) : (
                        <button
                          className="btn btn-outline-primary px-3 text-12"
                          style={{ height: 22, borderRadius: 14 }}
                          onClick={() => {
                            if (this.state.order_list.length === 0) {
                              alert("info", "แจ้งเตือน", "ไม่มีออเดอร์")
                              return
                            }
                            this.setState({
                              modal_address: true,
                              address: this.state.delivery_address,
                              // send_payment: this.state.delivery_price,
                            })
                          }}
                        >
                          + เพิ่มที่อยู่
                        </button>
                      )}
                      {/* <label>฿{this.state.delivery_price.toFixed(2)}</label> */}
                    </div>

                    <div className="d-flex justify-content-between ">
                      <label className="text-secondary">
                        รวมค่าสินค้า(รวมภาษี):
                      </label>
                      <label className="text-dark">
                        {baht(this.state.total_price)}
                      </label>
                    </div>

                    <hr></hr>
                    <div
                      className={
                        this.state.remark
                          ? "d-flex justify-content-between"
                          : "d-flex justify-content-center"
                      }
                    >
                      <label className="text-secondary">*หมายเหตุ:</label>
                      {this.state.remark ? (
                        <label className="text-dark text-right w-150px">
                          <label
                            className="icon mr-2 text-primary pointer "
                            onClick={() => {
                              this.setState({ modal_remark: true })
                            }}
                          >
                            {"\uf304"}
                          </label>
                          {this.state.remark}
                        </label>
                      ) : (
                        <label className="text-dark">
                          <label
                            className="icon ml-2 text-primary pointer"
                            onClick={() => {
                              if (this.state.order_list.length === 0) {
                                alert("info", "แจ้งเตือน", "ไม่มีออเดอร์")
                                return
                              }
                              this.setState({ modal_remark: true })
                            }}
                          >
                            {"\uf304"}
                          </label>{" "}
                          {this.state.remark}
                        </label>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                    <div className="d-flex justify-content-between">
                      <label className={this.state.order_list.filter(e => { return e.product_main.product_type === "technician" }).length === 0 ? "text-msg" : "text-secondary"}>ค่ามัดจำ:</label>
                      <label className={this.state.order_list.filter(e => { return e.product_main.product_type === "technician" }).length === 0 ? "text-msg" : "text-dark"}>
                        <label
                          disabled
                          className={this.state.order_list.filter(e => { return e.product_main.product_type === "technician" }).length === 0 ? "icon ml-2 text-msg pointer" : "icon ml-2 text-primary pointer"}
                          onClick={() => {
                            if (this.state.order_list.length === 0) {
                              alert("info", "แจ้งเตือน", "ไม่มีออเดอร์")
                              return
                            }

                            if (this.state.order_list.filter(e => { return e.product_main.product_type === "technician" }).length === 0) {
                              this.setState({ deposit: 0.0 })
                            } else {
                              this.setState({ txt_type: "ค่ามัดจำ", modal_type: true, price: this.state.deposit.toString() })
                            }
                          }}
                        >
                          {"\uf304"}
                        </label>{" "}
                        {baht(this.state.deposit)}
                      </label>
                    </div>
                    <div className="d-flex justify-content-between">
                      <label className="text-secondary">ส่วนลด:</label>
                      <label className="text-danger">
                        <label
                          className="icon ml-2 text-primary pointer"
                          onClick={() => {
                            if (this.state.order_list.length === 0) {
                              alert("info", "แจ้งเตือน", "ไม่มีออเดอร์")
                              return
                            }
                            this.setState({ txt_type: "ส่วนลด", modal_type: true, price: this.state.discount.toString() })
                          }}
                        >
                          {"\uf304"}
                        </label>{" "}
                        -
                        {baht(this.state.discount)}
                      </label>
                    </div>
                    <div className="d-flex justify-content-between">
                      <label className="text-secondary">ค่าบริการจัดส่ง:</label>
                      <label className="text-dark">
                        <label
                          className="icon ml-2 text-primary pointer"
                          onClick={() => {
                            if (this.state.order_list.length === 0) {
                              alert("info", "แจ้งเตือน", "ไม่มีออเดอร์")
                              return
                            }
                            this.setState({ txt_type: "ค่าบริการจัดส่ง", modal_type: true, price: this.state.delivery_price.toString() })
                          }}
                        >
                          {"\uf304"}
                        </label>{" "}
                        {baht(this.state.delivery_price)}
                      </label>
                    </div>

                    <div className="d-flex justify-content-between">
                      <label className="text-secondary">ภาษีมูลค่าเพิ่ม:</label>
                      <label className="text-dark">
                        {baht(this.state.vat)}
                      </label>
                    </div>
                    <div className="d-flex justify-content-between">
                      <b>ยอดเงินรวมทั้งหมด:</b>
                      <b>
                        {baht(this.state.total_price_final)}
                      </b>
                    </div>
                  </div>
                </div>
              </div>
              {/* ช่องที่ 4 ปุ่ม */}
              <div className="card px-3 pt-3">
                <div className="row mx-0">
                  {/* รายการธุรกรรม */}
                  <div className="col px-1 mb-3">
                    <button
                      className="btn btn-outline-primary w-100"
                      onClick={() => {
                        if (this.state.pause_status) {
                          this.setState({ modal_pause_clear: true, page_name: "/transaction" })
                        } else {
                          window.location.href = "/transaction"
                        }
                      }
                      }
                    >
                      <label className="icon mr-2">{"\uf0c9"}</label>
                      รายการธุรกรรม
                    </button>
                  </div>
                  {/* ค้างจ่าย */}
                  <div className="col px-1 mb-3">
                    <button
                      className="btn btn-outline-primary w-100"
                      onClick={async () => {
                        if (this.state.pause_status) {
                          this.setState({ modal_pause_clear: true, page_name: "/accrued" })
                        } else {
                          window.location.href = "/accrued"
                        }
                      }}
                    >
                      <label className="icon mr-2">{"\uf0d6"}</label>ค้างจ่าย
                    </button>
                  </div>
                  {/* บันทึก/รอชำระ */}
                  <div className="col px-1 mb-3">
                    <button
                      className="btn btn-green w-100"
                      onClick={() => {
                        if (this.state.order_list.length === 0) {
                          this.alert("info", "แจ้งเตือน", "ไม่มีออเดอร์")
                        } else {
                          this.setState({
                            status_order_main: 1,
                            payment_type_layer_1: 0,
                          })
                          setTimeout(() => {
                            if (this.state.order_list.filter(e => { return e.product_main.product_type === "technician" }).length === 0) {
                              if (this.state.edit_status) {
                                //กดมาจากพักออเดอร์จะเข้าเคสนี้
                                this.Edit()
                              } else {
                                this.Create()
                              }
                            } else {
                              let repair_detail = this.state.repair_detail
                              repair_detail.customer_name = repair_detail.customer_name ? repair_detail.customer_name : this.state.user_member_name
                              repair_detail.customer_address = repair_detail.customer_address ? repair_detail.customer_address : this.state.user_member_address
                              repair_detail.customer_phone = repair_detail.customer_phone ? repair_detail.customer_phone : this.state.user_member_phone ? this.state.user_member_phone : ""
                              repair_detail.date_time_repair = repair_detail.date_time_repair ? repair_detail.date_time_repair : null
                              repair_detail.remark_repair = repair_detail.remark_repair ? repair_detail.remark_repair : ""

                              this.setState({
                                repair_detail: repair_detail,
                                user_member_email: this.state.user_member_email ? this.state.user_member_email : null,
                                other_address: repair_detail.customer_address ? repair_detail.customer_address : this.state.user_member_address
                              })
                              this.setState({ modal_detail_repair_save: true })
                            }
                          }, 10)
                        }
                      }}
                    >
                      บันทึก/รอชำระ
                    </button>
                  </div>
                  {/* เคลียร์หน้าจอ */}
                  <div className="col px-1 mb-3">
                    <button
                      className="btn btn-danger w-100"
                      onClick={() => { this.setState({ modal_cancel: true }) }}
                    >
                      เคลียร์หน้าจอ
                    </button>
                  </div>
                  {/* ชำระเงิน */}
                  <div className="col px-1 mb-3">
                    <button
                      className="btn btn-primary w-100"
                      onClick={() => {
                        if (this.state.order_list.length === 0) {
                          this.alert("info", "แจ้งเตือน", "ไม่มีออเดอร์")
                        } else {
                          this.setState({
                            status_order_main: 2,
                            payment_type_layer_1: 1,
                            calculate_type: "เงินสด",
                            price: this.state.total_price_final.toString(),
                          })
                          if (Number(this.state.deposit) !== 0 && Number(this.state.deposit) > Number(this.state.total_price_final)) {
                            this.alert("info", "แจ้งเตือน", "ไม่สามารถกรอกค่ามัดจำมากกว่ายอดเงินรวมทั้งหมดได้")
                            this.setState({ price: this.state.total_price_final.toString() });
                            return
                          }
                          if (this.state.order_list.filter(e => { return e.product_main.product_type === "technician" }).length === 0) {
                            this.setState({
                              modal_payment: true,
                            })
                          } else {
                            let repair_detail = this.state.repair_detail
                            repair_detail.customer_name = repair_detail.customer_name ? repair_detail.customer_name : this.state.user_member_name
                            repair_detail.customer_address = repair_detail.customer_address ? repair_detail.customer_address : this.state.user_member_address
                            repair_detail.customer_phone = repair_detail.customer_phone ? repair_detail.customer_phone : this.state.user_member_phone
                            repair_detail.date_time_repair = repair_detail.date_time_repair ? repair_detail.date_time_repair : null
                            repair_detail.remark_repair = repair_detail.remark_repair ? repair_detail.remark_repair : ""

                            this.setState({
                              modal_detail_repair: true,
                              repair_detail: repair_detail,
                              user_member_email: this.state.user_member_email ? this.state.user_member_email : null,
                              other_address: repair_detail.customer_address ? repair_detail.customer_address : this.state.user_member_address
                            })
                          }

                        }
                      }}
                    >
                      ชำระเงิน
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* ขวา */}
            <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-4">
              <div className="card p-3 h-100 maxh-100 overflow-product">
                <div className="row mx-0">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 mb-3 px-1">
                    <input
                      type="text"
                      className="search form-control bg-secondary-light w-100"
                      placeholder="พิมพ์คำค้นหา..."
                      onChange={(e) => {
                        this.setState({ data_search: e.target.value })
                        if (e.target.value === "") {
                          this.setState({ data_search: "" })
                          setTimeout(() => {
                            this.GetProductAll()
                          }, 10)
                        }
                      }}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          setTimeout(async () => {
                            await this.GetProductAll()
                          }, 10)
                        }
                      }}
                      value={this.state.data_search}
                    ></input>
                  </div>
                  <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-3 mb-3 px-1">
                    <select
                      className="form-control"
                      onChange={async (e) => {
                        this.setState({ category_name: e.target.value })
                        setTimeout(() => {
                          this.GetProductAll()
                        }, 10)
                      }}
                      value={this.state.category_name}
                    >
                      <option value="all">ทั้งหมด</option>
                      <option value="air">เครื่องปรับอากาศ</option>
                      <option value="spare_part">อะไหล่</option>
                      <option value="technician">งานซ่อม</option>
                    </select>
                  </div>
                  <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-3 mb-3 px-1">
                    <select
                      className="form-control"
                      onChange={(e) => {
                        this.setState({ brand_id: e.target.value });
                        setTimeout(() => {
                          this.GetProductAll()
                        }, 10)
                      }}
                      value={this.state.brand_id}
                    >
                      <option value="all" selected>
                        ทั้งหมด
                      </option>
                      {this.state.data_brand &&
                        this.state.data_brand.data.map((item, index) => (
                          <option value={item.brand_id} key={index}>
                            {item.brand_name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>

                <div className="row h-100 overflow-scroll vh-100">
                  {this.state.data_product &&
                    this.state.data_product.data.length === 0 && (
                      <div className="h-100 w-100 center">ไม่มีข้อมูล</div>
                    )}
                  {this.state.data_product &&
                    this.state.data_product.data.map((item, index) => (
                      <>
                        {item.product_type !== "technician" && item.stock_qty === 0 ? (
                          <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 ">
                            <div className="container_sold_out">
                              {item.picture === "" ? (
                                <>
                                  <img
                                    className="product-empty-img"
                                    alt="logo"
                                    src={item.product_type === "air" ? IMGAIR : IMGSPAREPART}
                                    style={{ opacity: 0.5 }}
                                  ></img>
                                  <div className="d-flex justify-content-center align-items-center">
                                    <label className="text-10 text-empty">
                                      ยังไม่ได้อัปโหลดรูป
                                    </label>
                                  </div>
                                </>
                              ) : (
                                <div className="product-img" key={index}>
                                  <div
                                    className="sold-out d-flex justify-content-center align-items-center"
                                    style={{
                                      backgroundImage:
                                        'url("' + item.picture + '")',
                                      backgroundSize: "contain",
                                      backgroundRepeat: "no-repeat",
                                      opacity: 0.5,
                                    }}
                                  ></div>
                                </div>
                              )}
                              <div className="w-100 text-center">
                                <b className="text-primary text-14 mb-0 text_opacity">
                                  {item.product_name}
                                </b>
                                <br />
                                <b className="mb-0 text-14 text-dark text_opacity">
                                  {item.product_detail_name
                                    .replace("wall_air", "แอร์ผนัง")
                                    .replace("hanging_air", "แอร์แขวน")
                                    .replace(
                                      "4_direction_air",
                                      "แอร์ 4 ทิศทาง"
                                    )}
                                </b>
                                <br />
                                <label className="text-secondary text-12 text_opacity">
                                  ขายแล้ว:
                                  {" " + item.sale_qty} | คลัง:
                                  {" " + item.stock_qty}
                                </label>
                              </div>
                              <img
                                className="center_sold_out"
                                alt="logo"
                                src={SOLDOUT}
                              ></img>
                            </div>
                          </div>
                        ) : (
                          <a
                            href="#"
                            className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4"
                            onClick={() => {
                              if (item.product_type === "technician") {
                                let data = this.state.order_list;
                                data.push({
                                  product_main_id: item.product_id,
                                  stock_location_id: "",
                                  price_by_item: item.store_price,
                                  qty: 1,
                                  total_price: Number(item.store_price),
                                  technician_list: [],
                                  product_main: item,
                                  unit_name: item.unit_name,
                                  status_price: this.state.status_price
                                })
                                this.setState({
                                  order_list: data,
                                  product: null,
                                  count_order: data.length,
                                })
                                setTimeout(() => {
                                  this.TotalPrice()
                                }, 10)
                              } else {
                                this.GetStockAll(item.product_id)
                                this.setState({
                                  product: item,
                                  product_index: index,
                                })
                              }
                            }}
                          >
                            {item.picture === "" ? (
                              <>
                                <img
                                  className="product-empty-img"
                                  alt="logo"
                                  src={item.product_type === "air" ? IMGAIR : IMGSPAREPART}
                                ></img>
                                <div className="d-flex justify-content-center align-items-center">
                                  <label className="text-10 text-empty">
                                    ยังไม่ได้อัปโหลดรูป
                                  </label>
                                </div>
                              </>
                            ) : (
                              <div className="product-img" key={index}>
                                <div
                                  className="sold-out d-flex justify-content-center align-items-center"
                                  style={{
                                    backgroundImage: 'url("' + item.picture + '")',
                                    backgroundSize: "contain",
                                    backgroundRepeat: "no-repeat",
                                  }}
                                ></div>
                              </div>
                            )}
                            <div className="w-100 text-center">
                              <b className="text-primary text-14 mb-0">
                                {item.product_name}
                              </b>
                              <br />
                              <b className="mb-0 text-14 text-dark">
                                {item.product_detail_name.replace("wall_air", "แอร์ผนัง").replace("hanging_air", "แอร์แขวน").replace("4_direction_air", "แอร์ 4 ทิศทาง")}
                              </b>
                              <br />
                              <label className="text-secondary text-12"> ขายแล้ว: {" " + item.sale_qty} | คลัง: {" " + item.stock_qty} </label>
                            </div>
                          </a>
                        )}
                      </>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* modal */}
        {/* ลูกค้า */}
        {/* ค้นหาสมาชิก  */}
        <Modal show={this.state.modal_search_member} onHide={() => { this.setState({ modal_search_member: false }) }}>
          <Modal.Header>
            <Modal.Title className="w-100 d-flex justify-content-between">
              <b>ค้นหาสมาชิก</b>
              <button
                className={this.state.role.user_member[1] === 0 ? "btn btn-outline-disabled" : "btn btn-outline-primary"}
                onClick={() => { this.setState({ modal_add: true }) }}
                disabled={this.state.role.user_member[1] === 0 ? true : false}
              >
                + เพิ่มสมาชิก
              </button>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="border border-primary bg-white rounded hpx-48 mb-3">
              <div className="d-flex pt-1 pb-1 w-100 ">
                <button
                  type="button"
                  className={this.state.member_type === "name" ? "btn btn-primary w-50 hpx-32 mx-1 text-white" : "btn btn-white w-50 hpx-32  mx-1 text-login"}
                  onClick={() => { this.setState({ member_type: "name", user_member: "" }) }}>
                  <b>ชื่อ</b>
                </button>
                <button
                  type="button"
                  className={this.state.member_type === "phone" ? "btn btn-primary w-50 hpx-32 mx-1 text-white" : "btn btn-white w-50 hpx-32 mx-1 text-login"}
                  onClick={() => { this.setState({ member_type: "phone", user_member: "" }) }} >
                  <b>เบอร์โทรศัพท์</b>
                </button>
              </div>
            </div>
            <div className="w-100 mb-3">
              {this.state.user_member && (
                <div className="card rounded-8 bg-blue-sky p-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <h4>
                      {this.state.user_member && this.state.user_member.user_type === "general" ? this.state.user_member.full_name : this.state.user_member.company_name}
                    </h4>
                  </div>
                  <label className="text-secondary">
                    เบอร์โทร:{" "}
                    {this.state.user_member && this.state.user_member.phone
                      ? this.state.user_member.phone
                      : " - "}{" "}
                    | รหัสสมาชิก:{" "}
                    {this.state.user_member && this.state.user_member.user_code}
                  </label>
                </div>
              )}
            </div>
            {/* ค้นหา */}
            <div className="w-100 mb-3">
              <Select
                className="form-control px-0"
                options={
                  this.state.data_member &&
                  this.state.data_member.data.map((item, index) => ({
                    value: item.user_id,
                    label: this.state.member_type === "name" ? item.user_type === "general" ? item.full_name : item.company_name : item.phone,
                    full_name: item.user_type === "general" ? item.full_name : item.company_name,
                    phone: item.phone ? item.phone : " -",
                    id: item.user_code,
                    item: item,
                  }))
                }
                components={{ Option: UserOption }}
                styles={customStyles}
                placeholder={this.state.member_type === "name" ? "ค้นหาด้วยชื่อ" : "ค้นหาด้วยเบอร์โทร"}
                defaultValue={this.state.user_member ? this.state.user_member.user_member_id : null}
                defaultInputValue={this.state.user_member ? this.state.user_member.user_member_id : null}
                isClearable
                onChange={(e) => {
                  if (e === null) {
                    this.setState({ user_member: "" })
                  } else {
                    this.setState({ user_member: e.item, })
                  }
                }}
              />
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button className="btn btn-outline-primary w-100" onClick={() => { this.setState({ modal_search_member: false }) }}>
                  ย้อนกลับ
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    this.setState({
                      modal_search_member: false,
                      user_member_id: this.state.user_member.user_id,
                      user_member_name: this.state.user_member.user_type === "general" ? this.state.user_member.full_name : this.state.user_member.company_name,
                      user_member_code: this.state.user_member.user_code,
                      user_member_credit_day: this.state.user_member.credit_day,
                      user_member_address: this.state.user_member.address,
                      user_member_phone: this.state.user_member.phone,
                      user_member_email: this.state.user_member.email ? this.state.user_member.email : null,
                      user_member_line: this.state.user_member.line_id,
                      delivery_address: this.state.user_member.address,
                      user_member_tax_invoice: this.state.user_member.tax_invoice,
                    })
                  }}
                >
                  ยืนยัน
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* เพิ่มข้อมูลสมาชิก  */}
        <Modal show={this.state.modal_add} onHide={() => { this.setState({ modal_add: false }); this.clear_form() }}>
          <Modal.Header>
            <Modal.Title>
              <b>เพิ่มข้อมูลสมาชิก</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* ประเภท */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120"> <label>ประเภท:</label> </div>
              <div className="w-100">
                <select
                  className="form-control"
                  onChange={(e) => { this.setState({ user_type: e.target.value }) }}
                  value={this.state.user_type}
                >
                  <option value="" selected disabled> -- เลือก -- </option>
                  <option value="general">ลูกค้าสมาชิก</option>
                  <option value="corporation">ลูกค้านิติบุคคล</option>
                </select>
              </div>
            </div>
            {/* เครดิต(วัน) */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120"> <label>เครดิต(วัน):</label> </div>
              <div className="w-100">
                <input
                  className="form-control"
                  type="text"
                  placeholder="กรอกเครดิต(วัน)"
                  maxLength={10}
                  onChange={(e) => {
                    let credit_day = this.state.credit_day
                    credit_day = e.target.value.replace(/\D/g, "")
                    this.setState({ credit_day: credit_day })
                  }}
                  value={this.state.credit_day}
                />
              </div>
            </div>
            {/* ชื่อ-นามสกุล */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120"> <label>ชื่อ-นามสกุล:</label> </div>
              <div className="w-100">
                <input
                  className="form-control"
                  type="text"
                  maxLength={50}
                  placeholder="กรอกชื่อ-นามสกุล"
                  onChange={(e) => { this.setState({ full_name: e.target.value }) }}
                  value={this.state.full_name}
                />
              </div>
            </div>
            {/* ชื่อบริษัท */}
            {this.state.user_type === "corporation" && (
              <div className="w-100 d-flex mb-3">
                <div className="wpx-120"> <label>ชื่อบริษัท:</label> </div>
                <div className="w-100">
                  <input
                    className="form-control"
                    type="text"
                    maxLength={50}
                    placeholder="พิมพ์ชื่อบริษัท"
                    onChange={(e) => { this.setState({ company_name: e.target.value }) }}
                    value={this.state.company_name}
                  />
                </div>
              </div>
            )}
            {/* เลขกำกับภาษี */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120"> <label>เลขกำกับภาษี:</label> </div>
              <div className="w-100">
                <input
                  className="form-control"
                  maxLength={13}
                  type="text"
                  placeholder="กรอกเลขกำกับภาษี"
                  onChange={(e) => {
                    let tax_invoice = this.state.tax_invoice
                    tax_invoice = e.target.value.replace(/\D/g, "")
                    this.setState({ tax_invoice: tax_invoice, })
                  }}
                  value={this.state.tax_invoice}
                />
              </div>
            </div>
            {/* ที่อยู่ */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120"> <label>ที่อยู่:</label> </div>
              <div className="w-100">
                <textarea
                  className="form-control"
                  maxLength={100}
                  placeholder="กรอกที่อยู่"
                  onChange={(e) => { this.setState({ address_MEMBER: e.target.value }) }}
                  value={this.state.address_MEMBER}
                ></textarea>
              </div>
            </div>
            {/* เบอร์โทร */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120"> <label>เบอร์โทร:</label> </div>
              <div className="w-100">
                <input
                  className="form-control"
                  type="tel"
                  maxLength={10}
                  placeholder="กรอกเบอร์โทร"
                  onChange={(e) => {
                    let phone = this.state.phone
                    phone = e.target.value.replace(/\D/g, "")
                    this.setState({ phone: phone })
                  }}
                  value={this.state.phone}
                />
              </div>
            </div>
            {/* อีเมล */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120"> <label>อีเมล:</label> </div>
              <div className="w-100">
                <input
                  className="form-control"
                  type="email"
                  maxLength={50}
                  placeholder="กรอกอีเมล"
                  onChange={(e) => { this.setState({ email: e.target.value }) }}
                  value={this.state.email}
                />
              </div>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_add: false })
                    this.clear_form()
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.user_type === "") {
                      this.alert("info", "แจ้งเตือน", "กรุณาเลือกประเภท")
                      return
                    } else {
                      if (this.state.user_type === "general") {
                        if (this.state.full_name === "") {
                          this.alert("info", "แจ้งเตือน", "กรุณากรอกชื่อ")
                          return
                        } else if (this.state.tax_invoice.trim() !== "" && this.state.tax_invoice.length !== 13) {
                          this.alert("info", "แจ้งเตือน", "กรุณากรอกเลขกำกับภาษีให้ครบ 13 หลัก")
                          return
                        } else if (this.state.phone.trim() === "") {
                          this.alert("info", "แจ้งเตือน", "กรุณากรอกเบอร์โทรศัพท์")
                          return
                        } else if (!this.validatePhone(this.state.phone)) {
                          this.alert("info", "แจ้งเตือน", "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง")
                          return
                        } else if (this.state.email.trim() !== "" && !this.validateEmail(this.state.email)) {
                          this.alert("info", "แจ้งเตือน", "รูปแบบอีเมลไม่ถูกต้อง")
                          return
                        }
                      } else {
                        if (this.state.full_name.trim() === "") {
                          this.alert("info", "แจ้งเตือน", "กรุณากรอกชื่อ")
                          return
                        } else if (this.state.company_name.trim() === "") {
                          this.alert("info", "แจ้งเตือน", "กรุณากรอกชื่อบริษัท")
                          return
                        } else if (this.state.tax_invoice.trim() === "") {
                          this.alert("info", "แจ้งเตือน", "กรุณากรอกเลขกำกับภาษี")
                          return
                        } else if (this.state.tax_invoice.trim() !== "" && this.state.tax_invoice.length !== 13) {
                          this.alert("info", "แจ้งเตือน", "กรุณากรอกเลขกำกับภาษีให้ครบ 13 หลัก")
                          return
                        } else if (this.state.phone.trim() === "") {
                          this.alert("info", "แจ้งเตือน", "กรุณากรอกเบอร์โทรศัพท์")
                          return
                        } else if (!this.validatePhone(this.state.phone)) {
                          this.alert("info", "แจ้งเตือน", "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง")
                          return
                        } else if (this.state.phone.trim().length !== 10) {
                          this.alert("info", "แจ้งเตือน", "กรุณากรอกเบอร์โทรศัพท์ให้ครบ 10 หลัก")
                          return
                        } else if (this.state.email.trim() !== "" && !this.validateEmail(this.state.email)) {
                          this.alert("info", "แจ้งเตือน", "รูปแบบอีเมลไม่ถูกต้อง")
                          return
                        }
                      }
                    }
                    this.CreateMember()
                  }}
                >
                  เพิ่ม
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* จัดการที่อยู่  */}
        <Modal show={this.state.modal_address} onHide={() => { this.setState({ modal_address: false }) }} >
          <Modal.Header>
            <Modal.Title>
              <b>จัดการที่อยู่</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* ที่อยู่ */}
            <div className="w-100 mb-3">
              <label>ที่อยู่จัดส่ง</label>
              <textarea
                className="form-control"
                placeholder="กรอกข้อมูล"
                onChange={(e) => { this.setState({ address: e.target.value }) }}
                value={this.state.address}
              ></textarea>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button className="btn btn-outline-primary w-100" onClick={() => { this.setState({ modal_address: false }) }} >
                  ย้อนกลับ
                </button>
              </div>
              <div className="w-50 p-1">
                <button className="btn btn-primary w-100" onClick={() => { this.setState({ delivery_address: this.state.address, modal_address: false }) }} >
                  ยืนยัน
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* พักออร์เดอร์ */}
        {/* ดึงข้อมูลออเดอร์ที่พักไว้ */}
        <Modal show={this.state.modal_PauseDate} onHide={() => { this.setState({ modal_PauseDate: false }) }} >
          <Modal.Body>
            <div className="w-100 mb-3 text-center">
              <img className="clock-icon my-4" alt="logo" src={CLOCK}></img>
              <h2> <b style={{ color: "#595959" }}>ดึงข้อมูลออเดอร์ที่พักไว้</b> </h2>
              <div className="w-75 text-center mx-auto text-secondary mb-2">
                คุณแน่ใจว่าต้องการดึงข้อมูลออเดอร์ที่พักไว้
              </div>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button className="btn btn-outline-primary w-100" onClick={() => { this.setState({ modal_PauseDate: false }) }} >
                  ย้อนกลับ
                </button>
              </div>
              <div className="w-50 p-1">
                <button className="btn btn-primary w-100" onClick={() => {
                  this.setState({ ...JSON.parse(localStorage.getItem("POS")), data_hole: JSON.parse(localStorage.getItem("POS")) });
                }} >
                  ยืนยัน
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* ยืนยันการพักออเดอร์ */}
        <Modal show={this.state.modal_pause_order} onHide={() => { this.setState({ modal_pause_order: false }) }} >
          <Modal.Body>
            <div className="w-100 mb-3 text-center">
              <img className="clock-icon my-4" alt="logo" src={CLOCK}></img>
              <h2> <b style={{ color: "#595959" }}>ยืนยันการพักออเดอร์</b> </h2>
              <div className="w-75 text-center mx-auto text-secondary mb-2">
                คุณแน่ใจว่าต้องการพักออเดอร์สินค้า
              </div>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button className="btn btn-outline-primary w-100" onClick={() => { this.setState({ modal_pause_order: false }) }} >
                  ย้อนกลับ
                </button>
              </div>
              <div className="w-50 p-1">
                <button className="btn btn-primary w-100" onClick={() => {
                  this.setState({ modal_pause_order: false });
                  // this.Create()
                  setTimeout(() => {
                    localStorage.setItem("POS", JSON.stringify(this.state));
                    this.alert_url(
                      "success",
                      "พักออเดอร์เรียบร้อยแล้ว",
                      "คุณสามารถกลับมาจัดการออเดอร์ที่พักไว้ ในเมนูพักออเดอร์",
                      "/main"
                    )
                  }, 10);
                }} >
                  ยืนยัน
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* เคลียร์หน้าจอ */}
        {/* ยืนยันการยกเลิก */}
        <Modal show={this.state.modal_cancel} onHide={() => { this.setState({ modal_cancel: false }) }} >
          <Modal.Body>
            <div className="w-100 mb-3 text-center">
              <img className="clock-icon my-4" alt="logo" src={WARNING}></img>
              <h2> <b style={{ color: "#595959" }}>ยืนยันการยกเลิก</b> </h2>
              <div className="w-75 text-center mx-auto text-secondary mb-2">
                คุณแน่ใจว่าต้องการยกเลิกรายการสินค้าทั้งหมด
                เมื่อยืนยันแล้วจะไม่สามารถย้อนคือข้อมูลได้
              </div>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button className="btn btn-outline-primary w-100" onClick={() => { this.setState({ modal_cancel: false }) }} >
                  ย้อนกลับ
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-danger w-100"
                  onClick={() => {
                    if (this.props.location.query.retry_id) {
                      this.setState({ modal_cancel: false })
                      window.location.href = "/main"
                    } else if (this.state.order_list.length === 0) {
                      this.setState({ modal_cancel: false })
                      this.alert("warning", "แจ้งเตือน", "ไม่มีออเดอร์ในรายการ")
                      return
                    } else if (this.state.data_hole) {
                      localStorage.removeItem("POS")
                      alert_url("success", "ยกเลิกเรียบร้อยแล้ว", "ยกเลิกรายการสินค้าทั้งหมดแล้ว", "/main")
                    } else {
                      this.setState({ modal_cancel: false })
                      window.location.href = "/main"
                    }
                  }}
                >
                  ยืนยัน
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* เลือกสต๊อกสินค้า  */}
        <Modal show={this.state.modal_select_stock} onHide={() => { this.setState({ modal_select_stock: false }) }} >
          <Modal.Header>
            <Modal.Title>
              <b>เลือกสต๊อกสินค้า</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 mb-3">
              <label>
                โปรดเลือกสต๊อกสินค้าที่จัดเก็บ “
                {this.state.product && this.state.product.product_name + " : " + this.state.product.product_detail_name.replace("wall_air", "แอร์ผนัง").replace("hanging_air", "แอร์แขวน").replace("4_direction_air", "แอร์ 4 ทิศทาง")}
                ”
              </label>
            </div>
            <div className="w-100">
              <div className="row mx-0 overflow-scroll">
                {this.state.data_stock &&
                  this.state.data_stock.data.map((item, index) => (
                    <>
                      {item.stock_qty > 0 && (
                        <>
                          <div className="col-6 px-1 mb-3" key={index}>
                            <div
                              className={this.state.stock_location_id === item.stock_location_id ? "card bg-secondary-light p-2 text-center pointer border-primary" : "card bg-secondary-light p-2 text-center pointer"}
                              onClick={() => {
                                if (item.stock_qty !== 0) {
                                  this.setState({
                                    stock_location_id: item.stock_location_id,
                                    stock_main_name: item.stock_location_name,
                                    stock_main_qty: item.stock_qty,
                                  })
                                }
                              }}
                            >
                              <div className="d-flex w-100 justify-content-end">
                                {this.state.stock_location_id === item.stock_location_id ?
                                  (
                                    <input type="checkbox" checked></input>
                                  ) :
                                  (
                                    <input type="checkbox"></input>
                                  )}
                              </div>
                              <h2> <b className={item.stock_qty === 0 ? "text-secondary" : ""} > {item.stock_qty} </b> </h2>
                              <label className={item.stock_qty === 0 ? "text-secondary pointer" : "pointer"} >
                                {item.stock_location_name}
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  ))}
              </div>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button className="btn btn-outline-primary w-100" onClick={() => { this.setState({ modal_select_stock: false }) }} >
                  ย้อนกลับ
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.stock_location_id === "") {
                      this.alert("info", "แจ้งเตือน", "กรุณาเลือกสต๊อกสินค้า")
                    } else {
                      let data = this.state.order_list
                      let check = data.find((e, index) => {
                        if (e.stock_location_id === this.state.stock_location_id && e.product_main_id === this.state.product.product_id) {
                          return true
                        } else {
                          return false
                        }
                      })
                      if (!check) {
                        let price = Number(this.state.product.store_price * 1)
                        data.push({
                          product_main_id: this.state.product.product_id,
                          stock_location_id: this.state.stock_location_id,
                          stock_location_name: this.state.stock_main_name,
                          stock_qty: this.state.stock_main_qty,
                          price_by_item: price,
                          qty: 1,
                          total_price: price,
                          technician_list: [],
                          product_main: this.state.product,
                          product_index: this.state.product_index,
                          unit_name: this.state.product.unit_name,
                          status_price: this.state.status_price
                        })
                      } else {
                        data.find((e, index) => {
                          if (
                            e.stock_location_id ===
                            this.state.stock_location_id &&
                            e.product_main_id === this.state.product.product_id
                          ) {
                            if (data[index].qty < this.state.stock_main_qty) {
                              data[index].qty += 1
                            } else {
                              this.alert("warning", "แจ้งเตือน", "ไม่สามารถเพิ่มออเดอร์ได้เนื่องจากจำนวนคงเหลือในสต๊อกไม่เพียงพอ")
                            }
                          }
                        })
                      }
                      this.setState({
                        order_list: data,
                        product: null,
                        stock_location_id: "",
                        count_order: data.length,
                        modal_select_stock: false,
                        status_price: 1
                      })
                      setTimeout(() => {
                        this.TotalPrice()
                      }, 10);
                    }
                  }}
                >
                  ยืนยัน
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* ชำระเงิน  */}
        <Modal show={this.state.modal_payment} onHide={() => { this.setState({ modal_payment: false }) }} >
          <Modal.Body>
            {/* เมนู */}
            <div className="w-100 d-flex">
              {/* เงินสด status 1*/}
              <div className="w-20 px-1">
                <button
                  className={
                    this.state.calculate_type === "เงินสด"
                      ? "btn btn-calculate bg-secondary-light w-100 text-center p-0 position-relative"
                      : "btn btn-calculate bg-white w-100 text-center p-0 position-relative"
                  }
                  onClick={() => {
                    this.setState({
                      calculate_type: "เงินสด",
                      payment_type_layer_1: 1,
                      status_order_main: 2,
                      price: this.state.deposit === 0 ? this.state.total_price_final.toString() : this.state.deposit.toString(),
                    })
                    this.payment_clear()
                  }}
                >
                  <label className="icon mb-0 pointer">{"\uf0d6"}</label>
                  <br /> เงินสด
                  {this.state.calculate_type === "เงินสด" && (
                    <div style={{ width: "50%", height: 2, backgroundColor: "#232F85", position: "absolute", bottom: 0, left: "25%" }} ></div>
                  )}
                </button>
              </div>
              {/* เงินเชื่อ status 2*/}
              <div className="w-20 px-1">
                <button
                  className={
                    this.state.calculate_type === "เงินเชื่อ"
                      ? "btn btn-calculate bg-secondary-light w-100 text-center p-0 position-relative"
                      : "btn btn-calculate bg-white w-100 text-center p-0 position-relative"
                  }
                  onClick={() => {
                    this.setState({
                      calculate_type: "เงินเชื่อ",
                      payment_type_layer_1: 2,
                      status_order_main: 1,
                    })
                    this.payment_clear()
                  }}
                  disabled={
                    (this.state.user_member && this.state.user_member.user_code === "ME000000001") ||
                      (this.state.user_member && this.state.user_member.credit_day === 0) ||
                      (Number(this.state.deposit) !== 0) ? true : false
                  }
                >
                  <label className="icon mb-0 pointer">{"\uf783"}</label>
                  <br />
                  เงินเชื่อ
                  {this.state.calculate_type === "เงินเชื่อ" && (
                    <div style={{ width: "50%", height: 2, backgroundColor: "#232F85", position: "absolute", bottom: 0, left: "25%" }} ></div>
                  )}
                </button>
              </div>
              {/* บัตรเครดิต status 3*/}
              <div className="w-20 px-1">
                <button
                  className={
                    this.state.calculate_type === "บัตรเครดิต"
                      ? "btn btn-calculate bg-secondary-light w-100 text-center p-0 position-relative"
                      : "btn btn-calculate bg-white w-100 text-center p-0 position-relative"
                  }
                  onClick={() => {
                    this.setState({
                      calculate_type: "บัตรเครดิต",
                      payment_type_layer_1: 3,
                      status_order_main: 2,
                      price: this.state.deposit === 0 ? this.state.total_price_final.toString() : this.state.deposit.toString(),
                    })
                    this.payment_clear()
                  }}
                >
                  <label className="icon mb-0 pointer">{"\uf389"}</label>
                  <br />
                  บัตรเครดิต
                  {this.state.calculate_type === "บัตรเครดิต" && (
                    <div style={{ width: "50%", height: 2, backgroundColor: "#232F85", position: "absolute", bottom: 0, left: "25%" }} ></div>
                  )}
                </button>
              </div>

              {/* เงินโอน status 4*/}
              <div className="w-20 px-1">
                <button
                  className={
                    this.state.calculate_type === "เงินโอน"
                      ? "btn btn-calculate bg-secondary-light w-100 text-center p-0 position-relative"
                      : "btn btn-calculate bg-white w-100 text-center p-0 position-relative"
                  }
                  onClick={() => {
                    this.setState({
                      calculate_type: "เงินโอน",
                      payment_type_layer_1: 4,
                      status_order_main: 2,
                      price: this.state.deposit === 0 ? this.state.total_price_final.toString() : this.state.deposit.toString(),
                    })
                    this.payment_clear()
                  }}
                >
                  <label className="icon mb-0 pointer">{"\uf362"}</label>
                  <br />
                  เงินโอน
                  {this.state.calculate_type === "เงินโอน" && (
                    <div style={{ width: "50%", height: 2, backgroundColor: "#232F85", position: "absolute", bottom: 0, left: "25%" }} ></div>
                  )}
                </button>
              </div>

              {/* เป็นงวด status 5*/}
              <div className="w-20 px-1">
                <button
                  className={
                    this.state.calculate_type === "เป็นงวด"
                      ? "btn btn-calculate bg-secondary-light w-100 text-center p-0 position-relative"
                      : "btn btn-calculate bg-white w-100 text-center p-0 position-relative"
                  }
                  onClick={() => {
                    this.setState({
                      calculate_type: "เป็นงวด",
                      payment_type_layer_1: 5,
                      status_order_main: 1,
                    })
                    this.payment_clear()
                  }}
                  disabled={
                    (this.state.user_member && this.state.user_member.user_code === "ME000000001") ||
                      (this.state.user_member && this.state.user_member.credit_day === 0) ||
                      (Number(this.state.deposit) !== 0) ? true : false
                  }
                >
                  <label className="icon mb-0 pointer">{"\uf126"}</label>
                  <br />
                  เป็นงวด
                  {this.state.calculate_type === "เป็นงวด" && (
                    <div style={{ width: "50%", height: 2, backgroundColor: "#232F85", position: "absolute", bottom: 0, left: "25%" }} ></div>
                  )}
                </button>
              </div>
            </div>
            {/* เงินสด */}
            {this.state.calculate_type === "เงินสด" && (
              <div className="w-100">
                <div className="d-flex py-2 ">
                  {/* ปุ่มลบ */}
                  <div className="btn btn-link text-secondary mt-3" onClick={() => { this.setState({ price: "", }) }}>
                    <label className="icon bg-dark text-white" style={{ width: 24, height: 24, borderRadius: 12 }}>{"\uf00d"}</label>
                  </div>
                  {/* ช่องกรอก */}
                  <input
                    id="price"
                    className="text-right w-100 border-0 text-32"
                    style={{ fontWeight: "bold" }}
                    type="text"
                    placeholder="0.00"
                    maxLength={14}
                    onChange={(e) => {
                      if (/^\d*\.?\d{0,2}$/.test(e.target.value) && Number(e.target.value) < 999999999) {
                        this.setState({ price: e.target.value });
                      }
                    }}
                    value={this.state.price}
                  />
                </div>
                <div className="row mx-0">
                  <div className="col-3 cal" onClick={() => { this.calculate(7) }}><div><h5>7</h5></div></div>
                  <div className="col-3 cal" onClick={() => { this.calculate(8) }}><div><h5>8</h5></div></div>
                  <div className="col-3 cal" onClick={() => { this.calculate(9) }}><div><h5>9</h5></div></div>
                  <div className="col-3 cal" onClick={() => { this.calculate(1000) }}><div className="primary"><h5>1,000</h5></div></div>
                  <div className="col-3 cal" onClick={() => { this.calculate(4) }}><div><h5>4</h5></div></div>
                  <div className="col-3 cal" onClick={() => { this.calculate(5) }}><div><h5>5</h5></div></div>
                  <div className="col-3 cal" onClick={() => { this.calculate(6) }}><div><h5>6</h5></div></div>
                  <div className="col-3 cal" onClick={() => { this.calculate(500) }}><div className="primary"><h5>500</h5></div></div>
                  <div className="col-3 cal" onClick={() => { this.calculate(1) }}><div><h5>1</h5></div></div>
                  <div className="col-3 cal" onClick={() => { this.calculate(2) }}><div><h5>2</h5></div></div>
                  <div className="col-3 cal" onClick={() => { this.calculate(3) }}><div><h5>3</h5></div></div>
                  <div className="col-3 cal" onClick={() => { this.calculate(100) }}><div className="primary"><h5>100</h5></div></div>
                  <div className="col-3 cal" onClick={() => { this.calculate(".") }}><div><h5>.</h5></div></div>
                  <div className="col-3 cal" onClick={() => { this.calculate(0) }}><div><h5>0</h5></div></div>
                  <div className="col-3 cal" onClick={() => { this.calculate("delete") }}><div><h5 className="icon">{"\uf55a"}</h5></div></div>
                  <div className="col-3 cal" onClick={() => { this.calculate("full") }}><div className="primary"><h5>เต็ม</h5></div></div>
                </div>
              </div>
            )}
            {/* เงินเชื่อ */}
            {this.state.calculate_type === "เงินเชื่อ" && (
              <div className="w-100">
                <div className="w-100 d-flex justify-content-end align-items-center py-4 text-32">
                  <b> {baht(this.state.total_price_final)} </b>
                </div>
                <div className="card bg-secondary-light p-3">
                  <div className="d-flex justify-content-between">
                    <label className="w-25">เลขสมาชิก: </label>
                    <label className="w-75 text-right">
                      {this.state.user_member_code && this.state.user_member_code}
                    </label>
                  </div>
                  <div className="d-flex justify-content-between">
                    <label className="w-25">ชื่อ-นามสกุล: </label>
                    <label className="w-75 text-right">
                      {this.state.user_member_name && this.state.user_member_name}
                    </label>
                  </div>
                  <div className="d-flex justify-content-between">
                    <b className="w-25">เครดิต: </b>
                    <b className="w-75 text-right">
                      {this.state.user_member_credit_day && this.state.user_member_credit_day}{" "}
                      วัน
                    </b>
                  </div>
                </div>
                <div className="w-100 mt-4 mb-3">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="หมายเหตุ"
                    onChange={(e) => {
                      let data = this.state.payment_detail
                      data.remark_payment_s2 = e.target.value
                      this.setState({ payment_detail: data })
                    }}
                    value={this.state.payment_detail.remark_payment_s2}
                  />
                </div>
              </div>
            )}
            {/* บัตรเครดิต */}
            {this.state.calculate_type === "บัตรเครดิต" && (
              <div className="w-100">
                <div className="w-100 d-flex justify-content-end align-items-center py-4 text-32">
                  <b> {baht(this.state.price)} </b>
                </div>
                <div className="w-100 mb-3">
                  <div className="card rounded-8 border-primary" style={{ padding: 2 }} >
                    <div className="d-flex w-100">
                      <div className="w-50 pr-1">
                        <button
                          className={this.state.card_type === "บัตรเครดิต" ? "btn btn-primary w-100" : "btn btn-link w-100"}
                          onClick={() => {
                            this.setState({
                              card_type: "บัตรเครดิต",
                              bank_name: "",
                            })
                            let payment_detail = this.state.payment_detail
                            payment_detail.bank_name = ""
                            this.setState({ payment_detail: payment_detail })

                          }}
                        >
                          บัตรเครดิต
                        </button>
                      </div>
                      <div className="w-50 pl-1">
                        <button
                          className={this.state.card_type === "บัตรเดบิต" ? "btn btn-primary w-100" : "btn btn-link w-100"}
                          onClick={() => {
                            this.setState({
                              card_type: "บัตรเดบิต",
                              credit_card_type: "",
                            })
                            let payment_detail = this.state.payment_detail
                            payment_detail.credit_card_type = ""
                          }}
                        >
                          บัตรเดบิต
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  {this.state.card_type === "บัตรเครดิต" && (
                    <div className="col-12 mb-3">
                      <Select
                        className="form-control px-0"
                        options={[
                          { value: "มาสเตอร์การ์ด (Master Card)", label: "มาสเตอร์การ์ด (Master Card)", icon: CREDIT1 },
                          { value: "วีซ่า (Visa)", label: "วีซ่า (Visa)", icon: CREDIT2 },
                          { value: "JCB", label: "JCB", icon: CREDIT3 },
                          { value: "American Express", label: "American Express", icon: CREDIT4 },
                          { value: "Union Pay", label: "Union Pay", icon: CREDIT5 },
                        ]}
                        components={{ Option: IconOption }}
                        placeholder="-- เลือกประเภทบัตร --"
                        getOptionLabel={(e) => (
                          <div style={{ display: "flex", alignItems: "center" }} >
                            <img src={e.icon} style={{ width: 24, marginRight: 16 }} alt={e.label} />
                            <span style={{ marginLeft: 5 }}>{e.label}</span>
                          </div>
                        )}
                        defaultValue={this.state.payment_detail.credit_card_type}
                        defaultInputValue={this.state.payment_detail.credit_card_type}
                        onChange={(e) => {
                          let data = this.state.payment_detail
                          data.credit_card_type = e.value
                          this.setState({
                            payment_detail: data,
                          })
                        }}
                      />
                    </div>
                  )}
                  {this.state.card_type === "บัตรเดบิต" && (
                    <div className="col-12 mb-3">
                      <Select
                        className="form-control px-0"
                        options={[
                          { value: "กรุงเทพ", label: "กรุงเทพ", icon: DEBIT002 },
                          { value: "กสิกรไทย", label: "กสิกรไทย", icon: DEBIT004 },
                          { value: "กรุงไทย", label: "กรุงไทย", icon: DEBIT006 },
                          { value: "ทหารไทยธนชาติ", label: "ทหารไทยธนชาติ", icon: DEBIT011 },
                          { value: "ไทยพาณิชย์", label: "ไทยพาณิชย์", icon: DEBIT014 },
                          { value: "กรุงศรีอยุธยา", label: "กรุงศรีอยุธยา", icon: DEBIT025 },
                          { value: "ออมสิน", label: "ออมสิน", icon: DEBIT030 },
                          { value: "อาคารสงเคราะห์", label: "อาคารสงเคราะห์", icon: DEBIT033 },
                          { value: "เพื่อการเกษตรและสหกรณ์", label: "เพื่อการเกษตรและสหกรณ์", icon: DEBIT034 },
                          { value: "อิสลามแห่งปรเทศไทย", label: "อิสลามแห่งปรเทศไทย", icon: DEBIT066 },
                          { value: "ทิสโก้", label: "ทิสโก้", icon: DEBIT067 },
                          { value: "เกียรตินาคินภัทร", label: "เกียรตินาคินภัทร", icon: DEBIT069 },
                          { value: "เครดิตเพื่อรายย่อย", label: "เครดิตเพื่อรายย่อย", icon: DEBIT071 },
                          { value: "แลนด์ แอนด์ เฮ้าส์", label: "แลนด์ แอนด์ เฮ้าส์", icon: DEBIT073 },
                        ]}
                        components={{ Option: IconOption }}
                        placeholder="-- เลือกธนาคาร --"
                        getOptionLabel={(e) => (
                          <div style={{ display: "flex", alignItems: "center" }} >
                            <img src={e.icon} style={{ width: 24, marginRight: 16 }} alt={e.label} />
                            <span style={{ marginLeft: 5 }}>{e.label}</span>
                          </div>
                        )}
                        defaultValue={this.state.payment_detail.bank_name}
                        defaultInputValue={this.state.payment_detail.bank_name}
                        onChange={(e) => {
                          let data = this.state.payment_detail
                          data.bank_name = e.value
                          this.setState({
                            payment_detail: data,
                          })
                        }}
                      />
                      {/* <input
                        className="form-control"
                        type="text"
                        placeholder="กรอกชื่อธนาคาร"
                        onChange={(e) => {
                          let data = this.state.payment_detail
                          data.bank_name = e.target.value
                          this.setState({ payment_detail: data })
                        }}
                        value={this.state.payment_detail.bank_name}
                      /> */}
                    </div>
                  )}
                  <div className="col-12 mb-3">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="หมายเลขบัตร 4 ตัวท้าย"
                      onChange={(e) => {
                        let data = this.state.payment_detail
                        data.card_number = e.target.value.replace(/\D/g, "")
                        this.setState({ payment_detail: data })
                      }}
                      maxLength={4}
                      value={this.state.payment_detail.card_number}
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="เลขที่สลิป/ใบเสร็จ"
                      onChange={(e) => {
                        let data = this.state.payment_detail
                        data.slip_number = e.target.value.replace(/\D/g, "")
                        this.setState({ payment_detail: data })
                      }}
                      value={this.state.payment_detail.slip_number}
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="อธิบายการผ่อนชำระ"
                      onChange={(e) => {
                        let data = this.state.payment_detail
                        data.remark_payment_s3 = e.target.value
                        this.setState({ payment_detail: data })
                      }}
                      value={this.state.payment_detail.remark_payment_s3}
                    />
                  </div>
                </div>
              </div>
            )}
            {/* เงินโอน */}
            {this.state.calculate_type === "เงินโอน" && (
              <div className="w-100">
                <div className="w-100">
                  <div className="d-flex py-2 ">
                    {/* ปุ่มลบ */}
                    <div className="btn btn-link text-secondary mt-3" onClick={() => { this.setState({ price: "", }) }}>
                      <label className="icon bg-dark text-white" style={{ width: 24, height: 24, borderRadius: 12 }}>{"\uf00d"}</label>
                    </div>
                    {/* ช่องกรอก */}
                    <input
                      id="price"
                      className="text-right w-100 border-0 text-32"
                      style={{ fontWeight: "bold" }}
                      type="text"
                      placeholder="0.00"
                      maxLength={14}
                      onChange={(e) => {
                        if (/^\d*\.?\d{0,2}$/.test(e.target.value) && Number(e.target.value) < 999999999) {
                          this.setState({ price: e.target.value });
                        }
                      }}
                      value={this.state.price}
                    />
                  </div>

                  <div className="w-100 text-center mb-3">
                    <label
                      className="text-primary pointer"
                      onClick={() => { this.setState({ input: !this.state.input }) }}
                    >
                      กรอกตัวเลขด้วยตัวเอง
                      <span className="icon-brand ml-2"> {this.state.input ? "\uf077" : "\uf078"} </span>
                    </label>
                  </div>
                  {this.state.input && (
                    <div className="row mx-0">
                      <div className="col-3 cal" onClick={() => { this.calculate(7) }}><div><h5>7</h5></div></div>
                      <div className="col-3 cal" onClick={() => { this.calculate(8) }}><div><h5>8</h5></div></div>
                      <div className="col-3 cal" onClick={() => { this.calculate(9) }}><div><h5>9</h5></div></div>
                      <div className="col-3 cal" onClick={() => { this.calculate(1000) }}><div className="primary"><h5>1,000</h5></div></div>
                      <div className="col-3 cal" onClick={() => { this.calculate(4) }}><div><h5>4</h5></div></div>
                      <div className="col-3 cal" onClick={() => { this.calculate(5) }}><div><h5>5</h5></div></div>
                      <div className="col-3 cal" onClick={() => { this.calculate(6) }}><div><h5>6</h5></div></div>
                      <div className="col-3 cal" onClick={() => { this.calculate(500) }}><div className="primary"><h5>500</h5></div></div>
                      <div className="col-3 cal" onClick={() => { this.calculate(1) }}><div><h5>1</h5></div></div>
                      <div className="col-3 cal" onClick={() => { this.calculate(2) }}><div><h5>2</h5></div></div>
                      <div className="col-3 cal" onClick={() => { this.calculate(3) }}><div><h5>3</h5></div></div>
                      <div className="col-3 cal" onClick={() => { this.calculate(100) }}><div className="primary"><h5>100</h5></div></div>
                      <div className="col-3 cal" onClick={() => { this.calculate(".") }}><div><h5>.</h5></div></div>
                      <div className="col-3 cal" onClick={() => { this.calculate(0) }}><div><h5>0</h5></div></div>
                      <div className="col-3 cal" onClick={() => { this.calculate("delete") }}><div><h5 className="icon">{"\uf55a"}</h5></div></div>
                      <div className="col-3 cal" onClick={() => { this.calculate("full") }}><div className="primary"><h5>เต็ม</h5></div></div>
                    </div>
                  )}
                </div>
                <div className="row mt-3">
                  <div className="col-12 mb-3">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="ชื่อ-นามสกุลผู้โอน"
                      onChange={(e) => {
                        let data = this.state.payment_detail
                        data.full_name_transfer = e.target.value
                        this.setState({ payment_detail: data })
                      }}
                      value={this.state.payment_detail.full_name_transfer}
                    />
                  </div>
                </div>
                <div className="row mx-0">
                  <div className="col-12 mb-3 pl-0 pr-1 ">
                    <input
                      className="form-control"
                      type="datetime-local"
                      placeholder="วัน เดือน ปี"
                      onChange={(e) => {
                        let data = this.state.payment_detail
                        data.datetime_transfer = e.target.value
                        this.setState({ payment_detail: data })
                      }}
                      min={this.state.check_date}
                      value={this.state.payment_detail.datetime_transfer}
                    />
                  </div>
                </div>
                <Select
                  className="form-control px-0 mb-3"
                  options={[
                    { value: "กรุงเทพ", label: "กรุงเทพ", icon: DEBIT002 },
                    { value: "กสิกรไทย", label: "กสิกรไทย", icon: DEBIT004 },
                    { value: "กรุงไทย", label: "กรุงไทย", icon: DEBIT006 },
                    { value: "ทหารไทยธนชาติ", label: "ทหารไทยธนชาติ", icon: DEBIT011 },
                    { value: "ไทยพาณิชย์", label: "ไทยพาณิชย์", icon: DEBIT014 },
                    { value: "กรุงศรีอยุธยา", label: "กรุงศรีอยุธยา", icon: DEBIT025 },
                    { value: "ออมสิน", label: "ออมสิน", icon: DEBIT030 },
                    { value: "อาคารสงเคราะห์", label: "อาคารสงเคราะห์", icon: DEBIT033 },
                    { value: "เพื่อการเกษตรและสหกรณ์", label: "เพื่อการเกษตรและสหกรณ์", icon: DEBIT034 },
                    { value: "อิสลามแห่งปรเทศไทย", label: "อิสลามแห่งปรเทศไทย", icon: DEBIT066 },
                    { value: "ทิสโก้", label: "ทิสโก้", icon: DEBIT067 },
                    { value: "เกียรตินาคินภัทร", label: "เกียรตินาคินภัทร", icon: DEBIT069 },
                    { value: "เครดิตเพื่อรายย่อย", label: "เครดิตเพื่อรายย่อย", icon: DEBIT071 },
                    { value: "แลนด์ แอนด์ เฮ้าส์", label: "แลนด์ แอนด์ เฮ้าส์", icon: DEBIT073 },
                  ]}
                  components={{ Option: IconOption }}
                  placeholder="-- เลือกธนาคาร --"
                  getOptionLabel={(e) => (
                    <div style={{ display: "flex", alignItems: "center" }} >
                      <img src={e.icon} style={{ width: 24, marginRight: 16 }} alt={e.label} />
                      <span style={{ marginLeft: 5 }}>{e.label}</span>
                    </div>
                  )}
                  defaultValue={this.state.payment_detail.bank_name}
                  defaultInputValue={this.state.payment_detail.bank_name}
                  onChange={(e) => {
                    let data = this.state.payment_detail
                    data.bank_name = e.value
                    this.setState({
                      payment_detail: data,
                    })
                  }}
                />
                <div className="row">
                  <div className="col-12 mb-3">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="หมายเลขอ้างอิง"
                      onChange={(e) => {
                        let data = this.state.payment_detail
                        data.reference_number = e.target.value.replace(/\D/g, "")
                        this.setState({ payment_detail: data })
                      }}
                      value={this.state.payment_detail.reference_number}
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="หมายเหตุ"
                      onChange={(e) => {
                        let data = this.state.payment_detail
                        data.remark_payment_s4 = e.target.value
                        this.setState({ payment_detail: data })
                      }}
                      value={this.state.payment_detail.remark_payment_s4}
                    />
                  </div>
                </div>
                <div className="w-100 my-1">
                  <label>
                    คำแนะนำ: โปรดเก็บหลักฐานการชำระเงินทุกครั้ง
                    หลังกรอกข้อมูลเสร็จสิ้นแล้ว
                  </label>
                </div>
              </div>
            )}
            {/* เป็นงวด */}
            {this.state.calculate_type === "เป็นงวด" && (
              <div className="w-100">
                <div className="w-100 d-flex justify-content-end align-items-center py-4 text-32 mb-3">
                  <b> {baht(this.state.total_price_final)} </b>
                </div>
                <div className="w-100 d-flex mb-3">
                  <div className="wpx-120">
                    <label>ภายใน (เดือน): </label>
                  </div>
                  <div className="w-100">
                    <input
                      className="form-control"
                      type="number"
                      placeholder="ระบุระยะเวลา (เดือน)"
                      onChange={(e) => {
                        if (e.target.value >= 0) {
                          let data = this.state.payment_detail
                          data.installment = e.target.value
                          this.setState({ payment_detail: data })
                        }
                      }}
                      value={this.state.payment_detail.installment}
                    />
                  </div>
                </div>
                <div className="row ">
                  <div className="col-12 mb-3">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="หมายเหตุ"
                      onChange={(e) => {
                        let data = this.state.payment_detail
                        data.remark_payment_s5 = e.target.value
                        this.setState({ payment_detail: data })
                      }}
                      value={this.state.payment_detail.remark_payment_s5}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="w-100 d-flex mt-2">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_payment: false })
                    setTimeout(() => {
                      this.setState({ modal_detail_print: true })
                    }, 10);
                  }}
                >
                  ย้อนกลับ
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    let payment_detail = this.state.payment_detail
                    if (this.state.calculate_type === "บัตรเครดิต") {
                      if (this.state.card_type === "บัตรเครดิต") {
                        if (payment_detail.credit_card_type === "") {
                          alert("info", "แจ้งเตือน", "กรุณาเลือกประเภทบัตร")
                          return
                        } else if (payment_detail.card_number === "") {
                          alert("info", "แจ้งเตือน", "กรุณากรอกหมายเลขบัตร 4 ตัวท้าย")
                          return
                        }
                      } else {
                        if (payment_detail.bank_name === "") {
                          alert("info", "แจ้งเตือน", "กรอกชื่อธนาคาร")
                          return
                        } else if (payment_detail.card_number === "") {
                          alert("info", "แจ้งเตือน", "กรุณากรอกหมายเลขบัตร 4 ตัวท้าย")
                          return
                        }
                      }
                    } else if (this.state.calculate_type === "เงินโอน") {
                      if (payment_detail.full_name_transfer === "") {
                        alert("info", "แจ้งเตือน", "กรุณากรอกชื่อ-นามสกุลผู้โอน")
                        return
                      } else if (payment_detail.datetime_transfer === "") {
                        alert("info", "แจ้งเตือน", "กรุณากรอกวัน-เวลาที่โอน")
                        return
                      } else if (payment_detail.reference_number === "") {
                        alert("info", "แจ้งเตือน", "กรุณากรอกหมายเลขอ้างอิง")
                        return
                      }
                    } else if (this.state.calculate_type === "เป็นงวด") {
                      if (payment_detail.installment === "") {
                        alert("info", "แจ้งเตือน", "กรุณาระบุระยะเวลาในการผ่อนชำระ (เดือน)")
                        return
                      }
                    }
                    if (this.state.calculate_type === "เงินสด") {
                      payment_detail.total_pay = Number(this.state.price)
                      this.setState({ payment_type_layer_1: 1 })
                    } else if (this.state.calculate_type === "เงินเชื่อ") {
                      payment_detail.total_pay = 0.0
                      this.setState({ payment_type_layer_1: 2 })
                    } else if (this.state.calculate_type === "บัตรเครดิต") {
                      payment_detail.total_pay = Number(this.state.price)
                      if (this.state.card_type === "บัตรเครดิต") {
                        let payment_detail = this.state.payment_detail
                        payment_detail.bank_name = "-"
                        this.setState({ payment_detail: payment_detail })
                      } else {
                        let payment_detail = this.state.payment_detail
                        payment_detail.credit_card_type = "-"
                      }
                      this.setState({ payment_type_layer_1: 3 })
                    } else if (this.state.calculate_type === "เงินโอน") {
                      payment_detail.total_pay = Number(this.state.price)
                      this.setState({ payment_type_layer_1: 4 })
                    } else if (this.state.calculate_type === "เป็นงวด") {
                      payment_detail.total_pay = 0.0
                      this.setState({ payment_type_layer_1: 5 })
                    }
                    this.setState({ payment_detail: payment_detail })
                    setTimeout(() => {
                      if (this.state.edit_status) {
                        this.Edit()
                      } else {
                        this.Create()
                      }
                    }, 10)
                  }}
                >
                  ตกลง
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* แก้ไข ราคา/หน่วย  */}
        <Modal show={this.state.modal_edit_price} onHide={() => { this.setState({ modal_edit_price: false }) }} >
          <Modal.Body>
            <div className="w-100">
              <div className="w-100 d-flex">
                <div className="w-33 px-1">
                  <button
                    className={
                      this.state.price_type === "ราคาขายหน้าร้าน"
                        ? "btn btn-calculate bg-secondary-light w-100 text-center p-0 position-relative"
                        : "btn btn-calculate bg-white w-100 text-center p-0 position-relative"
                    }
                    disabled={this.state.product_main && this.state.product_main.product_type === "technician" ? true : false}
                    onClick={() => {
                      this.setState({
                        price_type: "ราคาขายหน้าร้าน",
                        price: this.state.product_main.store_price.toString(),
                        status_price: 1
                      })
                    }}
                  >
                    <label className="icon mb-0 pointer">{"\uf2e9"}</label>
                    <br /> ราคาหน้าร้าน
                    {this.state.price_type === "ราคาขายหน้าร้าน" && (
                      <div style={{ width: "50%", height: 2, backgroundColor: "#232F85", position: "absolute", bottom: 0, left: "25%" }} ></div>
                    )}
                  </button>
                </div>
                <div className="w-33 px-1">
                  <button
                    className={
                      this.state.price_type === "ราคาขายส่ง"
                        ? "btn btn-calculate bg-secondary-light w-100 text-center p-0 position-relative"
                        : "btn btn-calculate bg-white w-100 text-center p-0 position-relative"
                    }
                    disabled={this.state.product_main && this.state.product_main.product_type === "technician" ? true : false}
                    onClick={() => {
                      this.setState({
                        price_type: "ราคาขายส่ง",
                        price: this.state.product_main.wholesale_price.toString(),
                        status_price: 2
                      })
                    }}
                  >
                    <label className="icon mb-0 pointer">{"\uf2e9"}</label>
                    <br /> ราคาขายส่ง
                    {this.state.price_type === "ราคาขายส่ง" && (
                      <div style={{ width: "50%", height: 2, backgroundColor: "#232F85", position: "absolute", bottom: 0, left: "25%" }} ></div>
                    )}
                  </button>
                </div>
                <div className="w-33 px-1">
                  <button
                    className={
                      this.state.price_type === "ราคาขายฝาก"
                        ? "btn btn-calculate bg-secondary-light w-100 text-center p-0 position-relative"
                        : "btn btn-calculate bg-white w-100 text-center p-0 position-relative"
                    }
                    disabled={this.state.product_main && this.state.product_main.product_type === "technician" ? true : false}
                    onClick={() => {
                      this.setState({
                        price_type: "ราคาขายฝาก",
                        price: this.state.product_main.deposit_price.toString(),
                        status_price: 3

                      })
                    }}
                  >
                    <label className="icon mb-0 pointer">{"\uf2e9"}</label>
                    <br /> ราคาขายฝาก
                    {this.state.price_type === "ราคาขายฝาก" && (
                      <div style={{ width: "50%", height: 2, backgroundColor: "#232F85", position: "absolute", bottom: 0, left: "25%" }} ></div>
                    )}
                  </button>
                </div>
                <div className="w-33 px-1">
                  <button
                    className={
                      this.state.price_type === "แก้ไข"
                        ? "btn btn-calculate bg-secondary-light w-100 text-center p-0 position-relative"
                        : "btn btn-calculate bg-white w-100 text-center p-0 position-relative"
                    }
                    onClick={() => {
                      this.setState({ price_type: "แก้ไข", status_price: 1 })
                    }}
                  >
                    <label className="icon mb-0 pointer">{"\uf2e9"}</label>
                    <br /> แก้ไข
                    {this.state.price_type === "แก้ไข" && (
                      <div style={{ width: "50%", height: 2, backgroundColor: "#232F85", position: "absolute", bottom: 0, left: "25%" }} ></div>
                    )}
                  </button>
                </div>
              </div>
              {this.state.price_type === "ราคาขายหน้าร้าน" && (
                <div className="w-100 d-flex justify-content-end align-items-center py-4 text-32">
                  <b>{baht(this.state.product_main.store_price)}</b>
                </div>
              )}
              {this.state.price_type === "ราคาขายส่ง" && (
                <div className="w-100 d-flex justify-content-end align-items-center py-4 text-32">
                  <b>{baht(this.state.product_main.wholesale_price)}</b>
                </div>
              )}
              {this.state.price_type === "ราคาขายฝาก" && (
                <div className="w-100 d-flex justify-content-end align-items-center py-4 text-32">
                  <b>{baht(this.state.product_main.deposit_price)}</b>
                </div>
              )}
              {this.state.price_type === "แก้ไข" && (
                <>
                  <div className="w-100">
                    <div className="d-flex py-2 ">
                      {/* ปุ่มลบ */}
                      <div className="btn btn-link text-secondary mt-3" onClick={() => { this.setState({ price: "", }) }}>
                        <label className="icon bg-dark text-white" style={{ width: 24, height: 24, borderRadius: 12 }}>{"\uf00d"}</label>
                      </div>
                      {/* ช่องกรอก */}
                      <input
                        id="price"
                        className="text-right w-100 border-0 text-32"
                        style={{ fontWeight: "bold" }}
                        type="text"
                        placeholder="0.00"
                        maxLength={14}
                        onChange={(e) => {
                          if (/^\d*\.?\d{0,2}$/.test(e.target.value) && Number(e.target.value) < 999999999) {
                            this.setState({ price: e.target.value });
                          }
                        }}
                        value={this.state.price}
                      />
                    </div>
                    <div className="row mx-0">
                      <div className="col-3 cal" onClick={() => { this.calculate(7) }}><div><h5>7</h5></div></div>
                      <div className="col-3 cal" onClick={() => { this.calculate(8) }}><div><h5>8</h5></div></div>
                      <div className="col-3 cal" onClick={() => { this.calculate(9) }}><div><h5>9</h5></div></div>
                      <div className="col-3 cal" onClick={() => { this.calculate(1000) }}><div className="primary"><h5>1,000</h5></div></div>
                      <div className="col-3 cal" onClick={() => { this.calculate(4) }}><div><h5>4</h5></div></div>
                      <div className="col-3 cal" onClick={() => { this.calculate(5) }}><div><h5>5</h5></div></div>
                      <div className="col-3 cal" onClick={() => { this.calculate(6) }}><div><h5>6</h5></div></div>
                      <div className="col-3 cal" onClick={() => { this.calculate(500) }}><div className="primary"><h5>500</h5></div></div>
                      <div className="col-3 cal" onClick={() => { this.calculate(1) }}><div><h5>1</h5></div></div>
                      <div className="col-3 cal" onClick={() => { this.calculate(2) }}><div><h5>2</h5></div></div>
                      <div className="col-3 cal" onClick={() => { this.calculate(3) }}><div><h5>3</h5></div></div>
                      <div className="col-3 cal" onClick={() => { this.calculate(100) }}><div className="primary"><h5>100</h5></div></div>
                      <div className="col-3 cal" onClick={() => { this.calculate(".") }}><div><h5>.</h5></div></div>
                      <div className="col-6 cal" onClick={() => { this.calculate(0) }}><div><h5>0</h5></div></div>
                      <div className="col-3 cal" onClick={() => { this.calculate("delete") }}><div><h5 className="icon">{"\uf55a"}</h5></div></div>
                    </div>
                  </div>
                </>
              )}
              <div className="w-100 d-flex mt-2">
                <div className="w-50 p-1">
                  <button
                    className="btn btn-outline-primary w-100"
                    onClick={() => { this.setState({ modal_edit_price: false }) }}
                  >
                    ย้อนกลับ
                  </button>
                </div>
                <div className="w-50 p-1">
                  <button
                    className="btn btn-primary w-100"
                    onClick={() => {
                      let data = this.state.order_list
                      data[this.state.index].price_by_item = Number(
                        this.state.price
                      )
                      data[this.state.index].status_price = this.state.status_price
                      data[this.state.index].total_price = data[this.state.index].qty * data[this.state.index].price_by_item
                      this.setState({
                        modal_edit_price: false,
                        order_list: data,
                      })
                      setTimeout(() => {
                        this.TotalPrice()
                      }, 10);
                    }}
                  >
                    ตกลง
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* แก้ไข ส่วนลด ค่าบริการจัดส่ง ค่ามัดจำ */}
        <Modal show={this.state.modal_type} onHide={() => { this.setState({ modal_type: false }) }} >
          <Modal.Body>
            <div className="w-100">
              <div className="w-100">
                <div className="d-flex py-2 ">
                  {/* ปุ่มลบ */}
                  <div className="btn btn-link text-secondary mt-3" onClick={() => { this.setState({ price: "", }) }}>
                    <label className="icon bg-dark text-white" style={{ width: 24, height: 24, borderRadius: 12 }}>{"\uf00d"}</label>
                  </div>
                  {/* ช่องกรอก */}
                  <input
                    id="price"
                    className="text-right w-100 border-0 text-32"
                    style={{ fontWeight: "bold" }}
                    type="text"
                    placeholder="0.00"
                    maxLength={14}
                    onChange={(e) => {
                      if (/^\d*\.?\d{0,2}$/.test(e.target.value) && Number(e.target.value) < 999999999) {
                        this.setState({ price: e.target.value });
                      }
                    }}
                    value={this.state.price}
                  />
                </div>
                <div className="row mx-0">
                  <div className="col-3 cal" onClick={() => { this.calculate(7) }}><div><h5>7</h5></div></div>
                  <div className="col-3 cal" onClick={() => { this.calculate(8) }}><div><h5>8</h5></div></div>
                  <div className="col-3 cal" onClick={() => { this.calculate(9) }}><div><h5>9</h5></div></div>
                  <div className="col-3 cal" onClick={() => { this.calculate(1000) }}><div className="primary"><h5>1,000</h5></div></div>
                  <div className="col-3 cal" onClick={() => { this.calculate(4) }}><div><h5>4</h5></div></div>
                  <div className="col-3 cal" onClick={() => { this.calculate(5) }}><div><h5>5</h5></div></div>
                  <div className="col-3 cal" onClick={() => { this.calculate(6) }}><div><h5>6</h5></div></div>
                  <div className="col-3 cal" onClick={() => { this.calculate(500) }}><div className="primary"><h5>500</h5></div></div>
                  <div className="col-3 cal" onClick={() => { this.calculate(1) }}><div><h5>1</h5></div></div>
                  <div className="col-3 cal" onClick={() => { this.calculate(2) }}><div><h5>2</h5></div></div>
                  <div className="col-3 cal" onClick={() => { this.calculate(3) }}><div><h5>3</h5></div></div>
                  <div className="col-3 cal" onClick={() => { this.calculate(100) }}><div className="primary"><h5>100</h5></div></div>
                  <div className="col-3 cal" onClick={() => { this.calculate(".") }}><div><h5>.</h5></div></div>
                  <div className="col-3 cal" onClick={() => { this.calculate(0) }}><div><h5>0</h5></div></div>
                  <div className="col-6 cal" onClick={() => { this.calculate("delete") }}><div><h5 className="icon">{"\uf55a"}</h5></div></div>
                </div>
              </div>
              <div className="w-100 d-flex mt-2">
                <div className="w-50 p-1">
                  <button
                    className="btn btn-outline-primary w-100"
                    onClick={() => { this.setState({ modal_type: false }) }}
                  >
                    ย้อนกลับ
                  </button>
                </div>
                <div className="w-50 p-1">
                  <button
                    className="btn btn-primary w-100"
                    onClick={() => {
                      if (this.state.txt_type === "ค่ามัดจำ") {
                        if (Number(this.state.price) > Number(this.state.total_price_final)) {
                          this.alert("info", "แจ้งเตือน", "ไม่สามารถกรอกค่ามัดจำมากกว่ายอดเงินรวมทั้งหมดได้")
                          this.setState({ price: Number(this.state.total_price_final - 1).toString() });
                          return
                        }
                        this.setState({ deposit: Number(this.state.price) })

                      } else if (this.state.txt_type === "ส่วนลด") {
                        if (Number(this.state.price) > Number(this.state.total_price_final)) {
                          this.alert("info", "แจ้งเตือน", "ไม่สามารถกรอกส่วนลดมากกว่ายอดเงินรวมทั้งหมดได้")
                          this.setState({ price: this.state.total_price_final.toString() });
                          return
                        }
                        this.setState({ discount: Number(this.state.price) });
                      } else if (this.state.txt_type === "ค่าบริการจัดส่ง") {
                        this.setState({ delivery_price: Number(this.state.price), });
                      }
                      this.setState({
                        modal_type: false,
                      })
                      setTimeout(() => {
                        this.TotalPrice()
                      }, 10)
                    }}
                  >
                    ตกลง
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* หมายเหตุ  */}
        <Modal show={this.state.modal_remark} onHide={() => { this.setState({ modal_remark: false }); this.clear_form() }} >
          <Modal.Header>
            <Modal.Title>
              <b>หมายเหตุ</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* ที่อยู่ */}
            <div className="w-100 mb-3">
              <textarea
                className="form-control"
                placeholder="กรอกข้อมูล"
                onChange={(e) => { this.setState({ input_remark: e.target.value }) }}
                value={this.state.input_remark}
              ></textarea>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_remark: false })
                    this.clear_form()
                  }}
                >
                  ย้อนกลับ
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    this.setState({
                      modal_remark: false,
                      remark: this.state.input_remark,
                    })
                  }}
                >
                  ยืนยัน
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* ข้อมูลการแจ้งซ่อมเพิ่มเติม */}
        <Modal show={this.state.modal_detail_repair} onHide={() => { this.setState({ modal_detail_repair: false }); this.clear_form_data_check() }} >
          <Modal.Header>
            <Modal.Title>
              <b>ข้อมูลการแจ้งซ่อมเพิ่มเติม</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 mb-3">
              <label>ส่วนของผู้แจ้งซ่อม</label>
              <input
                type="text"
                className=" form-control w-100 mb-3"
                placeholder="ชื่อ-นามสกุล"
                value={this.state.repair_detail ? this.state.repair_detail.customer_name : ""}
                onChange={(e) => {
                  let repair_detail = this.state.repair_detail
                  repair_detail.customer_name = e.target.value
                  this.setState({ repair_detail: repair_detail })
                }}
              ></input>
              <textarea
                className="form-control mb-3"
                placeholder="ที่อยู่ผู้แจ้งซ่อม"
                value={this.state.repair_detail ? this.state.repair_detail.customer_address : ""}
                onChange={(e) => {
                  let repair_detail = this.state.repair_detail
                  repair_detail.customer_address = e.target.value
                  this.setState({ repair_detail: repair_detail })
                }}
              ></textarea>
              <input
                type="tel"
                className=" form-control w-100"
                placeholder="เบอร์โทร"
                maxLength={10}
                value={this.state.repair_detail ? this.state.repair_detail.customer_phone : ""}
                onChange={(e) => {
                  let repair_detail = this.state.repair_detail
                  repair_detail.customer_phone = e.target.value.replace(/\D/g, "")
                  this.setState({ repair_detail: repair_detail })
                }}
              ></input>
            </div>
            <hr />
            <div className="row mx-0">
              <div className="col-12">
                <label>ช่างซ่อม</label>
              </div>
              <div className="col-12 mb-3 pl-0 pr-1 ">
                {this.state.technician_list && this.state.technician_list.map((item, index) => (
                  <div
                    className="card rounded-8 bg-blue-sky pl-3 py-2 pr-1 mb-3"
                    key={index}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <label className="w-100 mb-0">{item.full_name}</label>
                      <div>
                        <button
                          className="btn btn-link text-secondary "
                          style={{ height: 28 }}
                          onClick={() => {
                            let technician = this.state.technician_list
                            technician.splice(index, 10)
                            this.setState({
                              technician_list: technician,
                            })
                          }}
                        >
                          <label
                            className="icon bg-danger text-14 text-white mb-0"
                            style={{ width: 24, height: 24, borderRadius: 12 }}
                          >
                            {"\uf00d"}
                          </label>
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
                <Select
                  className="form-control px-0"
                  options={
                    this.state.data_user &&
                    this.state.data_user.map((item, index) => ({
                      value: item.user_id,
                      label: item.full_name,
                      item: item,
                    }))
                  }
                  placeholder="พิมพ์ชื่อ"
                  value={this.state.technician}
                  onChange={(e) => {
                    let technician = this.state.technician_list
                    let find = technician.find((item) => {
                      if (item.user_id === e.value) {
                        return true
                      } else {
                        return false
                      }
                    })
                    if (!find) {
                      technician.push(e.item)
                    } else {
                    }
                    this.setState({
                      technician_list: technician,
                    })
                  }}
                />
              </div>
              <div className="col-12">
                <label>วันเวลาแจ้งซ่อม</label>
              </div>
              <div className="col-12 mb-3 pl-0 pr-1 ">
                <input
                  className="form-control"
                  type="date"
                  placeholder="วัน เดือน ปี"
                  value={this.state.repair_detail ? this.state.repair_detail.date_time_repair : null}
                  min={this.state.check_date}
                  onChange={(e) => {
                    let repair_detail = this.state.repair_detail
                    repair_detail.date_time_repair = e.target.value
                    this.setState({ repair_detail: repair_detail })
                  }}
                />
              </div>
            </div>
            <hr />
            <div className="w-100 mb-3">
              <input
                type="text"
                className="form-control w-100"
                placeholder="หมายเหตุ"
                value={this.state.repair_detail ? this.state.repair_detail.remark_repair : ""}
                onChange={(e) => {
                  let repair_detail = this.state.repair_detail
                  repair_detail.remark_repair = e.target.value
                  this.setState({ repair_detail: repair_detail })
                }}
              ></input>
            </div>
            <hr />
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_detail_repair: false })
                    this.clear_form_data_check()
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    let repair_detail = this.state.repair_detail
                    if (repair_detail.customer_name === "" || repair_detail.customer_name === null) {
                      this.alert("info", "แจ้งเตือน", "กรุณากรอกชื่อผู้แจ้งซ่อม")
                      return
                    } else if (repair_detail.customer_address === "" || repair_detail.customer_address === null) {
                      this.alert("info", "แจ้งเตือน", "กรุณากรอกที่อยู่")
                      return
                    } else if (repair_detail.customer_phone === "" || repair_detail.customer_phone === null) {
                      this.alert("info", "แจ้งเตือน", "กรุณากรอกเบอร์โทรศัพท์")
                      return
                    } else if (
                      repair_detail.customer_phone.length !== 10
                    ) {
                      this.alert("info", "แจ้งเตือน", "กรุณากรอกเบอร์โทรศัพท์ให้ครบ 10 หลัก")
                      return
                    } else if (
                      repair_detail.customer_phone !== "" && !this.validatePhone(repair_detail.customer_phone)
                    ) {
                      this.alert("info", "แจ้งเตือน", "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง")
                      return
                    } else if (repair_detail.date_time_repair === "" || repair_detail.date_time_repair === null) {
                      this.alert("info", "แจ้งเตือน", "กรุณาระบุวันที่แจ้งซ่อม")
                      return
                    }
                    if (this.state.deposit === 0) {
                      this.setState({ price: this.state.total_price_final.toString() })
                    } else {
                      this.setState({ price: this.state.deposit.toString() })
                    }
                    let order_list = this.state.order_list;
                    for (let or of order_list) {
                      if (or.product_main.product_type === "technician") {
                        or.technician_list = this.state.technician_list;
                      }
                    }
                    setTimeout(() => {
                      this.setState({
                        modal_detail_repair: false,
                        modal_payment: true,
                        order_list: order_list
                      })
                    }, 10);
                  }}
                >
                  ถัดไป
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* ข้อมูลการแจ้งซ่อมเพิ่มเติม (บันทึก/รอชำระ) */}
        <Modal show={this.state.modal_detail_repair_save} onHide={() => { this.setState({ modal_detail_repair_save: false }); this.clear_form_data_check() }} >
          <Modal.Header>
            <Modal.Title>
              <b>ข้อมูลการแจ้งซ่อมเพิ่มเติม (บันทึก/รอชำระ)</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row mx-0">
              <div className="col-12">
                <label>ช่างซ่อม</label>
              </div>
              <div className="col-12 mb-3 pl-0 pr-1 ">
                {this.state.technician_list && this.state.technician_list.map((item, index) => (
                  <div
                    className="card rounded-8 bg-blue-sky pl-3 py-2 pr-1 mb-3"
                    key={index}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <label className="w-100 mb-0">{item.full_name}</label>
                      <div>
                        <button
                          className="btn btn-link text-secondary "
                          style={{ height: 28 }}
                          onClick={() => {
                            let technician = this.state.technician_list
                            technician.splice(index, 10)
                            this.setState({
                              technician_list: technician,
                            })
                          }}
                        >
                          <label
                            className="icon bg-danger text-14 text-white mb-0"
                            style={{ width: 24, height: 24, borderRadius: 12 }}
                          >
                            {"\uf00d"}
                          </label>
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
                <Select
                  className="form-control px-0"
                  options={
                    this.state.data_user &&
                    this.state.data_user.map((item, index) => ({
                      value: item.user_id,
                      label: item.full_name,
                      item: item,
                    }))
                  }
                  placeholder="พิมพ์ชื่อ"
                  value={this.state.technician}
                  onChange={(e) => {
                    let technician = this.state.technician_list
                    let find = technician.find((item) => {
                      if (item.user_id === e.value) {
                        return true
                      } else {
                        return false
                      }
                    })
                    if (!find) {
                      technician.push(e.item)
                    } else {
                    }
                    this.setState({
                      technician_list: technician,
                    })
                  }}
                />
              </div>
            </div>
            <hr />
            <div className="w-100 mb-3">
              <input
                type="text"
                className="form-control w-100"
                placeholder="หมายเหตุ"
                value={this.state.repair_detail ? this.state.repair_detail.remark_repair : ""}
                onChange={(e) => {
                  let repair_detail = this.state.repair_detail
                  repair_detail.remark_repair = e.target.value
                  this.setState({ repair_detail: repair_detail })
                }}
              ></input>
            </div>
            <hr />
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_detail_repair_save: false })
                    this.clear_form_data_check()
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    let repair_detail = this.state.repair_detail
                    if (this.state.deposit === 0) {
                      this.setState({ price: this.state.total_price_final.toString() })
                    } else {
                      this.setState({ price: this.state.deposit.toString() })
                    }
                    let order_list = this.state.order_list;
                    for (let or of order_list) {
                      if (or.product_main.product_type === "technician") {
                        or.technician_list = this.state.technician_list;
                      }
                    }
                    this.setState({
                      modal_detail_repair_save: false,
                      order_list: order_list,
                    });
                    setTimeout(() => {
                      if (this.state.edit_status) {
                        //กดมาจากพักออเดอร์จะเข้าเคสนี้
                        this.Edit()
                      } else {
                        this.Create()
                      }
                    }, 10);
                  }}
                >
                  ถัดไป
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* ปริ้น  */}
        <ModalPrint
          value={this.state.message}
          general={this.state.general}
          show={this.state.modal_print}
          onHide={() => {
            this.setState({ modal_print: false })
            window.location.href = "/main"
          }}
        />


        {/* ล้างข้อมูล */}
        <Modal show={this.state.modal_pause_clear} onHide={() => { this.setState({ modal_pause_clear: false }) }} >
          <Modal.Body>
            <div className="w-100 mb-3 text-center">
              <img className="clock-icon my-4" alt="logo" src={CLOCK}></img>
              <h2> <b style={{ color: "#595959" }}>แจ้งเตือน</b> </h2>
              <div className="w-75 text-center mx-auto text-secondary mb-2">
                มีการพักออร์เดอร์ค้างอยู่ในระบบ ยืนยันที่จะล้างข้อมูลการพักออร์เดอร์แล้วออกจากหน้านี้หรือไม่
              </div>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button className="btn btn-outline-primary w-100" onClick={() => { this.setState({ modal_pause_clear: false }) }} >
                  ย้อนกลับ
                </button>
              </div>
              <div className="w-50 p-1">
                <button className="btn btn-primary w-100" onClick={() => {
                  this.setState({ modal_pause_clear: false });
                  // this.Create()
                  setTimeout(() => {
                    localStorage.removeItem("POS");
                    window.location.href = this.state.page_name;
                  }, 10);
                }} >
                  ยืนยัน
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div >
    )
  }
}
