// JS
import "../../../../../global.js"

// COMPONENT
import MenuComponent from "../../../SideMenu/SideMenu"
import BreadcrumbComponent from "../../../Breadcrumb/Breadcrumb"

// LIBRARY
import Swal from "sweetalert2"
import { toast } from "react-toastify"
import Cookies from "universal-cookie"
import React, { Component } from "react"
import Pagination from "react-js-pagination"

import { Modal } from "react-bootstrap"

// CSS
import "./style.css"
import "../../../../../bootstrap.css"
import "react-slideshow-image/dist/styles.css"
import "bootstrap-daterangepicker/daterangepicker.css"

const cookies = new Cookies()
toast.configure()

const array_month = [
  "ม.ค.",
  "ก.พ.",
  "มี.ค.",
  "เม.ย.",
  "พ.ค.",
  "มิ.ย.",
  "ก.ค.",
  "ส.ค.",
  "ก.ย.",
  "ต.ค.",
  "พ.ย.",
  "ธ.ค.",
]
export default class ListStockMoveScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      token: cookies.get("token_aip_shop") ? "token " + cookies.get("token_aip_shop") : "",
      role: cookies.get("role_data"),
      modal_edit: false,
      modal_delete: false,
      modal_detail: false,

      page: 1,
      loading: false,
      search: "",
      data: null,
      detail: null,
      data_detail: null,
      end_date: "",
      start_date: "",
    }
  }
  async componentDidMount() {
    if (this.state.role && this.state.role.stock_move_list[0] === 1) {
      await this.Get_All()
    }
  }

  Get_All = () => {
    this.setState({ loading: true })

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
      body: JSON.stringify({
        data_search: this.state.search,
        start_date: this.state.start_date,
        end_date: this.state.end_date,

      }),
    }
    fetch(
      global.api + "v1/stock-mgt/move/filter-search/page/" + this.state.page,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ data: result.data })
          this.setState({ loading: false })
        } else {
          switch (result.data.message) {
            case "This Page is not available":
              break
            case "Token is not found":
              this.alert("info", "แจ้งเตือน", "เซสชั่นหมดอายุ")
              this.Logout()
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              // this.Logout();
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
        this.setState({ loading: false })
      })
  }
  Get_Detail = (id) => {
    this.setState({ loading: true })
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
    }
    fetch(global.api + "v1/stock-mgt/move/detail/" + id, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {

          this.setState({ data_detail: result.data.data })
          this.setState({ loading: false })
          setTimeout(() => {
            this.setState({ modal_detail: true })
          }, 100)
        } else {
          switch (result.data.message) {
            case "This Page is not available":
              break
            case "Role no access permission":
              this.alert("info", "ขออภัย", "อีเมลนี้ไม่ได้รับอนุญาตให้เข้าถึง")
              this.Logout()
              break
            case "Token is not found":
              this.alert("info", "แจ้งเตือน", "เซสชั่นหมดอายุ")
              this.Logout()
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              // this.Logout();
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
        this.setState({ loading: false })
      })
  }

  Logout = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
    }
    fetch(global.api + "v1/logout", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        cookies.remove("token_aip_shop")
        cookies.remove("role_data")
        cookies.remove("user_profile")
        cookies.remove("first_page")
        window.location.href = "/login"
      })
      .catch((e) => {
        this.alert_url("info", "แจ้งเตือน", "เกิดข้อผิดพลาด", "/login")
      })
  }
  alert(icon, title, detail) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    })
  }
  alert_url(icon, title, detail, url) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    }).then(() => {
      window.location.href = url
    })
  }

  handlePageChange(pageNumber) {
    this.setState({ page: pageNumber })
    setTimeout(() => {
      this.Get_All()
    }, 20)
  }

  clear_form() {
    this.setState({
      loading: false,
      modal_detail: false,
    })
  }
  date = (date) => {
    let datetime =
      date.split("T")[0].split("-")[2] +
      " " +
      array_month[Number(date.split("T")[0].split("-")[1]) - 1] +
      " " +
      (Number(date.split("T")[0].split("-")[0]) + 543) +
      ", " +
      date.split("T")[1].slice(0, 5) +
      " น."
    return datetime
  }

  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <MenuComponent />
          <div className="bodyw-100">
            <BreadcrumbComponent page1="หน้าหลัก" page2="รายการย้ายสต๊อก" />
            <div style={{ height: 40 }}></div>
            <div className="w-100 d-flex align-items-center justify-content-between px-3 mt-5">
              <h2>
                <b>รายการย้ายสต๊อก</b>
              </h2>
              {this.state.role && this.state.role.stock_move_list[1] === 1 && (
                <div>
                  <button
                    type="button"
                    className="btn btn-primary w-100 px-4"
                    onClick={() => {
                      // this.Login();
                      window.location.href = "/create-stock-move"
                    }}
                  >
                    ย้ายสต๊อกสินค้า
                  </button>
                </div>
              )}
            </div>
            <div className="w-100 p-3">
              <div className="card rounded">
                <div className="card-header rounded-top">
                  <div className="row d-flex justify-content-start">
                    <div className="d-flex">
                      <input
                        className="form-control bg-white wpx-200 mx-2 mb-3"
                        type="search"
                        placeholder="ค้นหาด้วยเลขที่เอกสาร"
                        value={this.state.search}
                        onChange={(e) => {
                          this.setState({ search: e.target.value })
                          if (e.target.value === "") {
                            this.setState({ page: 1, search: "" })
                            setTimeout(() => {
                              this.Get_All()
                            }, 20)
                          }
                        }}
                        onKeyPress={(event) => {
                          if (event.key === "Enter") {
                            this.setState({
                              page: 1,
                            })
                            setTimeout(() => {
                              this.Get_All()
                            }, 20)
                          }
                        }}
                      />
                    </div>

                    <div className="d-flex ml-2">
                      <input
                        type="date"
                        className="form-control bg-white"
                        value={this.state.start_date}
                        onChange={(e) => {
                          this.setState({ start_date: e.target.value })
                          this.setState({
                            page: 1,
                          })
                          setTimeout(() => {
                            this.Get_All()
                          }, 20)
                        }}
                      ></input>
                      <label class="mx-3 mt-2">ถึง</label>
                      <input
                        type="date"
                        className="form-control bg-white"
                        value={this.state.end_date}
                        onChange={(e) => {
                          this.setState({ end_date: e.target.value })
                          this.setState({
                            page: 1,
                          })
                          setTimeout(() => {
                            this.Get_All()
                          }, 20)
                        }}
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table design-table">
                      <thead>
                        <tr>
                          <th className="text-left" style={{ minWidth: 130 }}>วัน-เวลา</th>
                          <th className="text-left ">เลขที่เอกสาร</th>
                          <th className="text-left" style={{ minWidth: 130 }}>จากสถานที่</th>
                          <th className="text-left" style={{ minWidth: 130 }}>ไปยังสถานที่</th>
                          <th className="text-center" style={{ minWidth: 130 }}>รายละเอียด</th>
                        </tr>
                      </thead>
                      <tbody>
                        {((this.state.data &&
                          this.state.data.data.length === 0) ||
                          !this.state.data) && (
                            <tr>
                              <td colSpan={6} className="text-center">
                                -- ไม่มีข้อมูล --
                              </td>
                            </tr>
                          )}
                        {this.state.data &&
                          this.state.data.data.map((item, index) => (
                            <tr key={index}>
                              <td>{this.date(item.datetime_create)}</td>
                              <td>{item.document_code}</td>
                              <td>{item.stock_location_from_name}</td>
                              <td>{item.stock_location_to_name}</td>
                              <td className="text-center">
                                <button
                                  className="btn btn-link text-secondary"
                                  onClick={() => {
                                    this.Get_Detail(item.stock_move_id)


                                  }}
                                >
                                  <label className="icon">{"\uf15c"}</label>
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {/* pagination */}
              {this.state.data && this.state.data.data.length !== 0 && (
                <div className="d-flex justify-content-between row mx-0 w-100 px-3 my-4">
                  <div>
                    <label>
                      แสดง {this.state.data.end_index} รายการจาก{" "}
                      {this.state.data.count} รายการ
                    </label>
                  </div>
                  <div className="d-flex">
                    <button
                      className="btn-circle-white mx-1"
                      onClick={() => {
                        this.handlePageChange(1)
                      }}
                    >
                      <label className="icon">{"\uf100"}</label>
                    </button>
                    <Pagination
                      activePage={this.state.page}
                      itemsCountPerPage={10}
                      totalItemsCount={this.state.data.count}
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChange.bind(this)}
                      prevPageText={<label className="icon">{"\uf104"}</label>}
                      nextPageText={<label className="icon">{"\uf105"}</label>}
                      hideFirstLastPages={true}
                    />
                    <button
                      className="btn-circle-white mx-1"
                      onClick={() => {
                        this.handlePageChange(this.state.data.page_number)
                      }}
                    >
                      <label className="icon">{"\uf101"}</label>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* modal */}
        {/* Detail modal */}
        <Modal
          show={this.state.modal_detail}
          size={"lg"}
          onHide={() => {
            this.setState({ modal_detail: false })
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b>รายละเอียดการย้ายสต๊อก</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 d-flex row mx-0 mb-3">
              {/* เลขที่เอกสาร */}

              <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-2 px-1">
                <div className="card border-secondary text-center p-2">
                  <label className="text-14 text-secondary">
                    เลขที่เอกสาร:
                  </label>
                  <label>{this.state.data_detail && this.state.data_detail.document_code}</label>
                </div>
              </div>
              <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-2 px-1">
                <div className="card border-secondary text-center p-2">
                  <label className="text-14 text-secondary">วันและเวลา:</label>
                  <label>{this.state.data_detail && this.date(this.state.data_detail.datetime_create)}</label>
                </div>
              </div>
              <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-2 px-1">
                <div className="card border-secondary text-center p-2">
                  <label className="text-14 text-secondary pt-1">
                    จากสถานที่:
                  </label>
                  <label>{this.state.data_detail && this.state.data_detail.stock_location_from_name}</label>
                </div>
              </div>
              <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-2 px-1">
                <div className="card border-secondary text-center p-2">
                  <label className="text-14 text-secondary pt-1">
                    ไปยังสถานที่:
                  </label>
                  <label>{this.state.data_detail && this.state.data_detail.stock_location_to_name}</label>
                </div>
              </div>
              <div className="w-100">
                <table className="table design-table-sell design-table-sell-primary">
                  <thead>
                    <tr>
                      <th>ลำดับที่</th>
                      <th>รหัสสินค้า</th>
                      <th>ชื่อสินค้า/บริการ</th>
                      <th className="text-center">จำนวนที่ย้าย</th>
                    </tr>
                  </thead>
                  <tbody>

                    {this.state.data_detail && this.state.data_detail.data_list.map((item, index) => (
                      <tr>
                        <td className="text-left">{index + 1}</td>
                        <td className="text-left">
                          {item.product_code}
                        </td>
                        <td className="text-left">
                          {item.product_name}
                        </td>
                        <td className="text-center">
                          {item.qty}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="w-100 text-center">
              <button
                className="btn btn-outline-primary px-3 mx-auto"
                onClick={() => {
                  this.setState({ modal_detail: false })
                }}
              >
                ปิดหน้าต่าง
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}
