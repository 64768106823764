global.toggle = false;
global.api = "https://air-shop-backend-aphs3zf2uq-as.a.run.app/";
global.version = "1.0.8";


global.message = {
    "phone is none": "กรุณากรอกเบอร์โทรศัพท์",
    "full name is none": "กรุณากรอกชื่อ-นามสกุล",
    "user type not found": "กรุณากรอกเบอร์โทรศัพท์",
    "email is none": "กรุณากรอกอีเมล",
    "email is duplicate": "อีเมลนี้ถูกใช้งานไปแล้ว",
    "full name is duplicate": "ชื่อ-นามสกุลนี้ถูกใช้งานไปแล้ว",
    "company name is duplicate": "มีชื่อบริษัทนี้ในระบบแล้ว",
    "Transaction Error Field 'credit_day' expected a number but got ''.": "กรุณากรอกเครดิต(วัน)",
    "Invalid Credentials or activate account": "ข้อมูลประจำตัวไม่ถูกต้องหรือเปิดใช้งานบัญชี",
    "There is an active user on this account": "บัญชีผู้ใช้งานนี้ได้เข้าสู่ระบบในอุปกรณ์เครื่องอื่นอยู่",
    "Email is not verify": "อีเมลไม่ได้รับการยืนยัน",
    "Email no access permission": "อีเมลนี้ไม่ได้รับอนุญาตให้เข้าถึง",
    "Role no access permission": "ไม่ได้รับอนุญาตให้เข้าถึง",
    "Token is expired": "เซสชั่นหมดอายุ",
    "Token is not found": "เซสชั่นหมดอายุ",
    "User is not found": "ไม่พบผู้ใช้",
    "This Page is not available": "หน้านี้ไม่พร้อมใช้งาน",
    "Role is not found": "ไม่พบบทบาท",
    "Company is not found": "ไม่พบบริษัท",
    "Brand is not found": "ไม่พบยี่ห้อ",
    "BTU is not found": "ไม่พบ BTU",
    "Unit is not found": "ไม่พบหน่วย",
    "Model is not found": "ไม่พบรุ่น",
    "Map air is not found": "ไม่พบรายการแอร์",
    "Spare part is not found": "ไม่พบอะไหล่",
    "Product main is not found": "ไม่พบสินค้า",
    "Success": "สำเร็จ",
    "Stock location is not found": "ไม่พบเส้นทางสต๊อก",
    "Stock location is fail stock main not empty": "เส้นทางสต็อกล้มเหลว สต็อกหลักไม่ว่างเปล่า",
    "Stock main is not found": "ไม่พบสต๊อก",
    "Stock move is not found": "ไม่พบการย้ายสต๊อก",
    "Stock adjust is not found": "ไม่พบการปรับสต๊อก",
    "Item in stock not enough to remove": "สินค้าในสต็อกไม่เพียงพอที่จะนำออก",
    "Item in stock not enough": "สินค้าในสต็อกไม่เพียงพอ",
    "Stock balance over stock transaction": "ยอดคงเหลือมากกว่าในสต๊อก",
    "Stock balance less 0": "ยอดคงเหลือในสต๊อกน้อยกว่า 0",
    "Commission is not found": "ไม่พบค่าคอมมิชชั่น",
    "Commission employee is not found": "ไม่พบช่าง",
    "Not paid in full": "กรุณาชำระเงินเต็มจำนวน",
    "Status order main is not available": "สถานะคำสั่งซื้อไม่พร้อมใช้งาน",
    "Order main is not found": "ไม่พบคำสั่งซื้อ",
    "Order list is not found": "ไม่พบรายการสั่งซื้อ",
    "Payment detail is not found": "ไม่พบรายละเอียดการชำระเงิน",
    "good receipt main is not found": "ไม่พบใบเสร็จ",
    "the technician has a task on the date": "ช่างไม่พร้อมให้บริการในวันและเวลาที่เลือก",
    "Stock qty not zero": "จำนวนสินค้าคงคลังไม่เป็นศูนย์",
    "role name is duplicate": "มีชื่อบทบาทนี้อยู่ในระบบแล้ว",
    "role is not select": "ไม่ได้เลือกบทบาท",
    "role is active": "บทบาททำงานอยู่",
    "brand name is duplicate": "มีชื่อยี่ห้อนี้อยู่ในระบบแล้ว",
    "brand is active": "ยี่ห้อทำงานอยู่",
    "btu name is duplicate": "มีชื่อ btu นี้อยู่ในระบบแล้ว",
    "btu is active": "btu ทำงานอยู่",
    "unit name is duplicate": "มีชื่อหน่วยนี้อยู่ในระบบแล้ว",
    "Unit is active": "หน่วยเปิดใช้งาน",
    "model name is duplicate": "มีชื่อรุ่นนี้อยู่ในระบบแล้ว",
    "air map name is duplicate": "มีชื่อรายการแอร์นี้อยู่ในระบบแล้ว",
    "air map is active": "รายการแอร์ทำงานอยู่",
    "spaer part name is duplicate": "มีชื่ออะไหล่นี้อยู่ในระบบแล้ว",
    "Spare part is active": "อะไหล่ทำงานอยู่",
    "product main is duplicate": "มีชื่อสินค้านี้อยู่ในระบบแล้ว",
    "stock location name is duplicate": "มีเส้นทางสต๊อกนี้อยู่ในระบบแล้ว",
    "commission type name is duplicate": "มีชื่อคอมมิชชั่นนี้อยู่ในระบบแล้ว",
    "commission employee is duplicate": "มีชื่อช่างนี้อยู่ในระบบแล้ว",
    "good receipt main is duplicate": "มีใบเสร็จนี้อยู่ในระบบแล้ว",
    "order main number is duplicate": "มีคำสั่งซื้อนี้อยู่ในระบบแล้ว",
    "stock location is active": "เส้นทางสต๊อกใช้งานอยู่",
    "order main number is not found": "ไม่พบหมายเลขคำสั่งซื้อหลัก",
    "user type is not use payment type": "ไม่ได้รับอนุญาตให้เข้าถึง",
    "lot is not found": "ไม่พบล็อต",
    "unit id is none": "กรุณาเลือกหน่วย",
    "This field may not be blank.": "กรุณากรอกชื่อสินค้า",
    "product map id is none": "กรุณาเลือกยี่ห้อ/รุ่น/BTU",
    "spare part type id is none": "กรุณาเลือกประเภทอะไหล่",
    "bank number is duplicate": "พบหมายเลขบัญชีนี้ในระบบ",
    "Type product name is duplicate": "พบชื่อประเภทผ้านี้ในระบบ",
    "warehouse from id is none": "กรุณาเลือกโกดังต้นทาง",
    "warehouse to id is none": "กรุณาเลือกโกดังปลายทาง",
    "qty is none": "กรุณากรอกจำนวนไม้ที่ต้องการย้าย",
    "yard qty is none": "กรุณากรอกจำนวนหลาที่ต้องการย้าย",
    "Can't move product to the same stock location": "ไม่สามารถย้ายสินค้าไปยังโกดังเดียวกันได้",
    "user type is not owner ": "ปรับสต๊อกได้เฉาะเจ้าของร้านเท่านั้น",
    "Invoice overdue is not found": "ไม่พบข้อมูล",
    "Payment type is not available": "เลือกวิธีการชำระ",
    "User member id is required": "ต้องระบุรหัสสมาชิกผู้ใช้",
    "Bank is not found": "เลือกธนาคาร",
    "full name transfer is none": "เลือกธนาคาร",
    "qty is not 0 can't delete ": "ไม่สามารถลบสินค้าชนิดนี้ได้ เนื่องจากมีสินค้าคงเหลืออยู่ในสต๊อก",
    "warehouse is active": "โกดังมีการใช้งานอยู่",
    "warehouse name is duplicate": "มีชื่อโกดังนี้อยู่ในระบบแล้ว",
    "qty is 0 but yard not 0 ": "ไม่สามารถทำการขายสินค้าชนิดนี้ได้ เนื่องจากจำนวนไม้หรือหลา ไม่สอดคล้องกัน",
    "The user still has unpaid bills in the system.": "ผู้ใช้ยังมีบิลค้างชำระอยู่ในระบบ",
    "Product category is Using": "ไม่สามารถลบได้ ประเภทผ้ามีการใช้งานอยู่",
    "end date less start date": "ไม่สามารถเลือกวันที่สิ้นสุดน้อยกว่าวันที่เริ่มต้น",
    "User is still a pending payment.": "สมาชิกรายนี้มียอดคงค้างชำระอยู่ในระบบ ไม่สามารถลบข้อมูลได้"
};