// JS
import "../../../../global.js"

// COMPONENT
import MenuComponent from "../../SideMenu/SideMenu"
import BreadcrumbComponent from "../../Breadcrumb/Breadcrumb"

// LIBRARY
import Swal from "sweetalert2"
import { toast } from "react-toastify"
import Cookies from "universal-cookie"
import React, { Component } from "react"
import { Modal } from "react-bootstrap"
import Select from "react-select"

// CSS
import "./style.css"
import "../../../../bootstrap.css"
import "react-slideshow-image/dist/styles.css"
import "bootstrap-daterangepicker/daterangepicker.css"

const cookies = new Cookies()
toast.configure()

const array_month = [
  "ม.ค.",
  "ก.พ.",
  "มี.ค.",
  "เม.ย.",
  "พ.ค.",
  "มิ.ย.",
  "ก.ค.",
  "ส.ค.",
  "ก.ย.",
  "ต.ค.",
  "พ.ย.",
  "ธ.ค.",
]
export default class EditReceiptScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      token: cookies.get("token_aip_shop") ? "token " + cookies.get("token_aip_shop") : "",
      modal_date: false,
      modal_supplier: false,
      modal_code: false,
      modal_add: false,
      page: 1,
      loading: false,
      search: "",
      data: null,
      data_supplier: null,
      data_product: null,
      data_warehouse: null,
      detail: null,
      product_map: null,

      reciept_date: "",
      company_supplier_name: "",
      supplier_id: "",
      reciept_code: "",
      stock_location_from_id: "",
      qty: 0,
      status: "0",

      g_rec_main_id: this.props.location.query.id,
      date: new Date(),
      company_supplier_id: "",
      bill_receipt_code: "DOC" + new Date().getTime(),
      ref_reciept_code: "",
      discount: 0,
      total_price_all: 0,
      stock_main_list: [],
    }
  }
  async componentDidMount() {
    await this.Get_Receipt(this.props.location.query.id)
    await this.Get_Supplier()
    // this.Get_Supplier_by_id(this.props.location.query.company_id);
    await this.Get_WareHouse_All()
  }

  Edit = () => {

    let date = ""
    try {
      date =
        ("0" + this.state.date.getDate()).slice(-2) +
        "/" +
        ("0" + (this.state.date.getMonth() + 1)).slice(-2) +
        "/" +
        this.state.date.getFullYear()
    } catch (e) {
      date =
        this.state.date.slice(8, 10) +
        "/" +
        this.state.date.slice(5, 7) +
        "/" +
        this.state.date.slice(0, 4)
    }
    this.setState({ loading: true })
    let stock_main_list = null
    if (this.state.data) {
      for (let data of this.state.data) {
        if (stock_main_list === null) {
          stock_main_list = []
        }
        // if (data.price_by_item === 0 || data.price_by_item === "") {
        //   this.alert("info", "แจ้งเตือน", "กรุณากรอกราคาที่รับมา")
        //   this.setState({ loading: false })
        //   return
        // }
        stock_main_list.push({
          stock_main_id: data.stock_main_id,
          price_by_item: data.price_by_item,
          qty: data.qty,
          total_price_by_item: data.total_price_by_item,
        })
      }
    }
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
      body: JSON.stringify({
        g_rec_main_id: this.state.g_rec_main_id,
        date: date,
        company_supplier_id: this.state.company_supplier_id,
        ref_reciept_code: this.state.ref_reciept_code,
        discount: this.state.discount,
        total_price_all: this.state.total_price_all,
        status: Number(this.state.status),
        stock_main_list: stock_main_list,
      }),
    }
    fetch(global.api + "v1/goods-receipt/update", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ loading: false, page: 1 })
          this.alert_url(
            "success",
            "แก้ไขใบรับสินค้าเรียบร้อยแล้ว",
            "ใบรับสินค้า ถูกเพิ่มในระบบแล้ว",
            "/receipt"
          )
        } else {
          switch (result.data.message) {
            case "brand name is none":
              this.alert("info", "แจ้งเตือน", "กรุณากรอกชื่อใบรับสินค้า")
              break
            case "Token is not found":
              this.alert("info", "แจ้งเตือน", "เซสชั่นหมดอายุ")
              this.Logout()
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              // this.Logout();
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
        this.setState({ loading: false })
      })
  }
  Get_Receipt = (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
    }
    fetch(global.api + "v1/goods-receipt/detail/" + id, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result)
        if (result.status) {
          let qty = 0
          let stock_main_list = []
          let data = []
          for (let d of result.data.data.goods_receipt_list) {
            stock_main_list.push({
              stock_main_id: d.stock_main_data.stock_main_id,
              price_by_item: d.price_by_item,
              qty: d.qty,
              total_price_by_item: d.total_price_by_item,
            })
            data.push({
              stock_main_id: d.stock_main_data.stock_main_id,
              price_by_item: d.price_by_item,
              qty: d.qty,
              total_price_by_item: d.total_price_by_item,
              ...d.stock_main_data,
            })
            qty += d.qty
          }
          let date = new Date()

          this.setState({
            reciept_date: "",
            company_supplier_name:
              result.data.data.company_supplier_data.company_name,
            supplier_id: result.data.data.company_supplier_data.company_id,
            reciept_code: result.data.data.bill_receipt_code,
            stock_location_from_id: "",
            qty: qty,
            company_supplier_id:
              result.data.data.company_supplier_data.company_id,
            bill_receipt_code: result.data.data.bill_receipt_code,
            discount: result.data.data.discount,
            total_price_all:
              result.data.data.goods_receipt_list[0].total_price_all,
            stock_main_list: stock_main_list,
            data: data,
            status: result.data.data.decision_status.toString(),
            date:
              date.getFullYear() +
              "-" +
              ("0" + (Number(date.getMonth()) + 1)).slice(-2) +
              "-" +
              ("0" + date.getDate()).slice(-2) +
              "T" +
              ("0" + date.getHours()).slice(-2) +
              ":" +
              ("0" + date.getMinutes()).slice(-2),
          })
          this.setState({ loading: false })
        } else {
          switch (result.data.message) {
            case "Token is not found":
              this.alert("info", "แจ้งเตือน", "เซสชั่นหมดอายุ")
              this.Logout()
              break
            case "Role no access permission":
              this.alert("info", "ขออภัย", "อีเมลนี้ไม่ได้รับอนุญาตให้เข้าถึง")
              this.Logout()
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              // this.Logout();
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
        this.setState({ loading: false })
      })
  }
  Get_Product_All = (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
    }
    fetch(global.api + "v1/stock-mgt/main/all/" + id, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        result.data.data = result.data.data.filter(
          (e) => e.product_main_data.category_name !== "technician"
        )
        if (result.status) {
          this.setState({ data_product: result.data })
          this.setState({ loading: false })
        } else {
          switch (result.data.message) {
            case "Token is not found":
              this.alert("info", "แจ้งเตือน", "เซสชั่นหมดอายุ")
              this.Logout()
              break
            case "Role no access permission":
              this.alert("info", "ขออภัย", "อีเมลนี้ไม่ได้รับอนุญาตให้เข้าถึง")
              this.Logout()
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              // this.Logout();
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
        this.setState({ loading: false })
      })
  }
  Get_Supplier = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
    }
    fetch(global.api + "v1/user-member/supplier/all/supplier", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ data_supplier: result.data })
          this.setState({ loading: false })
        } else {
          switch (result.data.message) {
            case "Token is not found":
              this.alert("info", "แจ้งเตือน", "เซสชั่นหมดอายุ")
              this.Logout()
              break
            case "Role no access permission":
              this.alert("info", "ขออภัย", "อีเมลนี้ไม่ได้รับอนุญาตให้เข้าถึง")
              this.Logout()
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              // this.Logout();
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
        this.setState({ loading: false })
      })
  }
  Get_Supplier_by_id = (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
    }
    fetch(global.api + "v1/user-member/supplier/detail/" + id, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({
            company_supplier_id: result.data.data.company_id,
            company_supplier_name: result.data.data.company_name,
          })
          this.setState({ loading: false })
        } else {
          switch (result.data.message) {
            case "Token is not found":
              this.alert("info", "แจ้งเตือน", "เซสชั่นหมดอายุ")
              this.Logout()
              break
            case "Role no access permission":
              this.alert("info", "ขออภัย", "อีเมลนี้ไม่ได้รับอนุญาตให้เข้าถึง")
              this.Logout()
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              // this.Logout();
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
        this.setState({ loading: false })
      })
  }
  Get_WareHouse_All = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
    }
    fetch(global.api + "v1/stock-mgt/location/all", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ data_warehouse: result.data })
          this.setState({ loading: false })
        } else {
          switch (result.data.message) {
            case "Token is not found":
              this.alert("info", "แจ้งเตือน", "เซสชั่นหมดอายุ")
              this.Logout()
              break
            case "Role no access permission":
              this.alert("info", "ขออภัย", "อีเมลนี้ไม่ได้รับอนุญาตให้เข้าถึง")
              this.Logout()
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              // this.Logout();
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
        this.setState({ loading: false })
      })
  }
  Logout = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
    }
    fetch(global.api + "v1/logout", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        cookies.remove("token_aip_shop")
        cookies.remove("role_data")
        cookies.remove("user_profile")
        cookies.remove("first_page")
        window.location.href = "/login"
      })
      .catch((e) => {
        this.alert_url("info", "แจ้งเตือน", "เกิดข้อผิดพลาด", "/login")
      })
  }
  alert(icon, title, detail) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    })
  }
  alert_url(icon, title, detail, url) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    }).then(() => {
      window.location.href = url
    })
  }

  clear_form() {
    this.setState({
      loading: false,
      modal_detail: false,
    })
  }
  date = (date) => {
    let datetime = ""
    try {
      datetime =
        date.getDate() +
        " " +
        array_month[date.getMonth()] +
        " " +
        (date.getFullYear() + 543) +
        ", " +
        date.getHours() +
        ":" +
        date.getMinutes() +
        " น."
    } catch (e) {
      datetime =
        date.split("T")[0].split("-")[2] +
        " " +
        array_month[Number(date.split("T")[0].split("-")[1]) - 1] +
        " " +
        (Number(date.split("T")[0].split("-")[0]) + 543) +
        ", " +
        date.split("T")[1].slice(0, 5) +
        " น."
    }
    return datetime
  }
  add_product = () => {
    let data = {
      ...this.state.product_map,
      price_by_item: 0,
      qty: 0,
      total_price_by_item: 0,
    }
    if (this.state.data) {
      let body = this.state.data
      body.push(data)
      this.setState({ data: body })
    } else {
      this.setState({ data: [data] })
    }
    this.setState({ modal_add: false })
    setTimeout(() => {
    }, 1000)
  }
  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <MenuComponent />
          <div className="bodyw-100">
            <BreadcrumbComponent
              page1="หน้าหลัก"
              page2="ใบรับสินค้า"
              page3="แก้ไขใบรับสินค้า"
            />
            <div style={{ height: 40 }}></div>
            <div className="w-100 d-flex align-items-center justify-content-between px-3 mt-5">
              <h2>
                <b>แก้ไขใบรับสินค้า</b>
              </h2>
              <div>
                <button
                  type="button"
                  className="btn btn-outline-primary w-100 px-4"
                  onClick={() => {
                    window.location.href = "/receipt"
                  }}
                >
                  <span className="icon-brand mr-2">{"\uf060"}</span>
                  ย้อนกลับ
                </button>
              </div>
            </div>
            <div className="w-100 p-3">
              <div className="card rounded">
                <div className="card-header rounded-top">
                  <div className="row">
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                      <div className="row d-flex justify-content-center align-items-center">
                        วันที่รับ: {this.date(this.state.date)}
                        <button
                          type="button"
                          className="btn btn-link"
                          onClick={() => {
                            this.setState({
                              modal_date: true,
                              reciept_date: this.state.date,
                            })
                          }}
                        >
                          <span className="icon-brand mr-1 text-14">
                            {"\uf304"}
                          </span>
                          เปลี่ยน
                        </button>
                      </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 border-left border-right">
                      <hr className="d-block d-sm-none" />
                      <div className="row d-flex justify-content-center align-items-center">
                        ซัพพลายเออร์: {this.state.company_supplier_name}
                        <button
                          type="button"
                          className="btn btn-link"
                          onClick={() => {
                            this.setState({
                              modal_supplier: true,
                              supplier_id: this.state.company_supplier_id,
                            })
                          }}
                        >
                          <span className="icon-brand mr-1 text-14">
                            {"\uf304"}
                          </span>
                          เปลี่ยน
                        </button>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 ">
                      <hr className="d-block d-xl-none" />
                      <div className="row d-flex justify-content-center align-items-center">
                        เลขที่เอกสาร: {this.state.bill_receipt_code}
                        <button type="button" className="btn btn-link"></button>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 ">
                      <hr className="d-block d-xl-none" />
                      <div className="row d-flex justify-content-center align-items-center">
                        เอกสารอ้างอิง: {this.state.ref_receipt_code}
                        <button
                          type="button"
                          className="btn btn-link"
                          onClick={() => {
                            this.setState({
                              modal_code: true,
                              ref_reciept_code: this.state.ref_receipt_code,
                            })
                          }}
                        >
                          <span className="icon-brand mr-1 text-14">
                            {"\uf304"}
                          </span>
                          เปลี่ยน
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table design-table">
                      <thead>
                        <tr>
                          <th className="text-left wpx-200">รหัสสินค้า</th>
                          <th className="text-left">ชื่อสินค้า</th>
                          <th className="text-right wpx-200">ราคาที่รับมา</th>
                          <th className="text-right wpx-200">จำนวน</th>
                          <th className="text-right wpx-200">รวมเป็นเงิน</th>
                          <th className="text-center wpx-120">นำออก</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.data &&
                          this.state.data.map((item, index) => (
                            <tr key={index}>
                              <td>{item.stock_main_id.slice(0, 10)}</td>
                              <td>{item.product_main_data.product_name}</td>
                              <td>
                                <input
                                  type="number"
                                  className="form-control text-right"
                                  onChange={(e) => {
                                    let data = this.state.data
                                    data[index].price_by_item = Number(
                                      e.target.value
                                    )
                                    data[index].total_price_by_item =
                                      Number(e.target.value) *
                                      Number(data[index].qty)
                                    this.setState({ data: data })
                                    setTimeout(() => {
                                      let total = 0
                                      for (let d of this.state.data) {
                                        total += d.total_price_by_item
                                      }

                                      this.setState({
                                        total_price_all: total,
                                      })
                                    }, 100)
                                  }}
                                  value={item.price_by_item}
                                ></input>
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="form-control text-right"
                                  onChange={(e) => {
                                    let data = this.state.data
                                    data[index].qty = Number(e.target.value)
                                    data[index].total_price_by_item =
                                      Number(e.target.value) *
                                      Number(data[index].price_by_item)
                                    this.setState({ data: data })
                                    setTimeout(() => {
                                      let total = 0
                                      let qty = 0
                                      for (let d of this.state.data) {
                                        total += d.total_price_by_item
                                        qty += d.qty
                                      }

                                      this.setState({
                                        total_price_all: total,
                                        qty: Number(qty),
                                      })
                                    }, 100)
                                  }}
                                  value={item.qty}
                                ></input>
                              </td>
                              <td className="text-right">
                                {item.total_price_by_item
                                  ? item.total_price_by_item
                                    .toFixed(2)
                                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                                  : "0.00"}
                              </td>

                              <td className="text-right">
                                <button
                                  className="btn btn-link text-secondary "
                                  onClick={() => {
                                    let data = this.state.data
                                    data.splice(index, 1)
                                    this.setState({ data: data })

                                    setTimeout(() => {
                                      let total = 0
                                      let qty = 0
                                      for (let d of this.state.data) {
                                        total += d.total_price_by_item
                                        qty += d.qty
                                      }

                                      this.setState({
                                        total_price_all: total,
                                        qty: Number(qty),
                                      })
                                    }, 50)
                                  }}
                                >
                                  <label
                                    className="icon bg-danger text-14 text-white"
                                    style={{
                                      width: 24,
                                      height: 24,
                                      borderRadius: 12,
                                    }}
                                  >
                                    {"\uf00d"}
                                  </label>
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="w-100 d-flex align-items-center justify-content-end px-3 mb-3">
                  <div>
                    <button
                      type="button"
                      className="btn btn-outline-primary w-100 px-4"
                      onClick={() => {
                        this.setState({
                          modal_add: true,
                          data_product: null,
                          stock_location_from_id: "",
                          product_map: null,
                        })
                      }}
                    >
                      <span className="text-24 mr-2">+</span>
                      เพิ่มสินค้า
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom-menu row bg-white d-flex align-items-center justify-content-between mx-0 pt-3 px-4 position-fixed bottom-0">
              {/* <div className="d-flex align-items-center pb-3">
                <label className="mr-3 wpx-120">ส่วนลดทั้งหมด:</label>
                <input
                  type="number"
                  className="form-control text-right"
                  onChange={(e) => {
                    this.setState({ discount: e.target.value })
                  }}
                  value={this.state.discount}
                ></input>
              </div> */}
              <div></div>
              <div className="d-flex align-items-center pb-3">
                <select className="form-control wpx-150 mr-3" onChange={(e) => { this.setState({ status: e.target.value }) }} value={this.state.status}>
                  <option value="0">รออนุมัติ</option>
                  <option value="1">อนุมัติ</option>
                </select>
                <label className="mr-3 d-flex w-100">
                  รวมสินค้า {this.state.qty} ชิ้น:
                </label>
                <h4 className="mr-3 text-primary">
                  <b>
                    ฿
                    {(this.state.total_price_all - this.state.discount).toFixed(
                      2
                    )}
                  </b>
                </h4>
                <button
                  type="button"
                  className="btn btn-primary w-100 px-4"
                  onClick={() => {

                    if (
                      !this.state.company_supplier_id &&
                      this.state.type === "reciept"
                    ) {
                      this.alert("info", "แจ้งเตือน", "กรุณาเลือกผู้จำหน่าย")
                      return
                    } else if (
                      this.state.data === null ||
                      this.state.data.length === 0
                    ) {
                      this.alert("info", "แจ้งเตือน", "กรุณาเพิ่มสินค้า")
                      return
                    }

                    this.Edit()
                  }}
                >
                  บันทึกการรับสินค้า
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* modal */}
        {/* DATE modal */}
        <Modal
          show={this.state.modal_date}
          onHide={() => {
            this.setState({ modal_date: false })
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b>แก้ไขวันที่รับ</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* วันที่รับ */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>วันที่รับ:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control"
                  type="datetime-local"
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ reciept_date: e.target.value })
                  }}
                  value={this.state.reciept_date}
                />
              </div>
            </div>

            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_date: false })
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    this.setState({
                      date: this.state.reciept_date,
                      modal_date: false,
                    })
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* SUPPLIER modal */}
        <Modal
          show={this.state.modal_supplier}
          onHide={() => {
            this.setState({ modal_supplier: false })
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b>แก้ไขซัพพลายเออร์</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* เลือก Supplier */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>เลือก Supplier:</label>
              </div>
              <div className="w-100">
                <Select
                  className="form-control px-0"
                  options={
                    this.state.data_supplier &&
                    this.state.data_supplier.data.map((item, index) => ({
                      value: item.company_id,
                      label: item.company_name,
                    }))
                  }
                  placeholder="พิมพ์ชื่อ"
                  defaultValue={this.state.company_supplier_id}
                  defaultInputValue={this.state.company_supplier_name}
                  onChange={(e) => {
                    this.setState({
                      supplier_id: e.value,
                    })
                  }}
                />
              </div>
            </div>

            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_supplier: false })
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    this.Get_Supplier_by_id(this.state.supplier_id)
                    this.setState({
                      modal_supplier: false,
                    })
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* RECEIPT CODE modal */}
        <Modal
          show={this.state.modal_code}
          onHide={() => {
            this.setState({ modal_code: false })
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b>แก้ไขเลขที่เอกสาร</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* เลขที่เอกสาร */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>เลขที่เอกสาร:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control"
                  type="text"
                  maxLength={30}
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ reciept_code: e.target.value })
                  }}
                  value={this.state.reciept_code}
                />
              </div>
            </div>

            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_code: false })
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    this.setState({
                      bill_receipt_code: this.state.reciept_code,
                      modal_code: false,
                    })
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* ADD modal */}
        <Modal
          show={this.state.modal_add}
          onHide={() => {
            this.setState({ modal_add: false })
          }}
        >
          <Modal.Body>
            <div className="d-flex w-100 justify-content-between align-items-center mb-3">
              <h3>
                <b>ค้นหาสินค้า</b>
              </h3>
              <button
                className="btn btn-link text-secondary"
                onClick={() => {
                  this.setState({ modal_add: false })
                }}
              >
                <label
                  className="icon bg-gray text-14 text-white"
                  style={{
                    width: 24,
                    height: 24,
                    borderRadius: 12,
                  }}
                >
                  {"\uf00d"}
                </label>
              </button>
            </div>
            <hr />
            <div className="w-100 d-flex mb-3 align-items-center">
              <label className="wpx-90">สถานที่:</label>
              <select
                className="form-control"
                onChange={(e) => {
                  this.setState({
                    stock_location_from_id: e.target.value,
                  })
                  this.Get_Product_All(e.target.value)
                }}
                value={this.state.stock_location_from_id}
              >
                <option value="" selected disabled>
                  -- เลือก --
                </option>
                {this.state.data_warehouse &&
                  this.state.data_warehouse.data.map((item, index) => (
                    <option value={item.stock_location_id}>
                      {item.stock_location_name}
                    </option>
                  ))}
              </select>
            </div>
            {this.state.product_map && (
              <div className="w-100 d-flex mb-3">
                <div className="card rounded-8 bg-blue-sky pt-3 pl-3 pr-1 pb-1 w-100">
                  <div className="d-flex justify-content-between">
                    <h4>
                      {this.state.product_map.product_main_data.product_name}
                    </h4>
                  </div>
                  <label className="text-secondary">
                    รหัสสินค้า:{" "}
                    {this.state.product_map.product_main_data.product_code}
                  </label>
                </div>
              </div>
            )}
            {this.state.data_product && (
              <div className="w-100 d-flex mb-3">
                <Select
                  className="form-control px-0"
                  options={
                    this.state.data_product &&
                    this.state.data_product.data.map((item, index) => ({
                      value: item,
                      label: item.product_main_data.product_name,
                    }))
                  }
                  placeholder="พิมพ์ชื่อสินค้า"
                  defaultValue={this.state.product_map}
                  onChange={(e) => {
                    this.setState({
                      product_map: e.value,
                    })
                  }}
                />
              </div>
            )}
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_add: false })
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.product_map === null) {
                      this.alert(
                        "info",
                        "แจ้งเตือน",
                        "กรุณาเลือกสถานที่และสินค้า"
                      )
                    } else {
                      this.add_product()
                    }
                  }}
                >
                  เพิ่ม
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}
