// JS
import "../../../../../global.js"

// COMPONENT
import MenuComponent from "../../../SideMenu/SideMenu"
import BreadcrumbComponent from "../../../Breadcrumb/Breadcrumb"
import TYPE1 from "./type1.png"
import TYPE2 from "./type2.png"
import TYPE3 from "./type3.png"

import IMGAIR from "./imgair.png"
import IMGSPAREPART from "./imgsparepart.png"

// LIBRARY
import Swal from "sweetalert2"
import { toast } from "react-toastify"
import Cookies from "universal-cookie"
import React, { Component } from "react"
import Pagination from "react-js-pagination"
import { Modal } from "react-bootstrap"

// CSS
import "./style.css"
import "../../../../../bootstrap.css"
import "react-slideshow-image/dist/styles.css"
import "bootstrap-daterangepicker/daterangepicker.css"

const cookies = new Cookies()
toast.configure()
export default class ListProductScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      token: cookies.get("token_aip_shop") ? "token " + cookies.get("token_aip_shop") : "",
      role: cookies.get("role_data"),
      modal_add: false,
      modal_edit: false,
      modal_delete: false,
      modal_type: false,
      page: 1,
      loading: false,
      search: "",
      filter_status: "",
      data: null,
      detail: null,

      type: "air",
    }
  }
  async componentDidMount() {
    if (this.state.role && this.state.role.product_all[0] === 1) {
      await this.Get_All()
    }
  }

  Get_All = () => {
    this.setState({ loading: true })
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
      body: JSON.stringify({
        data_search: this.state.search,
        status: this.state.filter_status,
      }),
    }
    fetch(
      global.api + "v1/product-mgt/all/filter-search/page/" + this.state.page,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ data: result.data })
          this.setState({ loading: false })
        } else {
          switch (result.data.message) {
            case "This Page is not available":
              this.setState({ data: [] })
              break
            case "Token is not found":
              this.alert("info", "แจ้งเตือน", "เซสชั่นหมดอายุ")
              this.Logout()
              break
            case "Role no access permission":
              this.alert("info", "ขออภัย", "อีเมลนี้ไม่ได้รับอนุญาตให้เข้าถึง")
              this.Logout()
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              // this.Logout();
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
        this.setState({ loading: false })
      })
  }

  Delete = () => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
      accept: "application/json",
    }
    fetch(
      global.api + "v1/product-mgt/all/delete/" + this.state.detail.product_id,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ loading: false, page: 1 })
          this.setState({ modal_delete: false })
          this.alert(
            "success",
            "ลบสินค้าเรียบร้อยแล้ว",
            "สินค้า “" + this.state.detail.product_name + "” ถูกลบเรียบร้อยแล้ว"
          )
          setTimeout(() => {
            this.Get_All()
          }, 10)
        } else {
          switch (result.data.message) {
            case "Stock qty not zero":
              this.alert("info", "แจ้งเตือน", "มีสินค้าคงค้างอยู่ในสต๊อก")
              this.setState({ modal_delete: false })
              break
            case "Token is not found":
              this.alert("info", "แจ้งเตือน", "เซสชั่นหมดอายุ")
              this.Logout()
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
        this.setState({ loading: false })
      })
  }
  Logout = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
    }
    fetch(global.api + "v1/logout", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        cookies.remove("token_aip_shop")
        cookies.remove("role_data")
        cookies.remove("user_profile")
        cookies.remove("first_page")
        window.location.href = "/login"
      })
      .catch((e) => {
        this.alert_url("info", "แจ้งเตือน", "เกิดข้อผิดพลาด", "/login")
      })
  }
  alert(icon, title, detail) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    })
  }
  alert_url(icon, title, detail, url) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    }).then(() => {
      window.location.href = url
    })
  }

  handlePageChange(pageNumber) {
    this.setState({ page: pageNumber })
    setTimeout(() => {
      this.Get_All()
    }, 20)
  }

  clear_form() {
    this.setState({
      loading: false,
      modal_detail: false,
    })
  }
  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <MenuComponent />
          <div className="bodyw-100">
            <BreadcrumbComponent page1="หน้าหลัก" page2="สินค้าทั้งหมด" />
            <div style={{ height: 40 }}></div>
            <div className="w-100 d-flex align-items-center justify-content-between px-3 mt-5">
              <h2>
                <b>สินค้าทั้งหมด</b>
              </h2>
              {this.state.role && this.state.role.product_all[1] === 1 && (
                <div>
                  <button
                    type="button"
                    className="btn btn-primary w-100 px-4"
                    onClick={() => {
                      // this.Login();
                      this.setState({ modal_type: true })
                    }}
                  >
                    เพิ่มสินค้า
                  </button>
                </div>
              )}
            </div>
            <div className="w-100 p-3">
              <div className="card rounded">
                <div className="card-header rounded-top">
                  <div className="row justify-content-between px-3">
                    <input
                      className="form-control bg-white mb-2"
                      type="search"
                      placeholder="ค้นหาด้วยรหัสสินค้า ชื่อสินค้า"
                      value={this.state.search}
                      onChange={(e) => {
                        this.setState({ search: e.target.value })
                        if (e.target.value === "") {
                          this.setState({ page: 1 })
                          setTimeout(() => {
                            this.Get_All()
                          }, 20)
                        }
                      }}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          this.setState({
                            page: 1,
                          })
                          setTimeout(() => {
                            this.Get_All()
                          }, 20)
                        }
                      }}
                    />
                    {/* หมวดหมู่ */}
                    <div className="wpx-200">
                      <select
                        className="form-control-select bg-white text-center"
                        value={this.state.filter_status}
                        onChange={(e) => {
                          this.setState({
                            filter_status: e.target.value,
                            page: 1,
                          })
                          setTimeout(() => {
                            this.Get_All()
                          }, 20)
                        }}
                      >
                        <option value="" selected>
                          ทั้งหมด
                        </option>
                        <option value="air">เครื่องปรับอากาศ</option>
                        <option value="spare_part">อะไหล่</option>
                        <option value="technician">งานช่าง</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table design-table">
                      <thead>
                        <tr>
                          <th className="text-left" style={{ minWidth: 100 }}>รหัสสินค้า</th>
                          <th className="text-center" style={{ minWidth: 100 }}>ภาพสินค้า</th>
                          <th className="text-left" style={{ minWidth: 100 }}>ชื่อสินค้า</th>
                          <th className="text-left">ยี่ห้อ</th>
                          <th className="text-left" style={{ minWidth: 100 }}>หมวดหมู่</th>
                          <th className="text-left" style={{ minWidth: 100 }}>หน่วยนับ</th>
                          <th className="text-right" style={{ minWidth: 170 }}>
                            แจ้งเตือนสต๊อกสินค้า
                          </th>
                          <th className="text-right" style={{ minWidth: 130 }}>ราคาหน้าร้าน</th>
                          {this.state.role &&
                            (this.state.role.product_all[2] === 1 ||
                              this.state.role.product_all[3] === 1) && (
                              <th className="text-center" style={{ minWidth: 130 }}>จัดการ</th>
                            )}
                        </tr>
                      </thead>
                      <tbody>
                        {((this.state.data &&
                          this.state.data.data.length === 0) ||
                          !this.state.data) && (
                            <tr>
                              <td colSpan={9} className="text-center">
                                -- ไม่มีข้อมูล --
                              </td>
                            </tr>
                          )}
                        {this.state.data &&
                          this.state.data.data.map((item, index) => (
                            <tr key={index}>
                              <td>{item.product_code}</td>
                              <td className="text-center">
                                <div className="rounded d-flex align-items-center justify-content-center overflow-hidden" style={{ width: 50, height: 50 }}>
                                  {item.picture ? (
                                    <img
                                      className="rounded img w-100 h-100"
                                      alt="logo"
                                      src={item.picture}
                                      style={{ objectFit: "contain", width: 50, height: 50 }}
                                    ></img>
                                  ) : (
                                    <div>
                                      <img
                                        className="rounded img w-75 h-75"
                                        alt="logo"
                                        src={
                                          item.category_name === "air"
                                            ? IMGAIR
                                            : IMGSPAREPART
                                        }
                                        style={{ objectFit: "contain", width: 50, height: 50 }}
                                      ></img>
                                      <div className="d-flex justify-content-center align-items-center">
                                        <label className="text-6 text-empty">
                                          ยังไม่ได้อัปโหลดรูป
                                        </label>
                                      </div>
                                    </div>
                                  )}</div>
                              </td>
                              <td>{item.product_name}</td>
                              <td>
                                {item.product_data
                                  ? item.product_data.brand_name
                                  : "-"}
                              </td>
                              <td>
                                {item.category_name === "air"
                                  ? "เครื่องปรับอากาศ"
                                  : item.category_name === "technician"
                                    ? "งานช่าง"
                                    : "อะไหล่"}
                              </td>
                              <td>{item.unit_name ? item.unit_name : "-"}</td>
                              <td className="text-center">
                                {item.safety_stock_noti ? item.safety_stock_noti : "-"}
                              </td>
                              <td className="text-right">
                                {item.store_price
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                              </td>

                              <td className="text-center">
                                {this.state.role &&
                                  this.state.role.product_all[2] === 1 && (
                                    <button
                                      className="btn btn-link text-secondary"
                                      onClick={() => {
                                        window.location.href =
                                          "/edit-product?id=" + item.product_id
                                      }}
                                    >
                                      <label className="icon mr-2">
                                        {"\uf304"}
                                      </label>
                                    </button>
                                  )}
                                {this.state.role &&
                                  this.state.role.product_all[3] === 1 && (
                                    <button
                                      className="btn btn-link text-secondary"
                                      onClick={() => {
                                        // this.Login();
                                        this.setState({
                                          modal_delete: true,
                                          detail: item,
                                        })
                                      }}
                                    >
                                      <label className="icon mr-2">
                                        {"\uf2ed"}
                                      </label>
                                    </button>
                                  )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {/* pagination */}
              {this.state.data && this.state.data.data.length !== 0 && (
                <div className="d-flex justify-content-between row mx-0 w-100 px-3 my-4">
                  <div>
                    <label>
                      แสดง {this.state.data.end_index} รายการจาก{" "}
                      {this.state.data.count} รายการ
                    </label>
                  </div>
                  <div className="d-flex">
                    <button
                      className="btn-circle-white mx-1"
                      onClick={() => {
                        this.handlePageChange(1)
                      }}
                    >
                      <label className="icon">{"\uf100"}</label>
                    </button>
                    <Pagination
                      activePage={this.state.page}
                      itemsCountPerPage={10}
                      totalItemsCount={this.state.data.count}
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChange.bind(this)}
                      prevPageText={<label className="icon">{"\uf104"}</label>}
                      nextPageText={<label className="icon">{"\uf105"}</label>}
                      hideFirstLastPages={true}
                    />
                    <button
                      className="btn-circle-white mx-1"
                      onClick={() => {
                        this.handlePageChange(this.state.data.page_number)
                      }}
                    >
                      <label className="icon">{"\uf101"}</label>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* modal */}
        {/* select type add modal */}
        <Modal
          show={this.state.modal_type}
          onHide={() => {
            this.setState({ modal_type: false })
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b>คุณต้องการเพิ่มสินค้าประเภทใด</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 mb-3">
              <div className="row mx-0">
                <div
                  className="col-6 px-1 mb-3 pointer"
                  onClick={() => {
                    this.setState({ type: "air" })
                  }}
                >
                  <div
                    className={
                      this.state.type === "air"
                        ? "card bg-secondary-light p-2 text-center border-primary"
                        : "card bg-secondary-light p-2 text-center"
                    }
                  >
                    <div className="d-flex w-100 justify-content-end">
                      <input
                        type="checkbox"
                        readOnly
                        checked={this.state.type === "air"}
                      ></input>
                    </div>
                    <h2>
                      <img className="logo-type" alt="logo" src={TYPE1}></img>
                    </h2>
                    <label>เครื่องปรับอากาศ</label>
                  </div>
                </div>
                <div
                  className="col-6 px-1 mb-3 pointer"
                  onClick={() => {
                    this.setState({ type: "spare_part" })
                  }}
                >
                  <div
                    className={
                      this.state.type === "spare_part"
                        ? "card bg-secondary-light p-2 text-center border-primary"
                        : "card bg-secondary-light p-2 text-center"
                    }
                  >
                    <div className="d-flex w-100 justify-content-end">
                      <input
                        type="checkbox"
                        readOnly
                        checked={this.state.type === "spare_part"}
                      ></input>
                    </div>
                    <h2>
                      <img className="logo-type" alt="logo" src={TYPE2}></img>
                    </h2>
                    <label>อะไหล่</label>
                  </div>
                </div>
                <div
                  className="col-6 px-1 mb-3 pointer"
                  onClick={() => {
                    this.setState({ type: "technician" })
                  }}
                >
                  <div
                    className={
                      this.state.type === "technician"
                        ? "card bg-secondary-light p-2 text-center border-primary"
                        : "card bg-secondary-light p-2 text-center"
                    }
                  >
                    <div className="d-flex w-100 justify-content-end">
                      <input
                        type="checkbox"
                        readOnly
                        checked={this.state.type === "technician"}
                      ></input>
                    </div>
                    <h2>
                      <img className="logo-type" alt="logo" src={TYPE3}></img>
                    </h2>
                    <label>งานซ่อม</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_type: false })
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    window.location.href =
                      "/create-product?type=" + this.state.type
                  }}
                >
                  ยืนยัน
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* delete modal */}
        <Modal
          show={this.state.modal_delete}
          onHide={() => {
            this.setState({ modal_delete: false })
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b>ยืนยันการลบสินค้า</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 d-flex mb-3">
              <label>
                ท่านแน่ในว่าต้องการลบสินค้า “
                {this.state.detail && this.state.detail.product_name}”
                หลังจากท่านยืนยันการยกแล้วจะไม่สามารถย้อนคืนข้อมูลได้
              </label>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_delete: false })
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-danger w-100"
                  onClick={() => {
                    this.Delete()
                  }}
                >
                  ยืนยัน
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}
