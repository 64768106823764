// JS
import "../../../../global.js"

// COMPONENT
import MenuComponent from "../../SideMenu/SideMenu"
import BreadcrumbComponent from "../../Breadcrumb/Breadcrumb"

// LIBRARY
import Swal from "sweetalert2"
import { toast } from "react-toastify"
import Cookies from "universal-cookie"
import React, { Component } from "react"
import Pagination from "react-js-pagination"

// CSS
import "./style.css"
import "../../../../bootstrap.css"
import "react-slideshow-image/dist/styles.css"
import "bootstrap-daterangepicker/daterangepicker.css"

const cookies = new Cookies()
toast.configure()
const array_month = [
  "ม.ค.",
  "ก.พ.",
  "มี.ค.",
  "เม.ย.",
  "พ.ค.",
  "มิ.ย.",
  "ก.ค.",
  "ส.ค.",
  "ก.ย.",
  "ต.ค.",
  "พ.ย.",
  "ธ.ค.",
]
export default class ListStockPathScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      token: cookies.get("token_aip_shop") ? "token " + cookies.get("token_aip_shop") : "",
      role: cookies.get("role_data"),
      modal_add: false,
      modal_edit: false,
      modal_delete: false,
      page: 1,
      loading: false,
      search: "",
      start_date: "",
      end_date: "",
      stock_tns_status: "",
      stock_location_from_id: "",
      data: null,
      detail: null,
      warhouse: null,
    }
  }
  async componentDidMount() {
    if (this.state.role && this.state.role.transaction_stock[0] === 1) {
      await this.Get_All()
      await this.Get_Warehouse_All()
    }
  }

  Get_All = () => {
    this.setState({ loading: true })
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
      body: JSON.stringify({
        data_search: this.state.search,
        start_date: this.state.start_date,
        end_date: this.state.end_date,
        stock_location_from_id: this.state.stock_location_from_id,
        stock_tns_status: this.state.stock_tns_status
      }),
    }
    fetch(
      global.api +
      "v1/stock-mgt/transaction/filter-search/page/" +
      this.state.page,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ data: result.data })
          this.setState({ loading: false })
        } else {
          switch (result.data.message) {
            case "This Page is not available":
              break
            case "Token is not found":
              this.alert("info", "แจ้งเตือน", "เซสชั่นหมดอายุ")
              this.Logout()
              break
            case "Role no access permission":
              this.alert("info", "ขออภัย", "อีเมลนี้ไม่ได้รับอนุญาตให้เข้าถึง")
              this.Logout()
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              // this.Logout();
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")

        this.setState({ loading: false })
      })
  }

  Get_Warehouse_All = () => {
    this.setState({ loading: true })
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
    }
    fetch(global.api + "v1/stock-mgt/location/all", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ warhouse: result.data.data })
          this.setState({ loading: false })
        } else {
          switch (result.data.message) {
            case "This Page is not available":
              break
            case "Token is not found":
              this.alert("info", "แจ้งเตือน", "เซสชั่นหมดอายุ")
              this.Logout()
              break
            case "Role no access permission":
              this.alert("info", "ขออภัย", "อีเมลนี้ไม่ได้รับอนุญาตให้เข้าถึง")
              this.Logout()
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              // this.Logout();
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
        this.setState({ loading: false })
      })
  }
  Logout = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
    }
    fetch(global.api + "v1/logout", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        cookies.remove("token_aip_shop")
        cookies.remove("role_data")
        cookies.remove("user_profile")
        cookies.remove("first_page")
        window.location.href = "/login"
      })
      .catch((e) => {
        this.alert_url("info", "แจ้งเตือน", "เกิดข้อผิดพลาด", "/login")
      })
  }
  alert(icon, title, detail) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    })
  }
  alert_url(icon, title, detail, url) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    }).then(() => {
      window.location.href = url
    })
  }

  handlePageChange(pageNumber) {
    this.setState({ page: pageNumber })
    setTimeout(() => {
      this.Get_All()
    }, 20)
  }

  clear_form() {
    this.setState({
      loading: false,
      modal_detail: false,
    })
  }
  date = (date) => {
    let datetime =
      date.split("T")[0].split("-")[2] +
      " " +
      array_month[Number(date.split("T")[0].split("-")[1]) - 1] +
      " " +
      (Number(date.split("T")[0].split("-")[0]) + 543) +
      ", " +
      date.split("T")[1].slice(0, 5) +
      " น."
    return datetime
  }
  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container ">
          <MenuComponent />
          <div className="bodyw-100">
            <BreadcrumbComponent page1="หน้าหลัก" page2="เส้นทางสต๊อก" />
            <div style={{ height: 40 }}></div>
            <div className="w-100 d-flex align-items-center justify-content-between px-3 mt-5">
              <h2>
                <b>เส้นทางสต๊อก</b>
              </h2>
            </div>
            <div className="w-100 p-3">
              <div className="card rounded">
                <div className="card-header rounded-top d-flex row mx-0 justify-content-between">
                  <input
                    className="form-control bg-white wpx-300 mx-2 mb-3"
                    type="search"
                    placeholder="ค้นหาด้วยชื่อสินค้า"
                    value={this.state.search}
                    onChange={(e) => {
                      this.setState({ search: e.target.value })
                      if (e.target.value === "") {
                        this.setState({ page: 1, search: "" })
                        setTimeout(() => {
                          this.Get_All()
                        }, 20)
                      }
                    }}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        this.setState({
                          page: 1,
                        })
                        setTimeout(() => {
                          this.Get_All()
                        }, 20)
                      }
                    }}
                  />
                  <div className="d-flex row mx-0">
                    <div className="d-flex row mx-0">
                      <input
                        type="date"
                        className="form-control bg-white wpx-200 mx-2 mb-3"
                        value={this.state.start_date}
                        onChange={(e) => {
                          this.setState({ start_date: e.target.value })
                          this.setState({
                            page: 1,
                          })
                          setTimeout(() => {
                            this.Get_All()
                          }, 20)
                        }}
                      ></input>
                      <label class="mx-2 mt-2">ถึง</label>
                      <input
                        type="date"
                        className="form-control bg-white wpx-200 mx-2 mb-3"
                        value={this.state.end_date}
                        onChange={(e) => {
                          this.setState({ end_date: e.target.value })
                          this.setState({
                            page: 1,
                          })
                          setTimeout(() => {
                            this.Get_All()
                          }, 20)
                        }}
                      ></input>
                    </div>
                    <div className="d-flex row mx-0">
                      <select
                        className="form-control bg-white wpx-200 mx-2 mb-3"
                        onChange={(e) => {
                          this.setState({
                            stock_tns_status: e.target.value,
                            page: 1,
                          })
                          setTimeout(() => {
                            this.Get_All()
                          }, 20)
                        }}
                        value={this.state.stock_tns_status}
                      >
                        <option value="" selected>
                          -- เลือกประเภททั้งหมด --
                        </option>
                        <option value="1">ย้ายสินค้า</option>
                        <option value="2">ปรับสินค้า</option>
                        <option value="3">รับสินค้า</option>
                      </select>

                      <select
                        className="form-control bg-white wpx-200 mx-2 mb-3"
                        value={this.state.stock_location_from_id}
                        onChange={(e) => {
                          this.setState({
                            page: 1,
                            stock_location_from_id: e.target.value,
                          })
                          setTimeout(() => {
                            this.Get_All()
                          }, 20)
                        }}
                      >
                        <option value="" selected>
                          -- เลือกสถานที่ทั้งหมด --
                        </option>
                        {this.state.warhouse &&
                          this.state.warhouse.map((item, index) => (
                            <option value={item.stock_location_id} key={index}>
                              {item.stock_location_name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table design-table">
                      <thead>
                        <tr>
                          <th className="text-left" style={{ minWidth: 200 }}>วัน-เวลา</th>
                          <th className="text-left" style={{ minWidth: 100 }}>รหัสสินค้า</th>
                          <th className="text-left " style={{ minWidth: 100 }}>ชื่อสินค้า</th>
                          <th className="text-center" style={{ minWidth: 130 }}>จากสถานที่</th>
                          <th className="text-center" style={{ minWidth: 130 }}>ไปยังสถานที่</th>
                          <th className="text-center" style={{ minWidth: 150 }}>สต็อก</th>
                          <th className="text-center" style={{ minWidth: 100 }}>จำนวน</th>
                        </tr>
                      </thead>
                      <tbody>
                        {((this.state.data &&
                          this.state.data.data.length === 0) ||
                          !this.state.data) && (
                            <tr>
                              <td colSpan={7} className="text-center">
                                -- ไม่มีข้อมูล --
                              </td>
                            </tr>
                          )}
                        {this.state.data &&
                          this.state.data.data.map((item, index) => (
                            <tr key={index}>
                              <td>{this.date(item.datetime_create)}</td>
                              <td>
                                {item.stock_tns_detail.product_code
                                  ? item.stock_tns_detail.product_code
                                  : "-"}
                              </td>
                              <td>
                                {item.stock_tns_detail.product_name
                                  ? item.stock_tns_detail.product_name
                                  : "-"}
                              </td>
                              <td className="text-center">
                                {item.stock_tns_detail.stock_location_name
                                  ? item.stock_tns_detail.stock_location_name
                                  : item.stock_tns_detail
                                    .stock_location_from_name
                                    ? item.stock_tns_detail
                                      .stock_location_from_name
                                    : "-"}
                              </td>
                              <td className="text-center">
                                {item.stock_tns_detail.stock_location_to_name
                                  ? item.stock_tns_detail.stock_location_to_name
                                  : "-"}
                              </td>
                              <td className="text-center">
                                <span
                                  className={"status bg-gray-c9 text-white"}
                                >
                                  {item.status === 1
                                    ? "ย้ายสต๊อก"
                                    : item.status === 2
                                      ? "ปรับสต๊อก"
                                      : "รับสินค้า"}
                                </span>
                              </td>
                              <td className="text-center">
                                {item.qty ? item.qty : "-"}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {/* pagination */}
              {this.state.data && this.state.data.data.length !== 0 && (
                <div className="d-flex justify-content-between row mx-0 w-100 px-3 my-4">
                  <div>
                    <label>
                      แสดง {this.state.data.end_index} รายการจาก{" "}
                      {this.state.data.count} รายการ
                    </label>
                  </div>
                  <div className="d-flex">
                    <button
                      className="btn-circle-white mx-1"
                      onClick={() => {
                        this.handlePageChange(1)
                      }}
                    >
                      <label className="icon">{"\uf100"}</label>
                    </button>
                    <Pagination
                      activePage={this.state.page}
                      itemsCountPerPage={10}
                      totalItemsCount={this.state.data.count}
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChange.bind(this)}
                      prevPageText={<label className="icon">{"\uf104"}</label>}
                      nextPageText={<label className="icon">{"\uf105"}</label>}
                      hideFirstLastPages={true}
                    />
                    <button
                      className="btn-circle-white mx-1"
                      onClick={() => {
                        this.handlePageChange(this.state.data.page_number)
                      }}
                    >
                      <label className="icon">{"\uf101"}</label>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
