// JS
import "../../../global.js"

// COMPONENT
import MenuComponent from "../SideMenu/SideMenu.js"
import BreadcrumbComponent from "../Breadcrumb/Breadcrumb.js"

// LIBRARY
import Swal from "sweetalert2"
import { toast } from "react-toastify"
import Cookies from "universal-cookie"
import React, { Component } from "react"
import { Modal } from "react-bootstrap"

// CSS
import "./style.css"
import "../../../bootstrap.css"
import "react-slideshow-image/dist/styles.css"
import "bootstrap-daterangepicker/daterangepicker.css"

const cookies = new Cookies()
toast.configure()
export default class ClearScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      token: cookies.get("token_aip_shop") ? "token " + cookies.get("token_aip_shop") : "",
      role: cookies.get("role_data"),
      modal_delete: false,

      start_date: "",
      end_date: ""
    }
  }
  async componentDidMount() {
  }

  Delete = () => {
    this.setState({ loading: true })
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
      body: JSON.stringify({
        start_date: this.state.start_date,
        end_date: this.state.end_date
      }),
      accept: "application/json",
    }
    fetch(global.api + "v1/stock-mgt/main/clear-data", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.alert(
            "success",
            "ลบข้อมูลเรียบร้อยแล้ว",
            "ข้อมูลในวันที่เลือกถูกลบเรียบร้อยแล้ว"
          )
          this.setState({ loading: false, modal_delete: false })
        } else {
          this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
        this.setState({ loading: false })
      })
  }

  Logout = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
    }
    fetch(global.api + "v1/logout", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        cookies.remove("token_aip_shop")
        cookies.remove("role_data")
        cookies.remove("user_profile")
        cookies.remove("first_page")
        window.location.href = "/login"
      })
      .catch((e) => {
        this.alert_url("info", "แจ้งเตือน", "เกิดข้อผิดพลาด", "/login")
      })
  }

  alert(icon, title, detail) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    })
  }
  alert_url(icon, title, detail, url) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    }).then(() => {
      window.location.href = url
    })
  }

  handlePageChange(pageNumber) {
    this.setState({ page: pageNumber })
    setTimeout(() => {
      this.Get_All()
    }, 1)
  }


  NumberZero = (number, length) => {
    return length ? number.toString().padStart(length, '0') : number.toString().padStart(2, '0');
  }
  format_date = (date, format, locale) => {
    let array_month = ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."];
    let array_full_month = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"];
    let new_date = new Date(date);
    let full_date = "-";
    let day = new_date.getDate();
    let month = new_date.getMonth();
    let year = new_date.getFullYear();
    let hour = new_date.getHours();
    let minute = new_date.getMinutes();
    let second = new_date.getSeconds();
    if (locale) {
      if (locale.toLowerCase() === "en") {
        array_month = ["Jan.", "Feb.", "Mar.", "Apr.", "May.", "Jun.", "Jul.", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."];
        array_full_month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        year = new_date.getFullYear() > 2100 ? new_date.getFullYear() - 543 : new_date.getFullYear();
      } else {
        year = new_date.getFullYear() > 2100 ? new_date.getFullYear() : new_date.getFullYear() + 543;
      }
    } else {
      year = new_date.getFullYear() > 2100 ? new_date.getFullYear() : new_date.getFullYear() + 543;
    }
    if (format) {
      format = format.toLowerCase();
      // TIME
      if (format.includes("hh:mm:ss")) {
        format = format.replaceAll("hh:mm:ss", this.NumberZero(hour) + ":" + this.NumberZero(minute) + ":" + this.NumberZero(second));
      } else if (format.includes("hh:mm")) {
        format = format.replaceAll("hh:mm", this.NumberZero(hour) + ":" + this.NumberZero(minute));
      } else if (format.includes("hh")) {
        format = format.replaceAll("hh:mm", this.NumberZero(hour));
      } else if (format.includes("h:m:s")) {
        format = format.replaceAll("h:m:s", hour + ":" + minute + ":" + second);
      } else if (format.includes("h:m")) {
        format = format.replaceAll("h:m", hour + ":" + minute);
      }
      // DAY
      if (format.includes("dd")) {
        format = format.replaceAll("dd", this.NumberZero(day));
      } else {
        format = format.replaceAll("d", day);
      }
      // MONTH
      if (format.includes("mmmm")) {
        format = format.replaceAll("mmmm", array_full_month[month]);
      } else if (format.includes("mmm")) {
        format = format.replaceAll("mmm", array_month[month]);
      } else if (format.includes("mm")) {
        format = format.replaceAll("mm", this.NumberZero(month + 1));
      } else if (format.includes("m")) {
        format = format.replaceAll("m", month + 1);
      }
      // YEAR
      if (format.includes("yyyy")) {
        format = format.replaceAll("yyyy", this.NumberZero(year, 4));
      } else if (format.includes("yyy")) {
        format = format.replaceAll("yyy", year.toString().slice(-3));
      } else if (format.includes("yy")) {
        format = format.replaceAll("yy", year.toString().slice(-2));
      } else if (format.includes("y")) {
        format = format.replaceAll("y", year);
      }
      full_date = format;
    } else {
      full_date = this.NumberZero(day) + "/" + this.NumberZero(month + 1) + "/" + this.NumberZero(year, 4);
    }
    return full_date;
  };
  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <MenuComponent />
          <div className="bodyw-100">
            <BreadcrumbComponent
              page1="หน้าหลัก"
              page2="รายชื่อโกดังสินค้าจำหน่าย"
            />
            <div style={{ height: 40 }}></div>
            <div className="w-100 d-flex align-items-center justify-content-between px-3 mt-5">
              <h2>
                <b>ล้างข้อมูล</b>
              </h2>
            </div>
            <div className="w-100 p-3">
              <div className="card rounded">
                <div className="card-header rounded-top">
                  <div className="d-flex row px-3 mb-2">
                    <div className="mr-3">
                      <label>วันที่เริ่มต้น</label>
                      <input
                        className="form-control bg-white wpx-200"
                        type="date"
                        placeholder="วันที่เริ่มต้น"
                        value={this.state.start_date}
                        onChange={(e) => {
                          this.setState({ start_date: e.target.value })
                        }}
                      />
                    </div>
                    <div className="mr-3">
                      <label>วันที่สิ้นสุด</label>
                      <input
                        className="form-control bg-white wpx-200"
                        type="date"
                        placeholder="วันที่สิ้นสุด"
                        value={this.state.end_date}
                        onChange={(e) => {
                          this.setState({ end_date: e.target.value })
                        }}
                      />
                    </div>
                    <div className="mr-3">
                      <label className="text-white">.</label><br />
                      <button
                        type="button"
                        className="btn btn-danger px-4"
                        onClick={() => {
                          if (this.state.start_date === "") {
                            this.alert("warning", "แจ้งเตือน", "กรุณาเลือกวันที่เริ่มต้น");
                            return;
                          }
                          if (this.state.end_date === "") {
                            this.alert("warning", "แจ้งเตือน", "กรุณาเลือกวันที่สิ้นสุด");
                            return;
                          }
                          this.setState({ modal_delete: true })
                        }}
                      >
                        ล้างข้อมูล
                      </button>
                    </div>
                  </div>
                  <small className="text-secondary">** ข้อมูลที่ลบจะเป็นข้อมูลในวันที่เลือก ตั้งแต่วันที่เริ่มต้นจนถึงวันที่สิ้นสุด</small>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* modal */}
        {/* delete modal */}
        <Modal
          show={this.state.modal_delete}
          onHide={() => {
            this.setState({ modal_delete: false })
            this.clear_form()
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b>ยืนยันการลบข้อมูล</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 d-flex mb-3">
              <label>
                ท่านแน่ในว่าต้องการลบข้อมูลตั้งแต่วันที่ {this.format_date(this.state.start_date)} ถึง {this.format_date(this.state.end_date)} หรือไม่? หลังจากท่านยืนยันการลบแล้วจะไม่สามารถย้อนคืนข้อมูลได้
              </label>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_delete: false })
                    this.clear_form()
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-danger w-100"
                  onClick={() => {
                    this.Delete()
                  }}
                >
                  ยืนยัน
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}
