// JS
import "../../../../global.js"

// COMPONENT
import MenuComponent from "../../SideMenu/SideMenu"
import BreadcrumbComponent from "../../Breadcrumb/Breadcrumb"

// LIBRARY
import Swal from "sweetalert2"
import { toast } from "react-toastify"
import Cookies from "universal-cookie"
import React, { Component } from "react"
import Pagination from "react-js-pagination"
import { Modal } from "react-bootstrap"

// CSS
import "./style.css"
import "../../../../bootstrap.css"
import "react-slideshow-image/dist/styles.css"
import "bootstrap-daterangepicker/daterangepicker.css"

const cookies = new Cookies()
toast.configure()
export default class CommissionTypeScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      token: cookies.get("token_aip_shop") ? "token " + cookies.get("token_aip_shop") : "",
      role: cookies.get("role_data"),
      modal_add: false,
      modal_edit: false,
      modal_delete: false,
      page: 1,
      loading: false,
      search: "",
      detail: null,

      commission_type_id: "",
      commission_type_name: "",
      commission_cal: "",
      commission_unit: "",
    }
  }
  async componentDidMount() {
    if (this.state.role && this.state.role.type_commission[0] === 1) {
      await this.Get_All()
    }
  }

  Get_All = () => {
    this.setState({ loading: true })
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
      body: JSON.stringify({
        data_search: this.state.search,
      }),
    }
    fetch(
      global.api + "v1/commission-mgt/filter/page/" + this.state.page,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ data: result.data })
          this.setState({ loading: false })
        } else {
          switch (result.data.message) {
            case "Token is not found":
              this.alert("info", "แจ้งเตือน", "เซสชั่นหมดอายุ")
              this.Logout()
              break
            case "This Page is not available":
              break
            case "Role no access permission":
              this.alert("info", "ขออภัย", "อีเมลนี้ไม่ได้รับอนุญาตให้เข้าถึง")
              this.Logout()
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              // this.Logout();
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
        this.setState({ loading: false })
      })
  }

  Create = () => {
    this.setState({ loading: true })

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
      body: JSON.stringify({
        commission_type_name: this.state.commission_type_name,
        commission_cal: this.state.commission_cal,
        commission_unit: this.state.commission_unit,
      }),
    }
    fetch(global.api + "v1/commission-mgt/create", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ loading: false, page: 1 })
          this.alert(
            "success",
            "เพิ่มประเภทค่าคอมมิชชันเรียบร้อยแล้ว",
            "ประเภทค่าคอมมิชชัน ถูกเพิ่มในระบบแล้ว"
          )
          this.clear_form()
          this.Get_All()
        } else {
          switch (result.data.message) {
            case "Token is not found":
              this.alert("info", "แจ้งเตือน", "เซสชั่นหมดอายุ")
              this.Logout()
              break
            case "commission type name is duplicate":
              this.alert(
                "info",
                "แจ้งเตือน",
                "มีชื่อประเภทค่าคอมมิชชันนี้ในระบบแล้ว"
              )
              break
            case "commission type name is none":
              this.alert("info", "แจ้งเตือน", "กรุณากรอกชื่อประเภทค่าคอมมิชชัน")
              break
            case "Role no access permission":
              this.alert("info", "ขออภัย", "อีเมลนี้ไม่ได้รับอนุญาตให้เข้าถึง")
              this.Logout()
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              // this.Logout()
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
        this.setState({ loading: false })
      })
  }

  Edit = () => {
    this.setState({ loading: true })
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
      body: JSON.stringify({
        commission_type_id: this.state.commission_type_id,
        commission_type_name: this.state.commission_type_name,
        commission_cal: this.state.commission_cal,
        commission_unit: this.state.commission_unit,
      }),
    }
    fetch(global.api + "v1/commission-mgt/update", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ loading: false, page: 1 })
          this.alert(
            "success",
            "แก้ไขข้อมูลประเภทค่าคอมมิชชันเรียบร้อยแล้ว",
            "ประเภทค่าคอมมิชชัน ถูกแก้ไขในระบบแล้ว"
          )
          this.clear_form()
          this.Get_All()
        } else {
          switch (result.data.message) {
            case "Token is not found":
              this.alert("info", "แจ้งเตือน", "เซสชั่นหมดอายุ")
              this.Logout()
              break
            case "commission type name is none":
              this.alert("info", "แจ้งเตือน", "กรุณากรอกชื่อประเภทค่าคอมมิชชัน")
              break
            case "commission type name is duplicate":
              this.alert(
                "info",
                "แจ้งเตือน",
                "มีชื่อประเภทค่าคอมมิชชันนี้ในระบบแล้ว"
              )
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              // this.Logout()
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
        this.setState({ loading: false })
      })
  }

  Delete = () => {
    this.setState({ loading: true })
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
      accept: "application/json",
    }
    fetch(
      global.api +
      "v1/commission-mgt/delete/" +
      this.state.detail.commission_type_id,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ loading: false, page: 1 })
          this.setState({ modal_delete: false })
          this.alert(
            "success",
            "ลบประเภทค่าคอมมิชชันเรียบร้อยแล้ว",
            "ประเภทค่าคอมมิชชัน ถูกลบเรียบร้อยแล้ว"
          )
          this.clear_form()
          this.Get_All()
        } else {
          switch (result.data.message) {
            case "Token is not found":
              this.alert("info", "แจ้งเตือน", "เซสชั่นหมดอายุ")
              this.Logout()
              break
            case "Role no access permission":
              this.alert("info", "ขออภัย", "อีเมลนี้ไม่ได้รับอนุญาตให้เข้าถึง")
              this.Logout()
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              // this.Logout()
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
        this.setState({ loading: false })
      })
  }

  Logout = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
    }
    fetch(global.api + "v1/logout", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        cookies.remove("token_aip_shop")
        cookies.remove("role_data")
        cookies.remove("user_profile")
        cookies.remove("first_page")
        window.location.href = "/login"
      })
      .catch((e) => {
        this.alert_url("info", "แจ้งเตือน", "เกิดข้อผิดพลาด", "/login")
      })
  }

  handlePageChange(pageNumber) {
    this.setState({ page: pageNumber })
    setTimeout(() => {
      this.Get_All()
    }, 1)
  }

  alert(icon, title, detail) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    })
  }
  alert_url(icon, title, detail, url) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    }).then(() => {
      window.location.href = url
    })
  }
  clear_form() {
    this.setState({
      modal_add: false,
      modal_edit: false,
      modal_delete: false,
      modal_add_unit: false,
      modal_edit_unit: false,
      modal_delete_unit: false,
      page: 1,
      loading: false,
      search: "",
      detail: null,

      commission_type_id: "",
      commission_type_name: "",
      commission_cal: "",
      commission_unit: "",
    })
  }
  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <MenuComponent />
          <div className="bodyw-100">
            <BreadcrumbComponent page1="หน้าหลัก" page2="ประเภทค่าคอมมิชชัน" />
            <div style={{ height: 40 }}></div>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="w-100 d-flex align-items-center justify-content-between px-3 mt-5">
                  <h2>
                    <b>ประเภทค่าคอมมิชชัน</b>
                  </h2>
                  {this.state.role && this.state.role.type_commission[1] === 1 && (
                    <div>
                      <button
                        type="button"
                        className="btn btn-primary w-100 px-4"
                        onClick={() => {
                          // this.Login();
                          this.clear_form()
                          this.setState({ modal_add: true })
                        }}
                      >
                        เพิ่มประเภทค่าคอมฯ
                      </button>
                    </div>
                  )}
                </div>
                <div className="w-100 p-3">
                  <div className="card rounded">
                    <div className="card-header rounded-top">
                      <input
                        className="form-control bg-white"
                        type="search"
                        placeholder="ค้นหาด้วยชื่อประเภท"
                        value={this.state.search}
                        onChange={(e) => {
                          this.setState({ search: e.target.value })
                          if (e.target.value === "") {
                            this.setState({ page: 1, search: "" })
                            setTimeout(() => {
                              this.Get_All()
                            }, 20)
                          }
                        }}
                        onKeyPress={(event) => {
                          if (event.key === "Enter") {
                            this.setState({
                              page: 1,
                            })
                            setTimeout(() => {
                              this.Get_All()
                            }, 20)
                          }
                        }}
                      />
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table design-table">
                          <thead>
                            <tr>
                              {/* <th>วันที่สร้าง</th> */}
                              <th className="text-left" style={{ minWidth: 120 }}>ชื่อประเภท</th>
                              <th className="text-left" style={{ minWidth: 130 }}>ค่าคอมมิสชัน</th>
                              <th className="text-left">หน่วย</th>
                              {this.state.role &&
                                (this.state.role.type_commission[2] === 1 ||
                                  this.state.role.type_commission[3] === 1) && (
                                  <th className="text-center" style={{ minWidth: 130 }}>
                                    จัดการ
                                  </th>
                                )}
                            </tr>
                          </thead>
                          <tbody>
                            {((this.state.data &&
                              this.state.data.data.length === 0) ||
                              !this.state.data) && (
                                <tr>
                                  <td colSpan={4} className="text-center">
                                    -- ไม่มีข้อมูล --
                                  </td>
                                </tr>
                              )}
                            {this.state.data &&
                              this.state.data.data.map((item, index) => (
                                <tr key={index}>
                                  <td>{item.commission_type_name}</td>
                                  <td>{item.commission_cal}</td>
                                  <td>
                                    {item.commission_unit === "baht"
                                      ? "บาท"
                                      : "%"}
                                  </td>

                                  <td className="text-center">
                                    {this.state.role &&
                                      this.state.role.type_commission[2] ===
                                      1 && (
                                        <button
                                          className="btn btn-link text-secondary"
                                          onClick={() => {
                                            // this.Login();
                                            this.setState({
                                              modal_edit: true,
                                              detail: item,
                                              commission_type_id:
                                                item.commission_type_id,
                                              commission_type_name:
                                                item.commission_type_name,
                                              commission_cal:
                                                item.commission_cal,
                                              commission_unit:
                                                item.commission_unit,
                                            })
                                          }}
                                        >
                                          <label className="icon mr-2">
                                            {"\uf304"}
                                          </label>
                                        </button>
                                      )}
                                    {this.state.role &&
                                      this.state.role.type_commission[3] ===
                                      1 && (
                                        <button
                                          className="btn btn-link text-secondary"
                                          onClick={() => {
                                            // this.Login();
                                            this.setState({
                                              modal_delete: true,
                                              detail: item,
                                            })
                                          }}
                                        >
                                          <label className="icon mr-2">
                                            {"\uf2ed"}
                                          </label>
                                        </button>
                                      )}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  {/* pagination */}
                  {this.state.data && this.state.data.data.length !== 0 && (
                    <div className="d-flex justify-content-between row mx-0 w-100 px-3 my-4">
                      <div>
                        <label>
                          แสดง {this.state.data.end_index} รายการจาก{" "}
                          {this.state.data.count} รายการ
                        </label>
                      </div>
                      <div className="d-flex">
                        <button
                          className="btn-circle-white mx-1"
                          onClick={() => {
                            this.handlePageChange(1)
                          }}
                        >
                          <label className="icon">{"\uf100"}</label>
                        </button>
                        <Pagination
                          activePage={this.state.page}
                          itemsCountPerPage={10}
                          totalItemsCount={this.state.data.count}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange.bind(this)}
                          prevPageText={
                            <label className="icon">{"\uf104"}</label>
                          }
                          nextPageText={
                            <label className="icon">{"\uf105"}</label>
                          }
                          hideFirstLastPages={true}
                        />
                        <button
                          className="btn-circle-white mx-1"
                          onClick={() => {
                            this.handlePageChange(this.state.data.page_number)
                          }}
                        >
                          <label className="icon">{"\uf101"}</label>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* modal */}
        {/* ADD modal Type */}
        <Modal
          show={this.state.modal_add}
          onHide={() => {
            this.setState({ modal_add: false })
            this.clear_form()
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b>เพิ่มประเภทค่าคอมมิชชัน</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* ชื่อประเภท */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ชื่อประเภท:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control"
                  type="text"
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ commission_type_name: e.target.value })
                  }}
                  value={this.state.commission_type_name}
                />
              </div>
            </div>
            {/* ค่าคอมมิชชัน */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ค่าคอมมิชชัน:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control"
                  type="text"
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    let commission_cal = this.state.commission_cal
                    commission_cal = e.target.value.replace(/\D/g, "")
                    this.setState({
                      commission_cal: commission_cal,
                    })
                  }}
                  value={this.state.commission_cal}
                />
              </div>
            </div>
            {/* หน่วย */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>หน่วย:</label>
              </div>
              <div className="w-100">
                <select
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ commission_unit: e.target.value })
                  }}
                  value={this.state.commission_unit}
                >
                  <option value="" selected disabled>
                    -- เลือก --
                  </option>
                  <option value="percent">% (เปอร์เซ็น)</option>
                  <option value="baht">฿ (บาท)</option>
                </select>
              </div>
            </div>

            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_add: false })
                    this.clear_form()
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.commission_type_name === "") {
                      this.alert("info", "แจ้งเตือน", "กรุณากรอกชื่อประเภท")
                      return
                    } else if (this.state.commission_cal === "") {
                      this.alert("info", "แจ้งเตือน", "กรุณากรอกค่าคอมมิชชัน")
                      return
                    } else if (this.state.commission_unit === "") {
                      this.alert("info", "แจ้งเตือน", "กรุณาเลือกหน่วย")
                      return
                    }

                    this.Create()
                  }}
                >
                  เพิ่ม
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* EDIT modal Type */}
        <Modal
          show={this.state.modal_edit}
          onHide={() => {
            this.setState({ modal_edit: false })
            this.clear_form()
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b>แก้ไขประเภทค่าคอมมิชชัน</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* ชื่อประเภท */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ชื่อประเภท:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control"
                  type="text"
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ commission_type_name: e.target.value })
                  }}
                  value={this.state.commission_type_name}
                />
              </div>
            </div>
            {/* ค่าคอมมิชชัน */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ค่าคอมมิชชัน:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control"
                  type="text"
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    let commission_cal = this.state.commission_cal
                    commission_cal = e.target.value.replace(/\D/g, "")
                    this.setState({
                      commission_cal: commission_cal,
                    })
                  }}
                  value={this.state.commission_cal}
                />
              </div>
            </div>
            {/* หน่วย */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>หน่วย:</label>
              </div>
              <div className="w-100">
                <select
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ commission_unit: e.target.value })
                  }}
                  value={this.state.commission_unit}
                >
                  <option value="" selected disabled>
                    -- เลือก --
                  </option>
                  <option value="percent">% (เปอร์เซ็น)</option>
                  <option value="baht">฿ (บาท)</option>
                </select>
              </div>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_edit: false })
                    this.clear_form()
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.commission_type_name === "") {
                      this.alert("info", "แจ้งเตือน", "กรุณากรอกชื่อประเภท")
                      return
                    } else if (this.state.commission_cal === "") {
                      this.alert("info", "แจ้งเตือน", "กรุณากรอกค่าคอมมิชชัน")
                      return
                    } else if (this.state.commission_unit === "") {
                      this.alert("info", "แจ้งเตือน", "กรุณาเลือกหน่วย")
                      return
                    }
                    this.Edit()
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* delete modal Type */}
        <Modal
          show={this.state.modal_delete}
          onHide={() => {
            this.setState({ modal_delete: false })
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b>ยืนยันการลบประเภทค่าคอมมิชชัน</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 d-flex mb-3">
              <label>
                ท่านแน่ในว่าต้องการลบประเภทค่าคอมมิชชัน ”
                {this.state.detail && this.state.detail.commission_type_name}”
                หลังจากท่านยืนยันการยกแล้วจะไม่สามารถย้อนคืนข้อมูลได้
              </label>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_delete: false })
                    this.clear_form()
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-danger w-100"
                  onClick={() => {
                    this.Delete()
                  }}
                >
                  ยืนยัน
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}
