// JS
import "../../../../global.js"

// COMPONENT
import MenuComponent from "../../SideMenu/SideMenu"
import BreadcrumbComponent from "../../Breadcrumb/Breadcrumb"

// LIBRARY
import Swal from "sweetalert2"
import { toast } from "react-toastify"
import Cookies from "universal-cookie"
import React, { Component } from "react"
import Pagination from "react-js-pagination"
import { Modal } from "react-bootstrap"

// CSS
import "./style.css"
import "../../../../bootstrap.css"
import "react-slideshow-image/dist/styles.css"
import "bootstrap-daterangepicker/daterangepicker.css"

const cookies = new Cookies()
toast.configure()
export default class ListBrandsScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      token: cookies.get("token_aip_shop") ? "token " + cookies.get("token_aip_shop") : "",
      role: cookies.get("role_data"),
      modal_add: false,
      modal_edit: false,
      modal_delete: false,
      page: 1,
      loading: false,
      search: "",
      data: null,
      detail: null,

      brand_id: "",
      brand_name: "",
      picture: "",
    }
  }
  async componentDidMount() {
    if (this.state.role && this.state.role.brand_all[0] === 1) {
      await this.Get_All()
    }
  }

  Get_All = () => {
    this.setState({ loading: true })
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
      body: JSON.stringify({
        data_search: this.state.search,
      }),
    }
    fetch(
      global.api + "v1/product-mgt/brand/filter-search/page/" + this.state.page,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ data: result.data })
          this.setState({ loading: false })
        } else {
          switch (result.data.message) {
            case "Token is not found":
              this.alert("info", "แจ้งเตือน", "เซสชั่นหมดอายุ")
              this.Logout()
              break
            case "Role no access permission":
              this.alert("info", "ขออภัย", "อีเมลนี้ไม่ได้รับอนุญาตให้เข้าถึง")
              this.Logout()
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              // this.Logout();
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
        this.setState({ loading: false })
      })
  }

  Create = () => {
    this.setState({ loading: true })

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
      body: JSON.stringify({
        brand_name: this.state.brand_name,
        picture: this.state.picture,
      }),
    }
    fetch(global.api + "v1/product-mgt/brand/create", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ loading: false, page: 1 })
          this.alert(
            "success",
            "เพิ่มยี่ห้อเรียบร้อยแล้ว",
            "ยี่ห้อ “" + this.state.brand_name + "” ถูกเพิ่มในระบบแล้ว"
          )
          this.clear_form()
          setTimeout(() => {
            this.Get_All()
          }, 10)
        } else {
          switch (result.data.message) {
            case "brand name is none":
              this.alert("info", "แจ้งเตือน", "กรุณากรอกชื่อยี่ห้อ")
              break
            case "brand name is duplicate":
              this.alert("info", "แจ้งเตือน", "มีชื่อยี่ห้อนี้ในระบบแล้ว")
              break
            case "Role no access permission":
              this.alert("info", "ขออภัย", "อีเมลนี้ไม่ได้รับอนุญาตให้เข้าถึง")
              this.Logout()
              break
            case "Token is not found":
              this.alert("info", "แจ้งเตือน", "เซสชั่นหมดอายุ")
              this.Logout()
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              // this.Logout();
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
        this.setState({ loading: false })
      })
  }

  Edit = () => {
    this.setState({ loading: true })
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
      body: JSON.stringify({
        brand_id: this.state.brand_id,
        brand_name: this.state.brand_name,
        picture: this.state.picture,
      }),
    }
    fetch(global.api + "v1/product-mgt/brand/update", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ loading: false, page: 1 })
          this.alert(
            "success",
            "แก้ไขข้อมูลยี่ห้อเรียบร้อยแล้ว",
            "ยี่ห้อ “" + this.state.brand_name + "” ถูกแก้ไขในระบบแล้ว"
          )
          this.clear_form()
          setTimeout(() => {
            this.Get_All()
          }, 10)
        } else {
          switch (result.data.message) {
            case "brand name is none":
              this.alert("info", "แจ้งเตือน", "กรุณากรอกชื่อยี่ห้อ")
              break
            case "brand name is duplicate":
              this.alert("info", "แจ้งเตือน", "มีชื่อยี่ห้อนี้ในระบบแล้ว")
              break
            case "Token is not found":
              this.alert("info", "แจ้งเตือน", "เซสชั่นหมดอายุ")
              this.Logout()
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              // this.Logout();
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
        this.setState({ loading: false })
      })
  }

  Delete = () => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
      accept: "application/json",
    }
    fetch(
      global.api + "v1/product-mgt/brand/delete/" + this.state.detail.brand_id,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ loading: false, page: 1 })
          this.setState({ modal_delete: false })
          this.alert(
            "success",
            "ลบยี่ห้อเรียบร้อยแล้ว",
            "ยี่ห้อ “" + this.state.detail.brand_name + "” ถูกลบเรียบร้อยแล้ว"
          )
          this.clear_form()
          setTimeout(() => {
            this.Get_All()
          }, 10)
        } else {
          switch (result.data.message) {
            case "brand is active":
              this.alert(
                "info",
                "แจ้งเตือน",
                "ไม่สามารถลบได้\nยี่ห้อนี้มีการใช้งานอยู่"
              )
              break
            case "Token is not found":
              this.alert("info", "แจ้งเตือน", "เซสชั่นหมดอายุ")
              this.Logout()
              break
            case "Role no access permission":
              this.alert("info", "ขออภัย", "อีเมลนี้ไม่ได้รับอนุญาตให้เข้าถึง")
              this.Logout()
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
        this.setState({ loading: false })
      })
  }

  Logout = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
    }
    fetch(global.api + "v1/logout", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        cookies.remove("token_aip_shop")
        cookies.remove("role_data")
        cookies.remove("user_profile")
        cookies.remove("first_page")
        window.location.href = "/login"
      })
      .catch((e) => {
        this.alert_url("info", "แจ้งเตือน", "เกิดข้อผิดพลาด", "/login")
      })
  }
  alert(icon, title, detail) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    })
  }
  alert_url(icon, title, detail, url) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    }).then(() => {
      window.location.href = url
    })
  }

  handlePageChange(pageNumber) {
    this.setState({ page: pageNumber })
    setTimeout(() => {
      this.Get_All()
    }, 20)
  }

  clear_form() {
    this.setState({
      modal_add: false,
      modal_edit: false,
      modal_delete: false,
      page: 1,
      loading: false,
      search: "",
      detail: null,

      brand_id: "",
      brand_name: "",
      picture: "",
    })
  }

  handleChange = (e) => {
    if (e.target.files.length) {
      let reader = new FileReader()
      reader.readAsDataURL(e.target.files[0])
      reader.onload = (e) => {
        this.setState({
          picture: e.target.result.split(",")[1],
        })
      }
    }
  }
  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <MenuComponent />
          <div className="bodyw-100">
            <BreadcrumbComponent page1="หน้าหลัก" page2="ยี่ห้อทั้งหมด" />
            <div style={{ height: 40 }}></div>
            <div className="w-100 d-flex align-items-center justify-content-between px-3 mt-5">
              <h2>
                <b>ยี่ห้อทั้งหมด</b>
              </h2>
              {this.state.role && this.state.role.brand_all[1] === 1 && (
                <div>
                  <button
                    type="button"
                    className="btn btn-primary w-100 px-4"
                    onClick={() => {
                      // this.Login();
                      this.clear_form()
                      this.setState({ modal_add: true })
                    }}
                  >
                    เพิ่มยี่ห้อ
                  </button>
                </div>
              )}
            </div>
            <div className="w-100 p-3">
              <div className="card rounded">
                <div className="card-header rounded-top">
                  <input
                    className="form-control bg-white"
                    type="search"
                    placeholder="ค้นหาด้วยยี่ห้อ"
                    value={this.state.search}
                    onChange={(e) => {
                      this.setState({ search: e.target.value })
                      if (e.target.value === "") {
                        this.setState({ page: 1, search: "" })
                        setTimeout(() => {
                          this.Get_All()
                        }, 20)
                      }
                    }}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        this.setState({
                          page: 1,
                        })
                        setTimeout(() => {
                          this.Get_All()
                        }, 20)
                      }
                    }}
                  />
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table design-table">
                      <thead>
                        <tr>
                          {/* <th>วันที่</th> */}
                          <th className="text-center wpx-150">ภาพสินค้า</th>
                          <th className="text-left">ยี่ห้อ</th>
                          {this.state.role &&
                            (this.state.role.brand_all[2] === 1 ||
                              this.state.role.brand_all[3] === 1) && (
                              <th className="text-center wpx-150">จัดการ</th>
                            )}
                        </tr>
                      </thead>
                      <tbody>
                        {((this.state.data &&
                          this.state.data.data.length === 0) ||
                          !this.state.data) && (
                            <tr>
                              <td colSpan={3} className="text-center">
                                -- ไม่มีข้อมูล --
                              </td>
                            </tr>
                          )}
                        {this.state.data &&
                          this.state.data.data.map((item, index) => (
                            <tr key={index}>
                              {/* <td>10 พ.ค. 65, 11:25 น.</td> */}
                              <td className="text-center">
                                {item.picture ? (
                                  <img
                                    className="rounded wpx-80 hpx-80 img bg-secondary-light"
                                    alt="logo"
                                    src={item.picture}
                                    style={{ objectFit: "contain" }}
                                  ></img>
                                ) : (
                                  <div className="ml-4">
                                    <div className="rounded wpx-80 hpx-80 img bg-secondary-light user-no-image ">
                                      <span className="icon text-secondary">
                                        {"\uf302"}
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </td>
                              <td>{item.brand_name}</td>

                              <td className="text-center">
                                {this.state.role &&
                                  this.state.role.brand_all[2] === 1 && (
                                    <button
                                      className="btn btn-link text-secondary"
                                      onClick={() => {
                                        // this.Login();
                                        this.setState({
                                          modal_edit: true,
                                          detail: item,
                                          brand_id: item.brand_id,
                                          brand_name: item.brand_name,
                                          picture: item.picture,
                                        })
                                      }}
                                    >
                                      <label className="icon mr-2">
                                        {"\uf304"}
                                      </label>
                                    </button>
                                  )}
                                {this.state.role &&
                                  this.state.role.brand_all[3] === 1 && (
                                    <button
                                      className="btn btn-link text-secondary"
                                      onClick={() => {
                                        // this.Login();
                                        this.setState({
                                          modal_delete: true,
                                          detail: item,
                                        })
                                      }}
                                    >
                                      <label className="icon mr-2">
                                        {"\uf2ed"}
                                      </label>
                                    </button>
                                  )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {/* pagination */}
              {this.state.data && this.state.data.data.length !== 0 && (
                <div className="d-flex justify-content-between row mx-0 w-100 px-3 my-4">
                  <div>
                    <label>
                      แสดง {this.state.data.end_index} รายการจาก{" "}
                      {this.state.data.count} รายการ
                    </label>
                  </div>
                  <div className="d-flex">
                    <button
                      className="btn-circle-white mx-1"
                      onClick={() => {
                        this.handlePageChange(1)
                      }}
                    >
                      <label className="icon">{"\uf100"}</label>
                    </button>
                    <Pagination
                      activePage={this.state.page}
                      itemsCountPerPage={10}
                      totalItemsCount={this.state.data.count}
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChange.bind(this)}
                      prevPageText={<label className="icon">{"\uf104"}</label>}
                      nextPageText={<label className="icon">{"\uf105"}</label>}
                      hideFirstLastPages={true}
                    />
                    <button
                      className="btn-circle-white mx-1"
                      onClick={() => {
                        this.handlePageChange(this.state.data.page_number)
                      }}
                    >
                      <label className="icon">{"\uf101"}</label>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <input
          type="file"
          id="file"
          ref="fileUploader"
          style={{ display: "none" }}
          onChange={(e) => {
            this.handleChange(e)
          }}
        />
        {/* modal */}
        {/* ADD modal */}
        <Modal
          show={this.state.modal_add}
          onHide={() => {
            this.setState({ modal_add: false })
            this.clear_form()
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b>เพิ่มยี่ห้อ</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* รูปภาพ */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>รูปภาพ:</label>
              </div>
              <div className="w-100">
                {this.state.picture === "" ? (
                  <div
                    className="card border-2 rounded text-secondary bg-secondary-light p-5 border-dash d-flex align-items-center justify-content-center pointer"
                    onClick={() => {
                      this.refs.fileUploader.click()
                    }}
                  >
                    <span className="icon-brand mr-2">{"\uf0ee"}</span>
                    <label className="w-100 text-center">อัปโหลดภาพ</label>
                  </div>
                ) : (
                  <div
                    className="card border-2 rounded text-secondary bg-secondary-light p-2 border-dash d-flex align-items-center justify-content-center pointer"
                    onClick={() => {
                      this.refs.fileUploader.click()
                    }}
                  >
                    <img
                      className="rounded w-100 h-100 img"
                      alt="logo"
                      src={"data:image/png;base64," + this.state.picture}
                      style={{ objectFit: "contain" }}
                    ></img>
                  </div>
                )}
              </div>
            </div>
            {/* ชื่อยี่ห้อ */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ชื่อยี่ห้อ:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control"
                  type="text"
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({
                      brand_name: e.target.value,
                    })
                  }}
                  value={this.state.brand_name}
                />
              </div>
            </div>

            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_add: false })
                    this.clear_form()
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.brand_name === "") {
                      this.alert("info", "แจ้งเตือน", "กรุณากรอกยี่ห้อ")
                      return
                    }
                    this.Create()
                  }}
                >
                  เพิ่ม
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* EDIT modal */}
        <Modal
          show={this.state.modal_edit}
          onHide={() => {
            this.setState({ modal_edit: false })
            this.clear_form()
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b>แก้ไขยี่ห้อ</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* รูปภาพ */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>รูปภาพ:</label>
              </div>
              <div className="w-100">
                {this.state.picture === "" ? (
                  <div
                    className="card border-2 rounded text-secondary bg-secondary-light p-5 border-dash d-flex align-items-center justify-content-center pointer"
                    onClick={() => {
                      this.refs.fileUploader.click()
                    }}
                  >
                    <span className="icon-brand mr-2">{"\uf0ee"}</span>
                    <label className="w-100 text-center">อัปโหลดภาพ</label>
                  </div>
                ) : (
                  <div
                    className="card border-2 rounded text-secondary bg-secondary-light p-2 border-dash d-flex align-items-center justify-content-center pointer"
                    onClick={() => {
                      this.refs.fileUploader.click()
                    }}
                  >
                    <img
                      className="rounded w-100 h-100 img"
                      alt="logo"
                      src={
                        this.state.picture &&
                          this.state.picture.includes("storage")
                          ? this.state.picture
                          : "data:image/png;base64," + this.state.picture
                      }
                      style={{ objectFit: "contain" }}
                    ></img>
                  </div>
                )}
              </div>
            </div>
            {/* ชื่อยี่ห้อ */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ชื่อยี่ห้อ:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control"
                  type="text"
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({
                      brand_name: e.target.value,
                    })
                  }}
                  value={this.state.brand_name}
                />
              </div>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_edit: false })
                    this.clear_form()
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.brand_name === "") {
                      this.alert("info", "แจ้งเตือน", "กรุณากรอกยี่ห้อ")
                      return
                    }
                    this.Edit()
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* delete modal */}
        <Modal
          show={this.state.modal_delete}
          onHide={() => {
            this.setState({ modal_delete: false })
            this.clear_form()
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b>ยืนยันการลบยี่ห้อ</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 d-flex mb-3">
              <label>
                ท่านแน่ในว่าต้องการลบยี่ห้อ “
                {this.state.detail && this.state.detail.brand_name}”
                หลังจากท่านยืนยันการยกแล้วจะไม่สามารถย้อนคืนข้อมูลได้
              </label>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_delete: false })
                    this.clear_form()
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-danger w-100"
                  onClick={() => {
                    this.Delete()
                  }}
                >
                  ยืนยัน
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}
