// JS
import "../../../../global.js"

// COMPONENT
import MenuComponent from "../../SideMenu/SideMenu"
import BreadcrumbComponent from "../../Breadcrumb/Breadcrumb"

// LIBRARY
import Swal from "sweetalert2"
import { toast } from "react-toastify"
import Cookies from "universal-cookie"
import React, { Component } from "react"
import Pagination from "react-js-pagination"
import { Modal } from "react-bootstrap"

// CSS
import "./style.css"
import "../../../../bootstrap.css"
import "react-slideshow-image/dist/styles.css"
import "bootstrap-daterangepicker/daterangepicker.css"

const array_month = [
  "ม.ค.",
  "ก.พ.",
  "มี.ค.",
  "เม.ย.",
  "พ.ค.",
  "มิ.ย.",
  "ก.ค.",
  "ส.ค.",
  "ก.ย.",
  "ต.ค.",
  "พ.ย.",
  "ธ.ค.",
]

const cookies = new Cookies()
toast.configure()
export default class ListCommissionScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      token: cookies.get("token_aip_shop") ? "token " + cookies.get("token_aip_shop") : "",
      role: cookies.get("role_data"),
      modal_detail: false,
      modal_edit: false,
      modal_delete: false,
      page: 1,
      loading: false,
      search: "",
      data: null,
      data_detail: null,
      data_type: null,
      detail: null,
      commission_emp_id: "",
      order_list_id: "",
      commission_type_id: "",
      employee_list: "",
      payment_income: 0,
      commission_by_one: 0,
      end_date: "",
      start_date: "",
    }
  }
  async componentDidMount() {
    if (this.state.role && this.state.role.commission[0] === 1) {
      await this.Get_All()
    }
    await this.Get_Type_All()
  }

  Get_All = () => {
    this.setState({ loading: true })
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
      body: JSON.stringify({
        data_search: this.state.search,
        start_date: this.state.start_date,
        end_date: this.state.end_date,
      }),
    }
    fetch(
      global.api + "v1/commission-mgt/employee/search/page/" + this.state.page,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          console.log(result)
          this.setState({ data: result.data })
          this.setState({ loading: false })
        } else {
          switch (result.data.message) {
            case "Token is not found":
              this.alert("info", "แจ้งเตือน", "เซสชั่นหมดอายุ")
              this.Logout()
              break
            case "This Page is not available":
              this.setState({ data: [] })
              break
            case "Role no access permission":
              this.alert("info", "ขออภัย", "อีเมลนี้ไม่ได้รับอนุญาตให้เข้าถึง")
              this.Logout()
              break
            default:
              this.Logout()
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")

        this.setState({ loading: false })
      })
  }

  Get_Detail = (id, type) => {
    this.setState({ loading: true })
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
    }
    fetch(
      global.api + "v1/commission-mgt/employee/detail/" + id,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ data_detail: result.data.data })
          this.setState({ loading: false })
          setTimeout(() => {
            if (type === "detail") {
              this.setState({ modal_detail: true })
            } else {
              this.setState({
                modal_edit: true,
                commission_emp_id: result.data.data.commission_emp_id,
                commission_type_id: result.data.data.commission_type_data
                  ? result.data.data.commission_type_data.commission_type_id
                  : "",
                payment_income: result.data.data.payment_income_from_list - result.data.data.discount,
                commission_by_one: result.data.data.commission_by_one,
              })
            }
          }, 100)
        } else {
          switch (result.data.message) {
            case "Token is not found":
              this.alert("info", "แจ้งเตือน", "เซสชั่นหมดอายุ")
              this.Logout()
              break
            case "This Page is not available":
              break
            case "Role no access permission":
              this.alert("info", "ขออภัย", "อีเมลนี้ไม่ได้รับอนุญาตให้เข้าถึง")
              this.Logout()
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              // this.Logout();
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")

        this.setState({ loading: false })
      })
  }

  Get_Type_All = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
    }
    fetch(global.api + "v1/commission-mgt/all", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ data_type: result.data })
        } else {
          switch (result.data.message) {
            case "Token is not found":
              this.alert("info", "แจ้งเตือน", "เซสชั่นหมดอายุ")
              this.Logout()
              break
            case "Role no access permission":
              this.alert("info", "ขออภัย", "อีเมลนี้ไม่ได้รับอนุญาตให้เข้าถึง")
              this.Logout()
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")

        this.setState({ loading: false })
      })
  }

  Edit = () => {
    this.setState({ loading: true })
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
      body: JSON.stringify({
        commission_emp_id: this.state.commission_emp_id,
        commission_type_id: this.state.commission_type_id,
        payment_income: this.state.payment_income,
        commission_by_one: this.state.commission_by_one,
      }),
    }
    fetch(global.api + "v1/commission-mgt/employee/update", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ loading: false, page: 1 })
          this.alert(
            "success",
            "แก้ไขข้อมูลค่าคอมมิชชันเรียบร้อยแล้ว",
            "ค่าคอมมิชชัน ถูกแก้ไขในระบบแล้ว"
          )
          this.clear_form()
          setTimeout(() => {
            this.Get_All()
          }, 10)
        } else {
          switch (result.data.message) {
            case "Token is not found":
              this.alert("info", "แจ้งเตือน", "เซสชั่นหมดอายุ")
              this.Logout()
              break
            case "Commission is not found":
              this.alert(
                "info",
                "แจ้งเตือน",
                "ไม่สามารถแก้ไขค่าคอมมิชชันนี้ได้"
              )
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")

        this.setState({ loading: false })
      })
  }

  Delete = () => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
      accept: "application/json",
    }
    fetch(
      global.api +
      "v1/commission-mgt/employee/delete/" +
      this.state.detail.commission_emp_id,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ loading: false, page: 1 })
          this.setState({ modal_delete: false })
          this.alert(
            "success",
            "ลบค่าคอมมิชชันเรียบร้อยแล้ว",
            "ค่าคอมมิชชัน ถูกลบเรียบร้อยแล้ว"
          )
          this.clear_form()
          setTimeout(() => {
            this.Get_All()
          }, 10)
        } else {
          switch (result.data.message) {
            case "Token is not found":
              this.alert("info", "แจ้งเตือน", "เซสชั่นหมดอายุ")
              this.Logout()
              break
            case "Role no access permission":
              this.alert("info", "ขออภัย", "อีเมลนี้ไม่ได้รับอนุญาตให้เข้าถึง")
              this.Logout()
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")

        this.setState({ loading: false })
      })
  }
  dates = (date) => {
    let datetimes =
      date.split(" - ")[0].split("/")[0] +
      " " +
      array_month[Number(date.split(" - ")[0].split("/")[1]) - 1] +
      " " +
      (Number(date.split(" - ")[0].split("/")[2]) + 543)
    // +
    // ", " +
    // date.split(" - ")[1] +
    // " น."

    return datetimes
  }

  Logout = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
    }
    fetch(global.api + "v1/logout", requestOptions)
      .then((response) => response.json())
      .then((data) => {

        cookies.remove("token_aip_shop")
        cookies.remove("role_data")
        cookies.remove("user_profile")
        cookies.remove("first_page")

        window.location.href = "/login"
      })
      .catch((e) => {
        this.alert_url("info", "แจ้งเตือน", "เกิดข้อผิดพลาด", "/login")
      })
  }
  alert(icon, title, detail) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    })
  }
  alert_url(icon, title, detail, url) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    }).then(() => {
      window.location.href = url
    })
  }

  handlePageChange(pageNumber) {
    this.setState({ page: pageNumber })
    setTimeout(() => {
      this.Get_All()
    }, 20)
  }

  clear_form() {
    this.setState({
      modal_detail: false,
      modal_edit: false,
      modal_delete: false,
      page: 1,
      loading: false,
      search: "",
      detail: null,
      commission_emp_id: "",
      order_list_id: "",
      commission_type_id: "",
      employee_list: "",
      payment_income: 0,
      commission_by_one: 0,
    })
  }

  status = (status) => {
    switch (status) {
      case 0:
        return "รอดำเนินการ"
      case 1:
        return "รอดำเนินการ"
      case 2:
        return "สำเร็จ"
      case 3:
        return "ยกเลิก"
      default:
        return ""
    }
  }
  status_color = (status) => {
    switch (status) {
      case 0:
        return "status status-blue"
      case 1:
        return "status status-blue"
      case 2:
        return "status status-green"
      case 3:
        return "status status-red"
      default:
        return ""
    }
  }
  status_card = (status) => {
    switch (status) {
      case 0:
        return "card p-3 bg-primary-light"
      case 1:
        return "card p-3 bg-primary-light"
      case 2:
        return "card p-3 bg-success-light"
      case 3:
        return "card p-3 bg-danger-light"
      default:
        return ""
    }
  }
  calculate_commission = () => {
    let total = 0
    try {
      let data =
        this.state.data_type && this.state.data_type.data
          ? this.state.data_type.data.filter((e) => {
            return e.commission_type_id === this.state.commission_type_id
          })[0]
          : []
      if (data !== []) {
        if (data.commission_unit === "baht") {
          total = data.commission_cal
        } else {
          total = (this.state.payment_income * data.commission_cal) / 100
        }
      }
    } catch (e) { }
    this.setState({ commission_by_one: total })
  }

  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <MenuComponent />
          <div className="bodyw-100">
            <BreadcrumbComponent page1="หน้าหลัก" page2="ค่าคอมมิชชัน" />
            <div style={{ height: 40 }}></div>
            <div className="w-100 d-flex align-items-center justify-content-between px-3 mt-5">
              <h2>
                <b>ค่าคอมมิชชัน</b>
              </h2>
            </div>
            <div className="w-100 p-3">
              <div className="card rounded">
                <div className="card-header rounded-top">
                  <div className="row d-flex justify-content-start">

                    <input
                      className="form-control bg-white wpx-200 mx-2 mb-3"
                      type="search"
                      placeholder="ค้นหาด้วยชื่องาน"
                      value={this.state.search}
                      onChange={(e) => {
                        this.setState({ search: e.target.value })
                        if (e.target.value === "") {
                          this.setState({ page: 1, search: "" })
                          setTimeout(() => {
                            this.Get_All()
                          }, 20)
                        }
                      }}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          this.setState({
                            page: 1,
                          })
                          setTimeout(() => {
                            this.Get_All()
                          }, 20)
                        }
                      }}
                    />


                    <div className="d-flex ml-2">
                      <input
                        type="date"
                        className="form-control bg-white "
                        value={this.state.start_date}
                        onChange={(e) => {
                          this.setState({ start_date: e.target.value })
                          this.setState({
                            page: 1,
                          })
                          setTimeout(() => {
                            this.Get_All()
                          }, 20)
                        }}
                      ></input>
                      <label class="mx-3 mt-2">ถึง</label>
                      <input
                        type="date"
                        className="form-control bg-white"
                        value={this.state.end_date}
                        onChange={(e) => {
                          this.setState({ end_date: e.target.value })
                          this.setState({
                            page: 1,
                          })
                          setTimeout(() => {
                            this.Get_All()
                          }, 20)
                        }}
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table design-table">
                      <thead>
                        <tr>
                          <th className=" text-left" style={{ minWidth: 140 }}>เลขที่รายการขาย</th>
                          <th className=" text-left" style={{ minWidth: 130 }}>วันที่แจ้งซ่อม</th>
                          <th className=" text-left" style={{ minWidth: 100 }}>ชื่องาน</th>
                          <th className=" text-left" style={{ minWidth: 140 }}>ช่างซ่อม</th>
                          <th className=" text-left" style={{ minWidth: 100 }}>สถานะ</th>
                          <th className="text-center" style={{ minWidth: 180 }}>จัดการ</th>
                        </tr>
                      </thead>
                      <tbody>
                        {((this.state.data &&
                          this.state.data.data.length === 0) ||
                          !this.state.data) && (
                            <tr>
                              <td colSpan={5} className="text-center">
                                -- ไม่มีข้อมูล --
                              </td>
                            </tr>
                          )}
                        {this.state.data &&
                          this.state.data.data.map((item, index) => (
                            <tr key={index}>
                              <td>{item.order_main_number}</td>
                              <td>{this.dates(item.datetime)}</td>
                              <td>{item.job_name}</td>

                              <td>
                                {item.employee_list.map((emp, emp_index) => (
                                  <>
                                    <label>
                                      {emp_index + 1} . {emp.full_name}
                                    </label>
                                    <br />
                                  </>
                                ))}
                              </td>
                              <td>
                                <span
                                  className={this.status_color(item.status_job)}
                                >
                                  {this.status(item.status_job)}
                                </span>
                              </td>

                              <td className="text-center">
                                <button
                                  className="btn btn-link text-secondary"
                                  onClick={() => {
                                    this.Get_Detail(
                                      item.commission_emp_id,
                                      "detail"
                                    )
                                    // this.setState({modal_detail:true})
                                  }}
                                >
                                  <label className="icon mr-2">
                                    {"\uf06e"}
                                  </label>
                                </button>
                                {this.state.role &&
                                  this.state.role.commission[2] === 1 && (
                                    <button
                                      className="btn btn-link text-secondary"
                                      onClick={() => {
                                        this.Get_Detail(
                                          item.commission_emp_id,
                                          "edit"
                                        )
                                        // this.setState({modal_edit:true})
                                      }}
                                    >
                                      <label className="icon mr-2">
                                        {"\uf304"}
                                      </label>
                                    </button>
                                  )}
                                {this.state.role &&
                                  this.state.role.commission[3] === 1 && (
                                    <button
                                      className="btn btn-link text-secondary"
                                      onClick={() => {
                                        this.setState({
                                          modal_delete: true,
                                          detail: item,
                                        })
                                      }}
                                    >
                                      <label className="icon mr-2">
                                        {"\uf2ed"}
                                      </label>
                                    </button>
                                  )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {/* pagination */}
              {this.state.data && this.state.data.data.length !== 0 && (
                <div className="d-flex justify-content-between row mx-0 w-100 px-3 my-4">
                  <div>
                    <label>
                      แสดง {this.state.data.end_index} รายการจาก{" "}
                      {this.state.data.count} รายการ
                    </label>
                  </div>
                  <div className="d-flex">
                    <button
                      className="btn-circle-white mx-1"
                      onClick={() => {
                        this.handlePageChange(1)
                      }}
                    >
                      <label className="icon">{"\uf100"}</label>
                    </button>
                    <Pagination
                      activePage={this.state.page}
                      itemsCountPerPage={10}
                      totalItemsCount={this.state.data.count}
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChange.bind(this)}
                      prevPageText={<label className="icon">{"\uf104"}</label>}
                      nextPageText={<label className="icon">{"\uf105"}</label>}
                      hideFirstLastPages={true}
                    />
                    <button
                      className="btn-circle-white mx-1"
                      onClick={() => {
                        this.handlePageChange(this.state.data.page_number)
                      }}
                    >
                      <label className="icon">{"\uf101"}</label>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* modal */}
        {/* Detail modal */}
        <Modal
          show={this.state.modal_detail}
          onHide={() => {
            this.setState({ modal_detail: false })
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b>รายละเอียดค่าคอมมิชชัน</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* วันที่ */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>วันที่:</label>
              </div>

              <div className="w-100 text-right">
                <label>
                  {this.state.data_detail && this.dates(this.state.data_detail.datetime)}
                </label>
              </div>
            </div>
            {/* ชื่องาน */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ชื่องาน:</label>
              </div>
              <div className="w-100 text-right">
                <label>
                  {this.state.data_detail && this.state.data_detail.job_name}
                </label>
              </div>
            </div>
            {/* รายละเอียด */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>รายละเอียด:</label>
              </div>
              <div className="w-100 text-right">
                {this.state.data_detail &&
                  this.state.data_detail.employee_list.map((item, index) => (
                    <input
                      className="form-control mb-3 "
                      type="text"
                      placeholder="กรอกข้อมูล"
                      value={item.full_name}
                      style={{ backgroundColor: "#e3f8ff" }}
                      readOnly
                    />
                  ))}
              </div>
            </div>
            <hr />
            {/* รายรับ */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>รายรับ:</label>
              </div>
              <div className="w-100 text-right">
                <label>
                  {this.state.data_detail &&
                    (this.state.data_detail.payment_income_from_list - this.state.data_detail.discount)
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, "$&,") + " บาท"}
                </label>
              </div>
            </div>
            {/* ประเภท */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ประเภท:</label>
              </div>
              <div className="w-100 text-right">
                <label>
                  {this.state.data_detail &&
                    this.state.data_detail.commission_type_data &&
                    this.state.data_detail.commission_type_data
                      .commission_type_name ? this.state.data_detail.commission_type_data
                    .commission_type_name : "-"}
                </label>
              </div>
            </div>

            {/* คอมมิสชัน */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>คอมมิสชัน:</label>
              </div>
              <div className="w-100 text-right">
                <label>
                  {this.state.data_detail &&
                    this.state.data_detail.commission_by_one
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, "$&,") + " บาท"}
                </label>
              </div>
            </div>

            <div className="w-100 d-flex">
              <div className="w-50 p-1 ml-auto">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    this.setState({ modal_detail: false })
                  }}
                >
                  ปิด
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* EDIT modal */}
        <Modal
          show={this.state.modal_edit}
          onHide={() => {
            this.setState({ modal_edit: false })
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b>แก้ไขค่าคอมมิชชัน</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* วันที่ */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>วันที่:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control"
                  type="text"
                  placeholder="กรอกข้อมูล"
                  value={
                    this.state.data_detail
                      ? this.dates(this.state.data_detail.datetime)
                      : ""
                  }
                  readOnly
                />
              </div>
            </div>
            {/* ชื่องาน */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ชื่องาน:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control"
                  type="text"
                  placeholder="กรอกข้อมูล"
                  value={
                    this.state.data_detail
                      ? this.state.data_detail.job_name
                      : ""
                  }
                  readOnly
                />
              </div>
            </div>
            {/* รายละเอียด */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>รายละเอียด:</label>
              </div>
              <div className="w-100">
                {this.state.data_detail &&
                  this.state.data_detail.employee_list.map((item, index) => (
                    <input
                      className="form-control mb-3"
                      type="text"
                      placeholder="กรอกข้อมูล"
                      value={item.full_name}
                      readOnly
                    />
                  ))}
              </div>
            </div>
            <hr />
            {/* รายรับ */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>รายรับ:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control"
                  type="number"
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ payment_income: e.target.value })
                    setTimeout(() => {
                      this.calculate_commission()
                    }, 1)
                  }}
                  value={this.state.payment_income}
                />
              </div>
            </div>
            {/* ประเภท */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ประเภท:</label>
              </div>
              <div className="w-100">
                <select
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ commission_type_id: e.target.value })
                    setTimeout(() => {
                      this.calculate_commission()
                    }, 1)
                  }}
                  value={this.state.commission_type_id}
                >
                  <option value="" selected disabled>
                    -- เลือก --
                  </option>
                  {this.state.data_type &&
                    this.state.data_type.data.map((item, index) => (
                      <option value={item.commission_type_id}>
                        {item.commission_type_name}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            {/* คอมมิสชัน */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>คอมมิสชัน:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control"
                  type="number"
                  placeholder="กรอกข้อมูล"
                  // readOnly
                  value={this.state.commission_by_one}
                  onChange={(e) => {
                    this.setState({ commission_by_one: e.target.value })
                  }}
                />
              </div>
            </div>

            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_edit: false })
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    this.Edit()
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* delete modal */}
        <Modal
          show={this.state.modal_delete}
          onHide={() => {
            this.setState({ modal_delete: false })
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b>ยืนยันการลบค่าคอมมิชชัน</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 d-flex mb-3">
              <label>
                ท่านแน่ในว่าต้องการลบค่าคอมมิชชัน “
                {this.state.data_detail && this.state.data_detail.job_name}”
                หลังจากท่านยืนยันการยกแล้วจะไม่สามารถย้อนคืนข้อมูลได้
              </label>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_delete: false })
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-danger w-100"
                  onClick={() => {
                    this.Delete()
                  }}
                >
                  ยืนยัน
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}
