import React from "react"
import { Router, Route } from "react-router"
import App from "./components/App/index"
import Loading from "./components/Loading/Loading"
import Login from "./components/Login/Login"
// ข้อมูลผู้ใช้
import ListUser from "./components/Admin/User/ListUser/ListUser"
import RoleUser from "./components/Admin/User/RoleUser/RoleUser/RoleUser"
import CreateRoleUser from "./components/Admin/User/RoleUser/CreateRoleUser/CreateRoleUser"
import EditRoleUser from "./components/Admin/User/RoleUser/EditRoleUser/EditRoleUser"

// ข้อมูลสมาชิก
import ListMember from "./components/Admin/ListMember/ListMember"
// รายชื่อบริษัทจำหน่าย
import ListCompany from "./components/Admin/ListCompany/ListCompany"
// ใบรับสินค้า
import Receipt from "./components/Admin/Receipt/ListReceipt/ListReceipt"
import CreateReceipt from "./components/Admin/Receipt/CreateReceipt/CreateReceipt"
import EditReceipt from "./components/Admin/Receipt/EditReceipt/EditReceipt"
// ปฏิทินงานซ่อม
import Calendar from "./components/Admin/Calendar/Calendar"
// จัดการสินค้า
import ListProduct from "./components/Admin/Product/ListProduct/ListProduct/ListProduct"
import CreateProduct from "./components/Admin/Product/ListProduct/CreateProduct/CreateProduct"
import EditProduct from "./components/Admin/Product/ListProduct/EditProduct/EditProduct"
import ListProductUnit from "./components/Admin/Product/ListProductUnit/ListProductUnit"
import ListCatagory from "./components/Admin/Product/ListCatagory/ListCatagory"
import PartsType from "./components/Admin/Product/PartsType/PartsType"
import ListBrands from "./components/Admin/Product/ListBrands/ListBrands"
import ListModel from "./components/Admin/Product/ListModel/ListModel"
import ListBTU from "./components/Admin/Product/ListBTU/ListBTU"
import ListAir from "./components/Admin/Product/ListAir/ListAir"
// จัดการคอมมิสชัน
import ListCommission from "./components/Admin/Commission/ListCommission/ListCommission"
import CommissionType from "./components/Admin/Commission/CommissionType/CommissionType"
// รายการขาย
import Sale from "./components/Admin/Sale/ListSale/Sale"
import Overdue from "./components/Admin/Sale/ListOverDue/overdue"
import History from "./components/Admin/Sale/History/history"

// จัดการสต๊อก
import ListStockMove from "./components/Admin/Stock/ListStockMove/ListStockMove/ListStockMove"
import CreateStockMove from "./components/Admin/Stock/ListStockMove/CreateStockMove/CreateStockMove"
import ListStockAdjustment from "./components/Admin/Stock/ListStockAdjustment/ListStockAdjustment/ListStockAdjustment"
import CreateStockAdjustment from "./components/Admin/Stock/ListStockAdjustment/CreateStockAdjustment/CreateStockAdjustment"
import ListStockPath from "./components/Admin/Stock/ListStockPath/ListStockPath"
import ListStock from "./components/Admin/Stock/ListStock/ListStock"
// สรุปการขาย
import Summary from "./components/Admin/Summary/Summary"

// โกดัง
import ListWareHouse from "./components/Admin/ListWareHouse/ListWareHouse"

import Clear from "./components/Admin/Clear/Clear"

// Saller
import Main from "./components/Seller/Main/Main"
// import Repair from "./components/Seller/Repair/Repair"
import ListTransaction from "./components/Seller/ListTransaction/ListTransaction"
import ListAccrued from "./components/Seller/ListAccrued/ListAccrued"
import ListAccruedList from "./components/Seller/ListAccruedList/ListAccruedList"

import "./global.js"

const Routes = (props) => (
  <Router {...props}>
    <Route path="/index" component={App} />
    <Route path="/" component={Loading} />
    <Route path="/login" component={Login} />
    {/* <Route path="/*" component={NotFound} /> */}
    {/* ข้อมูลผู้ใช้ */}
    <Route path="/list-user" component={ListUser} />
    <Route path="/role-user" component={RoleUser} />
    <Route path="/create-role-user" component={CreateRoleUser} />
    <Route path="/edit-role-user" component={EditRoleUser} />
    {/* ข้อมูลสมาชิก */}
    <Route path="/list-member" component={ListMember} />
    {/* รายชื่อบริษัทจำหน่าย */}
    <Route path="/list-company" component={ListCompany} />
    {/* ใบรับสินค้า */}
    <Route path="/receipt" component={Receipt} />
    <Route path="/create-receipt" component={CreateReceipt} />
    <Route path="/edit-receipt" component={EditReceipt} />
    {/* ปฏิทินงานซ่อม */}
    <Route path="/calendar" component={Calendar} />
    {/* จัดการสินค้า */}
    <Route path="/list-product" component={ListProduct} />
    <Route path="/create-product" component={CreateProduct} />
    <Route path="/edit-product" component={EditProduct} />
    <Route path="/list-product-unit" component={ListProductUnit} />
    <Route path="/list-catagory" component={ListCatagory} />
    <Route path="/parts-type" component={PartsType} />
    <Route path="/list-brands" component={ListBrands} />
    <Route path="/list-model" component={ListModel} />
    <Route path="/list-btu" component={ListBTU} />
    <Route path="/list-air" component={ListAir} />
    {/* จัดการคอมมิสชัน */}
    <Route path="/list-commission" component={ListCommission} />
    <Route path="/commission-type" component={CommissionType} />
    {/* รายการขาย */}
    <Route path="/list-sale" component={Sale} />
    <Route path="/list-overdue" component={Overdue} />
    <Route path="/history" component={History} />


    {/* จัดการสต๊อก */}
    <Route path="/list-stock-move" component={ListStockMove} />
    <Route path="/create-stock-move" component={CreateStockMove} />
    <Route path="/list-stock-adjustment" component={ListStockAdjustment} />
    <Route path="/create-stock-adjustment" component={CreateStockAdjustment} />
    <Route path="/list-stock-path" component={ListStockPath} />
    <Route path="/list-stock" component={ListStock} />
    {/* สรุปการขาย */}
    <Route path="/summary" component={Summary} />

    {/* โกดัง */}
    <Route path="/list-warehouse" component={ListWareHouse} />
    <Route path="/clear" component={Clear} />
    {/* Saller */}
    <Route path="/main" component={Main} />
    {/* <Route path="/repair" component={Repair} /> */}
    <Route path="/transaction" component={ListTransaction} />
    <Route path="/accrued" component={ListAccrued} />
    <Route path="/accrued-list" component={ListAccruedList} />
  </Router>
)

export default Routes
