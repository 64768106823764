// JS
import "../../../../global.js"

// COMPONENT
import MenuComponent from "../../SideMenu/SideMenu"
import BreadcrumbComponent from "../../Breadcrumb/Breadcrumb"

import { LOGOUT } from "../../../Custom/CustomComponent.js"

// LIBRARY
import Swal from "sweetalert2"
import { toast } from "react-toastify"
import Cookies from "universal-cookie"
import React, { Component } from "react"
import Pagination from "react-js-pagination"
import { Modal } from "react-bootstrap"

// CSS
import "./style.css"
import "../../../../bootstrap.css"
import "react-slideshow-image/dist/styles.css"
import "bootstrap-daterangepicker/daterangepicker.css"


import {
  tokens,
  user_profile,
  alert,
  alert_url,
  GET,
  POST,
  PUT,
  DELETE,
  // LOGOUT,
  validate,
  gen_Password,
  format_date,
  success_url,
  success
} from "../../../Custom/CustomComponent.js"

const cookies = new Cookies()
toast.configure()
export default class ListUserScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      token: cookies.get("token_aip_shop") ? "token " + cookies.get("token_aip_shop") : "",
      role: cookies.get("role_data"),
      modal_add: false,
      modal_edit: false,
      modal_delete: false,
      page: 1,
      loading: false,
      search: "",
      data: null,
      data_role: null,
      detail: null,

      user_id: "",
      user_type: "",
      role_id: "",
      full_name: "",
      address: "",
      email: "",
      phone: "",
      password: "",
      picture: "",
    }
  }
  async componentDidMount() {
    if (this.state.role && this.state.role.user_admin[0] === 1) {
      await this.Get_All()
    }
    await this.Get_Role_All()
  }

  Get_Role_All = async () => {
    this.setState({ loading: true })
    let result = await GET(tokens, "v1/user-admin/role/all", null);
    if (result && result.status) {
      this.setState({ data_role: result.data })
    }
    this.setState({ loading: false });
  }

  Get_All = async () => {
    this.setState({ loading: true })
    let body = {
      data_search: this.state.search,
    }

    let result = await POST(tokens, "v1/user-admin/filter-search/page/" + this.state.page, body);
    if (result && result.status) {
      this.setState({ data: result.data })
    }
    this.setState({ loading: false });
  }

  Create = async () => {
    this.setState({ loading: true })

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
      body: JSON.stringify({
        user_type: this.state.user_type,
        role_id: this.state.role_id,
        full_name: this.state.full_name,
        address: this.state.address,
        email: this.state.email,
        phone: this.state.phone,
        password: this.state.password,
        picture: this.state.picture,
      }),
    }
    fetch(global.api + "v1/user-admin/create", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ loading: false, page: 1 })
          this.alert(
            "success",
            "เพิ่มผู้ใช้เรียบร้อยแล้ว",
            "ผู้ใช้ “" + this.state.full_name + "” ถูกเพิ่มในระบบแล้ว"
          )
          this.clear_form()
          setTimeout(() => {
            this.Get_All()
          }, 10)
        } else {
          this.setState({ loading: false })
          switch (result.data.message) {
            case "Token is not found":
              this.alert("info", "แจ้งเตือน", "เซสชั่นหมดอายุ")
              LOGOUT()
              break
            case "company name is none":
              this.alert("info", "แจ้งเตือน", "กรุณากรอกชื่อบริษัท")
              break
            case "company name is duplicate":
              this.alert("info", "แจ้งเตือน", "พบชื่อบริษัทนี้ในระบบแล้ว")
              break
            case "email is duplicate":
              this.alert("info", "แจ้งเตือน", "อีเมลนี้ถูกใช้งานแล้ว")
              break
            case "This Page is not available":
              break
            case "Role no access permission":
              this.alert("info", "ขออภัย", "อีเมลนี้ไม่ได้รับอนุญาตให้เข้าถึง")
              LOGOUT()
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              // LOGOUT();
              break
          }
        }
      })
      .catch((e) => {
        this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
        this.setState({ loading: false })
      })
  }

  Edit = async () => {
    this.setState({ loading: true })

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
      body: JSON.stringify({
        user_id: this.state.user_id,
        user_type: this.state.user_type,
        role_id: this.state.role_id,
        full_name: this.state.full_name,
        address: this.state.address,
        email: this.state.email,
        phone: this.state.phone,
        password: this.state.password,
        picture: this.state.picture,
      }),
    }
    fetch(global.api + "v1/user-admin/update", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ loading: false, page: 1 })
          this.alert(
            "success",
            "แก้ไขข้อมูลผู้ใช้เรียบร้อยแล้ว",
            "ผู้ใช้ “" +
            this.state.detail.user_code +
            ", " +
            this.state.full_name +
            "” ถูกแก้ไขในระบบแล้ว"
          )
          this.clear_form()
          setTimeout(() => {
            this.Get_All()
          }, 10)
        } else {
          this.setState({ loading: false })
          switch (result.data.message) {
            case "Token is not found":
              this.alert("info", "แจ้งเตือน", "เซสชั่นหมดอายุ")
              LOGOUT()
              break
            case "company name is none":
              this.alert("info", "แจ้งเตือน", "กรุณากรอกชื่อบริษัท")
              break
            case "company name is duplicate":
              this.alert("info", "แจ้งเตือน", "พบชื่อบริษัทนี้ในระบบแล้ว")
              break
            case "This Page is not available":
              break
            case "email is duplicate":
              this.alert("info", "แจ้งเตือน", "อีเมลนี้ถูกใช้งานแล้ว")
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              // LOGOUT();
              break
          }
        }
      })
      .catch((e) => {
        this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
        this.setState({ loading: false })
      })
  }

  Delete = async () => {
    this.setState({ loading: true });
    let result = await DELETE(tokens, "v1/user-admin/delete/" + this.state.detail.user_id, null);
    if (result && result.status) {
      alert("success", "ลบผู้ใช้เรียบร้อยแล้ว", "ผู้ใช้ ' " + this.state.detail.full_name + " ' ถูกลบเรียบร้อยแล้ว")
      this.setState({ loading: false, modal_delete: false, page: 1 })
      this.clear_form()
      this.Get_All()
    }
    this.setState({ loading: false });

  }


  alert(icon, title, detail) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    })
  }

  alert_url(icon, title, detail, url) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    }).then(() => {
      window.location.href = url
    })
  }

  handlePageChange(pageNumber) {
    this.setState({ page: pageNumber })

    setTimeout(() => {
      this.Get_All()
    }, 20)
  }

  clear_form() {
    this.setState({
      modal_add: false,
      modal_edit: false,
      modal_delete: false,
      loading: false,
      search: "",
      detail: null,

      user_id: "",
      user_type: "",
      role_id: "",
      full_name: "",
      address: "",
      email: "",
      phone: "",
      password: "",
      picture: "",
    })
  }

  validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  }

  ValidatePhone = (phone) => {
    return phone.match(/^^[0][0-9]*$/)
  }

  gen_Password = () => {
    let result = ""
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
    var charactersLength = characters.length
    for (var i = 0; i < 6; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    this.setState({ password: result })
  }

  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <MenuComponent />
          <div className="bodyw-100">
            <BreadcrumbComponent
              page1="หน้าหลัก"
              page2="รายชื่อผู้ใช้ทั้งหมด"
            />
            <div style={{ height: 40 }}></div>
            <div className="w-100 d-flex align-items-center justify-content-between px-3 mt-5">
              <h2>
                <b>รายชื่อผู้ใช้ทั้งหมด</b>
              </h2>
              {this.state.role && this.state.role.user_admin[1] === 1 && (
                <div>
                  <button
                    type="button"
                    className="btn btn-primary w-100 px-4"
                    onClick={() => {
                      this.clear_form()
                      this.setState({ modal_add: true })
                    }}
                  >
                    เพิ่มผู้ใช้งาน
                  </button>
                </div>
              )}
            </div>
            <div className="w-100 p-3">
              <div className="card rounded">
                <div className="card-header rounded-top">
                  <input
                    className="form-control bg-white"
                    type="search"
                    placeholder="ค้นหาด้วยชื่อ"
                    value={this.state.search}
                    onChange={(e) => {
                      this.setState({ search: e.target.value })
                      if (e.target.value === "") {
                        this.setState({ page: 1, search: "" })

                        setTimeout(() => {
                          if (
                            this.state.role &&
                            this.state.role.user_admin[0] === 1
                          ) {
                            this.Get_All()
                          }
                          this.Get_Role_All()
                        }, 20)
                      }
                    }}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        this.setState({
                          page: 1,
                        })
                        setTimeout(() => {
                          this.Get_All()
                        }, 20)
                      }
                    }}
                  />
                </div>

                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table design-table">
                      <thead>
                        <tr>
                          <th className="text-left wpx-150">รหัสสมาชิก</th>
                          <th className="text-left">ชื่อผู้ใช้</th>
                          <th className="text-left wpx-120">เบอร์โทร</th>
                          <th className="text-left">บทบาท</th>
                          <th className="text-left wpx-150">ประเภทผู้ใช้</th>

                          {this.state.role &&
                            (this.state.role.user_admin[2] === 1 ||
                              this.state.role.user_admin[3] === 1) && (
                              <th className="text-center wpx-150">จัดการ</th>
                            )}
                        </tr>
                      </thead>
                      <tbody>
                        {((this.state.data &&
                          this.state.data.data.length === 0) ||
                          !this.state.data) && (
                            <tr>
                              <td colSpan={6} className="text-center">
                                -- ไม่มีข้อมูล --
                              </td>
                            </tr>
                          )}
                        {this.state.data &&
                          this.state.data.data.map((item, index) => (
                            <tr key={index}>
                              <td>{item.user_code}</td>
                              <td>{item.full_name}</td>
                              <td>{item.phone}</td>
                              <td>{item.role_data.role_name}</td>
                              <td>
                                <span className="status-grey-type wpx-200">
                                  {item.user_type === "emp_sale"
                                    ? "พนักงาน"
                                    : "ช่าง"}
                                </span>
                              </td>

                              <td className="text-center">
                                {this.state.role &&
                                  this.state.role.user_admin[2] === 1 && (
                                    <button
                                      className="btn btn-link text-secondary"
                                      onClick={() => {
                                        this.setState({
                                          modal_edit: true,
                                          detail: item,
                                          user_id: item.user_id,
                                          user_type: item.user_type,
                                          role_id: item.role_data.role_id,
                                          full_name: item.full_name,
                                          address: item.address,
                                          email: item.email,
                                          phone: item.phone,
                                          password: item.password,
                                          picture: item.picture,
                                        })
                                      }}
                                    >
                                      <label className="icon mr-2">
                                        {"\uf304"}
                                      </label>
                                    </button>
                                  )}
                                {this.state.role &&
                                  this.state.role.user_admin[3] === 1 && (
                                    <button
                                      className="btn btn-link text-secondary"
                                      onClick={() => {
                                        this.setState({
                                          modal_delete: true,
                                          detail: item,
                                        })
                                      }}
                                    >
                                      <label className="icon mr-2">
                                        {"\uf2ed"}
                                      </label>
                                    </button>
                                  )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {/* pagination */}
              {this.state.data && this.state.data.data.length !== 0 && (
                <div className="d-flex justify-content-between row mx-0 w-100 px-3 my-4">
                  <div>
                    <label>
                      แสดง {this.state.data.end_index} รายการจาก{" "}
                      {this.state.data.count} รายการ
                    </label>
                  </div>
                  <div className="d-flex">
                    <button
                      className="btn-circle-white mx-1"
                      onClick={() => {
                        this.handlePageChange(1)
                      }}
                    >
                      <label className="icon">{"\uf100"}</label>
                    </button>
                    <Pagination
                      activePage={this.state.page}
                      itemsCountPerPage={10}
                      totalItemsCount={this.state.data.count}
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChange.bind(this)}
                      prevPageText={<label className="icon">{"\uf104"}</label>}
                      nextPageText={<label className="icon">{"\uf105"}</label>}
                      hideFirstLastPages={true}
                    />
                    <button
                      className="btn-circle-white mx-1"
                      onClick={() => {
                        this.handlePageChange(this.state.data.page_number)
                      }}
                    >
                      <label className="icon">{"\uf101"}</label>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* modal */}
        {/* ADD modal */}
        <Modal
          show={this.state.modal_add}
          onHide={() => {
            this.setState({ modal_add: false })
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b>เพิ่มผู้ใช้งาน</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* ประเภท */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ประเภท:</label>
              </div>
              <div className="w-100">
                <select
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ user_type: e.target.value })
                  }}
                  value={this.state.user_type}
                >
                  <option value="" selected disabled>
                    -- เลือก --
                  </option>
                  <option value="emp_sale">พนักงาน</option>
                  <option value="emp_technician">ช่าง</option>
                </select>
              </div>
            </div>

            {/* บทบาท */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>บทบาท:</label>
              </div>
              <div className="w-100">
                <select
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ role_id: e.target.value })
                  }}
                  value={this.state.role_id}
                >
                  <option value="" selected disabled>
                    -- เลือก --
                  </option>
                  {this.state.data_role &&
                    this.state.data_role.data.map((item, index) => (
                      <option value={item.role_id} key={index}>
                        {item.role_name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            {/* ชื่อ-นามสกุล */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ชื่อ-นามสกุล:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control"
                  type="text"
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ full_name: e.target.value })
                  }}
                  value={this.state.full_name}
                />
              </div>
            </div>
            {/* ที่อยู่ */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ที่อยู่:</label>
              </div>
              <div className="w-100">
                <textarea
                  className="form-control"
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ address: e.target.value })
                  }}
                  value={this.state.address}
                ></textarea>
              </div>
            </div>
            {/* เบอร์โทร */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>เบอร์โทร:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control"
                  type="tel"
                  maxLength={10}
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    let phone = this.state.phone
                    phone = e.target.value.replace(/\D/g, "")
                    this.setState({
                      phone: phone,
                    })
                  }}
                  value={this.state.phone}
                />
              </div>
            </div>
            {/* อีเมล */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>อีเมล:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control"
                  type="email"
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ email: e.target.value })
                  }}
                  value={this.state.email}
                />
              </div>
            </div>
            {/* รหัสผ่าน */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>รหัสผ่าน:</label>
              </div>

              <div className="w-100">
                {this.state.password === "" ? (
                  <button
                    className="card w-100 p-3 align-items-center text-center bg-gray pointer"
                    onClick={() => {
                      this.gen_Password()
                    }}
                  >
                    <h5>
                      <b className="text-primary">
                        คลิกที่นี่เพื่อสุ่มรหัสผ่าน
                      </b>
                    </h5>
                    <span className="text-gray">คัดลอกรหัสผ่านอัตโนมัติ</span>
                  </button>
                ) : (
                  <input
                    className="form-control"
                    type="text"
                    placeholder="กรอกข้อมูล"
                    readOnly
                    onChange={(e) => {
                      this.setState({ password: e.target.value })
                    }}
                    value={this.state.password}
                  />
                )}
              </div>
            </div>

            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_add: false })
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.user_type === "") {
                      this.alert("info", "แจ้งเตือน", "กรุณาเลือกประเภท")
                      return
                    } else if (this.state.role_id === "") {
                      this.alert("info", "แจ้งเตือน", "กรุณาเลือกบทบาท")
                      return
                    } else if (this.state.full_name === "") {
                      this.alert("info", "แจ้งเตือน", "กรุณากรอกชื่อ")
                      return
                    } else if (this.state.address === "") {
                      this.alert("info", "แจ้งเตือน", "กรุณากรอกที่อยู่")
                      return
                    } else if (this.state.phone.trim() !== "" && !this.ValidatePhone(this.state.phone)) {
                      this.alert(
                        "info",
                        "แจ้งเตือน",
                        "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง"
                      )
                      return
                    } else if (this.state.phone.trim() !== "" && this.state.phone.length !== 10) {
                      this.alert(
                        "info",
                        "แจ้งเตือน",
                        "กรุณากรอกเบอร์โทรศัพท์ให้ครบ 10 หลัก"
                      )
                      return
                    } else if (this.state.email.trim() === "") {
                      this.alert("info", "แจ้งเตือน", "กรุณากรอกอีเมล")
                      return
                    } else if (this.state.email.trim() !== "" && !this.validateEmail(this.state.email)) {
                      this.alert("info", "แจ้งเตือน", "รูปแบบอีเมลไม่ถูกต้อง")
                      return
                    } else if (this.state.password.trim() === "") {
                      this.alert("info", "แจ้งเตือน", "กรุณาสร้างรหัสผ่าน")
                      return
                    }

                    this.Create()
                  }}
                >
                  เพิ่ม
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* EDIT modal */}
        <Modal
          show={this.state.modal_edit}
          onHide={() => {
            this.setState({ modal_edit: false })
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b>แก้ไขผู้ใช้งาน</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* รหัสผู้ใช้งาน */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>รหัสผู้ใช้งาน:</label>
              </div>
              <div className="w-100">
                <label className="text-secondary">
                  {this.state.detail && this.state.detail.user_code}
                </label>
              </div>
            </div>

            {/* ประเภท */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ประเภท:</label>
              </div>
              <div className="w-100">
                <select
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ user_type: e.target.value })
                  }}
                  value={this.state.user_type}
                >
                  <option value="" selected disabled>
                    -- เลือก --
                  </option>
                  <option value="emp_sale">พนักงานขาย</option>
                  <option value="emp_technician">ช่าง</option>
                </select>
              </div>
            </div>

            {/* บทบาท */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>บทบาท:</label>
              </div>
              <div className="w-100">
                <select
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ role_id: e.target.value })
                  }}
                  value={this.state.role_id}
                >
                  <option value="" selected disabled>
                    -- เลือก --
                  </option>
                  {this.state.data_role &&
                    this.state.data_role.data.map((item, index) => (
                      <option key={index} value={item.role_id}>
                        {item.role_name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            {/* ชื่อ-นามสกุล */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ชื่อ-นามสกุล:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control"
                  type="text"
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ full_name: e.target.value })
                  }}
                  value={this.state.full_name}
                />
              </div>
            </div>
            {/* ที่อยู่ */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ที่อยู่:</label>
              </div>
              <div className="w-100">
                <textarea
                  className="form-control"
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ address: e.target.value })
                  }}
                  value={this.state.address}
                ></textarea>
              </div>
            </div>
            {/* เบอร์โทร */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>เบอร์โทร:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control"
                  type="tel"
                  maxLength="10"
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    let phone = this.state.phone
                    phone = e.target.value.replace(/\D/g, "")
                    this.setState({
                      phone: phone,
                    })
                  }}
                  value={this.state.phone}
                />
              </div>
            </div>
            {/* อีเมล */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>อีเมล:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control"
                  type="email"
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ email: e.target.value })
                  }}
                  value={this.state.email}
                />
              </div>
            </div>
            {/* รหัสผ่าน */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>รหัสผ่าน:</label>
              </div>

              <div className="w-100">
                {this.state.password === "" ||
                  this.state.password === undefined ? (
                  <button
                    className="card w-100 p-3 align-items-center text-center bg-gray"
                    onClick={() => {
                      this.gen_Password()
                    }}
                  >
                    <h5>
                      <b className="text-primary">
                        คลิกที่นี่เพื่อสุ่มรหัสผ่านใหม่
                      </b>
                    </h5>
                    <span className="text-gray">คัดลอกรหัสผ่านอัตโนมัติ</span>
                  </button>
                ) : (
                  <input
                    className="form-control"
                    type="text"
                    placeholder="กรอกข้อมูล"
                    readOnly
                    onChange={(e) => {
                      this.setState({ password: e.target.value })
                    }}
                    value={this.state.password}
                  />
                )}
              </div>

            </div>

            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_edit: false })
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.user_type === "") {
                      this.alert("info", "แจ้งเตือน", "กรุณาเลือกประเภท")
                      return
                    } else if (this.state.role_id === "") {
                      this.alert("info", "แจ้งเตือน", "กรุณาเลือกบทบาท")
                      return
                    } else if (this.state.full_name === "") {
                      this.alert("info", "แจ้งเตือน", "กรุณากรอกชื่อ")
                      return
                    } else if (this.state.address === "") {
                      this.alert("info", "แจ้งเตือน", "กรุณากรอกที่อยู่")
                      return
                    } else if (this.state.phone.trim() !== "" && !this.ValidatePhone(this.state.phone)) {
                      this.alert(
                        "info",
                        "แจ้งเตือน",
                        "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง"
                      )
                      return
                    } else if (this.state.phone.trim() !== "" && this.state.phone.length !== 10) {
                      this.alert(
                        "info",
                        "แจ้งเตือน",
                        "กรุณากรอกเบอร์โทรศัพท์ให้ครบ 10 หลัก"
                      )
                      return
                    } else if (this.state.email.trim() === "") {
                      this.alert("info", "แจ้งเตือน", "กรุณากรอกอีเมล")
                      return
                    } else if (!this.validateEmail(this.state.email)) {
                      this.alert("info", "แจ้งเตือน", "รูปแบบอีเมลไม่ถูกต้อง")
                      return
                    }

                    this.Edit()
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* delete modal */}
        <Modal
          show={this.state.modal_delete}
          onHide={() => {
            this.setState({ modal_delete: false })
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b>ยืนยันการลบผู้ใช้งาน</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 d-flex mb-3">
              <label>
                ท่านแน่ในว่าต้องการลบผู้ใช้งาน “
                {this.state.detail && this.state.detail.full_name}”
                หลังจากท่านยืนยันการยกแล้วจะไม่สามารถย้อนคืนข้อมูลได้
              </label>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_delete: false })
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-danger w-100"
                  onClick={() => {
                    this.Delete()
                  }}
                >
                  ยืนยัน
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div >
    )
  }
}
