// LIBRARY
import Cookies from "universal-cookie"
import React, { Component } from "react"

// CSS
import "./style.css"
import "../../bootstrap.css"

const cookies = new Cookies()

export default class LoadingScreen extends Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      token: cookies.get("token_aip_shop") ? cookies.get("token_aip_shop") : "",
      first: cookies.get("first_page") ? cookies.get("first_page") : ""
    }
  }




  async componentDidMount() {

    if (this.state.token && this.state.first) {
      window.location.href = this.state.first
    } else {
      cookies.remove("token_aip_shop")
      cookies.remove("role_data")
      cookies.remove("user_profile")
      cookies.remove("first_page")

      window.location.href = "/login"
    }

  }
  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
      </div>
    )
  }
}
