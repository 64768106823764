// JS
import "../../../global.js"

// COMPONENT
import MenuComponent from "../SideMenu/SideMenu"
import BreadcrumbComponent from "../Breadcrumb/Breadcrumb"

// LIBRARY
import Swal from "sweetalert2"
import { toast } from "react-toastify"
import Cookies from "universal-cookie"
import React, { Component } from "react"
import { Modal } from "react-bootstrap"
import calendar from "calendar-month-array"
import sprintf from "sprintf"
// CSS

import "./style.css"
import "../../../bootstrap.css"
import "react-slideshow-image/dist/styles.css"
import "bootstrap-daterangepicker/daterangepicker.css"
import {
  tokens,
  alert,
  GET,
  PUT,
  baht,
} from "../../Custom/CustomComponent.js"



const cookies = new Cookies()
toast.configure()
const full_month = [
  "มกราคม",
  "กุมภาพันธ์",
  "มีนาคม",
  "เมษายน",
  "พฤษภาคม",
  "มิถุนายน",
  "กรกฎาคม",
  "สิงหาคม",
  "กันยายน",
  "ตุลาคม",
  "พฤศจิกายน",
  "ธันวาคม",
]



export default class CalendarScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      token: cookies.get("token_aip_shop") ? "token " + cookies.get("token_aip_shop") : "",
      role: cookies.get("role_data"),
      modal_add: false,
      modal_edit: false,
      modal_detail: false,
      page: 1,
      loading: false,
      search: "",
      type: "ปฏิทินรายเดือน",
      data: null,
      data_tecnician: [],
      detail: null,
      month: [],
      status: '',
      slide_index: 0,

      select_day: new Date().getDate(),
      select_month: new Date().getMonth(),
      select_year: new Date().getFullYear(),
    }
  }
  async componentDidMount() {
    if (this.state.role && this.state.role.repair_calendar[0] === 1) {
      await this.Get_All()
    }
  }

  Get_All = async () => {
    this.setState({ loading: true })
    let month =
      this.state.select_year +
      "-" +
      ("0" + (this.state.select_month + 1)).slice(-2) +
      "-" +
      ("0" + this.state.select_day).slice(-2)

    let result = await GET(tokens, "v1/calendar-technician/month/" + month, null);

    if (result && result.status) {
      this.setState({ data: result.data.data })
      this.setState({ loading: false })
      this.Generate_date()
    }
    this.setState({ loading: false })
  }
  Get_All_Day = async () => {
    this.setState({ loading: true })
    let month =
      this.state.select_year +
      "-" +
      ("0" + (this.state.select_month + 1)).slice(-2) +
      "-" +
      ("0" + this.state.select_day).slice(-2)

    let result = await GET(tokens, "v1/calendar-technician/day/" + month +
      "?search=" + this.state.search, null);
    if (result && result.status) {
      this.setState({ data_tecnician: result.data.data })
      this.setState({ loading: false })
      this.Generate_date()
    }
    this.setState({ loading: false })
  }
  Generate_date = () => {
    var weeks = calendar(
      new Date(this.state.select_year, this.state.select_month),
      {
        weekStartDay: 0,
        formatHeader: (date) => date.toString().slice(0, 2),
        formatDate: (date) => sprintf("%2d", date.getDate()),
        formatSiblingMonthDate: () => "  ",
      }
    )
    weeks[0] = [
      "อาทิตย์",
      "จันทร์์",
      "อังคาร",
      "พุธ",
      "พฤหัสบดี",
      "ศุกร์",
      "เสาร์",
    ]
    this.setState({ month: weeks })
  }
  Get_new_date(value) {
    let date = new Date(
      this.state.select_year,
      this.state.select_month,
      this.state.select_day
    )
    date.setDate(date.getDate() + value)
    this.setState({
      select_year: date.getFullYear(),
      select_month: date.getMonth(),
      select_day: date.getDate(),
    })
  }
  Edit = async () => {
    if (this.state.status === "" || this.state.status === "1" || this.state.status === 1) {
      this.alert("warning", "แจ้งเตือน", "กรุณาเลือกสถานะ")
      return
    }
    this.setState({ loading: true })

    let body = {
      repair_detail_id: this.state.detail.repair_detail_id,
      status: this.state.status,
    }


    let result = await PUT(tokens, "v1/calendar-technician/update_status_task", body);
    if (result && result.status) {

      alert(
        "success",
        "แก้ไขสถานะรายการเรียบร้อยแล้ว",
        "สถานะรายการถูกแก้ไขในระบบแล้ว"
      )
      this.clear_form()
      this.Get_All()
      this.setState({ loading: false, page: 1 })
    }
    this.setState({ loading: false })

  }
  Logout = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
    }
    fetch(global.api + "v1/logout", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        cookies.remove("token_aip_shop")
        cookies.remove("role_data")
        cookies.remove("user_profile")
        cookies.remove("first_page")
        window.location.href = "/login"
      })
      .catch((e) => {
        this.alert_url("info", "แจ้งเตือน", "เกิดข้อผิดพลาด", "/login")
      })
  }
  alert(icon, title, detail) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    })
  }
  alert_url(icon, title, detail, url) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    }).then(() => {
      window.location.href = url
    })
  }
  clear_form() {
    this.setState({
      modal_add: false,
      modal_edit: false,
      modal_detail: false,
      page: 1,
      loading: false,
      type: "ปฏิทินรายเดือน",
      status: 1,

      select_day: new Date().getDate(),
      select_month: new Date().getMonth(),
      select_year: new Date().getFullYear(),
    })
  }
  status = (status) => {
    switch (status) {
      case 0:
        return "รอดำเนินการ"
      case 1:
        return "รอดำเนินการ"
      case 2:
        return "สำเร็จ"
      case 3:
        return "ยกเลิก"
      case 4:
        return "บันทึกรอชำระ"
      default:
        return ""
    }
  }
  status_color = (status) => {
    switch (status) {
      case 0:
        return "status status-blue text-12"
      case 1:
        return "status status-blue text-12"
      case 2:
        return "status status-green text-12"
      case 3:
        return "status status-red text-12"
      case 4:
        return "status status-yellow text-12"
      default:
        return ""
    }
  }
  status_card = (status) => {
    switch (status) {
      case 0:
        return "card p-3 bg-primary-light task text-14 wpx-234"
      case 1:
        return "card p-3 bg-primary-light task text-14 wpx-234"
      case 2:
        return "card p-3 bg-success-light task text-14 wpx-234"
      case 3:
        return "card p-3 bg-danger-light task text-14 wpx-234"
      case 4:
        return "card p-3 bg-primary-light task text-14 wpx-234"
      default:
        return ""
    }
  }

  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <MenuComponent />
          <div className="bodyw-100">
            <BreadcrumbComponent page1="หน้าหลัก" page2="ปฏิทินงานซ่อม" />
            <div style={{ height: 40 }}></div>
            <div className="w-100 d-flex align-items-center justify-content-between px-3 mt-5">
              <h2>
                <b>ปฏิทินงานซ่อม</b>
              </h2>
            </div>
            <div className="w-100 p-3">
              <div className="card rounded">
                <div className="card-body">
                  <div className="row w-100">
                    <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-6 mb-3">
                      <div className="w-100 row mx-0 d-flex align-items-center">
                        <h2>
                          <b>
                            {this.state.type === "รายวัน/บุคคล" &&
                              this.state.select_day + " "}
                            {full_month[this.state.select_month]}
                            {" ,"}
                            {this.state.select_year + 543}
                          </b>
                        </h2>
                        <div className="d-flex">
                          <button
                            className="btn rounded mx-2 icon-arrow"
                            onClick={async () => {
                              if (this.state.type === "ปฏิทินรายเดือน") {
                                if (this.state.select_month === 0) {
                                  this.setState({
                                    select_month: 11,
                                    select_year: this.state.select_year - 1,
                                  })
                                } else {
                                  this.setState({
                                    select_month: this.state.select_month - 1,
                                  })
                                }
                                setTimeout(() => {
                                  if (this.state.role && this.state.role.repair_calendar[0] === 1) {
                                    this.Get_All()
                                  }
                                }, 20)
                              } else {
                                await this.Get_new_date(-1)

                                setTimeout(() => {
                                  if (this.state.role && this.state.role.repair_calendar[0] === 1) {
                                    this.Get_All_Day()
                                  }
                                }, 20)
                              }
                            }}
                          >
                            <label
                              className="text-18 button icon mt-1"
                              style={{
                                width: 32,
                                height: 32,
                                borderRadius: 16,
                              }}
                            >
                              {"\uf060"}
                            </label>
                          </button>

                          <button
                            className="btn rounded mx-2 icon-arrow"
                            onClick={async () => {
                              if (this.state.type === "ปฏิทินรายเดือน") {
                                if (this.state.select_month === 11) {
                                  this.setState({
                                    select_month: 0,
                                    select_year: this.state.select_year + 1,
                                  })
                                } else {
                                  this.setState({
                                    select_month: this.state.select_month + 1,
                                  })
                                }
                                setTimeout(() => {
                                  if (this.state.role && this.state.role.repair_calendar[0] === 1) {
                                    this.Get_All()
                                  }
                                }, 20)
                              } else {
                                await this.Get_new_date(1)

                                setTimeout(() => {
                                  if (this.state.role && this.state.role.repair_calendar[0] === 1) {
                                    this.Get_All_Day()
                                  }
                                }, 20)
                              }
                            }}
                          >
                            <label
                              className="text-18 button icon mt-1"
                              style={{
                                width: 32,
                                height: 32,
                                borderRadius: 16,
                              }}
                            >
                              {"\uf061"}
                            </label>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-2 mb-3">
                      {this.state.type === "รายวัน/บุคคล" && (
                        <div className="w-100 align-items-center">
                          <input
                            className="form-control bg-gray-f5"
                            type="search"
                            placeholder="ค้นหาด้วยชื่อช่าง"
                            value={this.state.search}
                            onChange={(e) => {
                              this.setState({ search: e.target.value })
                              if (e.target.value === "") {
                                this.setState({ page: 1, search: "" })
                                setTimeout(() => {
                                  this.Get_All_Day()
                                }, 20)
                              }
                            }}
                            onKeyPress={(event) => {
                              if (event.key === "Enter") {
                                this.setState({
                                  page: 1,
                                })
                                setTimeout(() => {
                                  this.Get_All_Day()
                                }, 20)
                              }
                            }}
                          />
                        </div>
                      )}
                    </div>

                    <div className="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-4 mb-3">
                      <div className="card rounded-8 border-primary p-1 w-100">
                        <div className="d-flex w-100">
                          <div className="w-50 pr-1">
                            <button
                              className={
                                this.state.type === "ปฏิทินรายเดือน"
                                  ? "btn btn-primary w-100"
                                  : "btn btn-link w-100"
                              }
                              onClick={() => {
                                this.setState({ type: "ปฏิทินรายเดือน" })
                              }}
                            >
                              ปฏิทินรายเดือน
                            </button>
                          </div>
                          <div className="w-50 pl-1">
                            <button
                              className={
                                this.state.type === "รายวัน/บุคคล"
                                  ? "btn btn-primary w-100"
                                  : "btn btn-link w-100"
                              }
                              onClick={() => {
                                this.setState({ type: "รายวัน/บุคคล" })
                                if (this.state.role && this.state.role.repair_calendar[0] === 1) {
                                  this.Get_All_Day()
                                }
                              }}
                            >
                              รายวัน/บุคคล
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {this.state.type === "ปฏิทินรายเดือน" ? (
                    // ปฏิทินรายเดือน
                    <div className="w-100 p-0 m-0">
                      <div className="table-responsive">
                        {this.state.month && this.state.month.length > 0 ? (
                          <table className="table table-bordered">
                            <thead className="bg-secondary-light">
                              <tr>
                                {/* วัน */}
                                {this.state.month &&
                                  this.state.month[0].map((item, index) => (
                                    <th className="text-center" style={{ width: (100 / 7).toString() + "%", minWidth: 140 }} key={index}>{item}</th>
                                  ))}
                              </tr>
                            </thead>
                            <tbody>
                              {/* วันที่ในตาราง */}
                              {this.state.month &&
                                this.state.month.map((week, index_week) => (
                                  <tr>
                                    {index_week !== 0 &&
                                      week.map((day, index_day) => (
                                        <td
                                          key={index_day}
                                          className={
                                            this.state.select_day === Number(day)
                                              ? "text-center pointer bg-primary-light"
                                              : "text-center pointer"
                                          }
                                          style={{ minWidth: 140 }}
                                          onClick={() => {
                                            if (day !== "  ") {
                                              this.setState({
                                                select_day: Number(day),
                                              })
                                            }
                                          }}
                                        >
                                          {day}
                                          {day !== "  " && (
                                            <div
                                              className={
                                                this.state.select_day ===
                                                  Number(day)
                                                  ? "row mt-2 justify-content-between"
                                                  : "row mt-2 justify-content-between"
                                              }
                                              style={{ minWidth: 140 }}
                                            >
                                              {this.state.data &&
                                                this.state.data[Number(day) - 1] &&
                                                this.state.data[Number(day) - 1]
                                                  .total === 0 &&
                                                this.state.data &&
                                                this.state.data[Number(day) - 1] &&
                                                this.state.data[Number(day) - 1]
                                                  .pending === 0 &&
                                                this.state.data &&
                                                this.state.data[Number(day) - 1] &&
                                                this.state.data[Number(day) - 1]
                                                  .cancal === 0 &&
                                                this.state.data &&
                                                this.state.data[Number(day) - 1] &&
                                                this.state.data[Number(day) - 1]
                                                  .sucess === 0 ? null : (

                                                <div className="m-3 w-100 text-center d-flex align-content-center justify-content-between p-0  text-11">
                                                  <div >
                                                    <label className="rounded-circle bg-bule mb-0 mr-1" style={{ width: 10, height: 10 }}></label>
                                                    <label className="text-bule mb-0 mr-1">
                                                      {this.state.data &&
                                                        this.state.data[
                                                        Number(day) - 1
                                                        ] &&
                                                        this.state.data[
                                                          Number(day) - 1
                                                        ].total}</label>
                                                  </div>
                                                  <div className="border-right hpx-16" />
                                                  <div >
                                                    <label className="rounded-circle bg-yellow mb-0 mr-1" style={{ width: 10, height: 10 }}></label>
                                                    <label className="text-yellow mb-0 mr-1 ">
                                                      {this.state.data &&
                                                        this.state.data[
                                                        Number(day) - 1
                                                        ] &&
                                                        this.state.data[
                                                          Number(day) - 1
                                                        ].pending}</label>
                                                  </div>
                                                  <div className="border-right hpx-16" />
                                                  <div  >
                                                    <label className="rounded-circle bg-danger mb-0 mr-1" style={{ width: 10, height: 10 }}></label>
                                                    <label className="text-danger mb-0 mr-1">
                                                      {this.state.data &&
                                                        this.state.data[
                                                        Number(day) - 1
                                                        ] &&
                                                        this.state.data[
                                                          Number(day) - 1
                                                        ].cancal}</label>
                                                  </div>
                                                  <div className="border-right hpx-16" />
                                                  <div>
                                                    <label className="rounded-circle bg-green mb-0 " style={{ width: 10, height: 10 }}></label>
                                                    <label className="text-green mb-0 ml-1">
                                                      {this.state.data &&
                                                        this.state.data[
                                                        Number(day) - 1
                                                        ] &&
                                                        this.state.data[
                                                          Number(day) - 1
                                                        ].sucess}</label>
                                                  </div>



                                                </div>
                                              )}
                                            </div>
                                          )}
                                        </td>
                                      ))}
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        ) : null}
                      </div>
                    </div>
                  ) : (
                    // รายวัน/บุคคล
                    <div className="w-100">
                      <div className="table-responsive">
                        {this.state.data_tecnician &&
                          this.state.data_tecnician.length === 0 && (
                            <div className="w-100 d-flex justify-content-center align-content-center">
                              <label>-- ไม่มีข้อมูล --</label>
                            </div>
                          )}
                        {this.state.month && this.state.month.length > 0 ? (
                          <table className="table table-bordered">
                            <thead className="bg-secondary-light">
                              <tr>
                                {this.state.data_tecnician &&
                                  this.state.data_tecnician.filter(
                                    (item) => item.task.length !== 0
                                  ).length > 5 && (
                                    <th th className="p-0">
                                      <button
                                        className="btn btn-link w-100"
                                        onClick={() => {
                                          if (this.state.slide_index > 0) {
                                            this.setState({
                                              slide_index:
                                                this.state.slide_index - 1,
                                            })
                                          }
                                        }}
                                      >
                                        <label className="text-18 icon">
                                          {"\uf060"}
                                        </label>
                                      </button>
                                    </th>
                                  )}

                                {this.state.data_tecnician &&
                                  this.state.data_tecnician
                                    .filter((item) => item.task.length !== 0)
                                    .map((item, index) => (
                                      <th
                                        className={
                                          index >= 0 + this.state.slide_index &&
                                            index < 5 + this.state.slide_index
                                            ? "text-left"
                                            : "d-none"
                                        }
                                        style={{ minWidth: 275 }}
                                        key={index}
                                      >
                                        {item.name}
                                      </th>
                                    ))}
                                {this.state.data_tecnician &&
                                  this.state.data_tecnician.filter(
                                    (item) => item.task.length !== 0
                                  ).length > 5 && (
                                    <th className="p-0">
                                      <button
                                        className="btn btn-link w-100"
                                        onClick={() => {
                                          if (
                                            this.state.slide_index <
                                            this.state.data_tecnician.filter(
                                              (item) => item.task.length !== 0
                                            ).length -
                                            5
                                          ) {
                                            this.setState({
                                              slide_index:
                                                this.state.slide_index + 1,
                                            })
                                          }
                                        }}
                                      >
                                        <label className="text-18 icon">
                                          {"\uf061"}
                                        </label>
                                      </button>
                                    </th>
                                  )}
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                {this.state.data_tecnician &&
                                  this.state.data_tecnician.filter(
                                    (item) => item.task.length !== 0
                                  ).length > 5 && <td></td>}
                                {this.state.data_tecnician &&
                                  this.state.data_tecnician
                                    .filter(
                                      (item, index) => item.task.length !== 0
                                    )
                                    .map((item, index) => (
                                      <td key={index}
                                        className={index >= 0 + this.state.slide_index && index < 5 + this.state.slide_index ? "text-center" : "d-none"}
                                        style={{ verticalAlign: "top" }}
                                      >
                                        {item.task.map((task, index_task) => (
                                          <div className="mb-3 position-relative wpx-234" key={index_task} >
                                            <div className={this.status_card(task.status_job)} onClick={() => { this.setState({ modal_detail: true, detail: task }) }} >
                                              <div className="w-100" >
                                                <div className=" d-flex mb-0 justify-content-between">
                                                  <div className={task.status_job !== 0 || task.status_job !== 1 ? " mb-2" : null}>
                                                    <span className={this.status_color(task.status_job)} >
                                                      {this.status(task.status_job)}
                                                    </span>
                                                  </div>
                                                </div>
                                                <hr className="mt-0 mb-2" />
                                                <div className="justify-content-start text-left" >
                                                  <p className="mb-0 d-flex"><b className="mr-2">บริการ:</b>
                                                    <label className="wpx-200">{task.repair_detail_name ? task.repair_detail_name : "-"}</label>
                                                  </p>
                                                  <p className="mb-0 d-flex"><b className="mr-2">ลูกค้า:</b>
                                                    <label className="wpx-200">{task.customer_name ? task.customer_name : "-"}</label>
                                                  </p>
                                                  <p className="mb-0 d-flex"><b className="mr-2">หมายเหตุ:</b>
                                                    <label className="wpx-200">{task.remark_repair ? task.remark_repair : "-"}</label>
                                                  </p>
                                                  <p className="mb-0 d-flex"><b className="mr-2">มัดจำ:</b>
                                                    <label className="wpx-200 text-danger">{task.deposit ? baht(task.deposit) : "-"}</label>
                                                  </p>
                                                  <p className="mb-0 d-flex"><b className="mr-2">ช่างในทีม:</b>
                                                    <label className="rounded-circle wpx-24 hpx-24 bg-gray-c9 mb-0 text-12 p-1">+{task.other_tc_count}</label>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                            <div
                                              className="wpx-38 position-absolute"
                                              style={{ right: 12, top: 12 }}
                                              onClick={() => {
                                                if (task.status_job === 0 || task.status_job === 1) {
                                                  this.setState({ modal_edit: true, detail: task, status: task.status_job })
                                                }
                                              }}
                                            >
                                              {item.user_code === "ME000000001" || (this.state.role && this.state.role.repair_calendar[2] === 1 && (
                                                <>
                                                  {(task.status_job === 0 || task.status_job === 1) && (
                                                    <label className="icon pointer"> {"\uf304"} </label>
                                                  )}
                                                </>
                                              ))}
                                            </div>
                                          </div>
                                        ))}
                                      </td>
                                    ))}{" "}
                                {this.state.data_tecnician &&
                                  this.state.data_tecnician.filter(
                                    (item) => item.task.length !== 0
                                  ).length > 5 && <td></td>}
                              </tr>
                            </tbody>
                          </table>
                        ) : null}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {this.state.month.length > 0 &&
                this.state.type === "ปฏิทินรายเดือน" && (
                  <div className="justify-content-end w-100 d-flex row col-12 text-12 mt-3 mb-3">
                    **หมายเหตุ: <div className="point-date-all mx-2" />
                    ทั้งหมด, <div className="point-date-yellow mx-2" />
                    รอดำเนินการ, <div className="point-date-danger mx-2" />
                    ยกเลิก, <div className="point-date-success mx-2" />
                    สำเร็จ
                  </div>
                )}
            </div>
          </div>
        </div >
        {/* modal */}
        {/* EDIT modal */}
        <Modal
          show={this.state.modal_edit}
          onHide={() => {
            this.setState({ modal_edit: false })
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b>เปลี่ยนสถานะรายการ</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* รหัสผู้ใช้งาน */}
            <div className="w-100  mb-3">
              <label>
                เปลี่ยนสถานะรายการของคุณ “
                {this.state.detail && this.state.detail.customer_name}”
              </label>
            </div>

            {/* สถานะ */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>สถานะ:</label>
              </div>
              <div className="w-100">
                <select
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ status: e.target.value })
                  }}
                  value={this.state.status}
                >
                  <option value="" selected disabled>
                    กรุณาเลือก
                  </option>
                  <option value="1">
                    รอดำเนินการ
                  </option>
                  <option value="2">สำเร็จ</option>
                  <option value="3">ยกเลิก</option>
                </select>
              </div>
            </div>

            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_edit: false })
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    this.Edit()
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* DETAIL modal */}
        <Modal
          show={this.state.modal_detail}
          onHide={() => {
            this.setState({ modal_detail: false })
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b>รายละเอียด</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* บริการ */}
            <div className="w-100 d-flex mb-3 align-items-center">
              <div className="wpx-120">
                <label><b>บริการ:</b></label>
              </div>
              <div className="w-100">
                <label>
                  {this.state.detail && this.state.detail.repair_detail_name
                    ? this.state.detail.repair_detail_name
                    : "-"}
                </label>
              </div>
            </div>
            {/* ลูกค้า */}
            <div className="w-100 d-flex mb-3 align-items-center">
              <div className="wpx-120">
                <label><b>ลูกค้า:</b></label>
              </div>
              <div className="w-100">
                <label>
                  {this.state.detail && this.state.detail.customer_name
                    ? this.state.detail.customer_name
                    : "-"}
                </label>
              </div>
            </div>
            {/* เบอร์โทร */}
            <div className="w-100 d-flex mb-3 align-items-center">
              <div className="wpx-120">
                <label><b>เบอร์โทร:</b></label>
              </div>
              <div className="w-100">
                <label>
                  {this.state.detail && this.state.detail.customer_phone
                    ? this.state.detail.customer_phone
                    : "-"}
                </label>
              </div>
            </div>
            {/* ที่อยู่ */}
            <div className="w-100 d-flex mb-3 align-items-center">
              <div className="wpx-120">
                <label><b>ที่อยู่:</b></label>
              </div>
              <div className="w-100">
                <label>
                  {this.state.detail && this.state.detail.customer_address
                    ? this.state.detail.customer_address
                    : "-"}
                </label>
              </div>
            </div>
            {/* หมายเหตุ */}
            <div className="w-100 d-flex mb-3 align-items-center">
              <div className="wpx-120">
                <label><b>หมายเหตุ:</b></label>
              </div>
              <div className="w-100">
                <label>
                  {this.state.detail && this.state.detail.remark_repair
                    ? this.state.detail.remark_repair
                    : "-"}
                </label>
              </div>
            </div>
            {/* สถานะ */}
            <div className="w-100 d-flex mb-3 align-items-center">
              <div className="wpx-120">
                <label><b>สถานะ:</b></label>
              </div>
              <div className="w-100">
                <span
                  className={this.status_color(
                    this.state.detail ? this.state.detail.status_job : 0
                  )}
                >
                  {this.status(
                    this.state.detail ? this.state.detail.status_job : 0
                  )}
                </span>
              </div>
            </div>
            {/* ช่างในทีม */}
            <div className="w-100 d-flex mb-3 align-items-center">
              <div className="wpx-120">
                <label><b>มัดจำ:</b></label>
              </div>
              <div className="w-100">
                <label className="text-danger">
                  {this.state.detail && this.state.detail.deposit
                    ? baht(this.state.detail.deposit)

                    : "-"}
                </label>
              </div>
            </div>
            {/* ช่างในทีม */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <p className="mb-0 mt-0"><b>ช่างในทีม:</b></p>
              </div>
              <div className="w-100">
                {this.state.detail && this.state.detail.other_tc_count > 0 ?
                  this.state.detail.other_tc.map((item, index) => (
                    <p className="mb-0">
                      {index + 1}. {item.full_name}
                    </p>
                  )) : "-"
                }
              </div>


            </div>

            <div className="w-100 d-flex justify-content-center">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_detail: false })
                  }}
                >
                  ตกลง
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div >
    )
  }
}
