/* eslint-disable jsx-a11y/anchor-is-valid */
// JS
import "../../../global.js"

// LIBRARY
import { toast } from "react-toastify"
import LOGO from "./logo.png"
import Cookies from "universal-cookie"
import React, { Component } from "react"

import {
  tokens,
  role,
  LOGOUT
} from "../../Custom/CustomComponent.js"

// CSS
import "./style.css"
import "../../../bootstrap.css"
import "react-toastify/dist/ReactToastify.css"
import "react-pro-sidebar/dist/css/styles.css"

const cookies = new Cookies()

toast.configure()

export default class BreadcrumbComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fullname: cookies.get("user_profile")
        ? cookies.get("user_profile").full_name
        : "ผู้ขาย",
      token: cookies.get("token_aip_shop") ? "token " + cookies.get("token_aip_shop") : "",
      user_profile: cookies.get("user_profile") ? cookies.get("user_profile") : "",

    }
  }

  componentDidMount() {
    if (this.state.user_profile === "" || role === "") {

      cookies.remove("token_aip_shop")
      cookies.remove("role_data")
      cookies.remove("user_profile")
      cookies.remove("first_page")

      window.location.href = "/login"
    }

  }

  Logout = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
    }
    fetch(global.api + "v1/logout", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        cookies.remove("token_aip_shop")
        cookies.remove("role_data")
        cookies.remove("user_profile")
        cookies.remove("first_page")
        window.location.href = "/login"
      })
      .catch((e) => {
        this.alert_url("info", "แจ้งเตือน", "เกิดข้อผิดพลาด", "/login")
      })
  }

  GetPageAdmin = async () => {


    let permission_page = ""

    if (role.user_admin[0] === 1) {
      permission_page = "/list-user"
    } else if (role.air_list[0] === 1) {
      permission_page = "/list-air"
    } else if (role.brand_all[0] === 1) {
      permission_page = "/list-brands"
    } else if (role.btu[0] === 1) {
      permission_page = "/list-btu"
    } else if (role.commission[0] === 1) {
      permission_page = "/list-commission"
    } else if (role.company_supplier[0] === 1) {
      permission_page = "/list-company"
    } else if (role.goods_receipt[0] === 1) {
      permission_page = "/receipt"
    } else if (role.product_all[0] === 1) {
      permission_page = "/list-product"
    } else if (role.product_unit[0] === 1) {
      permission_page = "/list-product-unit"
    } else if (role.repair_calendar[0] === 1) {
      permission_page = "/calendar"
    } else if (role.report_sale[0] === 1) {
      permission_page = "/summary"
    } else if (role.role[0] === 1) {
      permission_page = "/role-user"
    } else if (role.sale_list[0] === 1) {
      permission_page = "/list-sale"
    } else if (role.stock_move_list[0] === 1) {
      permission_page = "/list-stock-move"
    } else if (role.transaction_stock[0] === 1) {
      permission_page = "/list-stock-path"
    } else if (role.type_commission[0] === 1) {
      permission_page = "/commission-type"
    } else if (role.type_spare_part[0] === 1) {
      permission_page = "/parts-type"
    } else if (role.user_admin[0] === 1) {
      permission_page = "/list-user"
    } else if (role.user_member[0] === 1) {
      permission_page = "/list-member"
    } else if (role.warehouse[0] === 1) {
      permission_page = "/list-warehouse"
    } else if (role.adjust_stock[0] === 1) {
      permission_page = "/list-stock-adjustment"
    } else if (role.stock_all[0] === 1) {
      permission_page = "/list-stock"
    }





    if (this.state.token) {
      window.location.href = permission_page
      cookies.set("first_page", permission_page);

    }


  }

  render() {
    return (
      <div>
        <div className="breadcrumb-component row mx-0 px-0 position-fixed">
          <div className="col-12 d-flex justify-content-between align-items-center">
            <div className="align-items-center justify-content-start d-flex text-14 mx-0">
              <img className="logo-menu mr-3 " alt="logo" src={LOGO}></img>
            </div>

            <div className="d-flex align-items-center justify-content-center">
              <div className="d-none d-xl-block">

                {role && (role.user_member[0] === 1 ||
                  role.air_list[0] === 1 ||
                  role.brand_all[0] === 1 ||
                  role.btu[0] === 1 ||
                  role.commission[0] === 1 ||
                  role.company_supplier[0] === 1 ||
                  role.goods_receipt[0] === 1 ||
                  role.product_all[0] === 1 ||
                  role.product_unit[0] === 1 ||
                  role.repair_calendar[0] === 1 ||
                  role.report_sale[0] === 1 ||
                  role.role[0] === 1 ||
                  role.sale_list[0] === 1 ||
                  role.stock_move_list[0] === 1 ||
                  role.transaction_stock[0] === 1 ||
                  role.user_admin[0] === 1 ||
                  role.user_member[0] === 1 ||
                  role.warehouse[0] === 1 ||
                  role.adjust_stock[0] === 1 ||
                  role.stock_all[0] === 1) && (
                    <button
                      className="btn btn-outline-primary px-3 mx-3"
                      style={{ height: 28, borderRadius: 14 }}
                      onClick={() => {

                        this.GetPageAdmin()
                      }}
                    >
                      <span className="icon-brand text-primary text-14 mr-1">
                        {"\uf079"}
                      </span>
                      สลับไปยัง Admin
                    </button>
                  )}
              </div>

              <span className="mr-3">{this.state.fullname}</span>
              <div className="dropdowns">
                <a href="#" className="dropbtns">
                  <span className="icon-brand text-primary text-14">
                    {"\uf078"}
                  </span>
                </a>
                <div className="dropdowns-content">
                  {role && (role.user_member[0] === 1 ||
                    role.air_list[0] === 1 ||
                    role.brand_all[0] === 1 ||
                    role.btu[0] === 1 ||
                    role.commission[0] === 1 ||
                    role.company_supplier[0] === 1 ||
                    role.goods_receipt[0] === 1 ||
                    role.product_all[0] === 1 ||
                    role.product_unit[0] === 1 ||
                    role.repair_calendar[0] === 1 ||
                    role.report_sale[0] === 1 ||
                    role.role[0] === 1 ||
                    role.sale_list[0] === 1 ||
                    role.stock_move_list[0] === 1 ||
                    role.transaction_stock[0] === 1 ||
                    role.user_admin[0] === 1 ||
                    role.user_member[0] === 1 ||
                    role.warehouse[0] === 1 ||
                    role.adjust_stock[0] === 1 ||
                    role.stock_all[0] === 1) && (
                      <a className="d-xl-none"
                        onClick={() => {
                          localStorage.removeItem("POS");

                          this.GetPageAdmin()


                        }}>
                        <label className="icon">{"\uf079"} </label> สลับไปยัง Admin
                      </a>
                    )}

                  <a
                    href="#"
                    onClick={() => {
                      LOGOUT()
                    }}
                  >
                    <label className="icon">{"\uf30d"}</label> ออกจากระบบ
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
