// JS
import "../../../../../global.js"

// COMPONENT
import MenuComponent from "../../../SideMenu/SideMenu"
import BreadcrumbComponent from "../../../Breadcrumb/Breadcrumb"

// LIBRARY
import Swal from "sweetalert2"
import { toast } from "react-toastify"
import Cookies from "universal-cookie"
import React, { Component } from "react"
import Select from "react-select"

// CSS
import "./style.css"
import "../../../../../bootstrap.css"
import "react-slideshow-image/dist/styles.css"
import "bootstrap-daterangepicker/daterangepicker.css"

// const imageToBase64 = require("image-to-base64");

const cookies = new Cookies()
toast.configure()
export default class CreateProductScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      token: cookies.get("token_aip_shop") ? "token " + cookies.get("token_aip_shop") : "",

      modal_add: false,
      modal_edit: false,
      modal_delete: false,
      page: 1,
      loading: false,
      search: "",
      data: null,
      data_brand: null,
      data_unit: null,
      data_spare_part_type: null,
      data_spare_part_brand: null,
      detail: null,
      air_map_name: "",
      spare_part_brand_name: "",

      product_id: "",
      sku: "",
      other_sku: "",
      category_name: "",
      product_name: "",
      product_map_id: "",
      spare_part_type_id: "",
      spare_part_brand_id: "",
      unit_id: "",
      safety_stock_noti: 0,
      store_price: "",
      wholesale_price: "",
      deposit_price: "",
      picture: "",
    }
  }
  async componentDidMount() {
    await this.Get_Brand_All()
    await this.Get_Unit_All()
    await this.Get_Spare_Part_Type_All()
    await this.Get_Spare_Part_Brand_All()
    await this.Get(this.props.location.query.id)
  }
  Get = (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
    }
    fetch(global.api + "v1/product-mgt/all/detail/" + id, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          let img = ""
          if (result.data.data.picture) {
            img = result.data.data.picture
          }
          this.setState({
            data: result.data.data,
            product_id: result.data.data.product_id,
            sku: result.data.data.product_data ? result.data.data.product_data.sku : "",
            category_name: result.data.data.category_name,
            product_name: result.data.data.product_name,
            product_map_id: result.data.data.product_data.air_id
              ? result.data.data.product_data.air_id
              : "",
            spare_part_type_id: result.data.data.product_data.spare_part_type_id
              ? result.data.data.product_data.spare_part_type_id
              : "",
            spare_part_brand_id:
              !result.data.data.product_data.air_id &&
                result.data.data.product_data.brand_id
                ? result.data.data.product_data.brand_id
                : "",
            unit_id: result.data.data.unit_id,
            safety_stock_noti: result.data.data.safety_stock_noti,
            store_price: result.data.data.store_price,
            wholesale_price: result.data.data.wholesale_price,
            deposit_price: result.data.data.deposit_price,
            picture: img,

            air_map_name: result.data.data.product_data.air_map_name
              ? result.data.data.product_data.air_map_name
              : "",

            spare_part_brand_name: result.data.data.product_data.brand_name
              ? result.data.data.product_data.brand_name
              : "",
          })
          if (result.data.data.product_data) {
            if (result.data.data.product_data.sku !== "สีดำ" && result.data.data.product_data.sku !== "สีขาว" && result.data.data.product_data.sku !== "สีเทา") {
              if (result.data.data.product_data.sku === "") {
                this.setState({ other_sku: result.data.data.product_data.sku, sku: "" })
              } else {
                this.setState({ other_sku: result.data.data.product_data.sku, sku: "อื่นๆ" })
              }
            }
          }
          this.setState({ loading: false })
        } else {
          switch (result.data.message) {
            case "Token is not found":
              this.alert("info", "แจ้งเตือน", "เซสชั่นหมดอายุ")
              this.Logout()
              break
            case "Role no access permission":
              this.alert("info", "ขออภัย", "อีเมลนี้ไม่ได้รับอนุญาตให้เข้าถึง")
              this.Logout()
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              // cookies.remove("token_aip_shop");
              // window.location.href = "/login";
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
        this.setState({ loading: false })
      })
  }

  Get_Brand_All = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
    }
    fetch(global.api + "v1/product-mgt/air/all", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ data_brand: result.data })

          this.setState({ loading: false })
        } else {
          switch (result.data.message) {
            case "Token is not found":
              this.alert("info", "แจ้งเตือน", "เซสชั่นหมดอายุ")
              this.Logout()
              break
            case "Role no access permission":
              this.alert("info", "ขออภัย", "อีเมลนี้ไม่ได้รับอนุญาตให้เข้าถึง")
              this.Logout()
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              // this.Logout();
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
        this.setState({ loading: false })
      })
  }
  Get_Unit_All = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
    }
    fetch(global.api + "v1/product-mgt/unit/all", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ data_unit: result.data })
          this.setState({ loading: false })
        } else {
          switch (result.data.message) {
            case "Token is not found":
              this.alert("info", "แจ้งเตือน", "เซสชั่นหมดอายุ")
              this.Logout()
              break
            case "Role no access permission":
              this.alert("info", "ขออภัย", "อีเมลนี้ไม่ได้รับอนุญาตให้เข้าถึง")
              this.Logout()
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              // this.Logout();
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
        this.setState({ loading: false })
      })
  }
  Get_Spare_Part_Type_All = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
    }
    fetch(global.api + "v1/product-mgt/spare-part/all", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ data_spare_part_type: result.data })
          this.setState({ loading: false })
        } else {
          switch (result.data.message) {
            case "Token is not found":
              this.alert("info", "แจ้งเตือน", "เซสชั่นหมดอายุ")
              this.Logout()
              break
            case "Role no access permission":
              this.alert("info", "ขออภัย", "อีเมลนี้ไม่ได้รับอนุญาตให้เข้าถึง")
              this.Logout()
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              // this.Logout();
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
        this.setState({ loading: false })
      })
  }
  Get_Spare_Part_Brand_All = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
    }
    fetch(global.api + "v1/product-mgt/brand/all", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ data_spare_part_brand: result.data })
          this.setState({ loading: false })
        } else {
          switch (result.data.message) {
            case "Token is not found":
              this.alert("info", "แจ้งเตือน", "เซสชั่นหมดอายุ")
              this.Logout()
              break
            case "Role no access permission":
              this.alert("info", "ขออภัย", "อีเมลนี้ไม่ได้รับอนุญาตให้เข้าถึง")
              this.Logout()
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              // this.Logout();
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
        this.setState({ loading: false })
      })
  }
  Edit = () => {
    let sku = "";

    if (this.state.other_sku !== "") {
      sku = this.state.other_sku
    } else {
      if (this.state.sku === "อื่นๆ") {
        this.alert("warning", "แจ้งเตือน", "กรุณากรอกข้อมูล SKU")
        return
      }
      sku = this.state.sku
    }


    if (Number(this.state.store_price) === 0) {
      this.alert("warning", "แจ้งเตือน", "กรุณากรอกราคาขายหน้าร้านมากกว่า 0 บาท")
      return
    }

    this.setState({ loading: true })
    setTimeout(() => {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: this.state.token,
        },
        body: JSON.stringify({
          product_id: this.state.product_id,
          sku: sku,
          category_name: this.state.category_name,
          product_name: this.state.product_name,
          product_map_id: this.state.product_map_id,
          spare_part_type_id: this.state.spare_part_type_id,
          spare_part_brand_id: this.state.spare_part_brand_id,
          unit_id: this.state.unit_id,
          safety_stock_noti: this.state.safety_stock_noti,
          store_price: this.state.store_price,
          wholesale_price:
            this.state.wholesale_price === "" ? 0 : this.state.wholesale_price,
          deposit_price:
            this.state.deposit_price === "" ? 0 : this.state.deposit_price,
          picture: this.state.picture,
        }),
      }
      fetch(global.api + "v1/product-mgt/all/update", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status) {
            this.setState({ loading: false, page: 1 })
            this.alert_url(
              "success",
              "แก้ไขสินค้าเรียบร้อยแล้ว",
              "สินค้า “" + this.state.product_name + "” ถูกแก้ไขในระบบแล้ว",
              "/list-product"
            )
            this.clear_form()
          } else {
            switch (result.data.message) {
              case "product name is none":
                this.alert("info", "แจ้งเตือน", "กรุณากรอกชื่อสินค้า")
                break
              case "unit id is none":
                this.alert("info", "แจ้งเตือน", "กรุณาเลือกหน่วย")
                break
              case "store price is none":
                this.alert("info", "แจ้งเตือน", "กรุณากรอกราคาขายหน้าร้าน")
                break
              case "wholesale price is none":
                this.alert("info", "แจ้งเตือน", "กรุณากรอกราคาขายส่ง")
                break
              case "deposit price is none":
                this.alert("info", "แจ้งเตือน", "กรุณากรอกราคาขายฝาก")
                break
              case "spaer part name is duplicate":
                this.alert("info", "แจ้งเตือน", "มีชื่อสินค้านี้อยู่แล้ว")
                break
              case "product main is duplicate":
                if (this.state.category_name === "technician") {
                  this.alert("info", "แจ้งเตือน", "มีชื่องานนี้อยู่แล้ว")
                } else if (this.state.category_name === "spare_part") {
                  this.alert("info", "แจ้งเตือน", "มีชื่อสินค้านี้อยู่แล้ว")
                } else {
                  this.alert("info", "แจ้งเตือน", "มีสินค้านี้ในระบบแล้ว")
                }
                break
              case "Map air is not found":
                this.alert("info", "แจ้งเตือน", "ไม่มีสินค้านี้ในระบบ")
                break
              case "Token is not found":
                this.alert("info", "แจ้งเตือน", "เซสชั่นหมดอายุ")
                this.Logout()
                break
              default:
                this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
                break
            }
            this.setState({ loading: false })
          }
        })
        .catch((e) => {
          this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
          this.setState({ loading: false })
        })
    }, 10);
  }

  Logout = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
    }
    fetch(global.api + "v1/logout", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        cookies.remove("token_aip_shop")
        cookies.remove("role_data")
        cookies.remove("user_profile")
        cookies.remove("first_page")
        window.location.href = "/login"
      })
      .catch((e) => {
        this.alert_url("info", "แจ้งเตือน", "เกิดข้อผิดพลาด", "/login")
      })
  }
  alert(icon, title, detail) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    })
  }
  alert_url(icon, title, detail, url) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    }).then(() => {
      window.location.href = url
    })
  }

  clear_form() {
    this.setState({
      loading: false,
      modal_detail: false,
    })
  }

  handleChange = (e) => {
    if (e.target.files.length) {
      let reader = new FileReader()
      reader.readAsDataURL(e.target.files[0])
      reader.onload = (e) => {
        this.setState({
          picture: e.target.result.split(",")[1],
        })
      }
    }
  }
  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <MenuComponent />
          <div className="bodyw-100">
            <BreadcrumbComponent
              page1="หน้าหลัก"
              page2="สินค้าทั้งหมด"
              page3="แก้ไขสินค้า"
            />
            <div style={{ height: 40 }}></div>
            <div className="row">
              <div className="col-12 col-sm-1 col-md-2 col-lg-2 col-xl-3"></div>
              <div className="col-12 col-sm-10 col-md-8 col-lg-8 col-xl-6">
                <div className="w-100 d-flex align-items-center justify-content-between px-3 mt-5">
                  <h2>
                    <b>แก้ไขสินค้า</b>
                  </h2>
                  <div>
                    <button
                      type="button"
                      className="btn btn-outline-primary w-100 px-4"
                      onClick={() => {
                        // this.Login();
                        // this.setState({ modal_add: true });

                        window.location.href = "/list-product"
                      }}
                    >
                      <span className="icon-brand mr-2">{"\uf060"}</span>
                      ย้อนกลับ
                    </button>
                  </div>
                </div>
                <div className="w-100 p-3">
                  {/* ภาพสินค้า */}
                  <div className="card rounded p-3 mb-3">
                    <h4>
                      <b>ภาพสินค้า</b>
                    </h4>

                    {this.state.picture === "" ? (
                      <div
                        className="card border-2 rounded text-secondary bg-secondary-light p-5 border-dash d-flex align-items-center justify-content-center pointer"
                        onClick={() => {
                          this.refs.fileUploader.click()
                        }}
                      >
                        <span className="icon-brand mr-2">{"\uf0ee"}</span>
                        <label className="w-100 text-center">อัปโหลดภาพ</label>
                      </div>
                    ) : (
                      <div
                        className="card border-2 rounded text-secondary bg-secondary-light p-2 border-dash d-flex align-items-center justify-content-center pointer"
                        onClick={() => {
                          this.refs.fileUploader.click()
                        }}
                      >
                        <img
                          className="rounded w-100 h-100 img"
                          alt="logo"
                          src={
                            this.state.picture &&
                              this.state.picture.includes("storage")
                              ? this.state.picture
                              : "data:image/png;base64," + this.state.picture
                          }
                          style={{ objectFit: "contain" }}
                        ></img>
                      </div>
                    )}

                    <input
                      type="file"
                      id="file"
                      ref="fileUploader"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        this.handleChange(e)
                      }}
                    />
                  </div>
                  {/* รายละเอียด */}
                  <div className="card rounded p-3 mb-3">
                    <h4>
                      <b>รายละเอียด</b>
                    </h4>
                    <div className="row">
                      <div className="col-12 mb-3">
                        <label>ชื่อสินค้า:</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="กรอกข้อมูล"
                          onChange={(e) => {
                            this.setState({ product_name: e.target.value })
                          }}
                          value={this.state.product_name}
                        ></input>
                      </div>
                      <div
                        className={
                          this.state.category_name === "technician"
                            ? "col-12 mb-3"
                            : "col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3"
                        }
                      >
                        <label>หมวดหมู่:</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="กรอกข้อมูล"
                          readOnly
                          value={
                            this.state.category_name === "air"
                              ? "เครื่องปรับอากาศ"
                              : this.state.category_name === "technician"
                                ? "งานซ่อม"
                                : "อะไหล่"
                          }
                        ></input>
                      </div>

                      {this.state.category_name === "spare_part" && (
                        <>
                          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                            <label>ประเภทอะไหล่:</label>
                            <select
                              className="form-control"
                              onChange={(e) => {
                                this.setState({
                                  spare_part_type_id: e.target.value,
                                })
                              }}
                              value={this.state.spare_part_type_id}
                            >
                              <option value="" selected disabled>
                                -- เลือก --
                              </option>
                              {this.state.data_spare_part_type &&
                                this.state.data_spare_part_type.data.map(
                                  (item, index) => (
                                    <option value={item.spare_part_id}>
                                      {item.spare_part_name}
                                    </option>
                                  )
                                )}
                            </select>
                          </div>
                          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                            <label>ยี่ห้อ:</label>
                            <Select
                              className="form-control px-0"
                              options={
                                this.state.data_spare_part_brand &&
                                this.state.data_spare_part_brand.data.map(
                                  (item, index) => ({
                                    value: item.brand_id,
                                    label: item.brand_name,
                                  })
                                )
                              }
                              placeholder="-- เลือก --"
                              defaultValue={this.state.spare_part_brand_id}
                              defaultInputValue={
                                this.state.spare_part_brand_name
                              }
                              onChange={(e) => {
                                this.setState({
                                  spare_part_brand_id: e.value,
                                })
                              }}
                            />
                          </div>
                        </>
                      )}
                      {this.state.category_name !== "technician" && (
                        <>
                          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                            <label>หน่วย:</label>
                            <select
                              className="form-control"
                              onChange={(e) => {
                                this.setState({ unit_id: e.target.value })
                              }}
                              value={this.state.unit_id}
                            >
                              <option value="" selected disabled>
                                -- เลือก --
                              </option>
                              {this.state.data_unit &&
                                this.state.data_unit.data.map((item, index) => (
                                  <option value={item.unit_id}>
                                    {item.unit_name}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </>
                      )}
                      {this.state.category_name !== "technician" && (
                        <>
                          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                            <label>SKU:</label>
                            <select
                              className="form-control"
                              onChange={(e) => {
                                this.setState({ sku: e.target.value })
                                this.setState({ other_sku: "" })
                              }}
                              value={this.state.sku}
                            >
                              <option value="" selected disabled>-- เลือก --</option>
                              <option value="สีดำ">ดำ</option>
                              <option value="สีขาว">ขาว</option>
                              <option value="สีเทา">เทา</option>
                              <option value="อื่นๆ">อื่นๆ</option>
                            </select>
                          </div>
                        </>
                      )}
                      {this.state.sku === "อื่นๆ" && (
                        <>
                          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                            <label>อื่นๆ:</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="กรอกข้อมูล"
                              value={
                                this.state.other_sku
                              }
                              onChange={(e) => {
                                this.setState({ other_sku: e.target.value })
                              }}
                            ></input>
                          </div>
                        </>
                      )}
                      {this.state.category_name === "air" && (
                        <>
                          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                            <label>ยี่ห้อ/รุ่น/BTU:</label>
                            <Select
                              className="form-control px-0"
                              options={
                                this.state.data_brand &&
                                this.state.data_brand.data.map(
                                  (item, index) => ({
                                    value: item.air_id,
                                    label: item.air_map_name
                                      .replace("wall_air", "แอร์ผนัง")
                                      .replace("hanging_air", "แอร์แขวน")
                                      .replace(
                                        "4_direction_air",
                                        "แอร์ 4 ทิศทาง"
                                      ),
                                  })
                                )
                              }
                              placeholder="พิมพ์ชื่อแอร์"
                              defaultValue={this.state.product_map_id}
                              defaultInputValue={this.state.air_map_name
                                .replace("wall_air", "แอร์ผนัง")
                                .replace("hanging_air", "แอร์แขวน")
                                .replace("4_direction_air", "แอร์ 4 ทิศทาง")}
                              onChange={(e) => {
                                this.setState({
                                  product_map_id: e.value,
                                })
                              }}
                            />
                          </div>
                        </>
                      )}
                      {this.state.category_name !== "technician" && (
                        <>
                          <div className={"col-12 mb-3"}>
                            <label>แจ้งเตือนสต็อกสินค้า:</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="กรอกข้อมูล"
                              onChange={(e) => {
                                let safety_stock_noti =
                                  this.state.safety_stock_noti
                                safety_stock_noti = e.target.value.replace(
                                  /\D/g,
                                  ""
                                )
                                this.setState({
                                  safety_stock_noti: safety_stock_noti,
                                })
                              }}
                              value={this.state.safety_stock_noti}
                            ></input>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  {/* ราคาสินค้า */}
                  <div className="card rounded p-3 mb-3">
                    <h4>
                      <b>ราคาสินค้า</b>
                    </h4>
                    <div className="row">
                      <div className="col-12 mb-3">
                        <label>ราคาขายหน้าร้าน:</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="กรอกข้อมูล"
                          onChange={(e) => {
                            let store_price = this.state.store_price
                            store_price = e.target.value.replace(/\D/g, "")
                            this.setState({
                              store_price: store_price,
                            })
                          }}
                          value={this.state.store_price}
                        ></input>
                      </div>
                      {this.state.category_name !== "technician" && (
                        <>
                          <div className="col-12 mb-3">
                            <label>ราคาขายส่ง:</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="กรอกข้อมูล"
                              onChange={(e) => {
                                let wholesale_price = this.state.wholesale_price
                                wholesale_price = e.target.value.replace(
                                  /\D/g,
                                  ""
                                )
                                this.setState({
                                  wholesale_price: wholesale_price,
                                })
                              }}
                              value={this.state.wholesale_price}
                            ></input>
                          </div>
                          <div className="col-12 mb-3">
                            <label>ราคาขายฝาก:</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="กรอกข้อมูล"
                              onChange={(e) => {
                                let deposit_price = this.state.deposit_price
                                deposit_price = e.target.value.replace(
                                  /\D/g,
                                  ""
                                )
                                this.setState({
                                  deposit_price: deposit_price,
                                })
                              }}
                              value={this.state.deposit_price}
                            ></input>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="w-100">
                  <div className="row mx-0">
                    <div className="col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8 ml-auto mb-3">
                      <div className="d-flex">
                        <div className="mr-1 w-50">
                          <button
                            type="button"
                            className="btn btn-outline-primary w-100 px-4"
                            onClick={() => {
                              window.location.href = "/list-product"
                            }}
                          >
                            ยกเลิก
                          </button>
                        </div>
                        <div className="ml-1 w-50">
                          <button
                            type="button"
                            className="btn btn-primary w-100 px-4"
                            onClick={() => {
                              if (this.state.category_name === "air") {
                                if (this.state.product_name === "") {
                                  this.alert(
                                    "info",
                                    "แจ้งเตือน",
                                    "กรุณากรอกชื่อสินค้า"
                                  )
                                  return
                                } else if (this.state.unit_id === "") {
                                  this.alert(
                                    "info",
                                    "แจ้งเตือน",
                                    "กรุณาเลือกหน่วยของสินค้า"
                                  )
                                  return
                                } else if (this.state.product_map_id === "") {
                                  this.alert(
                                    "info",
                                    "แจ้งเตือน",
                                    "กรุณาเลือกยี่ห้อ/รุ่น/BTU"
                                  )
                                  return
                                } else if (!this.state.store_price) {
                                  this.alert(
                                    "info",
                                    "แจ้งเตือน",
                                    "กรุณากรอกราคาขายหน้าร้าน"
                                  )
                                  this.setState({ loading: false })
                                  return
                                }
                              } else if (
                                this.state.category_name === "spare_part"
                              ) {
                                if (this.state.product_name === "") {
                                  this.alert(
                                    "info",
                                    "แจ้งเตือน",
                                    "กรุณากรอกชื่อสินค้า"
                                  )
                                  return
                                } else if (
                                  this.state.spare_part_type_id === ""
                                ) {
                                  this.alert(
                                    "info",
                                    "แจ้งเตือน",
                                    "กรุณาเลือกประเภทอะไหล่"
                                  )
                                  return
                                } else if (
                                  this.state.spare_part_brand_id === ""
                                ) {
                                  this.alert(
                                    "info",
                                    "แจ้งเตือน",
                                    "กรุณาเลือกยี่ห้อของอะไหล่"
                                  )
                                  return
                                } else if (this.state.unit_id === "") {
                                  this.alert(
                                    "info",
                                    "แจ้งเตือน",
                                    "กรุณาเลือกหน่วยของสินค้า"
                                  )
                                  return
                                } else if (this.state.store_price === "") {
                                  this.alert(
                                    "info",
                                    "แจ้งเตือน",
                                    "กรุณากรอกราคาขายหน้าร้าน"
                                  )
                                  this.setState({ loading: false })
                                  return
                                }
                              } else {
                                if (this.state.product_name === "") {
                                  this.alert(
                                    "info",
                                    "แจ้งเตือน",
                                    "กรุณากรอกชื่อสินค้า"
                                  )
                                  return
                                } else if (!this.state.store_price) {
                                  this.alert(
                                    "info",
                                    "แจ้งเตือน",
                                    "กรุณากรอกราคา"
                                  )
                                  this.setState({ loading: false })
                                  return
                                }
                              }

                              this.Edit()
                            }}
                          >
                            บันทึก
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-1 col-md-2 col-lg-2 col-xl-3"></div>
            </div>
          </div>
        </div>
        {/* modal */}
      </div>
    )
  }
}
