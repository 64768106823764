// JS
import "../../../../../global.js"

// COMPONENT
import MenuComponent from "../../../SideMenu/SideMenu"
import BreadcrumbComponent from "../../../Breadcrumb/Breadcrumb"

// LIBRARY
import Swal from "sweetalert2"
import { toast } from "react-toastify"
import Cookies from "universal-cookie"
import React, { Component } from "react"
import { Modal } from "react-bootstrap"
import Select from "react-select"
import IMGAIR from "./imgair.png"
import IMGSPAREPART from "./imgsparepart.png"

// CSS
import "./style.css"
import "../../../../../bootstrap.css"
import "react-slideshow-image/dist/styles.css"
import "bootstrap-daterangepicker/daterangepicker.css"

const cookies = new Cookies()
toast.configure()
export default class CreateStockAdjustmentScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      token: cookies.get("token_aip_shop") ? "token " + cookies.get("token_aip_shop") : "",
      modal_add: false,
      modal_edit: false,
      modal_delete: false,
      modal_cancel: false,
      page: 1,
      loading: false,
      search: "",
      data: null,
      data_product: null,
      data_warehouse: null,
      detail: null,
      product_map: null,

      product_map_id: "",
      stock_location_from_id: "",
      type: "add",
      document_code: "SPM" + new Date().getTime(),
      remark: "",
      stock_main_list: null,
      date: "",
    }

  }
  async componentDidMount() {
    await this.Get_WareHouse_All()

    let date =
      ("00" + new Date().getDate()).slice(-2) +
      "/" +
      ("00" + (new Date().getMonth() + 1)).slice(-2) +
      "/" +
      (new Date().getFullYear() + 543)
    this.setState({ date: date })
  }

  Get_Product_All = (id) => {
    this.setState({ loading: true })
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
    }
    fetch(global.api + "v1/stock-mgt/main/all/" + id, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          result.data.data = result.data.data.filter(
            (e) => e.product_main_data.category_name !== "technician"
          )
          this.setState({ data_product: result.data })
          this.setState({ loading: false })
        } else {
          switch (result.data.message) {
            case "Token is not found":
              this.alert("info", "แจ้งเตือน", "เซสชั่นหมดอายุ")
              this.Logout()
              break
            case "Role no access permission":
              this.alert("info", "ขออภัย", "อีเมลนี้ไม่ได้รับอนุญาตให้เข้าถึง")
              this.Logout()
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              // this.Logout();
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
        this.setState({ loading: false })
      })
  }
  Get_WareHouse_All = () => {
    this.setState({ loading: true })
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
    }
    fetch(global.api + "v1/stock-mgt/location/all", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ data_warehouse: result.data })
          this.setState({ loading: false })
        } else {
          switch (result.data.message) {
            case "Token is not found":
              this.alert("info", "แจ้งเตือน", "เซสชั่นหมดอายุ")
              this.Logout()
              break
            case "Role no access permission":
              this.alert("info", "ขออภัย", "อีเมลนี้ไม่ได้รับอนุญาตให้เข้าถึง")
              this.Logout()
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              // this.Logout();
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
        this.setState({ loading: false })
      })
  }
  Create = () => {
    if (this.state.stock_location_from_id === "") {
      this.alert("info", "แจ้งเตือน", "กรุณาเลือกสถานที่")
      return
    }
    this.setState({ loading: true })
    let stock_main_list = null
    if (this.state.data) {
      for (let data of this.state.data) {
        if (stock_main_list === null) {
          stock_main_list = []
        }
        stock_main_list.push({
          stock_main_id: data.stock_main_id,
          qty: data.qty,
        })
      }
    }
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
      body: JSON.stringify({
        type: this.state.type,
        document_code: this.state.document_code,
        remark: this.state.remark,
        stock_main_list: stock_main_list,
      }),
    }
    fetch(global.api + "v1/stock-mgt/adjust/create", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.clear_form()
          this.setState({ loading: false, page: 1 })
          this.alert_url(
            "success",
            "ปรับสต๊อกสินค้าเรียบร้อยแล้ว",
            "สต๊อกสินค้า ถูกปรับในในระบบแล้ว",
            "/list-stock-adjustment"
          )
        } else {
          switch (result.data.message) {
            case "Stock balance less 0":
              this.alert(
                "info",
                "แจ้งเตือน",
                "จำนวนสินค้าในโกดังไม่เพียงพอสำหรับนำออก"
              )
              break
            case "Role no access permission":
              this.alert("info", "ขออภัย", "อีเมลนี้ไม่ได้รับอนุญาตให้เข้าถึง")
              this.Logout()
              break
            case "stock Location from id is none":
              this.alert("info", "แจ้งเตือน", "กรุณาเลือกโกดังต้นทาง")
              break
            case "stock Location to id is none":
              this.alert("info", "แจ้งเตือน", "กรุณาเลือกโกดังปลายทาง")
              break
            case "stock main list is none":
              this.alert("info", "แจ้งเตือน", "กรุณาเลือกสินค้าที่ต้องการปรับ")
              break
            case "Stock balance over stock transaction":
              this.alert("info", "แจ้งเตือน", "จำนวนสินค้าเกินกำหนด กรุณานำเข้าจากเมนูใบรับสินค้า")
              break
            case "qty is none":
              this.alert("info", "แจ้งเตือน", "กรุณากรอกจำนวนสินค้า")
              break
            case "Token is not found":
              this.alert("info", "แจ้งเตือน", "เซสชั่นหมดอายุ")
              this.Logout()
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              // this.Logout();
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
        this.setState({ loading: false })
      })
  }
  add_product = () => {
    let data = { ...this.state.product_map, qty: 0 }
    if (this.state.data) {
      let d = this.state.data.filter(e => e.stock_main_id === this.state.product_map.stock_main_id);
      if (d.length !== 0) {
        let array = this.state.data;
        array.find(e => {
          if (e.stock_main_id === this.state.product_map.stock_main_id) {
            if (e.qty + 1 <= (e.total_qty_in - e.total_qty_sold - e.stock_qty)) {
              e.qty += 1;
            }
          }
        })
        this.setState({ data: array })
      } else {
        let array = this.state.data;
        array.push(data);
        this.setState({ data: array })
      }
    } else {
      this.setState({ data: [data] })
    }
    this.setState({ modal_add: false })
  }
  Logout = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
    }
    fetch(global.api + "v1/logout", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        cookies.remove("token_aip_shop")
        cookies.remove("role_data")
        cookies.remove("user_profile")
        cookies.remove("first_page")
        window.location.href = "/login"
      })
      .catch((e) => {
        this.alert_url("info", "แจ้งเตือน", "เกิดข้อผิดพลาด", "/login")
      })
  }
  alert(icon, title, detail) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    })
  }
  alert_url(icon, title, detail, url) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    }).then(() => {
      window.location.href = url
    })
  }

  clear_form() {
    this.setState({
      loading: false,
      modal_detail: false,
    })
  }
  adjust_qty() {
    if (this.state.type === "remove") {
      if (this.state.data && this.state.data.length !== 0) {
        let array = this.state.data
        for (let data of array) {
          if (data.qty > data.stock_qty) {
            data.qty = data.stock_qty
          }
        }
        this.setState({ data: array })
      }
    }
  }
  interval;
  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <MenuComponent />
          <div
            className="bodyw-100 position-relative"
            style={{ height: "100%", paddingBottom: "76px" }}
          >
            <BreadcrumbComponent
              page1="หน้าหลัก"
              page2="รายการปรับสต๊อก"
              page3="ปรับสต๊อก"
            />
            <div style={{ height: 40 }}></div>
            <div className="w-100 d-flex align-items-center justify-content-between px-3 mt-5">
              <h2>
                <b>ปรับสต๊อก</b>
              </h2>
            </div>
            <div className="w-100 p-3 mb-3">
              <div className="card rounded">
                <div className="card-body">
                  <div className="row">
                    <div className="col-6 col-xl-3 mb-3 px-2">
                      <label className="w-100">เลขที่เอกสาร:</label>
                      <input
                        type="text"
                        className="form-control w-100"
                        value={this.state.document_code}
                        readOnly
                      ></input>
                    </div>
                    <div className="col-6 col-xl-3 mb-3 px-2">
                      <label className="w-100">วันที่:</label>
                      <input
                        type="text"
                        className="form-control w-100"
                        value={this.state.date}
                        readOnly
                      ></input>
                    </div>
                    <div className="col-6 col-xl-3 mb-3 px-2">
                      <label className="w-100">สถานที่:</label>
                      <select
                        className="form-control"
                        onChange={(e) => {
                          this.setState({
                            stock_location_from_id: e.target.value,
                          })
                          this.Get_Product_All(e.target.value)
                        }}
                        value={this.state.stock_location_from_id}
                        disabled={
                          this.state.data === null ||
                            this.state.data.length === 0
                            ? ""
                            : "disabled"
                        }
                      >
                        <option value="" selected disabled>
                          -- เลือก --
                        </option>
                        {this.state.data_warehouse &&
                          this.state.data_warehouse.data.map((item, index) => (
                            <option value={item.stock_location_id}>
                              {item.stock_location_name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-6 col-xl-3 mb-3 px-2">
                      <label className="w-100">เลือก:</label>
                      <div
                        className="card rounded-8 border-primary w-100"
                        style={{ padding: 2 }}
                      >
                        <div className="d-flex w-100">
                          <div className="w-50 pr-1">
                            <button
                              className={
                                this.state.type === "add"
                                  ? "btn btn-primary w-100"
                                  : "btn btn-link w-100"
                              }
                              onClick={() => {
                                this.setState({ type: "add" })
                                setTimeout(() => {
                                  this.adjust_qty()
                                }, 10)
                              }}
                            >
                              นำสต๊อกเข้า
                            </button>
                          </div>
                          <div className="w-50 pl-1">
                            <button
                              className={
                                this.state.type === "remove"
                                  ? "btn btn-primary w-100"
                                  : "btn btn-link w-100"
                              }
                              onClick={() => {
                                this.setState({ type: "remove" })
                                setTimeout(() => {
                                  this.adjust_qty()
                                }, 10)
                              }}
                            >
                              นำสต๊อกออก
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-xl-6 px-2 mb-3">
                      <label className="w-100">หมายเหตุการปรับ:</label>
                      <input
                        type="text"
                        className="form-control w-100"
                        placeholder="ไม่จำเป็นต้องกรอก"
                        value={this.state.remark}
                        onChange={(e) => {
                          this.setState({ remark: e.target.value })
                        }}
                      ></input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-100 p-3">
              <div className="card rounded" style={{ marginBottom: 32 }}>
                <div className="card-header rounded-top">
                  <div className="w-100 d-flex justify-content-between align-items-center pt-2">
                    <h3>
                      <b>สินค้าที่ต้องการปรับ</b>
                    </h3>
                    <div>
                      <button
                        className="btn btn-outline-primary px-3 bg-white"
                        onClick={() => {
                          if (this.state.stock_location_from_id === "") {
                            this.alert(
                              "warning",
                              "แจ้งเตือน",
                              "กรุณาเลือกสถานที่ก่อนการเพิ่มสินค้า"
                            )
                          } else {
                            this.setState({
                              modal_add: true,
                              product_map: null,
                            })
                          }
                        }}
                      >
                        + เพิ่มสินค้า
                      </button>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table design-table">
                      <thead>
                        <tr>
                          <th className="text-left" style={{ minWidth: 100 }}>รหัสสินค้า</th>
                          <th className="text-left" style={{ minWidth: 100 }}>ภาพสินค้า</th>
                          <th className="text-left" style={{ minWidth: 130 }}>ชื่อสินค้า</th>
                          <th className="text-center" style={{ minWidth: 170 }}>
                            จำนวนตามใบรับเข้า
                          </th>
                          <th className="text-center" style={{ minWidth: 170 }}>
                            จำนวนสินค้าในสต๊อก
                          </th>
                          <th className="text-center">จำนวน</th>
                          <th className="text-center" style={{ minWidth: 100 }}>นำออก</th>
                        </tr>
                      </thead>
                      <tbody>
                        {((this.state.data && this.state.data.length === 0) ||
                          !this.state.data) && (
                            <tr>
                              <td colSpan={6} className="text-center">
                                -- ไม่มีข้อมูล --
                              </td>
                            </tr>
                          )}
                        {this.state.data &&
                          this.state.data.map((item, index) => (
                            <tr key={index}>
                              <td>{item.product_main_data.product_code}</td>
                              <td>
                                {item.product_main_data.picture ? (
                                  <img
                                    className="rounded wpx-50 hpx-50 img"
                                    alt="logo"
                                    src={item.product_main_data.picture}
                                    style={{ objectFit: "cover" }}
                                  ></img>
                                ) : (<div>
                                  <img
                                    className="rounded img wpx-50 hpx-50"
                                    alt="logo"
                                    src={
                                      item.category_name === "air"
                                        ? IMGAIR
                                        : IMGSPAREPART
                                    }
                                    style={{ objectFit: "contain", width: 50, height: 50 }}
                                  ></img>
                                  <div className="d-flex justify-content-center align-items-center">
                                    <label className="text-6 text-empty">
                                      ยังไม่ได้อัปโหลดรูป
                                    </label>
                                  </div>
                                </div>)}
                              </td>
                              <td>{item.product_main_data.product_name}</td>
                              <td className="text-center">{(item.total_qty_in)}</td>
                              <td className="text-center">{item.stock_qty}</td>
                              <td className="text-center">
                                <button
                                  className="btn btn-link"
                                  onMouseDown={() => {
                                    this.interval = setInterval(() => {
                                      let data = this.state.data
                                      if (data[index].qty > 0) {
                                        data[index].qty -= 1
                                      }
                                      this.setState({ data: data })
                                    }, 300)
                                  }}
                                  onMouseUp={() => {
                                    clearInterval(this.interval);
                                  }}
                                  onClick={() => {
                                    let data = this.state.data
                                    if (data[index].qty > 0) {
                                      data[index].qty -= 1
                                    }
                                    this.setState({ data: data })
                                  }}
                                >
                                  <label
                                    className="bg-secondary text-18 text-white pointer"
                                    style={{
                                      width: 24,
                                      height: 24,
                                      borderRadius: 12,
                                    }}
                                  >
                                    -
                                  </label>
                                </button>
                                {item.qty}
                                {this.state.countDown}
                                <button
                                  className="btn btn-link"
                                  onMouseDown={() => {
                                    this.interval = setInterval(() => {
                                      let data = this.state.data
                                      if (this.state.type === "add") {
                                        if (data[index].qty < (data[index].total_qty_in - data[index].stock_qty)) {
                                          data[index].qty += 1
                                        } else if ((data[index].total_qty_in - data[index].stock_qty) < 0) {
                                          data[index].qty = 0
                                          clearInterval(this.interval);
                                        } else {
                                          data[index].qty = (data[index].total_qty_in - data[index].stock_qty)
                                          clearInterval(this.interval);
                                          this.alert("info", "แจ้งเตือน", "จำนวนสินค้าเกินกำหนด กรุณานำเข้าจากเมนูใบรับสินค้า")
                                        }
                                      } else {
                                        if (data[index].stock_qty === 0) {
                                          data[index].qty = 0
                                        } else if (
                                          data[index].qty < data[index].stock_qty
                                        ) {
                                          data[index].qty += 1
                                        }
                                      }

                                      this.setState({ data: data })
                                    }, 300)
                                  }}
                                  onMouseUp={() => {
                                    clearInterval(this.interval);
                                  }}
                                  onClick={() => {
                                    let data = this.state.data
                                    if (this.state.type === "add") {
                                      if (data[index].qty < (data[index].total_qty_in - data[index].stock_qty)) {
                                        data[index].qty += 1
                                      } else {
                                        this.alert("info", "แจ้งเตือน", "จำนวนสินค้าเกินกำหนด กรุณานำเข้าจากเมนูใบรับสินค้า")
                                      }
                                    } else {
                                      if (data[index].stock_qty === 0) {
                                        data[index].qty = 0
                                      } else if (
                                        data[index].qty < data[index].stock_qty
                                      ) {
                                        data[index].qty += 1
                                      }
                                    }

                                    this.setState({ data: data })
                                  }}
                                >
                                  <label
                                    className="bg-secondary text-18 text-white pointer"
                                    style={{
                                      width: 24,
                                      height: 24,
                                      borderRadius: 12,
                                    }}
                                  >
                                    +
                                  </label>
                                </button>
                              </td>
                              <td className="text-center">
                                <button
                                  className="btn btn-link text-secondary"
                                  onClick={() => {
                                    let data = this.state.data
                                    data.splice(index, 1)
                                    this.setState({ data: data })
                                  }}
                                >
                                  <label
                                    className="icon bg-danger text-14 text-white"
                                    style={{
                                      width: 24,
                                      height: 24,
                                      borderRadius: 12,
                                    }}
                                  >
                                    {"\uf00d"}
                                  </label>
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="position-fixed bottom-0 bg-white w-100 p-3">
          <div className="d-flex w-100 justify-content-end align-items-center">
            <div className="d-flex">
              <button
                className="btn btn-outline-primary mx-2 px-5"
                onClick={() => {
                  this.setState({ modal_cancel: true })
                }}
              >
                ยกเลิก
              </button>
              <button
                className="btn btn-primary mx-2 px-5"
                onClick={() => {
                  this.Create()
                }}
              >
                บันทึก
              </button>
            </div>
          </div>
        </div>
        {/* modal */}
        {/* ADD modal */}
        <Modal
          show={this.state.modal_add}
          onHide={() => {
            this.setState({ modal_add: false })
          }}
        >
          <Modal.Body>
            <div className="d-flex w-100 justify-content-between align-items-center mb-3">
              <h3>
                <b>ค้นหาสินค้า</b>
              </h3>
              <button
                className="btn btn-link text-secondary"
                onClick={() => {
                  this.setState({ modal_add: false })
                }}
              >
                <label
                  className="icon bg-gray text-14 text-white"
                  style={{
                    width: 24,
                    height: 24,
                    borderRadius: 12,
                  }}
                >
                  {"\uf00d"}
                </label>
              </button>
            </div>
            <hr />
            {this.state.product_map && (
              <div className="w-100 d-flex mb-3">
                <div className="card rounded-8 bg-blue-sky pt-3 pl-3 pr-1 pb-1 w-100">
                  <div className="d-flex justify-content-between">
                    <h4>
                      {this.state.product_map.product_main_data.product_name}
                    </h4>
                  </div>
                  <label className="text-secondary">
                    รหัสสินค้า:{" "}
                    {this.state.product_map.product_main_data.product_code}
                  </label>
                </div>
              </div>
            )}
            <div className="w-100 d-flex mb-3">
              <Select
                className="form-control px-0"
                options={
                  this.state.data_product &&
                  this.state.data_product.data.map((item, index) => ({
                    value: item,
                    label: item.product_main_data.product_name,
                  }))
                }
                placeholder="พิมพ์ชื่อสินค้า"
                defaultValue={this.state.product_map}
                onChange={(e) => {
                  this.setState({
                    product_map: e.value,
                  })
                }}
              />
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_add: false })
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.product_map) {
                      this.add_product()
                    } else {
                      this.alert(
                        "info",
                        "แจ้งเตือน",
                        "กรุณาเลือกสินค้า"
                      )
                    }
                  }}
                >
                  เพิ่ม
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* CANCEL modal */}
        <Modal
          show={this.state.modal_cancel}
          onHide={() => {
            this.setState({ modal_cancel: false })
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b>ยืนยันการยกเลิก</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 d-flex mb-3">
              <label>ท่านแน่ในว่าต้องการยกเลิกการทำรายการหรือไม่</label>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_cancel: false })
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-danger w-100"
                  onClick={() => {
                    window.location.href = "/list-stock-adjustment"
                  }}
                >
                  ยืนยัน
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}
