/* eslint-disable jsx-a11y/anchor-is-valid */
// JS
import "../../../global.js"

// LIBRARY
import { Link } from "react-router"
import { toast } from "react-toastify"
import LOGO from "./logo.png"
import Cookies from "universal-cookie"
import React, { Component } from "react"
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar"

import {
  role,
  LOGOUT,
  user_profile
} from "../../Custom/CustomComponent.js"

// CSS
import "./style.css"
import "../../../bootstrap.css"
import "react-toastify/dist/ReactToastify.css"
import "react-pro-sidebar/dist/css/styles.css"

const cookies = new Cookies()

toast.configure()

export default class BreadcrumbComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fullname: cookies.get("user_profile")
        ? cookies.get("user_profile").full_name
        : "ผู้ใช้งานทั่วไป",

      sidebar: false,
    }
  }

  componentDidMount() {

    if (role && !role.user_admin) {

      window.location.href = "/login"

      cookies.remove("role_data")
      cookies.remove("user_profile")
      cookies.remove("first_page")
      cookies.remove("token_aip_shop")
    }

    if (user_profile === undefined || role === undefined) {

      window.location.href = "/login"



      cookies.remove("role_data")
      cookies.remove("user_profile")
      cookies.remove("first_page")
      cookies.remove("token_aip_shop")

    }
  }


  render() {
    return (
      <div>
        <div className="breadcrumb-component row mx-0 px-0 position-fixed">
          <div className="col-12 d-flex row justify-content-between align-items-center">
            <div className="align-items-center justify-content-start d-flex row text-14 mx-0">
              {this.props.sidebar !== "false" && (
                <label
                  className="d-block d-lg-none d-xl-none mr-3 mt-2"
                  onClick={() => {
                    this.setState({ sidebar: !this.state.sidebar })
                  }}
                >
                  <span className="icon-brand text-dark">{"\uf0c9"}</span>
                </label>
              )}
              <img className="logo-menu mr-3 " alt="logo" src={LOGO}></img>
              <div className="d-none d-sm-none d-md-flex d-lg-flex d-xl-flex">
                <div className="border-right mr-3" style={{ height: 24 }}></div>
                <div className="text-secondary">
                  <span>{this.props.page1}</span>
                </div>
                {this.props.page2 && (
                  <span
                    className={
                      this.props.page3
                        ? "text-secondary ml-2 mr-2"
                        : "text-dark ml-2 mr-2"
                    }
                  >
                    {" > "}
                  </span>
                )}
                <span
                  className={
                    this.props.page3 ? "text-secondary" : "text-primary"
                  }
                >
                  {this.props.page2}
                </span>
                {this.props.page3 && (
                  <span className="text-dark ml-2 mr-2">{" > "}</span>
                )}
                <span className="text-primary">{this.props.page3}</span>
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-center">

              <div className="d-none d-xl-block">
                {role && (role.pos_system[0] === 1 &&
                  role.pos_system[1] === 1 &&
                  role.pos_system[2] === 1 &&
                  role.pos_system[3] === 1 &&
                  role.pos_system[4] === 1 &&
                  role.pos_system[5] === 1) && (
                    <button
                      className="btn btn-outline-primary px-3 mx-3"
                      style={{ height: 28, borderRadius: 14 }}
                      onClick={() => {
                        window.location.href = "/main"
                      }}
                    >
                      <span className="icon-brand text-primary text-14 mr-1">
                        {"\uf079"}
                      </span>
                      สลับไปยัง POS
                    </button>
                  )}
              </div>
              <span className="mr-3">{this.state.fullname}</span>


              <div className="dropdowns">
                <span className="dropbtns pointer">
                  <span className="icon-brand text-primary text-14">
                    {"\uf078"}
                  </span>
                </span>
                <div className="dropdowns-content">
                  <div className="d-xl-none">
                    {role && (role.pos_system[0] === 1 &&
                      role.pos_system[1] === 1 &&
                      role.pos_system[2] === 1 &&
                      role.pos_system[3] === 1 &&
                      role.pos_system[4] === 1 &&
                      role.pos_system[5] === 1) &&
                      (
                        <a onClick={() => {
                          window.location.href = "/main"
                        }}>
                          <label className="icon">{"\uf079"}</label> สลับไปยัง POS
                        </a>
                      )}
                  </div>
                  <a
                    onClick={() => {
                      LOGOUT()
                    }}
                  >
                    <label className="icon">{"\uf30d"}</label> ออกจากระบบ
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ProSidebar
          className="d-block d-lg-none d-xl-none full-screen"
          breakPoint="lg"
          toggled={this.state.sidebar}
          onFocus={() => {
            this.setState({ sidebar: this.state.sidebar })
          }}
          transitionduration={50}
        >
          <Menu
            iconShape="square"
            style={{ paddingTop: 60, paddingBottom: 60 }}
          >
            {/* ข้อมูลผู้ใช้ */}
            {role &&
              (role.user_admin[0] === 1 ||
                role.role[0] === 1) && (
                <SubMenu
                  icon={<label className="icon">{"\uf007"}</label>}
                  title="ข้อมูลผู้ใช้"
                  defaultOpen={
                    window.location.pathname === "/list-user" ||
                    window.location.pathname === "/role-user" ||
                    window.location.pathname === "/create-role-user"
                  }
                >
                  {role.user_admin[0] === 1 && (
                    <MenuItem
                      active={window.location.pathname === "/list-user"}
                    >
                      <Link to="/list-user" className="button">
                        รายชื่อผู้ใช้ทั้งหมด
                      </Link>
                    </MenuItem>
                  )}
                  {role.role[0] === 1 && (
                    <MenuItem
                      active={
                        window.location.pathname === "/role-user" ||
                        window.location.pathname === "/create-role-user" ||
                        window.location.pathname === "/edit-role-user"
                      }
                    >
                      <Link to="/role-user" className="button">
                        บทบาทผู้ใช้
                      </Link>
                    </MenuItem>
                  )}
                </SubMenu>
              )}
            {/* ข้อมูลสมาชิก */}
            {role &&
              role.user_member &&
              role.user_member[0] === 1 && (
                <MenuItem
                  icon={<label className="icon">{"\uf007"}</label>}
                  active={window.location.pathname === "/list-member"}
                >
                  <Link to="/list-member" className="button">
                    ข้อมูลสมาชิก
                  </Link>
                </MenuItem>
              )}
            {/* รายชื่อบริษัทจำหน่าย */}
            {role &&
              role.company_supplier &&
              role.company_supplier[0] === 1 && (
                <MenuItem
                  icon={<label className="icon">{"\uf03a"}</label>}
                  active={window.location.pathname === "/list-company"}
                >
                  <Link to="/list-company" className="button">
                    รายชื่อบริษัทจำหน่าย
                  </Link>
                </MenuItem>
              )}
            {/* ใบรับสินค้า */}
            {role &&
              role.goods_receipt &&
              role.goods_receipt[0] === 1 && (
                <MenuItem
                  icon={<label className="icon">{"\uf15c"}</label>}
                  active={window.location.pathname === "/receipt"}
                >
                  <Link to="/receipt" className="button">
                    ใบรับสินค้า
                  </Link>
                </MenuItem>
              )}
            {/* ปฏิทินงานซ่อม */}
            {role &&
              role.repair_calendar &&
              role.repair_calendar[0] === 1 && (
                <MenuItem
                  icon={<label className="icon">{"\uf133"}</label>}
                  active={window.location.pathname === "/calendar"}
                >
                  <Link to="/calendar" className="button">
                    ปฏิทินงานซ่อม
                  </Link>
                </MenuItem>
              )}
            {/* จัดการสินค้า */}
            {role &&
              (role.product_all[0] === 1 ||
                role.product_unit[0] === 1 ||
                role.type_spare_part[0] === 1 ||
                role.brand_all[0] === 1 ||
                role.btu[0] === 1 ||
                role.air_list[0] === 1) && (
                <SubMenu
                  icon={<label className="icon">{"\uf54e"}</label>}
                  title="จัดการสินค้า"
                  defaultOpen={
                    window.location.pathname === "/list-product" ||
                    window.location.pathname === "/list-product-unit" ||
                    window.location.pathname === "/parts-type" ||
                    window.location.pathname === "/list-brands" ||
                    window.location.pathname === "/list-btu" ||
                    window.location.pathname === "/list-air" ||
                    window.location.pathname === "/create-product" ||
                    window.location.pathname === "/edit-product"
                  }
                >
                  {role.product_all[0] === 1 && (
                    <MenuItem
                      active={window.location.pathname === "/list-product"}
                    >
                      <Link to="/list-product" className="button">
                        สินค้าทั้งหมด
                      </Link>
                    </MenuItem>
                  )}
                  {role.product_unit[0] === 1 && (
                    <MenuItem
                      active={window.location.pathname === "/list-product-unit"}
                    >
                      <Link to="/list-product-unit" className="button">
                        หน่วยนับสินค้า
                      </Link>
                    </MenuItem>
                  )}
                  {role.type_spare_part[0] === 1 && (
                    <MenuItem
                      active={window.location.pathname === "/parts-type"}
                    >
                      <Link to="/parts-type" className="button">
                        ประเภทอะไหล่
                      </Link>
                    </MenuItem>
                  )}
                  {role.brand_all[0] === 1 && (
                    <MenuItem
                      active={window.location.pathname === "/list-brands"}
                    >
                      <Link to="/list-brands" className="button">
                        ยี่ห้อทั้งหมด
                      </Link>
                    </MenuItem>
                  )}
                  {role.btu[0] === 1 && (
                    <MenuItem active={window.location.pathname === "/list-btu"}>
                      <Link to="/list-btu" className="button">
                        BTU
                      </Link>
                    </MenuItem>
                  )}
                  {role.air_list[0] === 1 && (
                    <MenuItem active={window.location.pathname === "/list-air"}>
                      <Link to="/list-air" className="button">
                        รายการแอร์
                      </Link>
                    </MenuItem>
                  )}
                </SubMenu>
              )}

            {/* จัดการคอมมิสชัน */}
            {user_profile && user_profile.user_type !== "emp_technician" && role &&
              (role.commission[0] === 1 ||
                role.type_commission[0] === 1) && (
                <SubMenu
                  icon={<label className="icon">{"\uf35d"}</label>}
                  title="จัดการคอมมิสชัน"
                  defaultOpen={
                    window.location.pathname === "/list-commission" ||
                    window.location.pathname === "/commission-type"
                  }
                >
                  {role.commission[0] === 1 && (
                    <MenuItem
                      active={window.location.pathname === "/list-commission"}
                    >
                      <Link to="/list-commission" className="button">
                        ค่าคอมมิสชัน
                      </Link>
                    </MenuItem>
                  )}
                  {role.type_commission[0] === 1 && (
                    <MenuItem
                      active={window.location.pathname === "/commission-type"}
                    >
                      <Link to="/commission-type" className="button">
                        ประเภทคอมมิสชัน
                      </Link>
                    </MenuItem>
                  )}
                </SubMenu>
              )}
            {/* ค่าคอมมิสชัน */}
            {user_profile && user_profile.user_type === "emp_technician" && role &&
              role.commission &&
              role.commission[0] === 1 && (
                <MenuItem
                  icon={<label className="icon">{"\uf15c"}</label>}
                  active={window.location.pathname === "/list-commission"}
                >
                  <Link to="/list-commission" className="button">
                    ค่าคอมมิสชัน
                  </Link>
                </MenuItem>
              )}
            {/* รายการขาย */}
            {role &&
              role.sale_list &&
              role.sale_list[0] === 1 && (
                <SubMenu
                  icon={<label className="icon">{"\uf15c"}</label>}
                  title="รายการขาย"
                  defaultOpen={
                    window.location.pathname === "/list-sale" ||
                    window.location.pathname === "/list-overdue" ||
                    window.location.pathname === "/history"
                  }
                >
                  {role.sale_list[0] === 1 && (
                    <MenuItem
                      active={window.location.pathname === "/list-sale"}
                    >
                      <Link to="/list-sale" className="button">
                        รายการธุรกรรม
                      </Link>
                    </MenuItem>
                  )}
                  {role.sale_list[0] === 1 && (
                    <MenuItem
                      active={window.location.pathname === "/list-overdue"}
                    >
                      <Link to="/list-overdue" className="button">
                        รายการขายค้างชำระ
                      </Link>
                    </MenuItem>
                  )}

                  {role.sale_list[0] === 1 && (
                    <MenuItem
                      active={window.location.pathname === "/history"}
                    >
                      <Link to="/history" className="button">
                        ประวัติการยกเลิก
                      </Link>
                    </MenuItem>
                  )}
                </SubMenu>
              )}
            {/* จัดการสต๊อก */}
            {role &&
              (role.stock_move_list[0] === 1 ||
                role.adjust_stock[0] === 1 ||
                role.transaction_stock[0] === 1 ||
                role.balance_stock[0] === 1 ||
                role.stock_all[0] === 1) && (
                <SubMenu
                  icon={<label className="icon">{"\uf49a"}</label>}
                  title="จัดการสต๊อก"
                  defaultOpen={
                    window.location.pathname === "/list-stock-move" ||
                    window.location.pathname === "/list-stock-adjustment" ||
                    window.location.pathname === "/list-stock-path" ||
                    window.location.pathname === "/list-stock" ||
                    window.location.pathname === "/create-stock-move" ||
                    window.location.pathname === "/create-stock-adjustment"
                  }
                >
                  {role.stock_move_list[0] === 1 && (
                    <MenuItem
                      active={window.location.pathname === "/list-stock-move"}
                    >
                      <Link to="/list-stock-move" className="button">
                        รายการย้ายสต๊อก
                      </Link>
                    </MenuItem>
                  )}
                  {role.adjust_stock[0] === 1 && (
                    <MenuItem
                      active={
                        window.location.pathname === "/list-stock-adjustment"
                      }
                    >
                      <Link to="/list-stock-adjustment" className="button">
                        รายการปรับสต๊อก
                      </Link>
                    </MenuItem>
                  )}
                  {role.transaction_stock[0] === 1 && (
                    <MenuItem
                      active={window.location.pathname === "/list-stock-path"}
                    >
                      <Link to="/list-stock-path" className="button">
                        เส้นทางสต๊อก
                      </Link>
                    </MenuItem>
                  )}
                  {role.stock_all[0] === 1 && (
                    <MenuItem
                      active={window.location.pathname === "/list-stock"}
                    >
                      <Link to="/list-stock" className="button">
                        สต๊อกสินค้า
                      </Link>
                    </MenuItem>
                  )}
                </SubMenu>
              )}
            {/* สรุปงานขาย */}
            {role &&
              role.report_sale &&
              role.report_sale[0] === 1 && (
                <MenuItem
                  icon={<label className="icon">{"\uf15c"}</label>}
                  active={window.location.pathname === "/summary"}
                >
                  <Link to="/summary" className="button">
                    สรุปงานขาย
                  </Link>
                </MenuItem>
              )}
            {/* โกดัง */}
            {role &&
              role.warehouse &&
              role.warehouse[0] === 1 && (
                <MenuItem
                  icon={<label className="icon">{"\uf3c5"}</label>}
                  active={window.location.pathname === "/list-warehouse"}
                >
                  <Link to="/list-warehouse" className="button">
                    โกดังสินค้า
                  </Link>
                </MenuItem>
              )}

            {/* ล้างข้อมูล */}
            {role &&
              role.clear &&
              role.clear[3] === 1 && (
                <MenuItem
                  icon={<label className="icon text-danger">{"\uf1f8"}</label>}
                  active={window.location.pathname === "/clear"}
                >
                  <Link to="/clear" className="text-danger">
                    ล้างข้อมูล
                  </Link>
                </MenuItem>
              )}
          </Menu>
        </ProSidebar>
      </div>
    )
  }
}
