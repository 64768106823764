/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-unreachable */
// JS
import "../../../global.js"

// COMPONENT
// import MenuComponent from "../SideMenu/SideMenu";
import BreadcrumbComponent from "../Breadcrumb/Breadcrumb"
import CORRECT from "./correct.gif"
import CREDIT1 from "./credit1.png"
import CREDIT2 from "./credit2.png"
import CREDIT3 from "./credit3.png"
import CREDIT4 from "./credit4.png"
import CREDIT5 from "./credit5.png"

// LIBRARY
import Swal from "sweetalert2"
import { toast } from "react-toastify"
import Cookies from "universal-cookie"
import React, { Component } from "react"
import { Modal } from "react-bootstrap"
import Select, { components } from "react-select"
import { Menu, MenuItem } from "@szhsin/react-menu"
import pdfMake from "pdfmake/build/pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts"
import "pdfmake/build/vfs_fonts"

// CSS
import "./style.css"
import "../../../bootstrap.css"
import "react-slideshow-image/dist/styles.css"
import "bootstrap-daterangepicker/daterangepicker.css"
import "@szhsin/react-menu/dist/index.css"
import "@szhsin/react-menu/dist/transitions/slide.css"

import {
  tokens,
  user_profile,
  alert,
  alert_url,
  GET,
  POST,
  PUT,
  DELETE,
  LOGOUT,
  validate,
  gen_Password,
  format_date,
  success_url,
  success,
  baht,
  ModalPrint,
  print_slip,
  date_overdue,
  ModalDetail,
  Modal_Print_All,
  ModalSaleHistory
} from "../../Custom/CustomComponent.js"

const cookies = new Cookies()
toast.configure()

pdfMake.vfs = pdfFonts.pdfMake.vfs
pdfMake.fonts = {
  FCIconic: {
    normal: "FCIconic-Regular.ttf",
    bold: "FCIconic-Bold.ttf",
    italics: "FCIconic-Italic.ttf",
    bolditalics: "FCIconic-Italic.ttf",
  },
  THSarabunNew: {
    normal: "THSarabunNew.ttf",
    bold: "THSarabunNew-Bold.ttf",
    italics: "THSarabunNew-Italic.ttf",
    bolditalics: "THSarabunNew-BoldItalic.ttf",
  },
  Roboto: {
    normal: "Roboto-Regular.ttf",
    bold: "Roboto-Medium.ttf",
    italics: "Roboto-Italic.ttf",
    bolditalics: "Roboto-MediumItalic.ttf",
  },
}
const array_month = [
  "ม.ค.",
  "ก.พ.",
  "มี.ค.",
  "เม.ย.",
  "พ.ค.",
  "มิ.ย.",
  "ก.ค.",
  "ส.ค.",
  "ก.ย.",
  "ต.ค.",
  "พ.ย.",
  "ธ.ค.",
]

const { Option } = components
const IconOption = (props) => (
  <Option {...props}>
    <img
      src={props.data.icon}
      style={{ width: 24, marginRight: 16 }}
      alt={props.data.label}
    />
    {props.data.label}
  </Option>
)
export default class ListAccruedListScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      token: cookies.get("token_aip_shop") ? "token " + cookies.get("token_aip_shop") : "",
      modal_payment: false,
      modal_create: false,
      modal_cancel: false,
      modal_receipt: false,
      modal_detail: false,
      modal_retry: false,
      modal_print: false,

      check_all: false,
      member_id: this.props.location.query.id,
      price: "00",
      card_type: "บัตรเครดิต",
      total_price_final: 0.0,
      calculate_type: "เงินสด",
      payment_type_layer_1: 1,
      status_order_main: 2,
      payment_detail: {
        credit_card_type: "",
        bank_name: "",
        card_number: "",
        slip_number: "",
        remark_payment_s2: "",
        remark_payment_s3: "",
        full_name_transfer: "",
        datetime_transfer: "",
        reference_number: "",
        remark_payment_s4: "",
        remark_payment_s5: "",
        total_pay: 0.0,
        installment: "",
      },
      page: 1,
      loading: false,
      data: null,
      data_detail: "",
      type: Number(this.props.location.query.type),
      order_main_id: "",

      index: 0,
      input: false,

      total_all: 0,
      count: 0,
      total_payment: 0,
      search: "",
      start_date: "",
      end_date: "",

      general: 0.0,
      payment_type: 0,
      modal_alert: false,

      message: { title: "", description: "", text: "", general: "", },


      order_main_number: "",
      order_item: null,

      modal_history: false,
      history: ""
    }
  }
  async componentDidMount() {
    await this.Get_All()
  }
  Get_All = async () => {
    this.setState({ loading: true, data: null })
    let result = await GET(tokens, "v1/pos-mgt/order/overdue/" + this.state.type + "/" + this.state.member_id, null);
    if (result && result.status) {
      let data = result.data;
      data.data = data.data.filter(item => item.status_order_main === 1);
      if (this.state.search.trim() !== "") {
        data.data = data.data.filter(item => item.order_main_number.includes(this.state.search));
      }
      if (this.state.start_date.trim() !== "" && this.state.end_date.trim() !== "") {
        let start = this.state.start_date
        let start_date = new Date(start.split("-")[0], start.split("-")[1], start.split("-")[2])
        let end = this.state.end_date
        let end_date = new Date(end.split("-")[0], end.split("-")[1], end.split("-")[2])

        data.data = data.data.filter(item => {
          let date = item.datetime_create.split(" ")[0];
          let item_date = new Date(date.split("-")[0], date.split("-")[1], date.split("-")[2]);
          return item_date >= start_date && item_date <= end_date;
        });
      }
      this.setState({ data: data })

      let total = 0
      for (let data of result.data.data) {
        if (data.status_order_main === 1) {
          total += data.total_balance
        }
      }
      this.setState({ total_all: total, loading: false })
    }
    this.setState({ loading: false })
  }
  Get_Detail = async () => {
    this.setState({ loading: true })
    let result = await GET(tokens, "v1/pos-mgt/order/detail/" + this.state.order_item.order_main_id, null);
    if (result && result.status) {

      let data = {
        detail: result.data.data,
        calculate_type: this.state.calculate_type,
        payment_detail: this.state.payment_detail,
        general: 0.00,
      }
      print_slip(data, "ใบส่งของ")
    }
    this.setState({ loading: false })
  }
  History = async (order_main_number) => {
    this.setState({ loading: true })
    let result = await POST(tokens, "/v1/transaction_payment/detail/" + order_main_number, null);
    if (result && result.status) {

      this.setState({ history: result.data.data })

      setTimeout(() => {
        this.setState({ modal_history: true, loading: false })
      }, 20)

    }
    this.setState({ loading: false })

  }
  Get_Modal_Detail = async (id) => {
    this.setState({ loading: true })
    let result = await GET(tokens, "v1/pos-mgt/order/detail/" + id, null);
    if (result && result.status) {

      this.setState({ data_detail: result.data.data })


      setTimeout(() => {
        this.setState({ modal_detail: true, loading: false })
      }, 20)
    }
    this.setState({ loading: false })

  }

  Payment = async (type) => {
    this.setState({ loading: true })
    let order = []
    for (let data of this.state.data.data) {
      if (data.selected === true) {
        order.push(data.order_main_id)
      }
    }

    if (type === "เงินเชื่อ") {
      if (Number(this.state.price) >= Number(this.state.total_payment)) {
        let general = Number(
          this.state.price - this.state.total_payment
        )
        this.setState({ general: general, text: "เงินทอน" })
      } else {
        this.setState({ loading: false })
        this.setState({ price: this.state.total_payment.toString(), general: 0.0 })
        this.alert("info", "แจ้งเตือน", "กรุณาชำระเงินเต็มจำนวน")
        return
      }

    } else if (type === "แบ่งงวด") {

      if (Number(this.state.price) >= Number(this.state.total_payment)) {
        let general = Number(
          this.state.price - this.state.total_payment
        )
        this.setState({ general: general, text: "เงินทอน" })
      } else {
        this.setState({ general: Number(this.state.price), text: "รับชำระ" })
      }

    }

    let body = {
      member_id: this.state.member_id,
      payment_type_layer_1: this.state.payment_type_layer_1,
      payment_detail: this.state.payment_detail,
      order_main_list: order,
    }

    let result = await PUT(tokens, "v1/pos-mgt/order/overdue/update", body);
    if (result && result.status) {
      this.setState({ tax_order: result.data.data, page: 1, modal_payment: false, loading: false })

      this.setState({
        modal_print: true,
        message: {
          title: "ชำระเงินแล้ว",
          description: "คุณสามารถออกบิลและชำระเงินย้อนหลังได้ที่รายการธุรกรรมหรือค้างจ่าย",
          text: this.state.text,
          general: this.state.general,

        },
      })



      this.clear_form()
      this.Get_All()

    }

    this.setState({ loading: false });

  }

  alert(icon, title, detail) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    })
  }
  alert_url(icon, title, detail, url) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    }).then(() => {
      window.location.href = url
    })
  }
  clear_form() {
    this.setState({
      loading: false,
      modal_detail: false,
      total_payment: 0,
      count: 0,
      search: "",
      start_date: "",
      end_date: "",
    })
  }
  date = (date) => {
    let datetime = ""
    try {
      datetime =
        date.getDate() +
        " " +
        array_month[date.getMonth()] +
        " " +
        (date.getFullYear() + 543) +
        ", " +
        date.getHours() +
        ":" +
        date.getMinutes() +
        " น."
    } catch (e) {
      if (date.split(" ")[1]) {
        datetime =
          date.split(" ")[0].split("-")[2] +
          " " +
          array_month[Number(date.split(" ")[0].split("-")[1]) - 1] +
          " " +
          (Number(date.split(" ")[0].split("-")[0]) + 543) +
          ", " +
          date.split(" ")[1].slice(0, 5) +
          " น."
      } else {
        datetime =
          date.split("T")[0].split("-")[2] +
          " " +
          array_month[Number(date.split(" ")[0].split("-")[1]) - 1] +
          " " +
          (Number(date.split(" ")[0].split("-")[0]) + 543) +
          ", " +
          date.split("T")[1].slice(0, 5) +
          " น."
      }
    }
    return datetime
  }
  status = (status) => {
    switch (status) {
      case 1:
        return "รอชำระ"
      case 2:
        return "ชำระเงินแล้ว"
      case 3:
        return "ยกเลิกสินค้า"
      case 4:
        return "พักออเดอร์"
      case 5:
        return "มัดจำ"

      default:
        return ""
    }
  }
  status_color = (status) => {
    switch (status) {
      case 1:
        return "status status-blue"
      case 2:
        return "status status-green"
      case 3:
        return "status status-black"
      case 4:
        return "status status-black"
      case 5:
        return "status status-yellow"
      default:
        return ""
    }
  }

  print_selected = (type) => {
    let data = this.state.data ? this.state.data.data.filter(item => item.selected) : [];
    let print = [];
    if (data.length > 0) {
      for (let d of data) {
        print = [...print, ...this.print_generate(d)]
      }

    } else {
      this.alert('info', 'แจ้งเตือน', 'กรุณาเลือกออร์เดอร์อย่างน้อย 1 รายการ')
    }
  }

  payment_clear = () => {
    let data = this.state.payment_detail
    data.credit_card_type = ""
    data.card_number = ""
    data.slip_number = ""
    data.remark_payment_s3 = ""
    data.full_name_transfer = ""
    data.datetime_transfer = ""
    data.reference_number = ""
    data.remark_payment_s4 = ""
    data.remark_payment_s5 = ""
    this.setState({
      payment_detail: data,
    })
  }

  retry = (id, type) => {
    window.location.href = "/main?retry_id=" + id
  }

  date_print = (date) => {
    let datetime = ""
    try {
      datetime =
        date.getDate() +
        " " +
        array_month[date.getMonth()] +
        " " +
        (date.getFullYear() + 543)
    } catch (e) {
      datetime =
        date.split("T")[0].split("-")[2] +
        " " +
        array_month[Number(date.split("T")[0].split("-")[1]) - 1] +
        " " +
        (Number(date.split("T")[0].split("-")[0]) + 543)
    }
    return datetime
  }

  payment_clear = () => {
    let data = this.state.payment_detail
    data.credit_card_type = ""
    data.bank_name = ""
    data.card_number = ""
    data.slip_number = ""
    data.remark_payment_s2 = ""
    data.remark_payment_s3 = ""
    data.full_name_transfer = ""
    data.datetime_transfer = ""
    data.reference_number = ""
    data.remark_payment_s4 = ""
    data.remark_payment_s5 = ""
    this.setState({
      payment_detail: data,
    })
  }

  calculate = (number) => {
    const input = document.getElementById('price');
    input.focus();
    if (number === "delete") {
      const currentPosition = input.selectionStart;
      const textBeforeCursor = input.value.slice(0, currentPosition);
      const textAfterCursor = input.value.slice(currentPosition);
      input.value = textBeforeCursor.slice(0, -1) + textAfterCursor;
      input.selectionStart = currentPosition - 1;
      input.selectionEnd = currentPosition - 1;
      if (/^\d*\.?\d{0,2}$/.test(input.value) && Number(input.value) < 999999999) {
        this.setState({ price: input.value });
      } else {
        this.setState({ price: this.state.price });
      }
    } else if (number === 100) {

      if (Number(this.state.price) + 100 < 999999999) {

        this.setState({ price: Number(this.state.price) + 100 });
      }
    } else if (number === 500) {
      if (Number(this.state.price) + 500 < 999999999) {
        this.setState({ price: Number(this.state.price) + 500 });
      }
    } else if (number === 1000) {
      if (Number(this.state.price) + 1000 < 999999999) {
        this.setState({ price: Number(this.state.price) + 1000 });
      }
    } else if (number === "full") {
      this.setState({
        price: this.state.total_payment.toString(),
      });
    } else {
      input.setRangeText(number, input.selectionStart, input.selectionEnd, 'end');
      if (/^\d*\.?\d{0,2}$/.test(input.value) && Number(input.value) < 999999999) {
        this.setState({ price: input.value });
      } else {
        this.setState({ price: this.state.price });
      }
    }
  }

  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="w-100">
          <BreadcrumbComponent />
          <div style={{ height: 60 }}></div>
          <div className="row mt-3 mx-0" style={{ paddingBottom: 90 }}>
            <div className="col-12 mb-3">
              <div className="card p-3">
                <div className="w-100 d-flex justify-content-between align-items-center">
                  <h2>
                    <b>รายการค้างจ่าย</b>
                  </h2>
                  <div>
                    <button
                      type="button"
                      className="btn btn-outline-primary w-100 px-4"
                      onClick={async () => {
                        window.location.href = "/accrued"
                      }}
                    >
                      <span className="icon-brand mr-2">{"\uf060"}</span>
                      ย้อนกลับ
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mb-3">
              <div className="row">
                <div className="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-4 mb-3">
                  <div className="w-100 d-flex align-items-center">
                    <div
                      className="card rounded-8 border-primary w-100"
                      style={{ padding: 2 }}
                    >
                      <div className="d-flex w-100">
                        <div className="w-50 pr-1">
                          <button
                            className={
                              this.state.type === 2
                                ? "btn btn-primary w-100"
                                : "btn btn-link w-100"
                            }
                            onClick={() => {
                              this.setState({
                                type: 2,
                                page: 1,
                                index: 0,
                                check_all: false,
                                count: 0,
                                total_payment: 0,
                              })
                              setTimeout(() => {
                                this.Get_All()
                              }, 20)
                            }}
                          >
                            เงินเชื่อ
                          </button>
                        </div>
                        <div className="w-50 pl-1">
                          <button
                            className={
                              this.state.type === 5
                                ? "btn btn-primary w-100"
                                : "btn btn-link w-100"
                            }
                            onClick={() => {
                              this.setState({
                                type: 5,
                                page: 1,
                                index: 0,
                                check_all: false,
                                count: 0,
                                total_payment: 0,
                              })
                              setTimeout(() => {
                                this.Get_All()
                              }, 20)
                            }}
                          >
                            แบ่งเป็นงวด
                          </button>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card p-3">
                <div className="row d-flex mb-3">
                  <div className="d-flex mx-3 mb-3">
                    <input
                      className="form-control bg-secondary-light wpx-200"
                      type="search"
                      placeholder="ค้นหาด้วยรหัสใบรับสินค้า"
                      value={this.state.search}
                      onChange={(e) => {
                        this.setState({ search: e.target.value })
                        if (e.target.value === "") {
                          this.setState({ page: 1, search: "" })
                          setTimeout(() => {
                            this.Get_All()
                          }, 20)
                        }
                      }}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          this.setState({
                            page: 1,
                          })
                          setTimeout(() => {
                            this.Get_All()
                          }, 20)
                        }
                      }}
                    />
                  </div>

                  <div className="d-flex mx-3">
                    <input
                      type="date"
                      className="form-control bg-secondary-light"
                      value={this.state.start_date}
                      onChange={(e) => {
                        this.setState({ start_date: e.target.value })
                        this.setState({
                          page: 1,
                        })
                        setTimeout(() => {
                          this.Get_All()
                        }, 20)
                      }}
                    ></input>
                    <label class="mx-3 mt-2">ถึง</label>
                    <input
                      type="date"
                      className="form-control bg-secondary-light"
                      value={this.state.end_date}
                      onChange={(e) => {
                        this.setState({ end_date: e.target.value })
                        this.setState({
                          page: 1,
                        })
                        setTimeout(() => {
                          this.Get_All()
                        }, 20)
                      }}
                    ></input>
                  </div>
                </div>

                <div className="w-100">
                  <div className="table-responsive">
                    {/* เงินเชื่อ */}
                    {this.state.type === 2 && (
                      <table className="table design-table table-striped design-table-sell">
                        <thead>
                          <tr>
                            <th className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  let data_array = this.state.data
                                    ? this.state.data
                                    : []
                                  let count = 0
                                  let total = 0
                                  for (let data of data_array.data) {
                                    if (data.status_order_main === 1) {
                                      data.selected = e.target.checked
                                      if (e.target.checked) {
                                        count += 1
                                        total += data.total_balance
                                      }
                                    }
                                  }
                                  this.setState({
                                    data: data_array,
                                    check_all: e.target.checked,
                                    count: count,
                                    total_payment: total,
                                  })
                                }}
                                checked={this.state.check_all}
                              />
                            </th>
                            <th className="text-left">เลขที่รายการ</th>
                            <th className="text-left" style={{ minWidth: 150 }}>วันที่</th>
                            <th className="text-left">รหัสลูกค้า</th>
                            <th className="text-left" style={{ minWidth: 120 }}>ชื่อลูกค้า</th>
                            <th className="text-left" style={{ minWidth: 130 }}>กำหนดจ่าย</th>
                            <th className="text-right" style={{ minWidth: 120 }}>ยอดค้างชำระ</th>
                            <th className="text-right ">ยอดรวม</th>
                            <th className="text-center" style={{ minWidth: 100 }}>สถานะ</th>
                            <th className="text-center">จัดการ</th>
                          </tr>
                        </thead>
                        <tbody>
                          {((this.state.data &&
                            this.state.data.data.length === 0) ||
                            !this.state.data) && (
                              <tr>
                                <td colSpan={10} className="text-center">
                                  -- ไม่มีข้อมูล --
                                </td>
                              </tr>
                            )}
                          {this.state.data &&
                            this.state.data.data.map((item, index) => (
                              <tr key={index}>
                                <td className="text-center">
                                  {item.status_order_main === 1 && (
                                    <input
                                      type="checkbox"
                                      onChange={(e) => {
                                        let data_array = this.state.data
                                          ? this.state.data
                                          : []
                                        if (data_array.data[index].selected) {
                                          data_array.data[index].selected =
                                            !item.selected
                                        } else {
                                          data_array.data[index].selected = true
                                        }
                                        this.setState({ data: data_array })
                                        let selected = true
                                        let count = 0
                                        let total = 0
                                        for (let data of this.state.data.data) {
                                          if (data.status_order_main === 1) {
                                            if (!data.selected) {
                                              selected = false
                                            } else {
                                              count += 1
                                              total += data.total_balance
                                            }
                                          }
                                        }
                                        this.setState({
                                          check_all: selected,
                                          count: count,
                                          total_payment: total,
                                        })
                                      }}
                                      checked={item.selected}
                                    />
                                  )}
                                </td>
                                <td className="text-left">{item.order_main_number}</td>
                                <td className="text-left">{this.date(item.datetime_create)}</td>
                                <td className="text-left">{item.member_data.user_code}</td>
                                <td className="text-left">{item.member_data.user_type === "corporation" ? item.member_company : item.member_name}</td>
                                <td className="text-left">{item.payment_due_date ? date_overdue(item.payment_due_date, item.payment_countdown) : ""}</td>
                                <td className="text-right">{baht(item.total_balance)}</td>
                                <td className="text-right">{baht(item.total_price_final)}</td>
                                <td className="text-center"><span className={this.status_color(item.status_order_main)}>{this.status(item.status_order_main)}</span></td>
                                <td className="text-center">
                                  <Menu
                                    menuButton={
                                      <button className="btn btn-link text-secondary ">
                                        <h2 className="icon h2">{"\uf141"}</h2>
                                      </button>
                                    }
                                    transition
                                  >
                                    <MenuItem
                                      onClick={async () => {

                                        this.setState({
                                          order_item: item,
                                          calculate_type: "",
                                        })


                                        setTimeout(async () => {
                                          await this.Get_Detail()
                                        }, 10);
                                      }}
                                    >
                                      <label className="icon mr-2">
                                        {"\uf15c"}
                                      </label>
                                      ใบส่งของ/แจ้งหนี้
                                    </MenuItem>
                                    <MenuItem
                                      onClick={async () => {

                                        await this.Get_Modal_Detail(
                                          item.order_main_id
                                        )

                                      }}
                                    >
                                      <label className="icon mr-2">
                                        {"\uf15c"}
                                      </label>
                                      รายละเอียด
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        this.retry(
                                          item.order_main_id,
                                          item.category_name
                                        )
                                      }}
                                    >
                                      <label className="icon mr-2">
                                        {"\uf01e"}
                                      </label>
                                      สั่งอีกครั้ง
                                    </MenuItem>
                                  </Menu>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    )}


                    {/* แบ่งเป็นงวด */}
                    {this.state.type === 5 && (
                      <table className="table design-table table-striped design-table-sell">
                        <thead>
                          <tr>
                            <th className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  let data_array = this.state.data
                                    ? this.state.data
                                    : []
                                  let count = 0
                                  let total = 0
                                  for (let data of data_array.data) {
                                    if (data.status_order_main === 1) {
                                      data.selected = e.target.checked
                                      if (e.target.checked) {
                                        count += 1
                                        total += data.total_balance
                                      }
                                    }
                                  }
                                  this.setState({
                                    data: data_array,
                                    check_all: e.target.checked,
                                    count: count,
                                    total_payment: total,
                                  })
                                }}
                                checked={this.state.check_all}
                              />
                            </th>
                            <th className="text-left" style={{ minWidth: 100 }}>เลขที่รายการ</th>
                            <th className="text-left" style={{ minWidth: 150 }}>วันที่</th>
                            <th className="text-left" >รหัสลูกค้า</th>
                            <th className="text-left" style={{ minWidth: 130 }}>ชื่อลูกค้า</th>
                            <th className="text-right" style={{ minWidth: 100 }}>ยอดค้างชำระ</th>
                            <th className="text-right" style={{ minWidth: 100 }}>ยอดรวม</th>
                            <th className="text-center" style={{ minWidth: 150 }}>สถานะ</th>
                            <th className="text-center">จัดการ</th>
                          </tr>
                        </thead>
                        <tbody>
                          {((this.state.data &&
                            this.state.data.data.length === 0) ||
                            !this.state.data) && (
                              <tr>
                                <td colSpan={9} className="text-center">
                                  -- ไม่มีข้อมูล --
                                </td>
                              </tr>
                            )}
                          {this.state.data &&
                            this.state.data.data.map((item, index) => (
                              <tr key={index}>
                                <td className="text-center">
                                  {item.status_order_main === 1 && (
                                    <input
                                      type="checkbox"
                                      onChange={(e) => {
                                        let data_array = this.state.data
                                          ? this.state.data
                                          : []
                                        if (data_array.data[index].selected) {
                                          data_array.data[index].selected =
                                            !item.selected
                                        } else {
                                          data_array.data[index].selected = true
                                        }
                                        this.setState({ data: data_array })
                                        let selected = true
                                        let count = 0
                                        let total = 0
                                        for (let data of this.state.data.data) {
                                          if (data.status_order_main === 1) {
                                            if (!data.selected) {
                                              selected = false
                                            } else {
                                              count += 1
                                              total += data.total_balance
                                            }
                                          }
                                        }
                                        this.setState({
                                          check_all: selected,
                                          count: count,
                                          total_payment: total,
                                        })
                                      }}
                                      checked={item.selected}
                                    />
                                  )}
                                </td>
                                <td className="text-left">{item.order_main_number}</td>
                                <td className="text-left" >{this.date(item.datetime_create)}</td>
                                <td className="text-left">{item.member_data.user_code}</td>
                                <td className="text-left">{item.member_data.user_type === "corporation" ? item.member_company : item.member_name}</td>
                                <td className="text-right">{baht(item.total_balance)}</td>
                                <td className="text-right">{baht(item.total_price_final)}</td>
                                <td className="text-center">
                                  <span
                                    className={this.status_color(
                                      item.status_order_main
                                    )}
                                  >
                                    {this.status(item.status_order_main)}
                                  </span>
                                </td>
                                <td className="text-center">
                                  <Menu
                                    menuButton={
                                      <button className="btn btn-link text-secondary ">
                                        <h2 className="icon h2">{"\uf141"}</h2>
                                      </button>
                                    }
                                    transition
                                  >
                                    <MenuItem
                                      onClick={async () => {

                                        this.setState({
                                          order_item: item,
                                          calculate_type: "",
                                        })


                                        setTimeout(async () => {
                                          await this.Get_Detail()
                                        }, 10);
                                      }}
                                    >
                                      <label className="icon mr-2">
                                        {"\uf15c"}
                                      </label>
                                      ใบส่งของ/แจ้งหนี้
                                    </MenuItem>
                                    <MenuItem
                                      onClick={async () => {

                                        await this.Get_Modal_Detail(
                                          item.order_main_id
                                        )

                                      }}
                                    >
                                      <label className="icon mr-2">
                                        {"\uf15c"}
                                      </label>
                                      รายละเอียด
                                    </MenuItem>

                                    <MenuItem
                                      onClick={async () => {

                                        this.History(item.order_main_number)

                                      }}
                                    >
                                      <label className="icon mr-2">
                                        {"\uf1da"}
                                      </label>
                                      ประวัติการผ่อนชำระ
                                    </MenuItem>

                                    <MenuItem
                                      onClick={() => {
                                        this.retry(
                                          item.order_main_id,
                                          item.category_name
                                        )
                                      }}
                                    >
                                      <label className="icon mr-2">
                                        {"\uf01e"}
                                      </label>
                                      สั่งอีกครั้ง
                                    </MenuItem>

                                  </Menu>


                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    )}

                  </div>
                </div>

              </div>
            </div>
          </div>

          <div className="w-100 bg-white position-fixed bottom-0 pt-3 px-3">

            <div className="w-100 row d-flex justify-content-between align-items-center pl-3">
              <label className="mb-3">
                รวมยอดค้างทั้งหมด:
                <label className="px-3"><b>{baht(this.state.total_all)}</b></label>
                บาท
              </label>
              <div className="d-flex align-items-center mb-3">
                <label >
                  รวมที่เลือก {this.state.count} รายการ:
                </label>
                <h3 className="text-primary px-3">
                  <b>
                    ฿
                    {baht(this.state.total_payment)}
                  </b>
                </h3>
                <button
                  type="button"
                  className="btn btn-primary px-3 ml-3"
                  onClick={() => {
                    if (this.state.count === 0) {

                      this.alert("info", "แจ้งเตือน", "ไม่มีรายการที่เลือก")

                    } else {
                      this.payment_clear()

                      this.setState({
                        calculate_type: "เงินสด",
                        payment_type_layer_1: 1,
                        price: this.state.total_payment.toString(),
                        modal_payment: true,
                      })

                    }


                  }}
                >
                  ยืนยันการชำระ
                </button>
              </div>
            </div>

          </div>
        </div>


        {/* ชำระเงิน modal */}
        <Modal
          show={this.state.modal_payment}
          onHide={() => {
            this.setState({ modal_payment: false })
          }}
        >
          <Modal.Body>
            {/* เมนู */}
            <div className="center rounded rounded-8 bg-blue-sky hpx-54 mb-0 pl-3 py-2 pr-1 mb-3 ">
              <label><b>
                ยอดที่ต้องชำระ: {baht(this.state.total_payment)} บาท</b></label>
            </div>

            <div className="w-100 d-flex">
              {/* เงินสด status 1*/}
              <div className="w-20 px-1">
                <button
                  className={
                    this.state.calculate_type === "เงินสด"
                      ? "btn btn-calculate bg-secondary-light w-100 text-center p-0 position-relative"
                      : "btn btn-calculate bg-white w-100 text-center p-0 position-relative"
                  }
                  onClick={() => {
                    this.setState({
                      calculate_type: "เงินสด",
                      payment_type_layer_1: 1,
                      status_order_main: 2,
                      price: this.state.total_payment.toString(),
                    })
                    this.payment_clear()
                  }}
                >
                  <label className="icon mb-0 pointer">{"\uf0d6"}</label>
                  <br /> เงินสด
                  {this.state.calculate_type === "เงินสด" && (
                    <div
                      style={{
                        width: "50%",
                        height: 2,
                        backgroundColor: "#232F85",
                        position: "absolute",
                        bottom: 0,
                        left: "25%",
                      }}
                    ></div>
                  )}
                </button>
              </div>
              {/* เงินเชื่อ status 2*/}
              <div className="w-20 px-1">
                <button
                  className={
                    this.state.calculate_type === "เงินเชื่อ"
                      ? "btn btn-calculate bg-secondary-light w-100 text-center p-0 position-relative"
                      : "btn btn-calculate bg-white w-100 text-center p-0 position-relative"
                  }
                  onClick={() => {
                    this.setState({
                      calculate_type: "เงินเชื่อ",
                      payment_type_layer_1: 2,
                      status_order_main: 1,
                      price: this.state.total_payment.toString(),
                    })
                    this.payment_clear()
                  }}
                  disabled={
                    this.state.type !== 0 ||
                      (this.state.member_data && this.state.member_data.user_code === "ME000000001") ||
                      (this.state.member_data && this.state.member_data.credit_day === 0) ||
                      (this.state.type === 0 &&
                        Number(this.state.status_order_main_for_check) === 1 && Number(this.state.deposit) !== 0)
                      ? true : false}
                >
                  <label className="icon mb-0 pointer">{"\uf783"}</label>
                  <br />
                  เงินเชื่อ
                  {this.state.calculate_type === "เงินเชื่อ" && (
                    <div
                      style={{
                        width: "50%",
                        height: 2,
                        backgroundColor: "#232F85",
                        position: "absolute",
                        bottom: 0,
                        left: "25%",
                      }}
                    ></div>
                  )}
                </button>
              </div>

              {/* บัตรเครดิต status 3*/}
              <div className="w-20 px-1">
                <button
                  className={
                    this.state.calculate_type === "บัตรเครดิต"
                      ? "btn btn-calculate bg-secondary-light w-100 text-center p-0 position-relative"
                      : "btn btn-calculate bg-white w-100 text-center p-0 position-relative"
                  }
                  onClick={() => {
                    this.setState({
                      calculate_type: "บัตรเครดิต",
                      payment_type_layer_1: 3,
                      status_order_main: 2,
                      price: this.state.total_payment.toString(),
                    })
                    this.payment_clear()
                  }}
                >
                  <label className="icon mb-0 pointer">{"\uf389"}</label>
                  <br />
                  บัตรเครดิต
                  {this.state.calculate_type === "บัตรเครดิต" && (
                    <div
                      style={{
                        width: "50%",
                        height: 2,
                        backgroundColor: "#232F85",
                        position: "absolute",
                        bottom: 0,
                        left: "25%",
                      }}
                    ></div>
                  )}
                </button>
              </div>

              {/* เงินโอน status 4*/}
              <div className="w-20 px-1">
                <button
                  className={
                    this.state.calculate_type === "เงินโอน"
                      ? "btn btn-calculate bg-secondary-light w-100 text-center p-0 position-relative"
                      : "btn btn-calculate bg-white w-100 text-center p-0 position-relative"
                  }
                  onClick={() => {
                    this.setState({
                      calculate_type: "เงินโอน",
                      payment_type_layer_1: 4,
                      status_order_main: 2,
                      price: this.state.total_payment.toString(),
                    })
                    this.payment_clear()
                  }}
                >
                  <label className="icon mb-0 pointer">{"\uf362"}</label>
                  <br />
                  เงินโอน
                  {this.state.calculate_type === "เงินโอน" && (
                    <div
                      style={{
                        width: "50%",
                        height: 2,
                        backgroundColor: "#232F85",
                        position: "absolute",
                        bottom: 0,
                        left: "25%",
                      }}
                    ></div>
                  )}
                </button>
              </div>

              {/* เป็นงวด status 5*/}
              <div className="w-20 px-1">
                <button
                  className={
                    this.state.calculate_type === "เป็นงวด"
                      ? "btn btn-calculate bg-secondary-light w-100 text-center p-0 position-relative"
                      : "btn btn-calculate bg-white w-100 text-center p-0 position-relative"
                  }
                  onClick={() => {
                    this.setState({
                      calculate_type: "เป็นงวด",
                      payment_type_layer_1: 5,
                      status_order_main: 1,
                      price: this.state.total_payment.toString(),
                    })
                    this.payment_clear()
                  }}
                  disabled={this.state.type !== 0 ||
                    (this.state.member_data && this.state.member_data.user_code === "ME000000001") ||
                    (this.state.member_data && this.state.member_data.credit_day === 0) ||
                    (this.state.type === 0 &&
                      Number(this.state.status_order_main_for_check) === 1 && Number(this.state.deposit) !== 0)
                    ? true : false}
                >
                  <label className="icon mb-0 pointer">{"\uf126"}</label>
                  <br />
                  เป็นงวด
                  {this.state.calculate_type === "เป็นงวด" && (
                    <div
                      style={{
                        width: "50%",
                        height: 2,
                        backgroundColor: "#232F85",
                        position: "absolute",
                        bottom: 0,
                        left: "25%",
                      }}
                    ></div>
                  )}
                </button>
              </div>

            </div>

            {/* เงินสด */}
            {this.state.calculate_type === "เงินสด" && (
              <div className="w-100">
                <div className="d-flex py-2 ">
                  {/* ปุ่มลบ */}
                  <div className="btn btn-link text-secondary mt-3" onClick={() => { this.setState({ price: "", }) }}>
                    <label className="icon bg-dark text-white" style={{ width: 24, height: 24, borderRadius: 12 }}>{"\uf00d"}</label>
                  </div>
                  {/* ช่องกรอก */}
                  <input
                    id="price"
                    className="text-right w-100 border-0 text-32"
                    style={{ fontWeight: "bold" }}
                    type="text"
                    placeholder="0.00"
                    maxLength={14}
                    onChange={(e) => {
                      if (/^\d*\.?\d{0,2}$/.test(e.target.value) && Number(e.target.value) < 999999999) {
                        this.setState({ price: e.target.value });
                      }
                    }}
                    value={this.state.price}
                  />
                </div>
                <div className="row mx-0">
                  <div className="col-3 cal" onClick={() => { this.calculate(7) }}><div><h5>7</h5></div></div>
                  <div className="col-3 cal" onClick={() => { this.calculate(8) }}><div><h5>8</h5></div></div>
                  <div className="col-3 cal" onClick={() => { this.calculate(9) }}><div><h5>9</h5></div></div>
                  <div className="col-3 cal" onClick={() => { this.calculate(1000) }}><div className="primary"><h5>1,000</h5></div></div>
                  <div className="col-3 cal" onClick={() => { this.calculate(4) }}><div><h5>4</h5></div></div>
                  <div className="col-3 cal" onClick={() => { this.calculate(5) }}><div><h5>5</h5></div></div>
                  <div className="col-3 cal" onClick={() => { this.calculate(6) }}><div><h5>6</h5></div></div>
                  <div className="col-3 cal" onClick={() => { this.calculate(500) }}><div className="primary"><h5>500</h5></div></div>
                  <div className="col-3 cal" onClick={() => { this.calculate(1) }}><div><h5>1</h5></div></div>
                  <div className="col-3 cal" onClick={() => { this.calculate(2) }}><div><h5>2</h5></div></div>
                  <div className="col-3 cal" onClick={() => { this.calculate(3) }}><div><h5>3</h5></div></div>
                  <div className="col-3 cal" onClick={() => { this.calculate(100) }}><div className="primary"><h5>100</h5></div></div>
                  <div className="col-3 cal" onClick={() => { this.calculate(".") }}><div><h5>.</h5></div></div>
                  <div className="col-3 cal" onClick={() => { this.calculate(0) }}><div><h5>0</h5></div></div>
                  <div className="col-3 cal" onClick={() => { this.calculate("delete") }}><div><h5 className="icon">{"\uf55a"}</h5></div></div>
                  <div className="col-3 cal" onClick={() => { this.calculate("full") }}><div className="primary"><h5>เต็ม</h5></div></div>
                </div>
              </div>

            )}

            {/* เงินเชื่อ */}
            {this.state.calculate_type === "เงินเชื่อ" && (
              <div className="w-100">
                <div className="w-100 d-flex justify-content-end align-items-center py-4 text-32">

                  <b>
                    {baht(this.state.total_price_final)}
                  </b>

                </div>
                <div className="card bg-secondary-light p-3">
                  <div className="d-flex justify-content-between">
                    <label className="w-25">เลขสมาชิก: </label>
                    <label className="w-75 text-right">
                      {this.state.member_data &&
                        this.state.member_data.user_code
                      }
                    </label>
                  </div>
                  <div className="d-flex justify-content-between">
                    <label className="w-25">ชื่อ-นามสกุล: </label>
                    <label className="w-75 text-right">
                      {this.state.member_data &&
                        this.state.member_data.full_name
                      }
                    </label>
                  </div>
                  <div className="d-flex justify-content-between">
                    <b className="w-25">เครดิต: </b>
                    <b className="w-75 text-right">
                      {this.state.member_data &&
                        this.state.member_data.credit_day}{" "}
                      วัน
                    </b>
                  </div>
                </div>
                <div className="w-100 mt-4 mb-3">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="หมายเหตุ"
                    onChange={(e) => {
                      let data = this.state.payment_detail
                      data.remark_payment_s2 = e.target.value
                      this.setState({
                        payment_detail: data,
                      })
                    }}
                    value={this.state.payment_detail.remark_payment_s2}
                  />
                </div>
              </div>
            )}
            {/* บัตรเครดิต */}
            {this.state.calculate_type === "บัตรเครดิต" && (
              <div className="w-100">
                <div className="w-100 d-flex justify-content-end align-items-center py-4 text-32">

                  <b>
                    {baht(this.state.price)}
                  </b>

                </div>

                <div className="w-100 mb-3">
                  <div
                    className="card rounded-8 border-primary "
                    style={{ padding: 2 }}
                  >
                    <div className="d-flex w-100">
                      <div className="w-50 pr-1">
                        <button
                          className={
                            this.state.card_type === "บัตรเครดิต"
                              ? "btn btn-primary w-100"
                              : "btn btn-link w-100"
                          }
                          onClick={() => {
                            this.setState({
                              card_type: "บัตรเครดิต",
                              bank_name: "",
                            })
                            let payment_detail = this.state.payment_detail
                            payment_detail.bank_name = ""
                            this.setState({ payment_detail: payment_detail })

                          }}
                        >
                          บัตรเครดิต
                        </button>
                      </div>
                      <div className="w-50 pl-1">
                        <button
                          className={
                            this.state.card_type === "บัตรเดบิต"
                              ? "btn btn-primary w-100"
                              : "btn btn-link w-100"
                          }
                          onClick={() => {
                            this.setState({
                              card_type: "บัตรเดบิต",
                              credit_card_type: "",
                            })
                            let payment_detail = this.state.payment_detail
                            payment_detail.credit_card_type = ""

                          }}
                        >
                          บัตรเดบิต
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  {this.state.card_type === "บัตรเครดิต" && (
                    <div className="col-12 mb-3">
                      <Select
                        className="form-control px-0"
                        options={[
                          {
                            value: "มาสเตอร์การ์ด (Master Card)",
                            label: "มาสเตอร์การ์ด (Master Card)",
                            icon: CREDIT1,
                          },
                          {
                            value: "วีซ่า (Visa)",
                            label: "วีซ่า (Visa)",
                            icon: CREDIT2,
                          },
                          {
                            value: "JCB",
                            label: "JCB",
                            icon: CREDIT3,
                          },
                          {
                            value: "American Express",
                            label: "American Express",
                            icon: CREDIT4,
                          },
                          {
                            value: "Union Pay",
                            label: "Union Pay",
                            icon: CREDIT5,
                          },
                        ]}
                        components={{ Option: IconOption }}
                        placeholder="-- เลือกประเภทบัตร --"
                        getOptionLabel={(e) => (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src={e.icon}
                              style={{ width: 24, marginRight: 16 }}
                              alt={e.label}
                            />
                            <span style={{ marginLeft: 5 }}>{e.label}</span>
                          </div>
                        )}
                        defaultValue={
                          this.state.payment_detail.credit_card_type
                        }
                        defaultInputValue={
                          this.state.payment_detail.credit_card_type
                        }
                        onChange={(e) => {
                          let data = this.state.payment_detail

                          data.credit_card_type = e.value
                          this.setState({
                            payment_detail: data,
                          })
                        }}
                      />
                    </div>
                  )}
                  {this.state.card_type === "บัตรเดบิต" && (
                    <div className="col-12 mb-3">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="กรอกชื่อธนาคาร"
                        onChange={(e) => {
                          let data = this.state.payment_detail
                          data.bank_name = e.target.value
                          this.setState({
                            payment_detail: data,
                          })
                        }}
                        value={this.state.payment_detail.bank_name}
                      />
                    </div>
                  )}
                  <div className="col-12 mb-3">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="หมายเลขบัตร 4 ตัวท้าย"
                      onChange={(e) => {
                        let data = this.state.payment_detail
                        data.card_number = e.target.value.replace(/\D/g, "")
                        this.setState({
                          payment_detail: data,
                        })
                      }}
                      maxLength={4}
                      value={this.state.payment_detail.card_number}
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="เลขที่สลิป/ใบเสร็จ"
                      onChange={(e) => {
                        let data = this.state.payment_detail
                        data.slip_number = e.target.value.replace(/\D/g, "")
                        this.setState({
                          payment_detail: data,
                        })
                      }}
                      value={this.state.payment_detail.slip_number}
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="อธิบายการผ่อนชำระ"
                      onChange={(e) => {
                        let data = this.state.payment_detail
                        data.remark_payment_s3 = e.target.value
                        this.setState({
                          payment_detail: data,
                        })
                      }}
                      value={this.state.payment_detail.remark_payment_s3}
                    />
                  </div>
                </div>
              </div>
            )}
            {/* เงินโอน */}
            {this.state.calculate_type === "เงินโอน" && (
              <div className="w-100">
                <div className="w-100">
                  <div className="d-flex py-2 ">
                    {/* ปุ่มลบ */}
                    <div className="btn btn-link text-secondary mt-3" onClick={() => { this.setState({ price: "", }) }}>
                      <label className="icon bg-dark text-white" style={{ width: 24, height: 24, borderRadius: 12 }}>{"\uf00d"}</label>
                    </div>
                    {/* ช่องกรอก */}
                    <input
                      id="price"
                      className="text-right w-100 border-0 text-32"
                      style={{ fontWeight: "bold" }}
                      type="text"
                      placeholder="0.00"
                      maxLength={14}
                      onChange={(e) => {
                        if (/^\d*\.?\d{0,2}$/.test(e.target.value) && Number(e.target.value) < 999999999) {
                          this.setState({ price: e.target.value });
                        }
                      }}
                      value={this.state.price}
                    />
                  </div>

                  <div className="w-100 text-center mb-3">
                    <label
                      className="text-primary pointer"
                      onClick={() => {
                        this.setState({ input: !this.state.input })
                      }}
                    >
                      กรอกตัวเลขด้วยตัวเอง
                      <span className="icon-brand ml-2">
                        {this.state.input ? "\uf077" : "\uf078"}
                      </span>
                    </label>
                  </div>
                  {this.state.input && (
                    <div className="row mx-0">
                      <div className="col-3 cal" onClick={() => { this.calculate(7) }}><div><h5>7</h5></div></div>
                      <div className="col-3 cal" onClick={() => { this.calculate(8) }}><div><h5>8</h5></div></div>
                      <div className="col-3 cal" onClick={() => { this.calculate(9) }}><div><h5>9</h5></div></div>
                      <div className="col-3 cal" onClick={() => { this.calculate(1000) }}><div className="primary"><h5>1,000</h5></div></div>
                      <div className="col-3 cal" onClick={() => { this.calculate(4) }}><div><h5>4</h5></div></div>
                      <div className="col-3 cal" onClick={() => { this.calculate(5) }}><div><h5>5</h5></div></div>
                      <div className="col-3 cal" onClick={() => { this.calculate(6) }}><div><h5>6</h5></div></div>
                      <div className="col-3 cal" onClick={() => { this.calculate(500) }}><div className="primary"><h5>500</h5></div></div>
                      <div className="col-3 cal" onClick={() => { this.calculate(1) }}><div><h5>1</h5></div></div>
                      <div className="col-3 cal" onClick={() => { this.calculate(2) }}><div><h5>2</h5></div></div>
                      <div className="col-3 cal" onClick={() => { this.calculate(3) }}><div><h5>3</h5></div></div>
                      <div className="col-3 cal" onClick={() => { this.calculate(100) }}><div className="primary"><h5>100</h5></div></div>
                      <div className="col-3 cal" onClick={() => { this.calculate(".") }}><div><h5>.</h5></div></div>
                      <div className="col-3 cal" onClick={() => { this.calculate(0) }}><div><h5>0</h5></div></div>
                      <div className="col-3 cal" onClick={() => { this.calculate("delete") }}><div><h5 className="icon">{"\uf55a"}</h5></div></div>
                      <div className="col-3 cal" onClick={() => { this.calculate("full") }}><div className="primary"><h5>เต็ม</h5></div></div>
                    </div>
                  )}
                </div>

                <div className="row mt-3">
                  <div className="col-12 mb-3">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="ชื่อ-นามสกุลผู้โอน"
                      onChange={(e) => {
                        let data = this.state.payment_detail
                        data.full_name_transfer = e.target.value
                        this.setState({
                          payment_detail: data,
                        })
                      }}
                      value={this.state.payment_detail.full_name_transfer}
                    />
                  </div>
                </div>
                <div className="row mx-0">
                  <div className="col-12 mb-3 pl-0 pr-1 ">
                    <input
                      className="form-control"
                      type="datetime-local"
                      placeholder="วัน เดือน ปี"
                      onChange={(e) => {
                        let data = this.state.payment_detail
                        data.datetime_transfer = e.target.value
                        this.setState({
                          payment_detail: data,
                        })
                      }}
                      min={this.state.check_date}
                      value={this.state.payment_detail.datetime_transfer}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mb-3">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="หมายเลขอ้างอิง"
                      onChange={(e) => {
                        let data = this.state.payment_detail
                        data.reference_number = e.target.value.replace(/\D/g, "")
                        this.setState({
                          payment_detail: data,
                        })
                      }}
                      value={this.state.payment_detail.reference_number}
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="หมายเหตุ"
                      onChange={(e) => {
                        let data = this.state.payment_detail
                        data.remark_payment_s4 = e.target.value
                        this.setState({
                          payment_detail: data,
                        })
                      }}
                      value={this.state.payment_detail.remark_payment_s4}
                    />
                  </div>
                </div>
                <div className="w-100 my-1">
                  <label>
                    คำแนะนำ: โปรดเก็บหลักฐานการชำระเงินทุกครั้ง
                    หลังกรอกข้อมูลเสร็จสิ้นแล้ว
                  </label>
                </div>
              </div>
            )}
            {/* เป็นงวด */}
            {this.state.calculate_type === "เป็นงวด" && (
              <div className="w-100">
                <div className="w-100 d-flex justify-content-end align-items-center py-4 text-32 mb-3">
                  <b>
                    {baht(this.state.total_price_final)}
                  </b>
                </div>

                <div className="w-100 d-flex mb-3">
                  <div className="wpx-120">
                    <label>ภายใน (เดือน): </label>
                  </div>
                  <div className="w-100">
                    <input
                      className="form-control"
                      type="number"
                      placeholder="กรอกระยะเวลา (เดือน)"
                      onChange={(e) => {
                        if (e.target.value >= 0) {
                          let data = this.state.payment_detail
                          data.installment = e.target.value
                          this.setState({
                            payment_detail: data,
                          })
                        }
                      }}
                      value={this.state.payment_detail.installment}
                    />
                  </div>
                </div>
                <div className="row ">
                  <div className="col-12 mb-3">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="หมายเหตุ"
                      onChange={(e) => {
                        let data = this.state.payment_detail
                        data.remark_payment_s5 = e.target.value
                        this.setState({
                          payment_detail: data,
                        })
                      }}
                      value={this.state.payment_detail.remark_payment_s5}
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="w-100 d-flex mt-2">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_payment: false })

                  }}
                >
                  ย้อนกลับ
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (Number(this.state.price) === 0) {
                      this.alert('warning', "แจ้งเตือน", "กรุณากรอกจำนวนเงิน")
                      return
                    }

                    let payment_detail = this.state.payment_detail
                    if (this.state.calculate_type === "บัตรเครดิต") {
                      if (this.state.card_type === "บัตรเครดิต") {
                        if (payment_detail.credit_card_type === "") {
                          alert("info", "แจ้งเตือน", "กรุณาเลือกประเภทบัตร")
                          return
                        } else if (payment_detail.card_number === "") {
                          alert("info", "แจ้งเตือน", "กรุณากรอกหมายเลขบัตร 4 ตัวท้าย")
                          return
                        }
                      } else {
                        if (payment_detail.bank_name === "") {
                          alert("info", "แจ้งเตือน", "กรอกชื่อธนาคาร")
                          return
                        } else if (payment_detail.card_number === "") {
                          alert("info", "แจ้งเตือน", "กรุณากรอกหมายเลขบัตร 4 ตัวท้าย")
                          return
                        }
                      }

                    } else if (this.state.calculate_type === "เงินโอน") {
                      if (payment_detail.full_name_transfer === "") {
                        alert("info", "แจ้งเตือน", "กรุณากรอกชื่อ-นามสกุลผู้โอน")
                        return
                      } else if (payment_detail.datetime_transfer === "") {
                        alert("info", "แจ้งเตือน", "กรุณากรอกวัน-เวลาที่โอน")
                        return
                      } else if (payment_detail.reference_number === "") {
                        alert("info", "แจ้งเตือน", "กรุณากรอกหมายเลขอ้างอิง")
                        return
                      }
                    } else if (this.state.calculate_type === "เงินโอน") {
                      if (payment_detail.installment === "") {
                        alert("info", "แจ้งเตือน", "กรุณาระบุระยะเวลาในการผ่อนชำระ (เดือน)")
                        return
                      }
                    }


                    if (this.state.calculate_type === "เงินสด") {
                      payment_detail.total_pay = Number(this.state.price)
                      this.setState({ payment_type_layer_1: 1 })

                    } else if (this.state.calculate_type === "เงินเชื่อ") {

                      payment_detail.total_pay = 0.0
                      this.setState({ payment_type_layer_1: 2 })

                    } else if (this.state.calculate_type === "บัตรเครดิต") {
                      payment_detail.total_pay =
                        this.state.type === 5 ?
                          Number(this.state.price)
                          : this.state.type === 0
                            ? Number(this.state.total_payment)
                            : this.state.total_price_final

                      if (this.state.card_type === "บัตรเครดิต") {
                        let payment_detail = this.state.payment_detail
                        payment_detail.bank_name = "-"
                        this.setState({ payment_detail: payment_detail })
                      } else {
                        let payment_detail = this.state.payment_detail
                        payment_detail.credit_card_type = "-"
                      }

                      this.setState({ payment_type_layer_1: 3 })
                    } else if (this.state.calculate_type === "เงินโอน") {

                      payment_detail.total_pay =
                        this.state.type === 5 ?
                          Number(this.state.price)
                          : this.state.type === 0
                            ? Number(this.state.total_payment)
                            : this.state.total_price_final

                      this.setState({ payment_type_layer_1: 4 })

                    } else if (this.state.calculate_type === "เป็นงวด") {
                      payment_detail.total_pay = 0.0
                      this.setState({ payment_type_layer_1: 5 })
                    }
                    this.setState({ payment_detail: payment_detail })


                    if (this.state.type === 2) {
                      this.Payment("เงินเชื่อ")
                    } else if (this.state.type === 5) {
                      this.Payment("แบ่งงวด")
                    } else if (this.state.type === 0) {
                      this.Payment("รอชำระ")
                    }


                  }}
                >
                  ตกลง
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* ปริ้น modal */}
        < Modal_Print_All
          value={this.state.message}
          general={this.state.general}
          show={this.state.modal_print}
          onHide={() => {
            this.setState({ modal_print: false })

            this.clear_form()
            this.Get_All()
          }}
        />

        {/* รายละเอียด modal */}
        < ModalDetail
          value={this.state.data_detail}
          show={this.state.modal_detail}
          onHide={() => {
            this.setState({ modal_detail: false })
          }}
        />


        {/* ประวัติการชำระเงิน */}
        < ModalSaleHistory
          value={this.state.history}
          show={this.state.modal_history}
          onHide={() => {
            this.setState({ modal_history: false })
          }}
        />

      </div>
    )
  }
}
