// JS
import "../../../../global.js"

// COMPONENT
import MenuComponent from "../../SideMenu/SideMenu.js"
import BreadcrumbComponent from "../../Breadcrumb/Breadcrumb.js"

// LIBRARY
import Swal from "sweetalert2"
import { toast } from "react-toastify"
import Cookies from "universal-cookie"
import React, { Component } from "react"
import Pagination from "react-js-pagination"
import { Modal } from "react-bootstrap"
import { Menu, MenuItem } from "@szhsin/react-menu"

// CSS
import "./style.css"
import "../../../../bootstrap.css"
import "react-slideshow-image/dist/styles.css"
import "bootstrap-daterangepicker/daterangepicker.css"
import "@szhsin/react-menu/dist/index.css"
import "@szhsin/react-menu/dist/transitions/slide.css"


import {
  tokens,
  user_profile,
  alert,
  alert_url,
  GET,
  POST,
  PUT,
  DELETE,
  LOGOUT,
  validate,
  gen_Password,
  format_date,
  success_url,
  success,
  baht,
  ModalDetail,
  ModalSaleHistory
} from "../../../Custom/CustomComponent.js"

const cookies = new Cookies()
toast.configure()

const array_month = [
  "ม.ค.",
  "ก.พ.",
  "มี.ค.",
  "เม.ย.",
  "พ.ค.",
  "มิ.ย.",
  "ก.ค.",
  "ส.ค.",
  "ก.ย.",
  "ต.ค.",
  "พ.ย.",
  "ธ.ค.",
]

export default class HistoryScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      token: cookies.get("token_aip_shop") ? "token " + cookies.get("token_aip_shop") : "",
      role: cookies.get("role_data"),
      modal_detail: false,
      modal_edit: false,
      modal_delete: false,

      page: 1,
      loading: false,
      search: "",
      data: null,
      detail: null,
      data_detail: "",
      status_order: "",
      category_name: "",
      data_search: "",
      payment_type: "",
      end_date: "",
      start_date: "",

      modal_history: false,
      history: ""
    }
  }
  async componentDidMount() {
    if (this.state.role && this.state.role.sale_list[0] === 1) {
      await this.Get_All()
    }
  }

  Get_All = async () => {
    this.setState({ loading: true })
    let body = {
      category_name: this.state.category_name,
      data_search: this.state.data_search,
      status_order_main: 3,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
    }
    let result = await POST(tokens, "v1/pos-mgt/transaction/filter/page/" + this.state.page, body);
    if (result && result.status) {
      this.setState({ data: result.data })
    }
    this.setState({ loading: false })
  }

  Get_Modal_Detail = async (id) => {
    this.setState({ loading: true })
    let result = await GET(tokens, "v1/pos-mgt/order/detail/" + id, null);
    if (result && result.status) {

      this.setState({ data_detail: result.data.data })


      setTimeout(() => {
        this.setState({ modal_detail: true, loading: false })
      }, 20)
    }
    this.setState({ loading: false })


  }


  History = async (order_main_number) => {
    this.setState({ loading: true })
    let result = await POST(tokens, "/v1/transaction_payment/detail/" + order_main_number, null);
    if (result && result.status) {

      this.setState({ history: result.data.data })

      setTimeout(() => {
        this.setState({ modal_history: true, loading: false })
      }, 20)

    }
    this.setState({ loading: false })

  }

  Logout = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
    }
    fetch(global.api + "v1/logout", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        cookies.remove("token_aip_shop")
        cookies.remove("role_data")
        cookies.remove("user_profile")
        cookies.remove("first_page")
        window.location.href = "/login"
      })
      .catch((e) => {
        this.alert_url("info", "แจ้งเตือน", "เกิดข้อผิดพลาด", "/login")
      })
  }
  date = (date) => {
    let datetime =
      date.split("T")[0].split("-")[2] +
      " " +
      array_month[Number(date.split("T")[0].split("-")[1]) - 1] +
      " " +
      (Number(date.split("T")[0].split("-")[0]) + 543) +
      ", " +
      date.split("T")[1].slice(0, 5) +
      " น."
    return datetime
  }
  dates = (mdate) => {
    let datetimes =
      mdate.split(" ")[0].split("-")[2] +
      " " +
      array_month[Number(mdate.split(" ")[0].split("-")[1]) - 1] +
      " " +
      (Number(mdate.split(" ")[0].split("-")[0]) + 543) +
      ", " +
      mdate.split(" ")[1] +
      " น."

    return datetimes
  }
  status = (status) => {
    switch (status) {
      case 1:
        return "รอชำระ"
      case 2:
        return "ชำระเงินแล้ว"
      case 3:
        return "ยกเลิกสินค้า"
      case 4:
        return "พักออเดอร์"
      case 5:
        return "มัดจำ"

      default:
        return ""
    }
  }
  status_color = (status) => {
    switch (status) {
      case 1:
        return "status status-blue"
      case 2:
        return "status status-green"
      case 3:
        return "status status-black"
      case 4:
        return "status status-black"
      case 5:
        return "status status-yellow"
      default:
        return ""
    }
  }

  alert(icon, title, detail) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    })
  }
  alert_url(icon, title, detail, url) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    }).then(() => {
      window.location.href = url
    })
  }

  handlePageChange(pageNumber) {
    this.setState({ page: pageNumber })
    setTimeout(() => {
      this.Get_All()
    }, 20)
  }

  clear_form() {
    this.setState({
      loading: false,
      modal_detail: false,
    })
  }

  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <MenuComponent />
          <div className="body w-100">
            <BreadcrumbComponent page1="หน้าหลัก" page2="ประวัติการยกเลิก" />
            <div style={{ height: 40 }}></div>
            <div className="w-100 d-flex align-items-center justify-content-between px-3 mt-5">
              <h2>
                <b>ประวัติการยกเลิก</b>
              </h2>
            </div>
            <div className="w-100 p-3">
              <div className="card rounded">
                <div className="card-header rounded-top d-flex row mx-0 justify-content-between">
                  <input
                    className="form-control bg-white wpx-200 mx-2 mb-3"
                    type="search"
                    placeholder="ค้นหาด้วยชื่อลูกค้า"
                    value={this.state.data_search}
                    onChange={(e) => {
                      this.setState({ data_search: e.target.value })
                      if (e.target.value === "") {
                        this.setState({ page: 1, data_search: "" })
                        setTimeout(() => {
                          this.Get_All()
                        }, 20)
                      }
                    }}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        this.setState({
                          page: 1,
                        })
                        setTimeout(() => {
                          this.Get_All()
                        }, 20)
                      }
                    }}
                  />
                  <div className="d-flex row mx-0">
                    <div className="d-flex row mx-0">
                      <input
                        type="date"
                        className="form-control bg-white wpx-200 mx-2 mb-3"
                        value={this.state.start_date}
                        onChange={(e) => {
                          this.setState({ start_date: e.target.value })
                          this.setState({
                            page: 1,
                          })
                          setTimeout(() => {
                            this.Get_All()
                          }, 20)
                        }}
                      ></input>
                      <label class="mx-2 mt-2">ถึง</label>
                      <input
                        type="date"
                        className="form-control bg-white wpx-200 mx-2 mb-3"
                        value={this.state.end_date}
                        onChange={(e) => {
                          this.setState({ end_date: e.target.value })
                          this.setState({
                            page: 1,
                          })
                          setTimeout(() => {
                            this.Get_All()
                          }, 20)
                        }}
                      ></input>
                    </div>
                    <div className="d-flex row mx-0">
                      {/* <select
                        className="form-control bg-white wpx-200 mx-2 mb-3"
                        onChange={(e) => {
                          this.setState({
                            status_order: e.target.value,
                            page: 1,
                          })
                          setTimeout(() => {
                            this.Get_All()
                          }, 20)
                        }}
                        value={this.state.status_order}
                      >
                        <option value="" selected>
                          -- สถานะทั้งหมด --
                        </option>
                        <option value="2">ชำระเงินแล้ว</option>
                        <option value="5">ค่ามัดจำ</option>
                        <option value="3">ยกเลิก (คืนสินค้า)</option>
                      </select> */}

                      {/* <select
                        className="form-control bg-white wpx-200 mx-2 mb-3"
                        onChange={(e) => {
                          this.setState({
                            category_name: e.target.value,
                            page: 1,
                          })
                          setTimeout(() => {
                            this.Get_All()
                          }, 20)
                        }}
                        value={this.state.category_name}
                      >
                        <option value="" selected>
                          -- หมวดหมู่ทั้งหมด --
                        </option>
                        <option value="air">เครื่องปรับอากาศ</option>
                        <option value="spare_part">อะไหล่</option>
                        <option value="technician">งานช่าง</option>
                      </select> */}
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table design-table">
                      <thead>
                        <tr>


                          <th className="text-left">เลขที่รายการ</th>
                          <th className="text-left" style={{ minWidth: 130 }}>วันที่</th>
                          <th className="text-left" >รหัสลูกค้า</th>
                          <th className="text-left" style={{ minWidth: 100 }}>ชื่อลูกค้า</th>
                          <th className="text-right" style={{ minWidth: 100 }}>ค่ามัดจำ</th>
                          <th className="text-right " style={{ minWidth: 120 }}>ยอดรวม</th>
                          <th className="text-center" style={{ minWidth: 120 }}>สถานะ</th>
                          <th className="text-center" style={{ minWidth: 120 }}>จัดการ</th>
                        </tr>
                      </thead>
                      <tbody>
                        {((this.state.data &&
                          this.state.data.data.length === 0) ||
                          !this.state.data) && (
                            <tr>
                              <td colSpan={9} className="text-center">
                                -- ไม่มีข้อมูล --
                              </td>
                            </tr>
                          )}
                        {this.state.data &&
                          this.state.data.data.map((item, index) => (
                            <tr key={index}>
                              <td className="text-left" >{item.order_main_number}</td>
                              <td className="text-left">{this.dates(item.datetime_create)}</td>
                              <td className="text-left">{item.member_data.user_code}</td>
                              <td className="text-left">
                                {item.member_data ? item.member_data.company_name ? item.member_data.company_name : item.member_data.full_name : "-"}
                              </td>
                              <td className="text-right">
                                {baht(item.deposit)}
                              </td>
                              <td className="text-right"> {baht(item.total_price_final)} </td>
                              <td className="text-center">
                                <span
                                  className={this.status_color(
                                    item.status_order_main
                                  )}
                                >
                                  {this.status(item.status_order_main)}
                                </span>
                              </td>
                              <td className="text-center">
                                <Menu
                                  menuButton={
                                    <button className="btn btn-link text-secondary">
                                      <h2 className="icon h2">{"\uf141"}</h2>
                                    </button>
                                  }
                                  transition
                                >
                                  <MenuItem
                                    onClick={async () => {
                                      this.Get_Modal_Detail(item.order_main_id)
                                    }}
                                  >
                                    <label className="icon mr-2">
                                      {"\uf15c"}
                                    </label>
                                    รายละเอียด
                                  </MenuItem>

                                  {item.payment_type_layer_1 === 5 && (
                                    <MenuItem
                                      onClick={async () => {
                                        this.History(item.order_main_number)

                                      }}
                                    >
                                      <label className="icon mr-2">
                                        {"\uf1da"}
                                      </label>
                                      ประวัติการผ่อนชำระ
                                    </MenuItem>
                                  )}

                                </Menu>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {/* pagination */}
              {this.state.data && this.state.data.data.length !== 0 && (
                <div className="d-flex justify-content-between row mx-0 w-100 px-3 my-4">
                  <div>
                    <label>
                      แสดง {this.state.data.end_index} รายการจาก{" "}
                      {this.state.data.count} รายการ
                    </label>
                  </div>
                  <div className="d-flex">
                    <button
                      className="btn-circle-white mx-1"
                      onClick={() => {
                        this.handlePageChange(1)
                      }}
                    >
                      <label className="icon">{"\uf100"}</label>
                    </button>
                    <Pagination
                      activePage={this.state.page}
                      itemsCountPerPage={10}
                      totalItemsCount={this.state.data.count}
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChange.bind(this)}
                      prevPageText={<label className="icon">{"\uf104"}</label>}
                      nextPageText={<label className="icon">{"\uf105"}</label>}
                      hideFirstLastPages={true}
                    />
                    <button
                      className="btn-circle-white mx-1"
                      onClick={() => {
                        this.handlePageChange(this.state.data.page_number)
                      }}
                    >
                      <label className="icon">{"\uf101"}</label>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>


        {/* รายละเอียด modal */}
        < ModalDetail
          value={this.state.data_detail}
          show={this.state.modal_detail}
          onHide={() => {
            this.setState({ modal_detail: false })
          }}
        />


        {/* ประวัติการชำระเงิน */}
        < ModalSaleHistory
          value={this.state.history}
          show={this.state.modal_history}
          onHide={() => {
            this.setState({ modal_history: false })
          }}
        />


      </div>
    )
  }
}
