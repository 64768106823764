/* eslint-disable array-callback-return */
// JS
import "../../../../../global.js";

// COMPONENT
import MenuComponent from "../../../SideMenu/SideMenu";
import BreadcrumbComponent from "../../../Breadcrumb/Breadcrumb";

// LIBRARY
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import React, { Component } from "react";

// CSS
import "./style.css";
import "../../../../../bootstrap.css";
import "react-slideshow-image/dist/styles.css";
import "bootstrap-daterangepicker/daterangepicker.css";

const cookies = new Cookies();
toast.configure();
export default class CreateRoleUserScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: cookies.get("token_aip_shop") ? "token " + cookies.get("token_aip_shop") : "",
      loading: false,
      checked_all: false,
      checked_view: false,
      checked_create: false,
      checked_update: false,
      checked_delete: false,
      role_name: "",
      role_description: "",
      role_detail: [
        {
          menu_name: "user_admin",
          permission: {
            all: "false",
            view: "false",
            create: "false",
            update: "false",
            delete: "false",
            download: "false",
            upload: "false",
          },
        },
        {
          menu_name: "role",
          permission: {
            all: "false",
            view: "false",
            create: "false",
            update: "false",
            delete: "false",
            download: "false",
            upload: "false",
          },
        },
        {
          menu_name: "user_member",
          permission: {
            all: "false",
            view: "false",
            create: "false",
            update: "false",
            delete: "false",
            download: "false",
            upload: "false",
          },
        },
        {
          menu_name: "company_supplier",
          permission: {
            all: "false",
            view: "false",
            create: "false",
            update: "false",
            delete: "false",
            download: "false",
            upload: "false",
          },
        },
        {
          menu_name: "goods_receipt",
          permission: {
            all: "false",
            view: "false",
            create: "false",
            update: "false",
            delete: "false",
            download: "false",
            upload: "false",
          },
        },
        {
          menu_name: "repair_calendar",
          permission: {
            all: "false",
            view: "false",
            create: "false",
            update: "false",
            delete: "false",
            download: "false",
            upload: "false",
          },
        },
        {
          menu_name: "product_all",
          permission: {
            all: "false",
            view: "false",
            create: "false",
            update: "false",
            delete: "false",
            download: "false",
            upload: "false",
          },
        },
        {
          menu_name: "product_unit",
          permission: {
            all: "false",
            view: "false",
            create: "false",
            update: "false",
            delete: "false",
            download: "false",
            upload: "false",
          },
        },
        {
          menu_name: "product_category",
          permission: {
            all: "false",
            view: "false",
            create: "false",
            update: "false",
            delete: "false",
            download: "false",
            upload: "false",
          },
        },
        {
          menu_name: "type_spare_part",
          permission: {
            all: "false",
            view: "false",
            create: "false",
            update: "false",
            delete: "false",
            download: "false",
            upload: "false",
          },
        },
        {
          menu_name: "brand_all",
          permission: {
            all: "false",
            view: "false",
            create: "false",
            update: "false",
            delete: "false",
            download: "false",
            upload: "false",
          },
        },
        {
          menu_name: "product_model",
          permission: {
            all: "false",
            view: "false",
            create: "false",
            update: "false",
            delete: "false",
            download: "false",
            upload: "false",
          },
        },
        {
          menu_name: "btu",
          permission: {
            all: "false",
            view: "false",
            create: "false",
            update: "false",
            delete: "false",
            download: "false",
            upload: "false",
          },
        },
        {
          menu_name: "air_list",
          permission: {
            all: "false",
            view: "false",
            create: "false",
            update: "false",
            delete: "false",
            download: "false",
            upload: "false",
          },
        },
        {
          menu_name: "commission",
          permission: {
            all: "false",
            view: "false",
            create: "false",
            update: "false",
            delete: "false",
            download: "false",
            upload: "false",
          },
        },
        {
          menu_name: "type_commission",
          permission: {
            all: "false",
            view: "false",
            create: "false",
            update: "false",
            delete: "false",
            download: "false",
            upload: "false",
          },
        },
        {
          menu_name: "sale_list",
          permission: {
            all: "false",
            view: "false",
            create: "false",
            update: "false",
            delete: "false",
            download: "false",
            upload: "false",
          },
        },
        {
          menu_name: "stock_move_list",
          permission: {
            all: "false",
            view: "false",
            create: "false",
            update: "false",
            delete: "false",
            download: "false",
            upload: "false",
          },
        },
        {
          menu_name: "adjust_stock",
          permission: {
            all: "false",
            view: "false",
            create: "false",
            update: "false",
            delete: "false",
            download: "false",
            upload: "false",
          },
        },
        {
          menu_name: "transaction_stock",
          permission: {
            all: "false",
            view: "false",
            create: "false",
            update: "false",
            delete: "false",
            download: "false",
            upload: "false",
          },
        },
        {
          menu_name: "balance_stock",
          permission: {
            all: "false",
            view: "false",
            create: "false",
            update: "false",
            delete: "false",
            download: "false",
            upload: "false",
          },
        },
        {
          menu_name: "stock_all",
          permission: {
            all: "false",
            view: "false",
            create: "false",
            update: "false",
            delete: "false",
            download: "false",
            upload: "false",
          },
        },
        {
          menu_name: "report_sale",
          permission: {
            all: "false",
            view: "false",
            create: "false",
            update: "false",
            delete: "false",
            download: "false",
            upload: "false",
          },
        },
        {
          menu_name: "warehouse",
          permission: {
            all: "false",
            view: "false",
            create: "false",
            update: "false",
            delete: "false",
            download: "false",
            upload: "false",
          },
        },
        {
          menu_name: "clear",
          permission: {
            all: "false",
            view: "false",
            create: "false",
            update: "false",
            delete: "false",
            download: "false",
            upload: "false",
          },
        },
        {
          menu_name: "pos_system",
          permission: {
            all: "false",
            view: "false",
            create: "false",
            update: "false",
            delete: "false",
            download: "false",
            upload: "false",
          },
        },
      ],
    };
  }
  componentDidMount() { }

  Create = () => {
    this.setState({ loading: true });
    let role = this.state.role_detail.map((e) => {
      return {
        menu_name: e.menu_name,
        permission: {
          view: e.permission.view,
          create: e.permission.create,
          update: e.permission.update,
          delete: e.permission.delete,
          download: e.permission.download,
          upload: e.permission.upload,
        },
      };
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
      body: JSON.stringify({
        role_name: this.state.role_name,
        role_description: this.state.role_description,
        role_detail: role,
      }),
    };
    fetch(global.api + "v1/user-admin/role/create", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.clear_form();
          this.setState({ loading: false, page: 1 });
          this.alert_url(
            "success",
            "เพิ่มบทบาทเรียบร้อยแล้ว",
            "บทบาท “" + this.state.role_name + "” ถูกเพิ่มในระบบแล้ว",
            "/role-user"
          );
        } else {
          this.setState({ loading: false });
          switch (result.data.message) {
            case "Data role name is none":
              this.alert("info", "แจ้งเตือน", "กรุณากรอกชื่อบทบาท");
              break;
            case "role name is duplicate":
              this.alert("info", "แจ้งเตือน", "มีชื่อบทบาทนี้ในระบบแล้ว");
              break;
            case "Data role description is none":
              this.alert("info", "แจ้งเตือน", "กรุณากรอกรายละเอียด");
              break;
            case "Role no access permission":
              this.alert("info", "ขออภัย", "อีเมลนี้ไม่ได้รับอนุญาตให้เข้าถึง");
              this.Logout();
              break;
            case "role is not select":
              this.alert(
                "info",
                "แจ้งเตือน",
                "กรุณาเลือกบทบาทในเมนูอย่างน้อย 1 เมนู"
              );
              break;
            case "Token is not found":
              this.alert("info", "แจ้งเตือน", "เซสชั่นหมดอายุ");
              this.Logout();
              break;
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด");
              // this.Logout();
              break;
          }
        }
      })
      .catch((e) => {
        this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด");
        this.setState({ loading: false });
      });
  };
  Logout = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
    };
    fetch(global.api + "v1/logout", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        cookies.remove("token_aip_shop");
        cookies.remove("role_data");
        cookies.remove("user_profile");
        cookies.remove("first_page");
        window.location.href = "/login";
      })
      .catch((e) => {
        this.alert_url("info", "แจ้งเตือน", "เกิดข้อผิดพลาด", "/login");
      });
  };
  alert(icon, title, detail) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    });
  }
  alert_url(icon, title, detail, url) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    }).then(() => {
      window.location.href = url;
    });
  }
  clear_form() {
    this.setState({
      loading: false,
      modal_detail: false,
    });
  }
  change_role = (e, role_name, permission) => {
    let checked = e.target.checked ? "true" : "false";
    let array = this.state.role_detail;
    array.find((e, index) => {
      if (e.menu_name === role_name) {
        e.permission[permission] = checked;
        if (permission === "view") {
          e.permission["download"] = checked;
          e.permission["upload"] = checked;
        }
        if (permission === "all") {
          e.permission["all"] = checked;
          e.permission["view"] = checked;
          e.permission["create"] = checked;
          e.permission["update"] = checked;
          e.permission["delete"] = checked;
          e.permission["download"] = checked;
          e.permission["upload"] = checked;
        }
        if (
          e.permission["view"] === "true" &&
          e.permission["create"] === "true" &&
          e.permission["update"] === "true" &&
          e.permission["delete"] === "true"
        ) {
          e.permission["all"] = "true";
        } else {
          this.setState({ checked_all: false });
        }
      }
      if (role_name === "pos_system" && checked === "true" && e.menu_name === "user_member") {
        e.permission["view"] = checked;
        e.permission["create"] = checked;
      }
      if (role_name === "pos_system" && checked === "true" && e.menu_name === "sale_list") {
        e.permission["all"] = checked;
        e.permission["view"] = checked;
        e.permission["create"] = checked;
        e.permission["update"] = checked;
        e.permission["delete"] = checked;
        e.permission["download"] = checked;
        e.permission["upload"] = checked;
      }
    });
    this.setState({ role_detail: array });
  };
  getPermission(role_name, permission) {
    let array = this.state.role_detail;
    let checked = array.find((e) => {
      if (e.menu_name === role_name) {
        return e;
      }
    });
    if (permission === "all") {
      if (
        checked.permission["view"] === "false" ||
        checked.permission["create"] === "false" ||
        checked.permission["update"] === "false" ||
        checked.permission["delete"] === "false"
      ) {
        return false;
      }
    }
    return checked.permission[permission] === "true" ? true : false;
  }
  setAllPermission(e, type) {
    let array = this.state.role_detail;
    let checked = e.target.checked ? "true" : "false";
    switch (type) {
      case "all":
        array.find((e) => {
          e.permission["all"] = checked;
          e.permission["view"] = checked;
          e.permission["create"] = checked;
          e.permission["update"] = checked;
          e.permission["delete"] = checked;
          e.permission["download"] = checked;
          e.permission["upload"] = checked;
        });
        this.setState({
          role_detail: array,
          checked_all: e.target.checked,
          checked_view: e.target.checked,
          checked_create: e.target.checked,
          checked_update: e.target.checked,
          checked_delete: e.target.checked
        });
        break;
      case "view":
        array.find((e) => {
          e.permission[type] = checked;
          e.permission["download"] = checked;
          e.permission["upload"] = checked;
        });
        this.setState({ role_detail: array, checked_view: e.target.checked });
        break;
      case "create":
        array.find((e) => {
          e.permission[type] = checked;
        });
        this.setState({ role_detail: array, checked_create: e.target.checked });
        break;
      case "update":
        array.find((e) => {
          e.permission[type] = checked;
        });
        this.setState({ role_detail: array, checked_update: e.target.checked });
        break;
      case "delete":
        array.find((e) => {
          e.permission[type] = checked;
        });
        this.setState({ role_detail: array, checked_delete: e.target.checked });
        break;
      default:
        array.find((e) => {
          e.permission[type] = checked;
        });
        this.setState({ role_detail: array });
        break;
    }
    setTimeout(() => {
      if (this.state.checked_view
        && this.state.checked_create
        && this.state.checked_update
        && this.state.checked_delete) {
        array.find((e) => {
          e.permission["all"] = 'true';
          e.permission["view"] = 'true';
          e.permission["create"] = 'true';
          e.permission["update"] = 'true';
          e.permission["delete"] = 'true';
          e.permission["download"] = 'true';
          e.permission["upload"] = 'true';
        });
        this.setState({ checked_all: true })
      } else {
        this.setState({ checked_all: false })
      }
    }, 10);

  }
  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <MenuComponent />
          <div className="bodyw-100">
            <BreadcrumbComponent page1="หน้าหลัก" page2="บทบาทผู้ใช้" />
            <div style={{ height: 40 }}></div>
            <div className="w-100 d-flex align-items-center justify-content-between px-3 mt-5">
              <h2 className="w-100">
                <b>บทบาทผู้ใช้</b>
              </h2>
              <div>
                <button
                  type="button"
                  className="btn btn-outline-primary w-100 px-4"
                  onClick={() => {
                    window.location.href = "/role-user";
                  }}
                >
                  <span className="icon-brand mr-2">{"\uf060"}</span>
                  ย้อนกลับ
                </button>
              </div>
            </div>
            <div className="w-100 p-3">
              <div className="card rounded">
                <div className="card-body">
                  <div className="d-flex row mx-0 align-items-center mb-3">
                    <div className="wpx-120">
                      <label>ชื่อบทบาท:</label>
                    </div>
                    <div className="w-50">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="กรอกข้อมูล"
                        onChange={(e) => {
                          this.setState({ role_name: e.target.value });
                        }}
                        value={this.state.role_name}
                      ></input>
                    </div>
                  </div>
                  <div className="d-flex row mx-0 align-items-center">
                    <div className="wpx-120">
                      <label>รายละเอียด:</label>
                    </div>
                    <div className="w-50">
                      <textarea
                        className="form-control"
                        placeholder="กรอกข้อมูล"
                        onChange={(e) => {
                          this.setState({ role_description: e.target.value });
                        }}
                        value={this.state.role_description}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-100 p-3">
              <div className="card rounded">
                <div className="card-body">
                  <div className="row">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="text-left w-25">เมนูจัดการ</th>
                            <th className="text-center wpx-120">
                              <input
                                className="mr-2"
                                type="checkbox"
                                onChange={(e) => {
                                  this.setAllPermission(e, 'all');
                                }}
                                checked={this.state.checked_all}
                              />
                              ทั้งหมด
                            </th>
                            <th className="text-center wpx-120">
                              <input
                                className="mr-2"
                                type="checkbox"
                                onChange={(e) => {
                                  this.setAllPermission(e, 'view');
                                }}
                                checked={this.state.checked_view}
                              />ดูข้อมูล</th>
                            <th className="text-center wpx-120">
                              <input
                                className="mr-2"
                                type="checkbox"
                                onChange={(e) => {
                                  this.setAllPermission(e, 'create');
                                }}
                                checked={this.state.checked_create}
                              />เพิ่มข้อมูล</th>
                            <th className="text-center wpx-120">
                              <input
                                className="mr-2"
                                type="checkbox"
                                onChange={(e) => {
                                  this.setAllPermission(e, 'update');
                                }}
                                checked={this.state.checked_update}
                              />แก้ไขข้อมูล</th>
                            <th className="text-center wpx-120">
                              <input
                                className="mr-2"
                                type="checkbox"
                                onChange={(e) => {
                                  this.setAllPermission(e, 'delete');
                                }}
                                checked={this.state.checked_delete}
                              />ลบข้อมูล</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* ข้อมูลผู้ใช้ */}
                          <tr className="bg-secondary-light">
                            <td colSpan={6}>
                              <b>ข้อมูลผู้ใช้</b>
                            </td>
                          </tr>
                          {/* รายชื่อผู้ใช้ทั้งหมด */}
                          <tr>
                            <td>รายชื่อผู้ใช้ทั้งหมด</td>
                            <td className="text-center ">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "user_admin", "all");
                                }}
                                checked={this.getPermission(
                                  "user_admin",
                                  "all"
                                )}
                              />
                            </td>
                            <td className="text-center ">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "user_admin", "view");
                                }}
                                checked={this.getPermission(
                                  "user_admin",
                                  "view"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "user_admin", "create");
                                }}
                                checked={this.getPermission(
                                  "user_admin",
                                  "create"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "user_admin", "update");
                                }}
                                checked={this.getPermission(
                                  "user_admin",
                                  "update"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "user_admin", "delete");
                                }}
                                checked={this.getPermission(
                                  "user_admin",
                                  "delete"
                                )}
                              />
                            </td>
                          </tr>
                          {/* บทบาทผู้ใช้ */}
                          <tr>
                            <td>บทบาทผู้ใช้</td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "role", "all");
                                }}
                                checked={this.getPermission("role", "all")}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "role", "view");
                                }}
                                checked={this.getPermission("role", "view")}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "role", "create");
                                }}
                                checked={this.getPermission("role", "create")}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "role", "update");
                                }}
                                checked={this.getPermission("role", "update")}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "role", "delete");
                                }}
                                checked={this.getPermission("role", "delete")}
                              />
                            </td>
                          </tr>
                          {/* ข้อมูลสมาชิก */}
                          <tr>
                            <td>ข้อมูลสมาชิก</td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "user_member", "all");
                                }}
                                checked={this.getPermission(
                                  "user_member",
                                  "all"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "user_member", "view");
                                }}
                                checked={this.getPermission(
                                  "user_member",
                                  "view"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "user_member", "create");
                                }}
                                checked={this.getPermission(
                                  "user_member",
                                  "create"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "user_member", "update");
                                }}
                                checked={this.getPermission(
                                  "user_member",
                                  "update"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "user_member", "delete");
                                }}
                                checked={this.getPermission(
                                  "user_member",
                                  "delete"
                                )}
                              />
                            </td>
                          </tr>
                          {/* รายชื่อบริษัทจำหน่าย */}
                          <tr>
                            <td>รายชื่อบริษัทจำหน่าย</td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(
                                    e,
                                    "company_supplier",
                                    "all"
                                  );
                                }}
                                checked={this.getPermission(
                                  "company_supplier",
                                  "all"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(
                                    e,
                                    "company_supplier",
                                    "view"
                                  );
                                }}
                                checked={this.getPermission(
                                  "company_supplier",
                                  "view"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(
                                    e,
                                    "company_supplier",
                                    "create"
                                  );
                                }}
                                checked={this.getPermission(
                                  "company_supplier",
                                  "create"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(
                                    e,
                                    "company_supplier",
                                    "update"
                                  );
                                }}
                                checked={this.getPermission(
                                  "company_supplier",
                                  "update"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(
                                    e,
                                    "company_supplier",
                                    "delete"
                                  );
                                }}
                                checked={this.getPermission(
                                  "company_supplier",
                                  "delete"
                                )}
                              />
                            </td>
                          </tr>
                          {/* ใบรับสินค้า */}
                          <tr>
                            <td>ใบรับสินค้า</td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "goods_receipt", "all");
                                }}
                                checked={this.getPermission(
                                  "goods_receipt",
                                  "all"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "goods_receipt", "view");
                                }}
                                checked={this.getPermission(
                                  "goods_receipt",
                                  "view"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(
                                    e,
                                    "goods_receipt",
                                    "create"
                                  );
                                }}
                                checked={this.getPermission(
                                  "goods_receipt",
                                  "create"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(
                                    e,
                                    "goods_receipt",
                                    "update"
                                  );
                                }}
                                checked={this.getPermission(
                                  "goods_receipt",
                                  "update"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(
                                    e,
                                    "goods_receipt",
                                    "delete"
                                  );
                                }}
                                checked={this.getPermission(
                                  "goods_receipt",
                                  "delete"
                                )}
                              />
                            </td>
                          </tr>
                          {/* ปฏิทินงานซ่อม */}
                          <tr>
                            <td>ปฏิทินงานซ่อม</td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "repair_calendar", "all");
                                }}
                                checked={this.getPermission(
                                  "repair_calendar",
                                  "all"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(
                                    e,
                                    "repair_calendar",
                                    "view"
                                  );
                                }}
                                checked={this.getPermission(
                                  "repair_calendar",
                                  "view"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(
                                    e,
                                    "repair_calendar",
                                    "create"
                                  );
                                }}
                                checked={this.getPermission(
                                  "repair_calendar",
                                  "create"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(
                                    e,
                                    "repair_calendar",
                                    "update"
                                  );
                                }}
                                checked={this.getPermission(
                                  "repair_calendar",
                                  "update"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(
                                    e,
                                    "repair_calendar",
                                    "delete"
                                  );
                                }}
                                checked={this.getPermission(
                                  "repair_calendar",
                                  "delete"
                                )}
                              />
                            </td>
                          </tr>
                          {/* จัดการสินค้า */}
                          <tr className="bg-secondary-light">
                            <td colSpan={6}>
                              <b>จัดการสินค้า</b>
                            </td>
                          </tr>
                          {/* สินค้าทั้งหมด */}
                          <tr>
                            <td>สินค้าทั้งหมด</td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "product_all", "all");
                                }}
                                checked={this.getPermission(
                                  "product_all",
                                  "all"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "product_all", "view");
                                }}
                                checked={this.getPermission(
                                  "product_all",
                                  "view"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "product_all", "create");
                                }}
                                checked={this.getPermission(
                                  "product_all",
                                  "create"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "product_all", "update");
                                }}
                                checked={this.getPermission(
                                  "product_all",
                                  "update"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "product_all", "delete");
                                }}
                                checked={this.getPermission(
                                  "product_all",
                                  "delete"
                                )}
                              />
                            </td>
                          </tr>
                          {/* หน่วยนับสินค้า */}
                          <tr>
                            <td>หน่วยนับสินค้า</td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "product_unit", "all");
                                }}
                                checked={this.getPermission(
                                  "product_unit",
                                  "all"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "product_unit", "view");
                                }}
                                checked={this.getPermission(
                                  "product_unit",
                                  "view"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "product_unit", "create");
                                }}
                                checked={this.getPermission(
                                  "product_unit",
                                  "create"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "product_unit", "update");
                                }}
                                checked={this.getPermission(
                                  "product_unit",
                                  "update"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "product_unit", "delete");
                                }}
                                checked={this.getPermission(
                                  "product_unit",
                                  "delete"
                                )}
                              />
                            </td>
                          </tr>
                          {/* ประเภทอะไหล่ */}
                          <tr>
                            <td>ประเภทอะไหล่</td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "type_spare_part", "all");
                                }}
                                checked={this.getPermission(
                                  "type_spare_part",
                                  "all"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(
                                    e,
                                    "type_spare_part",
                                    "view"
                                  );
                                }}
                                checked={this.getPermission(
                                  "type_spare_part",
                                  "view"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(
                                    e,
                                    "type_spare_part",
                                    "create"
                                  );
                                }}
                                checked={this.getPermission(
                                  "type_spare_part",
                                  "create"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(
                                    e,
                                    "type_spare_part",
                                    "update"
                                  );
                                }}
                                checked={this.getPermission(
                                  "type_spare_part",
                                  "update"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(
                                    e,
                                    "type_spare_part",
                                    "delete"
                                  );
                                }}
                                checked={this.getPermission(
                                  "type_spare_part",
                                  "delete"
                                )}
                              />
                            </td>
                          </tr>
                          {/* ยี่ห้อทั้งหมด */}
                          <tr>
                            <td>ยี่ห้อทั้งหมด</td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "brand_all", "all");
                                }}
                                checked={this.getPermission("brand_all", "all")}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "brand_all", "view");
                                }}
                                checked={this.getPermission(
                                  "brand_all",
                                  "view"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "brand_all", "create");
                                }}
                                checked={this.getPermission(
                                  "brand_all",
                                  "create"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "brand_all", "update");
                                }}
                                checked={this.getPermission(
                                  "brand_all",
                                  "update"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "brand_all", "delete");
                                }}
                                checked={this.getPermission(
                                  "brand_all",
                                  "delete"
                                )}
                              />
                            </td>
                          </tr>
                          {/* BTU */}
                          <tr>
                            <td>BTU</td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "btu", "all");
                                }}
                                checked={this.getPermission("btu", "all")}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "btu", "view");
                                }}
                                checked={this.getPermission("btu", "view")}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "btu", "create");
                                }}
                                checked={this.getPermission("btu", "create")}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "btu", "update");
                                }}
                                checked={this.getPermission("btu", "update")}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "btu", "delete");
                                }}
                                checked={this.getPermission("btu", "delete")}
                              />
                            </td>
                          </tr>
                          {/* รายการแอร์ */}
                          <tr>
                            <td>รายการแอร์</td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "air_list", "all");
                                }}
                                checked={this.getPermission("air_list", "all")}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "air_list", "view");
                                }}
                                checked={this.getPermission("air_list", "view")}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "air_list", "create");
                                }}
                                checked={this.getPermission(
                                  "air_list",
                                  "create"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "air_list", "update");
                                }}
                                checked={this.getPermission(
                                  "air_list",
                                  "update"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "air_list", "delete");
                                }}
                                checked={this.getPermission(
                                  "air_list",
                                  "delete"
                                )}
                              />
                            </td>
                          </tr>
                          {/* จัดการคอมมิสชัน */}
                          <tr className="bg-secondary-light">
                            <td className="wpx-200" colSpan={6}>
                              <b>จัดการคอมมิสชัน</b>
                            </td>
                          </tr>
                          {/* ค่าคอมมิสชัน */}
                          <tr>
                            <td>ค่าคอมมิสชัน</td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "commission", "all");
                                }}
                                checked={this.getPermission(
                                  "commission",
                                  "all"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "commission", "view");
                                }}
                                checked={this.getPermission(
                                  "commission",
                                  "view"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "commission", "create");
                                }}
                                checked={this.getPermission(
                                  "commission",
                                  "create"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "commission", "update");
                                }}
                                checked={this.getPermission(
                                  "commission",
                                  "update"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "commission", "delete");
                                }}
                                checked={this.getPermission(
                                  "commission",
                                  "delete"
                                )}
                              />
                            </td>
                          </tr>
                          {/* ประเภทคอมมิสชัน */}
                          <tr>
                            <td>ประเภทคอมมิสชัน</td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "type_commission", "all");
                                }}
                                checked={this.getPermission(
                                  "type_commission",
                                  "all"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(
                                    e,
                                    "type_commission",
                                    "view"
                                  );
                                }}
                                checked={this.getPermission(
                                  "type_commission",
                                  "view"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(
                                    e,
                                    "type_commission",
                                    "create"
                                  );
                                }}
                                checked={this.getPermission(
                                  "type_commission",
                                  "create"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(
                                    e,
                                    "type_commission",
                                    "update"
                                  );
                                }}
                                checked={this.getPermission(
                                  "type_commission",
                                  "update"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(
                                    e,
                                    "type_commission",
                                    "delete"
                                  );
                                }}
                                checked={this.getPermission(
                                  "type_commission",
                                  "delete"
                                )}
                              />
                            </td>
                          </tr>
                          {/* รายการขาย */}
                          <tr className="bg-secondary-light">
                            <td><b>รายการขาย</b></td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "sale_list", "all");
                                }}
                                checked={this.getPermission("sale_list", "all")}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "sale_list", "view");
                                }}
                                checked={this.getPermission(
                                  "sale_list",
                                  "view"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "sale_list", "create");
                                }}
                                checked={this.getPermission(
                                  "sale_list",
                                  "create"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "sale_list", "update");
                                }}
                                checked={this.getPermission(
                                  "sale_list",
                                  "update"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "sale_list", "delete");
                                }}
                                checked={this.getPermission(
                                  "sale_list",
                                  "delete"
                                )}
                              />
                            </td>
                          </tr>
                          {/* จัดการสต๊อก */}
                          <tr className="bg-secondary-light">
                            <td colSpan={6}>
                              <b>จัดการสต๊อก</b>
                            </td>
                          </tr>
                          {/* รายการย้ายสต๊อก */}
                          <tr>
                            <td>รายการย้ายสต๊อก</td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "stock_move_list", "all");
                                }}
                                checked={this.getPermission(
                                  "stock_move_list",
                                  "all"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(
                                    e,
                                    "stock_move_list",
                                    "view"
                                  );
                                }}
                                checked={this.getPermission(
                                  "stock_move_list",
                                  "view"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(
                                    e,
                                    "stock_move_list",
                                    "create"
                                  );
                                }}
                                checked={this.getPermission(
                                  "stock_move_list",
                                  "create"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(
                                    e,
                                    "stock_move_list",
                                    "update"
                                  );
                                }}
                                checked={this.getPermission(
                                  "stock_move_list",
                                  "update"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(
                                    e,
                                    "stock_move_list",
                                    "delete"
                                  );
                                }}
                                checked={this.getPermission(
                                  "stock_move_list",
                                  "delete"
                                )}
                              />
                            </td>
                          </tr>
                          {/* รายการปรับสต๊อก */}
                          <tr>
                            <td>รายการปรับสต๊อก</td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "adjust_stock", "all");
                                }}
                                checked={this.getPermission(
                                  "adjust_stock",
                                  "all"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "adjust_stock", "view");
                                }}
                                checked={this.getPermission(
                                  "adjust_stock",
                                  "view"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "adjust_stock", "create");
                                }}
                                checked={this.getPermission(
                                  "adjust_stock",
                                  "create"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "adjust_stock", "update");
                                }}
                                checked={this.getPermission(
                                  "adjust_stock",
                                  "update"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "adjust_stock", "delete");
                                }}
                                checked={this.getPermission(
                                  "adjust_stock",
                                  "delete"
                                )}
                              />
                            </td>
                          </tr>
                          {/* เส้นทางสต๊อก */}
                          <tr>
                            <td>เส้นทางสต๊อก</td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(
                                    e,
                                    "transaction_stock",
                                    "all"
                                  );
                                }}
                                checked={this.getPermission(
                                  "transaction_stock",
                                  "all"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(
                                    e,
                                    "transaction_stock",
                                    "view"
                                  );
                                }}
                                checked={this.getPermission(
                                  "transaction_stock",
                                  "view"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(
                                    e,
                                    "transaction_stock",
                                    "create"
                                  );
                                }}
                                checked={this.getPermission(
                                  "transaction_stock",
                                  "create"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(
                                    e,
                                    "transaction_stock",
                                    "update"
                                  );
                                }}
                                checked={this.getPermission(
                                  "transaction_stock",
                                  "update"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(
                                    e,
                                    "transaction_stock",
                                    "delete"
                                  );
                                }}
                                checked={this.getPermission(
                                  "transaction_stock",
                                  "delete"
                                )}
                              />
                            </td>
                          </tr>
                          {/* สต๊อกสินค้า */}
                          <tr>
                            <td>สต๊อกสินค้า</td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "stock_all", "all");
                                }}
                                checked={this.getPermission("stock_all", "all")}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "stock_all", "view");
                                }}
                                checked={this.getPermission(
                                  "stock_all",
                                  "view"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "stock_all", "create");
                                }}
                                checked={this.getPermission(
                                  "stock_all",
                                  "create"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "stock_all", "update");
                                }}
                                checked={this.getPermission(
                                  "stock_all",
                                  "update"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "stock_all", "delete");
                                }}
                                checked={this.getPermission(
                                  "stock_all",
                                  "delete"
                                )}
                              />
                            </td>
                          </tr>
                          {/* สรุปงานขาย */}
                          <tr className="bg-secondary-light">
                            <td>สรุปงานขาย</td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "report_sale", "all");
                                }}
                                checked={this.getPermission(
                                  "report_sale",
                                  "all"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "report_sale", "view");
                                }}
                                checked={this.getPermission(
                                  "report_sale",
                                  "view"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "report_sale", "create");
                                }}
                                checked={this.getPermission(
                                  "report_sale",
                                  "create"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "report_sale", "update");
                                }}
                                checked={this.getPermission(
                                  "report_sale",
                                  "update"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "report_sale", "delete");
                                }}
                                checked={this.getPermission(
                                  "report_sale",
                                  "delete"
                                )}
                              />
                            </td>
                          </tr>
                          {/* โกดังสินค้า */}
                          <tr className="bg-secondary-light">
                            <td>โกดังสินค้า</td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "warehouse", "all");
                                }}
                                checked={this.getPermission("warehouse", "all")}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "warehouse", "view");
                                }}
                                checked={this.getPermission(
                                  "warehouse",
                                  "view"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "warehouse", "create");
                                }}
                                checked={this.getPermission(
                                  "warehouse",
                                  "create"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "warehouse", "update");
                                }}
                                checked={this.getPermission(
                                  "warehouse",
                                  "update"
                                )}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "warehouse", "delete");
                                }}
                                checked={this.getPermission(
                                  "warehouse",
                                  "delete"
                                )}
                              />
                            </td>
                          </tr>
                          {/* ล้างข้อมูล */}
                          <tr className="bg-secondary-light">
                            <td>ล้างข้อมูล</td>
                            <td className="text-center">
                              {/* <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "clear", "all");
                                }}
                                checked={this.getPermission("clear", "all")}
                              /> */}
                            </td>
                            <td className="text-center">
                              {/* <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "clear", "view");
                                }}
                                checked={this.getPermission(
                                  "clear",
                                  "view"
                                )}
                              /> */}
                            </td>
                            <td className="text-center">
                              {/* <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "clear", "create");
                                }}
                                checked={this.getPermission(
                                  "clear",
                                  "create"
                                )}
                              /> */}
                            </td>
                            <td className="text-center">
                              {/* <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "clear", "update");
                                }}
                                checked={this.getPermission(
                                  "clear",
                                  "update"
                                )}
                              /> */}
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "clear", "delete");
                                }}
                                checked={this.getPermission(
                                  "clear",
                                  "delete"
                                )}
                              />
                            </td>
                          </tr>
                          {/* ระบบ POS */}
                          <tr className="bg-secondary-light">
                            <td>
                              <b>ระบบ POS</b>
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  this.change_role(e, "pos_system", "all");
                                }}
                                checked={this.getPermission(
                                  "pos_system",
                                  "all"
                                )}
                              />
                            </td>
                            <td className="text-center" colSpan={4}></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-100 p-3 bg-white">
              <div className="row mx-0">
                <div className="ml-auto col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-2">
                  <button
                    className="btn btn-outline-primary w-100"
                    onClick={() => {
                      window.location.href = "role-user";
                    }}
                  >
                    ยกเลิก
                  </button>
                </div>
                <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-2">
                  <button
                    className="btn btn-primary w-100"
                    onClick={() => {
                      if (this.state.role_name === "") {
                        this.alert("info", "แจ้งเตือน", "กรุณากรอกชื่อบทบาท");
                        return;
                      }
                      if (this.state.role_description === "") {
                        this.alert("info", "แจ้งเตือน", "กรุณากรอกรายละเอียด");
                        return;
                      }
                      this.Create();
                    }}
                  >
                    บันทึก
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
