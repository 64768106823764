// JS
import "../../../global.js"

// COMPONENT
import MenuComponent from "../SideMenu/SideMenu"
import BreadcrumbComponent from "../Breadcrumb/Breadcrumb"

// LIBRARY
import Swal from "sweetalert2"
import { toast } from "react-toastify"
import Cookies from "universal-cookie"
import React, { Component } from "react"
import Pagination from "react-js-pagination"
import { Modal } from "react-bootstrap"

// CSS
import "./style.css"
import "../../../bootstrap.css"
import "react-slideshow-image/dist/styles.css"
import "bootstrap-daterangepicker/daterangepicker.css"

import {
  tokens,
  alert,
  POST,
  PUT,
  DELETE,
} from "../../Custom/CustomComponent.js"

const cookies = new Cookies()
toast.configure()
export default class ListMemberScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      token: cookies.get("token_aip_shop") ? "token " + cookies.get("token_aip_shop") : "",
      role: cookies.get("role_data"),
      modal_add: false,
      modal_edit: false,
      modal_delete: false,
      page: 1,
      loading: false,
      search: "",
      filter_status: "",
      data: null,
      data_supplier: null,
      detail: null,

      company_name: "",

      user_type: "",
      credit_day: 0,
      full_name: "",
      tax_invoice: "",
      address: "",
      phone: "",
      email: "",
    }
  }
  async componentDidMount() {
    if (this.state.role && this.state.role.user_member[0] === 1) {
      await this.Get_All()
    }
  }

  Get_All = async () => {
    this.setState({ loading: true })
    let body = {
      data_search: this.state.search,
      status: this.state.filter_status,
    }
    let result = await POST(tokens, "v1/user-member/search/page/" + this.state.page, body);
    if (result && result.status) {
      this.setState({ data: result.data })
    }
    this.setState({ loading: false });
  }

  Create = async () => {
    if (this.state.user_type === "") {
      alert("warning", "แจ้งเตือน", "กรุณาเลือกประเภท");
      return;
    } else if (this.state.full_name === "") {
      alert("warning", "แจ้งเตือน", "กรุณากรอกชื่อ-นามสกุล");
      return;
    }
    this.setState({ loading: true })
    let body = {
      user_type: this.state.user_type,
      credit_day: Number(this.state.credit_day),
      full_name: this.state.full_name,
      company_name: this.state.company_name,
      tax_invoice: this.state.tax_invoice,
      address: this.state.address,
      phone: this.state.phone,
      email: this.state.email,
      line_id: "",
    }
    let result = await POST(tokens, "v1/user-member/create", body);
    if (result && result.status) {
      alert("success", "เพิ่มสมาชิกเรียบร้อยแล้ว", "สมาชิก ' " + this.state.full_name + " ' ถูกเพิ่มในระบบแล้ว")
      this.setState({ loading: false, modal_add: false, page: 1 })
      this.clear_form()
      this.Get_All()
    }
    this.setState({ loading: false });
  }

  Edit = async () => {
    if (this.state.user_type === "") {
      alert("warning", "แจ้งเตือน", "กรุณาเลือกประเภท");
      return;
    } else if (this.state.full_name === "") {
      alert("warning", "แจ้งเตือน", "กรุณากรอกชื่อ-นามสกุล");
      return;
    }
    this.setState({ loading: true })
    let body = {
      user_id: this.state.detail.user_id,
      user_type: this.state.user_type,
      credit_day: Number(this.state.credit_day),
      full_name: this.state.full_name,
      company_name: this.state.company_name,
      tax_invoice: this.state.tax_invoice,
      address: this.state.address,
      phone: this.state.phone,
      email: this.state.email,
      line_id: "",
    }
    let result = await PUT(tokens, "v1/user-member/update", body);
    if (result && result.status) {
      alert("success", "แก้ไขข้อมูลสมาชิกเรียบร้อยแล้ว", "สมาชิก ' " + this.state.full_name + " ' ถูกแก้ไขข้อมูลในระบบแล้ว")
      this.setState({ loading: false, modal_edit: false, page: 1 })
      this.clear_form()
      this.Get_All()
    }
    this.setState({ loading: false });
  }

  Delete = async () => {
    this.setState({ loading: true });
    let result = await DELETE(tokens, "v1/user-member/delete/" + this.state.detail.user_id, null);
    if (result && result.status) {
      alert("success", "ลบข้อมูลสมาชิกเรียบร้อยแล้ว", "สมาชิก ' " + this.state.detail.full_name + " ' ถูกลบข้อมูลในระบบแล้ว")
      this.setState({ loading: false, modal_delete: false, page: 1 })
      this.clear_form()
      this.Get_All()
    }
    this.setState({ loading: false });
  }

  Logout = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
    }
    fetch(global.api + "v1/logout", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        cookies.remove("token_aip_shop")
        cookies.remove("role_data")
        cookies.remove("user_profile")
        cookies.remove("first_page")
        window.location.href = "/login"
      })
      .catch((e) => {
        this.alert_url("info", "แจ้งเตือน", "เกิดข้อผิดพลาด", "/login")
      })
  }

  alert(icon, title, detail) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    })
  }
  alert_url(icon, title, detail, url) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    }).then(() => {
      window.location.href = url
    })
  }

  handlePageChange(pageNumber) {
    this.setState({ page: pageNumber })

    setTimeout(() => {
      this.Get_All()
    }, 20)
  }

  clear_form() {
    this.setState({
      modal_add: false,
      modal_edit: false,
      modal_delete: false,
      loading: false,
      search: "",
      detail: null,
      company_name: "",
      user_type: "",
      credit_day: "",
      full_name: "",
      tax_invoice: "",
      address: "",
      phone: "",
      email: "",
    })
  }

  validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  }

  validatePhone = (phone) => {
    return phone.match(/^^[0][0-9]*$/)
  }

  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <MenuComponent />
          <div className="bodyw-100">
            <BreadcrumbComponent page1="หน้าหลัก" page2="ข้อมูลสมาชิก" />
            <div style={{ height: 40 }}></div>
            <div className="w-100 d-flex align-items-center justify-content-between px-3 mt-5">
              <h2>
                <b>ข้อมูลสมาชิก</b>
              </h2>
              {this.state.role && this.state.role.user_member[1] === 1 && (
                <div>
                  <button
                    type="button"
                    className="btn btn-primary w-100 px-4"
                    onClick={() => {

                      this.clear_form()
                      this.setState({ modal_add: true })
                    }}
                  >
                    เพิ่มสมาชิก
                  </button>
                </div>
              )}
            </div>
            <div className="w-100 p-3">
              <div className="card rounded">
                <div className="card-header rounded-top">
                  <div className="row justify-content-between px-3">
                    <input
                      className="form-control bg-white mb-2"
                      type="search"
                      placeholder="ค้นหาด้วยรหัสสมาชิก ชื่อ เบอร์โทร"
                      value={this.state.search}
                      onChange={(e) => {
                        this.setState({ search: e.target.value })
                        if (e.target.value === "") {
                          this.setState({
                            page: 1,
                            search: "",
                            filter_status: "",
                          })
                          setTimeout(() => {
                            this.Get_All()
                          }, 20)
                        }
                      }}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          this.setState({ page: 1 })
                          setTimeout(() => {
                            this.Get_All()
                          }, 20)
                        }
                      }}
                    />
                    {/* ประเภทลูกค้า */}
                    <div className="wpx-200 ">
                      <select
                        className="form-control-select bg-white text-center"
                        value={this.state.filter_status}
                        onChange={(e) => {
                          this.setState({
                            filter_status: e.target.value,
                          })
                          setTimeout(() => {
                            this.Get_All()
                          }, 20)
                        }}
                      >
                        <option value="" selected>
                          ทั้งหมด
                        </option>
                        <option value="general">ลูกค้าสมาชิก</option>
                        <option value="corporation">ลูกค้านิติบุคคล</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table design-table">
                      <thead>
                        <tr>
                          <th className="text-left" style={{ minWidth: 90 }}>รหัสสมาชิก</th>
                          <th className="text-left" style={{ minWidth: 120 }}>ชื่อผู้ใช้</th>
                          <th className="text-left">ชื่อบริษัท</th>
                          <th className="text-left wpx-150">เลขที่กำกับภาษี</th>
                          <th className="text-left wpx-120">เบอร์โทร</th>
                          <th className="text-left wpx-150">ประเภทสมาชิก</th>
                          {this.state.role &&
                            (this.state.role.user_member[2] === 1 ||
                              this.state.role.user_member[3] === 1) && (
                              <th className="text-center wpx-150">จัดการ</th>
                            )}
                        </tr>
                      </thead>
                      <tbody>
                        {((this.state.data &&
                          this.state.data.data.length === 0) ||
                          !this.state.data) && (
                            <tr>
                              <td colSpan={7} className="text-center">
                                -- ไม่มีข้อมูล --
                              </td>
                            </tr>
                          )}
                        {this.state.data &&
                          this.state.data.data.map((item, index) => (
                            <tr key={index}>
                              <td>{item.user_code}</td>
                              <td>{item.full_name}</td>
                              <td>
                                {item.company_name === "no company" ||
                                  item.company_name === ""
                                  ? "-"
                                  : item.company_name}
                              </td>
                              <td>
                                {item.tax_invoice ? item.tax_invoice : "-"}
                              </td>
                              <td>{item.phone ? item.phone : "-"}</td>
                              <td>
                                <span className="status-grey-type wpx-200">
                                  {item.user_type === "general"
                                    ? "ลูกค้าสมาชิก"
                                    : "ลูกค้านิติบุคคล"}
                                </span>
                              </td>
                              <td className="text-center">
                                {item.user_code === "ME000000001" ||
                                  (this.state.role &&
                                    this.state.role.user_member[2] === 1 && (
                                      <button
                                        className="btn btn-link text-secondary"
                                        onClick={() => {
                                          this.setState({
                                            modal_edit: true,
                                            detail: item,
                                            user_type: item.user_type,
                                            credit_day: item.credit_day,
                                            full_name: item.full_name,
                                            company_name: item.company_name,
                                            tax_invoice: item.tax_invoice,
                                            address: item.address,
                                            phone: item.phone,
                                            email: item.email,
                                          })
                                        }}
                                      >
                                        <label className="icon mr-2">
                                          {"\uf304"}
                                        </label>
                                      </button>
                                    ))}
                                {item.user_code === "ME000000001" ||
                                  (this.state.role &&
                                    this.state.role.user_member[3] === 1 && (
                                      <button
                                        className="btn btn-link text-secondary"
                                        onClick={() => {
                                          this.setState({
                                            modal_delete: true,
                                            detail: item,
                                          })
                                        }}
                                      >
                                        <label className="icon mr-2">
                                          {"\uf2ed"}
                                        </label>
                                      </button>
                                    ))}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {/* pagination */}
              {this.state.data && this.state.data.data.length !== 0 && (
                <div className="d-flex justify-content-between row mx-0 w-100 px-3 my-4">
                  <div>
                    <label>
                      แสดง {this.state.data.end_index} รายการจาก{" "}
                      {this.state.data.count} รายการ
                    </label>
                  </div>
                  <div className="d-flex">
                    <button
                      className="btn-circle-white mx-1"
                      onClick={() => {
                        this.handlePageChange(1)
                      }}
                    >
                      <label className="icon">{"\uf100"}</label>
                    </button>
                    <Pagination
                      activePage={this.state.page}
                      itemsCountPerPage={10}
                      totalItemsCount={this.state.data.count}
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChange.bind(this)}
                      prevPageText={<label className="icon">{"\uf104"}</label>}
                      nextPageText={<label className="icon">{"\uf105"}</label>}
                      hideFirstLastPages={true}
                    />
                    <button
                      className="btn-circle-white mx-1"
                      onClick={() => {
                        this.handlePageChange(this.state.data.page_number)
                      }}
                    >
                      <label className="icon">{"\uf101"}</label>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* modal */}
        {/* ADD modal */}
        <Modal
          show={this.state.modal_add}
          onHide={() => {
            this.setState({ modal_add: false })
            this.clear_form()
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b>เพิ่มข้อมูลสมาชิก</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* ประเภท */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ประเภท:</label>
              </div>
              <div className="w-100">
                <select
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ user_type: e.target.value })
                  }}
                  value={this.state.user_type}
                >
                  <option value="" selected disabled>
                    -- เลือก --
                  </option>
                  <option value="general">ลูกค้าสมาชิก</option>
                  <option value="corporation">ลูกค้านิติบุคคล</option>
                  <option value="dormitory">หอพัก</option>
                  <option value="village">หมู่บ้าน</option>
                  <option value="agency">หน่วยงาน</option>
                </select>
              </div>
            </div>
            {/* เครดิต(วัน) */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>เครดิต(วัน):</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control"
                  type="text"
                  placeholder="กรอกข้อมูล"
                  maxLength={10}
                  onChange={(e) => {
                    let credit_day = this.state.credit_day
                    credit_day = e.target.value.replace(/\D/g, "")
                    this.setState({
                      credit_day: credit_day,
                    })
                  }}
                  value={this.state.credit_day}
                />
              </div>
            </div>
            {/* ชื่อ-นามสกุล */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ชื่อ-นามสกุล:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control"
                  type="text"
                  maxLength={50}
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ full_name: e.target.value })
                  }}
                  value={this.state.full_name}
                />
              </div>
            </div>
            {/* ชื่อบริษัท */}
            {this.state.user_type !== "general" && this.state.user_type !== "" && (
              <div className="w-100 d-flex mb-3">
                <div className="wpx-120">
                  <label>
                    {this.state.user_type === "corporation" ? "ชื่อบริษัท:" :
                      this.state.user_type === "dormitory" ? "ชื่อหอพัก:" :
                        this.state.user_type === "village" ? "ชื่อหมู่บ้าน:" :
                          this.state.user_type === "agency" ? "ชื่อหน่วยงาน:" : ""}
                  </label>
                </div>
                <div className="w-100">
                  <input
                    className="form-control"
                    type="text"
                    maxLength={50}
                    placeholder="กรอกข้อมูล"
                    onChange={(e) => {
                      this.setState({ company_name: e.target.value })
                    }}
                    value={this.state.company_name}
                  />
                </div>
              </div>
            )}

            {/* เลขกำกับภาษี */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>เลขกำกับภาษี:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control"
                  type="text"
                  maxLength={13}
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    let tax_invoice = this.state.tax_invoice
                    tax_invoice = e.target.value.replace(/\D/g, "")
                    this.setState({
                      tax_invoice: tax_invoice,
                    })
                  }}
                  value={this.state.tax_invoice}
                />
              </div>
            </div>
            {/* ที่อยู่ */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ที่อยู่:</label>
              </div>
              <div className="w-100">
                <textarea
                  className="form-control"
                  placeholder="กรอกข้อมูล"
                  maxLength={100}
                  onChange={(e) => {
                    this.setState({ address: e.target.value })
                  }}
                  value={this.state.address}
                ></textarea>
              </div>
            </div>
            {/* เบอร์โทร */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>เบอร์โทร:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control"
                  type="tel"
                  maxLength={10}
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    let phone = this.state.phone
                    phone = e.target.value.replace(/\D/g, "")
                    this.setState({
                      phone: phone,
                    })
                  }}
                  value={this.state.phone}
                />
              </div>
            </div>
            {/* อีเมล */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>อีเมล:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control"
                  type="email"
                  maxLength={50}
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ email: e.target.value })
                  }}
                  value={this.state.email}
                />
              </div>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_add: false })
                    this.clear_form()
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    // if (this.state.user_type === "") {
                    //   this.alert("info", "แจ้งเตือน", "กรุณาเลือกประเภท")
                    //   return
                    // } else {
                    //   if (this.state.user_type === "general") {
                    //     if (this.state.full_name === "") {
                    //       this.alert("info", "แจ้งเตือน", "กรุณากรอกชื่อ")
                    //       return
                    //     } else if (this.state.phone.trim() === "") {
                    //       this.alert(
                    //         "info",
                    //         "แจ้งเตือน",
                    //         "กรุณากรอกเบอร์โทรศัพท์"
                    //       )
                    //       return
                    //     } else if (this.state.tax_invoice.trim() !== "" && this.state.tax_invoice.length !== 13) {
                    //       this.alert(
                    //         "info",
                    //         "แจ้งเตือน",
                    //         "กรุณากรอกเลขกำกับภาษีให้ครบ 13 หลัก"
                    //       )
                    //       return
                    //     } else if (!this.validatePhone(this.state.phone)) {
                    //       this.alert(
                    //         "info",
                    //         "แจ้งเตือน",
                    //         "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง"
                    //       )
                    //       return
                    //     } else if (this.state.phone.trim().length < 9) {
                    //       this.alert(
                    //         "info",
                    //         "แจ้งเตือน",
                    //         "กรุณากรอกเบอร์โทรศัพท์ให้ครบถ้วน"
                    //       )
                    //       return
                    //     } else if (this.state.email.trim() !== "" && !this.validateEmail(this.state.email)) {
                    //       this.alert(
                    //         "info",
                    //         "แจ้งเตือน",
                    //         "รูปแบบอีเมลไม่ถูกต้อง"
                    //       )
                    //       return
                    //     }
                    //   } else {
                    //     if (this.state.full_name === "") {
                    //       this.alert("info", "แจ้งเตือน", "กรุณากรอกชื่อ")
                    //       return
                    //     } else if (this.state.company_name === "") {
                    //       this.alert("info", "แจ้งเตือน", "กรุณากรอกชื่อบริษัท")
                    //       return
                    //     } else if (this.state.tax_invoice.trim() === "") {
                    //       this.alert(
                    //         "info",
                    //         "แจ้งเตือน",
                    //         "กรุณากรอกเลขกำกับภาษี"
                    //       )
                    //       return
                    //     } else if (this.state.tax_invoice.trim() !== "" && this.state.tax_invoice.length !== 13) {
                    //       this.alert(
                    //         "info",
                    //         "แจ้งเตือน",
                    //         "กรุณากรอกเลขกำกับภาษีให้ครบ 13 หลัก"
                    //       )
                    //       return
                    //     } else if (this.state.phone.trim() === "") {
                    //       this.alert(
                    //         "info",
                    //         "แจ้งเตือน",
                    //         "กรุณากรอกเบอร์โทรศัพท์"
                    //       )
                    //       return
                    //     } else if (!this.validatePhone(this.state.phone)) {
                    //       this.alert(
                    //         "info",
                    //         "แจ้งเตือน",
                    //         "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง"
                    //       )
                    //       return
                    //     } else if (this.state.phone.trim().length < 9) {
                    //       this.alert(
                    //         "info",
                    //         "แจ้งเตือน",
                    //         "กรุณากรอกเบอร์โทรศัพท์ให้ครบถ้วน"
                    //       )
                    //       return
                    //     } else if (this.state.email.trim() !== "" && !this.validateEmail(this.state.email)) {
                    //       this.alert(
                    //         "info",
                    //         "แจ้งเตือน",
                    //         "รูปแบบอีเมลไม่ถูกต้อง"
                    //       )
                    //       return
                    //     }
                    //   }
                    // }
                    this.Create()
                  }}
                >
                  เพิ่ม
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* EDIT modal */}
        <Modal
          show={this.state.modal_edit}
          onHide={() => {
            this.setState({ modal_edit: false })
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b>แก้ไขข้อมูลสมาชิก</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* รหัสสมาชิก */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>รหัสสมาชิก:</label>
              </div>
              <div className="w-100">
                <label className="text-secondary">
                  {this.state.detail && this.state.detail.user_code}
                </label>
              </div>
            </div>
            {/* ประเภท */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ประเภท:</label>
              </div>
              <div className="w-100">
                <label>
                  {
                    this.state.user_type === "general" ? "ลูกค้าสมาชิก" :
                      this.state.user_type === "corporation" ? "ลูกค้านิติบุคคล" :
                        this.state.user_type === "dormitory" ? "หอพัก" :
                          this.state.user_type === "village" ? "หมู่บ้าน" :
                            this.state.user_type === "agency" ? "หน่วยงาน" : ""
                  }
                </label>
              </div>
            </div>
            {/* เครดิต(วัน) */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>เครดิต(วัน):</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control"
                  type="text"
                  maxLength={10}
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    let credit_day = this.state.credit_day
                    credit_day = e.target.value.replace(/\D/g, "")
                    this.setState({
                      credit_day: credit_day,
                    })
                  }}
                  value={this.state.credit_day}
                />
              </div>
            </div>
            {/* ชื่อ-นามสกุล */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ชื่อ-นามสกุล:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control"
                  type="text"
                  maxLength={50}
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ full_name: e.target.value })
                  }}
                  value={this.state.full_name}
                />
              </div>
            </div>
            {/* ชื่อบริษัท */}
            {this.state.user_type !== "general" && (
              <div className="w-100 d-flex mb-3">
                <div className="wpx-120">
                  <label>
                    {this.state.user_type === "corporation" ? "ชื่อบริษัท:" :
                      this.state.user_type === "dormitory" ? "ชื่อหอพัก:" :
                        this.state.user_type === "village" ? "ชื่อหมู่บ้าน:" :
                          this.state.user_type === "agency" ? "ชื่อหน่วยงาน:" : ""}
                  </label>
                </div>
                <div className="w-100">
                  <input
                    className="form-control"
                    type="text"
                    maxLength={50}
                    placeholder="กรอกข้อมูล"
                    onChange={(e) => {
                      this.setState({ company_name: e.target.value })
                    }}
                    value={this.state.company_name}
                  />
                </div>
              </div>
            )}

            {/* เลขกำกับภาษี */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>เลขกำกับภาษี:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control"
                  type="text"
                  maxLength={13}
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    let tax_invoice = this.state.tax_invoice
                    tax_invoice = e.target.value.replace(/\D/g, "")
                    this.setState({
                      tax_invoice: tax_invoice,
                    })
                  }}
                  value={this.state.tax_invoice}
                />
              </div>
            </div>
            {/* ที่อยู่ */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ที่อยู่:</label>
              </div>
              <div className="w-100">
                <textarea
                  className="form-control"
                  maxLength={100}
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ address: e.target.value })
                  }}
                  value={this.state.address}
                ></textarea>
              </div>
            </div>
            {/* เบอร์โทร */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>เบอร์โทร:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control"
                  type="tel"
                  maxLength={10}
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    let phone = this.state.phone
                    phone = e.target.value.replace(/\D/g, "")
                    this.setState({
                      phone: phone,
                    })
                  }}
                  value={this.state.phone}
                />
              </div>
            </div>
            {/* อีเมล */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>อีเมล:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control"
                  type="email"
                  maxLength={50}
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ email: e.target.value })
                  }}
                  value={this.state.email}
                />
              </div>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_edit: false })
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    // if (this.state.user_type === "") {
                    //   this.alert("info", "แจ้งเตือน", "กรุณาเลือกประเภท")
                    //   return
                    // } else {
                    //   if (this.state.user_type === "general") {
                    //     if (this.state.full_name === "") {
                    //       this.alert("info", "แจ้งเตือน", "กรุณากรอกชื่อ")
                    //       return
                    //     } else if (this.state.tax_invoice.trim() !== "" && this.state.tax_invoice.length !== 13) {
                    //       this.alert(
                    //         "info",
                    //         "แจ้งเตือน",
                    //         "กรุณากรอกเลขกำกับภาษีให้ครบ 13 หลัก"
                    //       )
                    //       return
                    //     } else if (this.state.phone.trim() === "") {
                    //       this.alert(
                    //         "info",
                    //         "แจ้งเตือน",
                    //         "กรุณากรอกเบอร์โทรศัพท์"
                    //       )
                    //       return
                    //     } else if (!this.validatePhone(this.state.phone)) {
                    //       this.alert(
                    //         "info",
                    //         "แจ้งเตือน",
                    //         "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง"
                    //       )
                    //       return
                    //     } else if (this.state.phone.trim().length < 9) {
                    //       this.alert(
                    //         "info",
                    //         "แจ้งเตือน",
                    //         "กรุณากรอกเบอร์โทรศัพท์ให้ครบถ้วน"
                    //       )
                    //       return
                    //     } else if (this.state.email.trim() !== "" && !this.validateEmail(this.state.email)) {
                    //       this.alert(
                    //         "info",
                    //         "แจ้งเตือน",
                    //         "รูปแบบอีเมลไม่ถูกต้อง"
                    //       )
                    //       return
                    //     }
                    //   } else {
                    //     if (this.state.full_name === "") {
                    //       this.alert("info", "แจ้งเตือน", "กรุณากรอกชื่อ")
                    //       return
                    //     } else if (this.state.company_name === "") {
                    //       this.alert("info", "แจ้งเตือน", "กรุณากรอกชื่อบริษัท")
                    //       return
                    //     } else if (this.state.tax_invoice === "") {
                    //       this.alert(
                    //         "info",
                    //         "แจ้งเตือน",
                    //         "กรุณากรอกเลขกำกับภาษี"
                    //       )
                    //       return
                    //     } else if (this.state.tax_invoice !== "" && this.state.tax_invoice.length !== 13) {
                    //       this.alert(
                    //         "info",
                    //         "แจ้งเตือน",
                    //         "กรุณากรอกเลขกำกับภาษีให้ครบ 13 หลัก"
                    //       )
                    //       return
                    //     } else if (this.state.phone.trim() === "") {
                    //       this.alert(
                    //         "info",
                    //         "แจ้งเตือน",
                    //         "กรุณากรอกเบอร์โทรศัพท์"
                    //       )
                    //       return
                    //     } else if (!this.validatePhone(this.state.phone)) {
                    //       this.alert(
                    //         "info",
                    //         "แจ้งเตือน",
                    //         "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง"
                    //       )
                    //       return
                    //     } else if (this.state.phone.trim().length < 9) {
                    //       this.alert(
                    //         "info",
                    //         "แจ้งเตือน",
                    //         "กรุณากรอกเบอร์โทรศัพท์ให้ครบถ้วน"
                    //       )
                    //       return
                    //     } else if (this.state.email.trim() !== "" && !this.validateEmail(this.state.email)) {
                    //       this.alert(
                    //         "info",
                    //         "แจ้งเตือน",
                    //         "รูปแบบอีเมลไม่ถูกต้อง"
                    //       )
                    //       return
                    //     }
                    //   }
                    // }
                    this.Edit()
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* delete modal */}
        <Modal
          show={this.state.modal_delete}
          onHide={() => {
            this.setState({ modal_delete: false })
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b>ยืนยันการลบสมาชิก</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 d-flex mb-3">
              <label>
                ท่านแน่ในว่าต้องการลบสมาชิก “
                {this.state.detail && this.state.detail.full_name}”
                หลังจากท่านยืนยันการยกแล้วจะไม่สามารถย้อนคืนข้อมูลได้
              </label>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_delete: false })
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-danger w-100"
                  onClick={() => {
                    this.Delete()
                  }}
                >
                  ยืนยัน
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}
