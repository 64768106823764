// JS
import "../../../../global.js"

// COMPONENT
import MenuComponent from "../../SideMenu/SideMenu"
import BreadcrumbComponent from "../../Breadcrumb/Breadcrumb"

// LIBRARY
import Swal from "sweetalert2"
import { toast } from "react-toastify"
import React, { Component } from "react"
import Pagination from "react-js-pagination"
import { Modal } from "react-bootstrap"

// CSS
import "./style.css"
import "../../../../bootstrap.css"
import "react-slideshow-image/dist/styles.css"
import "bootstrap-daterangepicker/daterangepicker.css"

toast.configure()
export default class ListModelScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      modal_add: false,
      modal_edit: false,
      modal_delete: false,
      page: 1,
      loading: false,
      search: "",
      data: null,
      detail: null,
    }
  }
  componentDidMount() { }

  alert(icon, title, detail) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    })
  }
  alert_url(icon, title, detail, url) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    }).then(() => {
      window.location.href = url
    })
  }

  handlePageChange(pageNumber) {
    this.setState({ page: pageNumber })
    setTimeout(() => { }, 1)
  }

  clear_form() {
    this.setState({
      loading: false,
      modal_detail: false,
    })
  }
  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <MenuComponent />
          <div className="bodyw-100">
            <BreadcrumbComponent page1="หน้าหลัก" page2="รุ่นสินค้า" />
            <div style={{ height: 40 }}></div>
            <div className="w-100 d-flex align-items-center justify-content-between px-3 mt-5">
              <h2>
                <b>รุ่นสินค้า</b>
              </h2>
              <div>
                <button
                  type="button"
                  className="btn btn-primary w-100 px-4"
                  onClick={() => {
                    // this.Login();
                    this.setState({ modal_add: true })
                  }}
                >
                  เพิ่มหมวดหมู่
                </button>
              </div>
            </div>
            <div className="w-100 p-3">
              <div className="card rounded">
                <div className="card-header rounded-top">
                  <input
                    className="form-control bg-white"
                    type="search"
                    placeholder="พิมพ์คำค้นหา..."
                    value={this.state.search}
                    onChange={(e) => {
                      this.setState({ search: e.target.value })
                      if (e.target.value === "") {
                        this.setState({ page: 1 })
                      }
                    }}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        this.setState({
                          page: 1,
                        })
                        // this.search();
                      }
                    }}
                  />
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table design-table">
                      <thead>
                        <tr>
                          <th>วันที่</th>
                          <th>รุ่นสินค้า</th>
                          <th className="text-center">จัดการ</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>10 พ.ค. 65, 11:25 น.</td>
                          <td>Carrier ion-strike 42TVBA010 9200btu</td>

                          <td className="text-center">
                            <button
                              className="btn btn-link text-secondary"
                              onClick={() => {
                                // this.Login();
                                this.setState({
                                  modal_edit: true,
                                  detail: null,
                                })
                              }}
                            >
                              <label className="icon mr-2">{"\uf304"}</label>
                            </button>
                            <button
                              className="btn btn-link text-secondary"
                              onClick={() => {
                                // this.Login();
                                this.setState({
                                  modal_delete: true,
                                  detail: null,
                                })
                              }}
                            >
                              <label className="icon mr-2">{"\uf2ed"}</label>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {/* pagination */}
              {this.state.data && this.state.data.data.length !== 0 && (
                <div className="d-flex justify-content-between row mx-0 w-100 px-3 my-4">
                  <div>
                    <label>
                      แสดง {this.state.data.end_index} รายการจาก{" "}
                      {this.state.data.count} รายการ
                    </label>
                  </div>
                  <div className="d-flex">
                    <button
                      className="btn-circle-white mx-1"
                      onClick={() => {
                        this.handlePageChange(1)
                      }}
                    >
                      <label className="icon">{"\uf100"}</label>
                    </button>
                    <Pagination
                      activePage={this.state.page}
                      itemsCountPerPage={10}
                      totalItemsCount={this.state.data.count}
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChange.bind(this)}
                      prevPageText={<label className="icon">{"\uf104"}</label>}
                      nextPageText={<label className="icon">{"\uf105"}</label>}
                      hideFirstLastPages={true}
                    />
                    <button
                      className="btn-circle-white mx-1"
                      onClick={() => {
                        this.handlePageChange(this.state.data.page_number)
                      }}
                    >
                      <label className="icon">{"\uf101"}</label>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* modal */}
        {/* ADD modal */}
        <Modal
          show={this.state.modal_add}
          onHide={() => {
            this.setState({ modal_add: false })
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b>เพิ่มรุ่นสินค้า</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* รุ่นสินค้า */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>รุ่นสินค้า:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control"
                  type="text"
                  placeholder="กรอกข้อมูล"
                />
              </div>
            </div>

            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_add: false })
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    this.setState({ modal_add: false })
                    this.alert(
                      "success",
                      "เพิ่มรุ่นสินค้าเรียบร้อยแล้ว",
                      "รุ่นสินค้า “Carrier ion-strike 42TVBA010 9200btu” ถูกเพิ่มในระบบแล้ว"
                    )
                  }}
                >
                  เพิ่ม
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* EDIT modal */}
        <Modal
          show={this.state.modal_edit}
          onHide={() => {
            this.setState({ modal_edit: false })
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b>แก้ไขรุ่นสินค้า</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* รุ่นสินค้า */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>รุ่นสินค้า:</label>
              </div>
              <div className="w-100">
                <input
                  className="form-control"
                  type="text"
                  placeholder="กรอกข้อมูล"
                />
              </div>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_edit: false })
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    this.setState({ modal_edit: false })
                    this.alert(
                      "success",
                      "แก้ไขข้อมูลรุ่นสินค้าเรียบร้อยแล้ว",
                      "รุ่นสินค้า “Carrier ion-strike 42TVBA010 9200btu” ถูกแก้ไขข้อมูลในระบบแล้ว"
                    )
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* delete modal */}
        <Modal
          show={this.state.modal_delete}
          onHide={() => {
            this.setState({ modal_delete: false })
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b>ยืนยันการลบรุ่นสินค้า</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 d-flex mb-3">
              <label>
                ท่านแน่ในว่าต้องการลบรุ่นสินค้า “Carrier ion-strike 42TVBA010
                9200btu” หลังจากท่านยืนยันการยกแล้วจะไม่สามารถย้อนคืนข้อมูลได้
              </label>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_delete: false })
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-danger w-100"
                  onClick={() => {
                    this.setState({ modal_delete: false })
                    this.alert(
                      "success",
                      "ลบรุ่นสินค้าเรียบร้อยแล้ว",
                      "รุ่นสินค้า “Carrier ion-strike 42TVBA010 9200btu” ถูกลบเรียบร้อยแล้ว"
                    )
                  }}
                >
                  ยืนยัน
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}
