// JS
import "../../../../global.js"

// COMPONENT
import MenuComponent from "../../SideMenu/SideMenu"
import BreadcrumbComponent from "../../Breadcrumb/Breadcrumb"

// LIBRARY
import Swal from "sweetalert2"
import { toast } from "react-toastify"
import Cookies from "universal-cookie"
import React, { Component } from "react"
import Pagination from "react-js-pagination"
import { Modal } from "react-bootstrap"
import Select from "react-select"

// CSS
import "./style.css"
import "../../../../bootstrap.css"
import "react-slideshow-image/dist/styles.css"
import "bootstrap-daterangepicker/daterangepicker.css"

const cookies = new Cookies()
toast.configure()
export default class ListStockScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      token: cookies.get("token_aip_shop") ? "token " + cookies.get("token_aip_shop") : "",
      role: cookies.get("role_data"),
      modal_add: false,
      modal_edit: false,
      modal_delete: false,
      page: 1,
      loading: false,
      search: "",
      stock_location_id: "",
      data: null,
      data_warehouse: null,
      data_product: null,
      detail: null,
      category_name: ""
    }
  }
  async componentDidMount() {
    if (this.state.role && this.state.role.stock_all[0] === 1) {
      await this.Get_Warehouse_All()
    }
  }

  Get_All = () => {
    this.setState({ loading: true })
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
      body: JSON.stringify({
        data_search: this.state.search,
        stock_location_id: this.state.stock_location_id,
        category_name: this.state.category_name
      }),
    }
    fetch(
      global.api + "v1/stock-mgt/main/filter-search/page/" + this.state.page,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ data: result.data })
          this.setState({ loading: false })
        } else {
          switch (result.data.message) {
            case "This Page is not available":
              this.setState({ data: [] })
              break
            case "Token is not found":
              this.alert("info", "แจ้งเตือน", "เซสชั่นหมดอายุ")
              this.Logout()
              break
            case "Role no access permission":
              this.alert("info", "ขออภัย", "อีเมลนี้ไม่ได้รับอนุญาตให้เข้าถึง")
              this.Logout()
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              // this.Logout();
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
        this.setState({ loading: false })
      })
  }

  Get_Warehouse_All = () => {
    this.setState({ loading: true })
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
    }
    fetch(global.api + "v1/stock-mgt/location/all", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ data_warehouse: result.data })
          if (result.data && result.data.data && result.data.data[0]) {
            this.setState({ stock_location_id: result.data.data[0].stock_location_id })
          }
          this.setState({ loading: false })
        } else {
          switch (result.data.message) {
            case "This Page is not available":
              break
            case "Token is not found":
              this.alert("info", "แจ้งเตือน", "เซสชั่นหมดอายุ")
              this.Logout()
              break
            case "Role no access permission":
              this.alert("info", "ขออภัย", "อีเมลนี้ไม่ได้รับอนุญาตให้เข้าถึง")
              this.Logout()
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              // this.Logout();
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
        this.setState({ loading: false })
      })
      .finally(() => {
        this.Get_All();
      })
  }

  Logout = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
    }
    fetch(global.api + "v1/logout", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        cookies.remove("token_aip_shop")
        cookies.remove("role_data")
        cookies.remove("user_profile")
        cookies.remove("first_page")
        window.location.href = "/login"
      })
      .catch((e) => {
        this.alert_url("info", "แจ้งเตือน", "เกิดข้อผิดพลาด", "/login")
      })
  }
  alert(icon, title, detail) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    })
  }
  alert_url(icon, title, detail, url) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    }).then(() => {
      window.location.href = url
    })
  }

  handlePageChange(pageNumber) {
    this.setState({ page: pageNumber })
    setTimeout(() => {
      this.Get_All()
    }, 20)
  }

  clear_form() {
    this.setState({
      loading: false,
      modal_detail: false,
    })
  }
  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <MenuComponent />
          <div className="bodyw-100">
            <BreadcrumbComponent page1="หน้าหลัก" page2="สต๊อกสินค้า" />
            <div style={{ height: 40 }}></div>
            <div className="w-100 d-flex align-items-center justify-content-between px-3 mt-5">
              <h2>
                <b>สต๊อกสินค้า</b>
              </h2>
            </div>
            <div className="w-100 p-3">
              <div className="card rounded">
                <div className="row card-header rounded-top d-flex justify-content-between">
                  <input
                    className="form-control bg-white wpx-200 mx-2 mb-3"
                    type="search"
                    placeholder="ค้นหาด้วยชื่อสินค้า ยี่ห้อ รุ่น"
                    value={this.state.search}
                    onChange={(e) => {
                      this.setState({ search: e.target.value })
                      if (e.target.value === "") {
                        this.setState({ page: 1, search: "" })
                        setTimeout(() => {
                          this.Get_All()
                        }, 20)
                      }
                    }}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        this.setState({
                          page: 1,
                        })
                        setTimeout(() => {
                          this.Get_All()
                        }, 20)
                      }
                    }}
                  />
                  <div className="d-flex row mx-0">
                    <div className="wpx-200 mx-2">
                      <select
                        className="form-control-select bg-white text-center"
                        value={this.state.category_name}
                        onChange={(e) => {
                          this.setState({
                            category_name: e.target.value,
                          })
                          setTimeout(() => {
                            this.Get_All()
                          }, 100)
                        }}
                      >
                        <option value="" >
                          ทั้งหมด
                        </option>
                        <option value="air" >
                          เครื่องปรับอากาศ
                        </option>
                        <option value="spare_part" >
                          อะไหล่
                        </option>
                      </select>
                    </div>
                    <div className="wpx-200 mx-2">
                      <select
                        className="form-control-select bg-white text-center"
                        value={this.state.stock_location_id}
                        onChange={(e) => {
                          this.setState({
                            stock_location_id: e.target.value,
                          })
                          setTimeout(() => {
                            this.Get_All()
                          }, 100)
                        }}
                      >
                        <option value="" >
                          ทั้งหมด
                        </option>
                        {this.state.data_warehouse &&
                          this.state.data_warehouse.data.map((item, index) => (
                            <option value={item.stock_location_id}>
                              {item.stock_location_name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table design-table">
                      <thead>
                        <tr>
                          <th className="text-left wpx-120">รหัสสินค้า</th>
                          <th className="text-left">ชื่อสินค้า</th>
                          <th className="text-left">ยี่ห้อ</th>
                          <th className="text-left">รุ่น</th>
                          <th className="text-left wpx-120">BTU</th>
                          <th className="text-left">โกดัง</th>
                          <th className="text-right wpx-120">ราคา</th>
                          <th className="text-center wpx-170">
                            จํานวนสินค้าคงเหลือ
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {((this.state.data &&
                          this.state.data.data.length === 0) ||
                          !this.state.data) && (
                            <tr>
                              <td colSpan={8} className="text-center">
                                -- ไม่มีข้อมูล --
                              </td>
                            </tr>
                          )}
                        {this.state.data &&
                          this.state.data.data.map((item, index) => (
                            <tr key={index}>
                              <td>{item.product_main_data.product_code ? item.product_main_data.product_code : "-"}</td>
                              <td>{item.product_main_data.product_name ? item.product_main_data.product_name : "-"}</td>
                              <td>
                                {item.product_main_data.product_data.brand_name ? item.product_main_data.product_data.brand_name : "-"}
                              </td>
                              <td>
                                {item.product_main_data.product_data.model_name ? item.product_main_data.product_data.model_name : "-"}
                              </td>
                              <td>
                                {item.product_main_data.product_data.btu_name ? item.product_main_data.product_data.btu_name : "-"}
                              </td>
                              <td>{item.stock_location_name}</td>
                              <td className="text-right">
                                {item.product_main_data.store_price ? item.product_main_data.store_price
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, "$&,") : "-"}
                              </td>

                              <td className="text-center">
                                <span
                                  className={
                                    item.stock_qty <=
                                      item.product_main_data.safety_stock_noti
                                      ? "bg-stock-red  font-weight-bold"
                                      : "bg-stock-grey"
                                  }
                                >
                                  {item.stock_qty}
                                </span>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {/* pagination */}
              {this.state.data && this.state.data.data.length !== 0 && (
                <div className="d-flex justify-content-between row mx-0 w-100 px-3 my-4">
                  <div>
                    <label>
                      แสดง {this.state.data.end_index} รายการจาก{" "}
                      {this.state.data.count} รายการ
                    </label>
                  </div>
                  <div className="d-flex">
                    <button
                      className="btn-circle-white mx-1"
                      onClick={() => {
                        this.handlePageChange(1)
                      }}
                    >
                      <label className="icon">{"\uf100"}</label>
                    </button>
                    <Pagination
                      activePage={this.state.page}
                      itemsCountPerPage={10}
                      totalItemsCount={this.state.data.count}
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChange.bind(this)}
                      prevPageText={<label className="icon">{"\uf104"}</label>}
                      nextPageText={<label className="icon">{"\uf105"}</label>}
                      hideFirstLastPages={true}
                    />
                    <button
                      className="btn-circle-white mx-1"
                      onClick={() => {
                        this.handlePageChange(this.state.data.page_number)
                      }}
                    >
                      <label className="icon">{"\uf101"}</label>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* modal */}
        {/* ADD modal */}
        <Modal
          show={this.state.modal_add}
          onHide={() => {
            this.setState({ modal_add: false })
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b>เพิ่มสต๊อกสินค้า</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* รหัสผู้ใช้งาน */}
            <div className="w-100 mb-3">
              <label className="w-100">รหัสผู้ใช้งาน:</label>
              <div className="w-100">
                <Select
                  className="form-control px-0"
                  options={
                    this.state.data_product &&
                    this.state.data_product.data
                      .filter(function (obj) {
                        return obj.product_data.air_id
                      })
                      .map((item, index) => ({
                        value: item,
                        label: item.product_data.air_map_name,
                      }))
                  }
                  placeholder="พิมพ์ชื่อสินค้า รุ่น BTU"
                  defaultValue={this.state.product_map}
                  onChange={(e) => {
                    this.setState({
                      product_map: e.value,
                    })
                  }}
                />
              </div>
            </div>
            <div className="d-flex">
              {/* โกดัง */}
              <div className="w-50 mb-3 mr-2">
                <label>โกดัง:</label>
                <div className="w-100">
                  <select className="form-control">
                    <option value="" selected disabled>
                      -- เลือก --
                    </option>
                  </select>
                </div>
              </div>

              {/* จำนวนสต๊อก */}
              <div className="w-50 mb-3 ml-2">
                <label>จำนวนสต๊อก:</label>
                <div className="w-100">
                  <input
                    className="form-control"
                    type="number"
                    placeholder="กรอกข้อมูล"
                  />
                </div>
              </div>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_add: false })
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    this.setState({ modal_add: false })
                    this.alert(
                      "success",
                      "เพิ่มผู้ใช้งานเรียบร้อยแล้ว",
                      "ผู้ใช้งาน “M52554145, ชัยมงคล จิระอนันต์” ถูกเพิ่มในระบบแล้ว"
                    )
                  }}
                >
                  เพิ่ม
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}
