// JS
import "../../global.js"

// LIBRARY
import Swal from "sweetalert2"
import Cookies from "universal-cookie"
import React, { Component } from "react"
import { Modal } from "react-bootstrap"

// CSS
import "./style.css"
import "../../bootstrap.css"

const cookies = new Cookies()

export default class LoginScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      password: "",
      loading: false,
      type: "ระบบ POS",
      confirm_password: "",
      modal_login: false,
      modal_alert: false,
      token: "",
    }
  }

  componentDidMount() {
    const token = cookies.get("token_aip_shop")
    let first = cookies.get("first_page")

    if (token) {
      window.location.href = first
    }
  }

  alert(icon, title, detail) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    })
  }
  alert_url(icon, title, detail, url) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    }).then(() => {
      window.location.href = url
    })
  }
  Login = () => {
    this.setState({ loading: true })

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        email: this.state.email.trim(),
        password: this.state.password,
        remember: true,
        access_by:
          this.state.type === "ระบบ ADMIN" ? "back_office" : "pos_system",
      }),
    }
    fetch(global.api + "v1/login", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          // ROLE
          let role = result.data.detail.role_data.role_detail
          let role_array = {}
          let first = ""
          for (let r of role) {
            let data = [0, 0, 0, 0, 0, 0]
            data[0] = r.permission.view === "true" ? 1 : 0 //view
            data[1] = r.permission.create === "true" ? 1 : 0 //create
            data[2] = r.permission.update === "true" ? 1 : 0 //update
            data[3] = r.permission.delete === "true" ? 1 : 0 //delete
            data[4] = r.permission.download === "true" ? 1 : 0 //download
            data[5] = r.permission.upload === "true" ? 1 : 0 //upload
            role_array[r.menu_name] = data


            if (first === "" && r.permission.view === "true") {
              switch (r.menu_name) {
                case "user_admin":
                  first = "/list-user"
                  break
                case "role":
                  first = "/role-user"
                  break
                case "user_member":
                  first = "/list-member"
                  break
                case "company_supplier":
                  first = "/list-company"
                  break
                case "goods_receipt":
                  first = "/receipt"
                  break
                case "repair_calendar":
                  first = "/calendar"
                  break
                case "product_all":
                  first = "/list-product"
                  break
                case "product_unit":
                  first = "/list-product-unit"
                  break
                case "product_category":
                  first = "/list-catagory"
                  break
                case "type_spare_part":
                  first = "/parts-type"
                  break
                case "brand_all":
                  first = "/list-brands"
                  break
                case "btu":
                  first = "/list-btu"
                  break
                case "air_list":
                  first = "/list-air"
                  break
                case "commission":
                  first = "/list-commission"
                  break
                case "type_commission":
                  first = "/commission-type"
                  break
                case "sale_list":
                  first = "/list-sale"
                  break
                case "stock_move_list":
                  first = "/list-stock-move"
                  break
                case "adjust_stock":
                  first = "/list-stock-adjustment"
                  break
                case "transaction_stock":
                  first = "/list-stock-path"
                  break
                case "stock_all":
                  first = "/list-stock"
                  break
                case "report_sale":
                  first = "/summary"
                  break
                case "warehouse":
                  first = "/list-warehouse"
                  break
                case "clear":
                  first = "/clear"
                  break
                case "pos_system":
                  first = "/main"
                  break

                default:
                  first = ""
                  break
              }
            }
            console.log(first)
            console.log(r.permission.delete)
            console.log(r.name)
            if (first === "" && r.permission.delete === "true" && r.menu_name === "clear") {
              first = "/clear"
            }
          }

          // USER
          let user = result.data.detail
          user.role_data = ""
          cookies.set("token_aip_shop", "" + result.data.token)
          cookies.set("role_data", JSON.stringify(role_array))
          cookies.set("user_profile", JSON.stringify(user))
          cookies.set("first_page", first)
          cookies.set("user_type", result.data.detail.user_type)

          this.setState({ loading: false })

          if (first === "") {
            cookies.remove("token_aip_shop")
            cookies.remove("role_data")
            cookies.remove("user_profile")
            cookies.remove("first_page")
            this.alert(
              "info",
              "แจ้งเตือน",
              "ผู้ใช้งานนี้ไม่มีสิทธิ์เข้าถึง กรุณาลองใหม่อีกครั้ง"
            )
            return
          }


          setTimeout(() => {
            if (result.data.detail.user_type !== "emp_technician") {
              if (this.state.type === "ระบบ ADMIN") {
                window.location.href = first
                return
              }
              window.location.href = "/main"
            } else {
              if (this.state.type === "ระบบ POS") {
                cookies.remove("token_aip_shop")
                cookies.remove("role_data")
                cookies.remove("user_profile")
                cookies.remove("first_page")
                this.alert(
                  "info",
                  "แจ้งเตือน",
                  "ผู้ใช้งานนี้ไม่มีสิทธิ์เข้าถึง กรุณาลองใหม่อีกครั้ง"
                )
                return
              }
              window.location.href = first
            }
          }, 10);

        } else {
          this.setState({ loading: false })
          switch (result.data.message) {
            case "There is an active user on this account":
              if (this.state.type === "ระบบ POS") {
                // this.alert(
                //   "info",
                //   "แจ้งเตือน",
                //   "มีการเข้าใช้งานผู้ใช้งานนี้ กรุณาออกจากระบบก่อนทำการเข้าสู่ระบบอีกครั้ง"
                // );
                this.setState({
                  modal_login: true,
                  token: "token " + result.data.token,
                })
              } else {
                // this.alert(
                //   "info",
                //   "แจ้งเตือน",
                //   "มีการเข้าใช้งานผู้ใช้งานนี้ กรุณาออกจากระบบก่อนทำการเข้าสู่ระบบอีกครั้ง"
                // );
                this.setState({
                  modal_login: true,
                  token: "token " + result.data.token,
                })
              }
              break
            case "email is none":
              this.alert("info", "แจ้งเตือน", "กรุณากรอกชื่อผู้ใช้หรืออีเมล")
              break
            case "password is none":
              this.alert("info", "แจ้งเตือน", "กรุณากรอกรหัสผ่าน")
              break
            case "Invalid Credentials or activate account":
              this.alert("info", "แจ้งเตือน", "อีเมลหรือรหัสผ่านไม่ถูกต้อง")
              break
            case "User is not found":
              this.alert("info", "แจ้งเตือน", "ไม่พบอีเมลนี้ในระบบ")
              break
            case "Role no access permission":
              this.alert(
                "info",
                "ขออภัย",
                "อีเมลนี้ไม่ได้รับอนุญาตให้เข้าถึงระบบ POS"
              )
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              break
          }
        }
      })
      .catch((e) => {
        this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
        this.setState({ loading: false })
      })
  }

  Logout = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
    }
    fetch(global.api + "v1/logout", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        cookies.remove("token_aip_shop")
        cookies.remove("role_data")
        cookies.remove("user_profile")
        cookies.remove("first_page")
        this.Login()
      })
      .catch((e) => {
        this.alert_url("info", "แจ้งเตือน", "เกิดข้อผิดพลาด", "/login")
      })
  }
  render() {
    return (
      <div className="full center bg-contain">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="col-xl-4 col-lg-6 col-md-8 col-sm-10 col-10 mx-auto">
          <div className="w-100 d-flex">
            <button
              className={
                this.state.type === "ระบบ POS"
                  ? "btn btn-white w-50"
                  : "btn btn-gray w-50 text-secondary"
              }
              style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
              onClick={() => {
                this.setState({ type: "ระบบ POS" })
              }}
            >
              ระบบ POS
            </button>
            <button
              className={
                this.state.type === "ระบบ ADMIN"
                  ? "btn btn-white w-50"
                  : "btn btn-gray w-50 text-secondary"
              }
              style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
              onClick={() => {
                this.setState({ type: "ระบบ ADMIN" })
              }}
            >
              ระบบ ADMIN
            </button>
          </div>
          <div
            className="card"
            style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
          >
            <div className="col-12 p-4">
              <h3 className="mb-4">
                <b>ลงชื่อเข้าใช้ ({this.state.type})</b>
              </h3>
              <input
                className="form-control mb-4"
                type="text"
                id="email"
                name="name"
                placeholder="Username/email"
                onKeyUp={(e) => {
                  this.setState({ email: e.target.value })
                }}
              ></input>
              <input
                className="form-control mb-4"
                type="password"
                id="password"
                name="password"
                placeholder="รหัสผ่าน"
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    setTimeout(() => {
                      this.Login()
                    }, 10)
                  } else {
                    this.setState({ password: e.target.value })
                  }
                }}
              ></input>
              <button
                type="button"
                className="btn btn-primary w-100"
                onClick={() => {
                  setTimeout(() => {
                    this.Login()
                  }, 10)
                }}
              >
                ลงชื่อเข้าใช้
              </button>
              <div className="w-100 text-center mt-3">
                <small className="text-secondary">v.{global.version}</small>
              </div>
            </div>
          </div>
        </div>

        <Modal
          show={this.state.modal_login}
          onHide={() => {
            this.setState({ modal_login: false })
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b>แจ้งเตือน</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 d-flex mb-3">
              <label>
                บัญชีผู้ใช้งานนี้ได้เข้าสู่ระบบในอุปกรณ์เครื่องอื่นอยู่
                หากต้องการออกจากระบบในอุปกรณ์เครื่องอื่น กรุณากรอกรหัสผ่าน
                แล้วกด "ยืนยัน"
              </label>
            </div>
            {/* เลือก Supplier */}
            <div className="w-100 d-flex mb-3">
              <input
                className="form-control mb-4"
                type="password"
                id="confirm_password"
                name="confirm_password"
                placeholder="รหัสผ่าน"
                onKeyUp={(e) => {
                  this.setState({ confirm_password: e.target.value })
                }}
              ></input>
            </div>

            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_login: false })
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.confirm_password === this.state.password) {
                      this.setState({ modal_alert: true, modal_login: false })
                    } else {
                      this.alert("warning", "แจ้งเตือน", "รหัสผ่านไม่ถูกต้อง")
                    }
                  }}
                >
                  ยืนยัน
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.modal_alert}
          onHide={() => {
            this.setState({ modal_alert: false })
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b>แจ้งเตือน</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 d-flex mb-3">
              <label>ยืนยันการออกจากระบบในอุปกรณ์เครื่องอื่นหรือไม่</label>
            </div>

            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_alert: false })
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    this.Logout()
                  }}
                >
                  ยืนยัน
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}
