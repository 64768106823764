// JS
import "../../../../global.js"

// COMPONENT
import MenuComponent from "../../SideMenu/SideMenu"
import BreadcrumbComponent from "../../Breadcrumb/Breadcrumb"

// LIBRARY
import Swal from "sweetalert2"
import { toast } from "react-toastify"
import Cookies from "universal-cookie"
import React, { Component } from "react"
import Pagination from "react-js-pagination"
import { Modal } from "react-bootstrap"

// CSS
import "./style.css"
import "../../../../bootstrap.css"
import "react-slideshow-image/dist/styles.css"
import "bootstrap-daterangepicker/daterangepicker.css"

const cookies = new Cookies()
toast.configure()
export default class ListAirScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      token: cookies.get("token_aip_shop") ? "token " + cookies.get("token_aip_shop") : "",
      role: cookies.get("role_data"),
      modal_add: false,
      modal_edit: false,
      modal_delete: false,
      page: 1,
      loading: false,
      search: "",
      filter_brand: "",
      data: null,
      data_brand: null,
      data_btu: null,
      data_model: null,
      detail: null,

      air_id: "",
      air_type: "",
      air_system: "",
      brand_id: "",
      model_name: "",
      btu_id: "",
      filter_id: "",
    }
  }
  async componentDidMount() {
    if (this.state.role && this.state.role.air_list[0] === 1) {
      await this.Get_All()
    }
    await this.Get_All_Brand()
    await this.Get_All_BTU()
    await this.Get_All_Model()
  }

  Get_All_Brand = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
    }
    fetch(global.api + "v1/product-mgt/brand/all", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ data_brand: result.data })
          this.setState({ loading: false })
        } else {
          switch (result.data.message) {
            case "Token is not found":
              this.alert("info", "แจ้งเตือน", "เซสชั่นหมดอายุ")
              this.Logout()
              break
            case "Role no access permission":
              this.alert("info", "ขออภัย", "อีเมลนี้ไม่ได้รับอนุญาตให้เข้าถึง")
              this.Logout()
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              // this.Logout();
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
        this.setState({ loading: false })
      })
  }

  Get_All_BTU = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
    }
    fetch(global.api + "v1/product-mgt/btu/all", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ data_btu: result.data })
          this.setState({ loading: false })
        } else {
          switch (result.data.message) {
            case "Token is not found":
              this.alert("info", "แจ้งเตือน", "เซสชั่นหมดอายุ")
              this.Logout()
              break
            case "Role no access permission":
              this.alert("info", "ขออภัย", "อีเมลนี้ไม่ได้รับอนุญาตให้เข้าถึง")
              this.Logout()
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              // this.Logout();
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
        this.setState({ loading: false })
      })
  }

  Get_All_Model = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
    }
    fetch(global.api + "v1/product-mgt/model/all", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ data_model: result.data })
          this.setState({ loading: false })
        } else {
          switch (result.data.message) {
            case "Token is not found":
              this.alert("info", "แจ้งเตือน", "เซสชั่นหมดอายุ")
              this.Logout()
              break
            case "Role no access permission":
              this.alert("info", "ขออภัย", "อีเมลนี้ไม่ได้รับอนุญาตให้เข้าถึง")
              this.Logout()
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              // this.Logout();
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
        this.setState({ loading: false })
      })
  }

  Get_All = () => {
    this.setState({ loading: true })
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
      body: JSON.stringify({
        brand_name: this.state.filter_id,
        data_search: this.state.search,
      }),
    }
    fetch(
      global.api + "v1/product-mgt/air/filter-search/page/" + this.state.page,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ data: result.data })
          this.setState({ loading: false })
        } else {
          switch (result.data.message) {
            case "Token is not found":
              this.alert("info", "แจ้งเตือน", "เซสชั่นหมดอายุ")
              this.Logout()
              break
            case "Role no access permission":
              this.alert("info", "ขออภัย", "อีเมลนี้ไม่ได้รับอนุญาตให้เข้าถึง")
              this.Logout()
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              // this.Logout();
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
        this.setState({ loading: false })
      })
  }

  Create = () => {
    this.setState({ loading: true })
    let name =
      this.state.data_brand.data.find((e) => e.brand_id === this.state.brand_id)
        .brand_name +
      " " +
      this.state.model_name +
      " ," +
      this.state.data_btu.data.find((e) => e.btu_id === this.state.btu_id)
        .btu_name
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
      body: JSON.stringify({
        air_type: this.state.air_type,
        air_system: this.state.air_system,
        brand_id: this.state.brand_id,
        model_name: this.state.model_name,
        btu_id: this.state.btu_id,
      }),
    }
    fetch(global.api + "v1/product-mgt/air/create", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ loading: false, page: 1 })
          this.alert(
            "success",
            "เพิ่มรายการแอร์เรียบร้อยแล้ว",
            "รายการแอร์ “" + name + "” ถูกเพิ่มในระบบแล้ว"
          )
          this.clear_form()
          setTimeout(() => {
            this.Get_All()
          }, 10)
        } else {
          this.setState({ loading: false })
          switch (result.data.message) {
            case "Token is not found":
              this.alert("info", "แจ้งเตือน", "เซสชั่นหมดอายุ")
              this.Logout()
              break
            case "Role no access permission":
              this.alert("info", "ขออภัย", "อีเมลนี้ไม่ได้รับอนุญาตให้เข้าถึง")
              this.Logout()
              break
            case "brand id is none":
              this.alert("info", "แจ้งเตือน", "กรุณาเลือกยี่ห้อ")
              break
            case "btu id is none":
              this.alert("info", "แจ้งเตือน", "กรุณาเลือก BTU")
              break
            case "air type is none":
              this.alert("info", "แจ้งเตือน", "กรุณาเลือกประเภท")
              break
            case "model name is none":
              this.alert("info", "แจ้งเตือน", "กรุณากรอกชื่อรุ่น")
              break
            case "air map name is duplicate":
              this.alert("info", "แจ้งเตือน", "มีรายการแอร์นี้ในระบบแล้ว")
              break

            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              // this.Logout();
              break
          }
        }
      })
      .catch((e) => {
        this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
        this.setState({ loading: false })
      })
  }

  Edit = () => {
    this.setState({ loading: true })
    let name =
      this.state.data_brand.data.find((e) => e.brand_id === this.state.brand_id)
        .brand_name +
      " " +
      this.state.model_name +
      " ," +
      this.state.data_btu.data.find((e) => e.btu_id === this.state.btu_id)
        .btu_name
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
      body: JSON.stringify({
        air_id: this.state.air_id,
        air_type: this.state.air_type,
        air_system: this.state.air_system,
        brand_id: this.state.brand_id,
        model_name: this.state.model_name,
        btu_id: this.state.btu_id,
      }),
    }
    fetch(global.api + "v1/product-mgt/air/update", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ loading: false, page: 1 })
          this.alert(
            "success",
            "แก้ไขข้อมูลรายการแอร์เรียบร้อยแล้ว",
            "รายการแอร์ “" + name + "” ถูกแก้ไขในระบบแล้ว"
          )
          this.clear_form()
          setTimeout(() => {
            this.Get_All()
          }, 10)
        } else {
          switch (result.data.message) {
            case "brand id is none":
              this.alert("info", "แจ้งเตือน", "กรุณาเลือกยี่ห้อ")
              break
            case "btu id is none":
              this.alert("info", "แจ้งเตือน", "กรุณาเลือก BTU")
              break
            case "air type is none":
              this.alert("info", "แจ้งเตือน", "กรุณาเลือกประเภท")
              break
            case "model name is none":
              this.alert("info", "แจ้งเตือน", "กรุณากรอกชื่อรุ่น")
              break
            case "air map name is duplicate":
              this.alert("info", "แจ้งเตือน", "มีรายการแอร์นี้ในระบบแล้ว")
              break
            case "Token is not found":
              this.alert("info", "แจ้งเตือน", "เซสชั่นหมดอายุ")
              this.Logout()
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              // this.Logout();
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
        this.setState({ loading: false })
      })
  }

  Delete = () => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
      accept: "application/json",
    }
    fetch(
      global.api + "v1/product-mgt/air/delete/" + this.state.detail.air_id,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ loading: false, page: 1 })
          this.setState({ modal_delete: false })
          this.alert(
            "success",
            "ลบรายการแอร์เรียบร้อยแล้ว",
            "รายการแอร์ ถูกลบเรียบร้อยแล้ว"
          )
          this.clear_form()
          setTimeout(() => {
            this.Get_All()
          }, 10)
        } else {
          switch (result.data.message) {
            case "air map  is active":
              this.alert(
                "info",
                "แจ้งเตือน",
                "ไม่สามารถลบได้\nรายการแอร์นี้มีการใช้งานอยู่"
              )
              break
            case "Token is not found":
              this.alert("info", "แจ้งเตือน", "เซสชั่นหมดอายุ")
              this.Logout()
              break
            case "Role no access permission":
              this.alert("info", "ขออภัย", "อีเมลนี้ไม่ได้รับอนุญาตให้เข้าถึง")
              this.Logout()
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
        this.setState({ loading: false })
      })
  }
  Logout = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
    }
    fetch(global.api + "v1/logout", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        cookies.remove("token_aip_shop")
        cookies.remove("role_data")
        cookies.remove("user_profile")
        cookies.remove("first_page")
        window.location.href = "/login"
      })
      .catch((e) => {
        this.alert_url("info", "แจ้งเตือน", "เกิดข้อผิดพลาด", "/login")
      })
  }
  alert(icon, title, detail) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    })
  }
  alert_url(icon, title, detail, url) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    }).then(() => {
      window.location.href = url
    })
  }

  handlePageChange(pageNumber) {
    this.setState({ page: pageNumber })
    setTimeout(() => {
      this.Get_All()
    }, 20)
  }

  clear_form() {
    this.setState({
      modal_add: false,
      modal_edit: false,
      modal_delete: false,
      page: 1,
      loading: false,
      search: "",
      detail: null,

      air_id: "",
      air_type: "",
      air_system: "",
      brand_id: "",
      model_name: "",
      btu_id: "",
    })
  }
  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <MenuComponent />
          <div className="bodyw-100">
            <BreadcrumbComponent page1="หน้าหลัก" page2="รายการแอร์" />
            <div style={{ height: 40 }}></div>
            <div className="w-100 d-flex align-items-center justify-content-between px-3 mt-5">
              <h2>
                <b>รายการแอร์</b>
              </h2>
              {this.state.role && this.state.role.air_list[1] === 1 && (
                <div>
                  <button
                    type="button"
                    className="btn btn-primary w-100 px-4"
                    onClick={() => {
                      this.clear_form()
                      this.setState({ modal_add: true })
                    }}
                  >
                    เพิ่มแอร์
                  </button>
                </div>
              )}
            </div>
            <div className="w-100 p-3">
              <div className="card rounded">
                <div className="card-header rounded-top d-flex justify-content-between">
                  <input
                    className="form-control bg-white mr-3"
                    type="search"
                    placeholder="ค้นหาด้วยยี่ห้อ รุ่น"
                    value={this.state.search}
                    onChange={(e) => {
                      this.setState({ search: e.target.value })
                      if (e.target.value === "") {
                        this.setState({ page: 1, search: "" })
                        setTimeout(() => {
                          this.Get_All()
                        }, 20)
                      }
                    }}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        this.setState({
                          page: 1,
                        })
                        setTimeout(() => {
                          this.Get_All()
                        }, 20)
                      }
                    }}
                  />
                  <div className="wpx-200">
                    <select
                      className="form-control-select bg-white text-center"
                      value={this.state.filter_id}
                      onChange={(e) => {
                        this.setState({
                          page: 1,
                        })
                        let brand_id = e.target.value
                        if (brand_id === "") {
                          this.setState({
                            filter_brand: "",
                            filter_id: "",
                          })
                        } else {
                          this.state.data_brand.data.filter((e) => {
                            if (brand_id === e.brand_id) {
                              this.setState({
                                filter_brand: e.brand_name,
                                filter_id: e.brand_id,
                              })
                            }
                          })
                        }

                        setTimeout(async () => {
                          await this.Get_All()
                        }, 20)
                      }}
                    >
                      <option value="" selected>
                        ทั้งหมด
                      </option>
                      {this.state.data_brand &&
                        this.state.data_brand.data.map((item, index) => (
                          <option value={item.brand_id} key={index}>
                            {item.brand_name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table design-table">
                      <thead>
                        <tr>
                          <th className="text-left wpx-200">ประเภท</th>
                          <th className="text-left wpx-200">ยี่ห้อ</th>
                          <th className="text-left">รุ่น</th>
                          <th className="text-left wpx-200">BTU</th>
                          {this.state.role &&
                            (this.state.role.air_list[2] === 1 ||
                              this.state.role.air_list[3] === 1) && (
                              <th className="text-center wpx-150">จัดการ</th>
                            )}
                        </tr>
                      </thead>
                      <tbody>
                        {((this.state.data &&
                          this.state.data.data.length === 0) ||
                          !this.state.data) && (
                            <tr>
                              <td colSpan={5} className="text-center">
                                -- ไม่มีข้อมูล --
                              </td>
                            </tr>
                          )}
                        {this.state.data &&
                          this.state.data.data.map((item, index) => (
                            <tr key={index}>
                              <td>
                                {item.air_type === "wall_air"
                                  ? "แอร์ผนัง"
                                  : item.air_type === "hanging_air"
                                    ? "แอร์แขวน"
                                    : "แอร์ 4 ทิศทาง"}
                              </td>
                              <td>{item.brand_name}</td>
                              <td>{item.model_name}</td>
                              <td>{item.btu_name}</td>
                              <td className="text-center">
                                {this.state.role &&
                                  this.state.role.air_list[2] === 1 && (
                                    <button
                                      className="btn btn-link text-secondary"
                                      onClick={() => {
                                        this.setState({
                                          modal_edit: true,
                                          detail: item,

                                          air_id: item.air_id,
                                          air_type: item.air_type,
                                          air_system: item.air_system,
                                          brand_id: item.brand_id,
                                          model_name: item.model_name,
                                          btu_id: item.btu_id,
                                        })
                                      }}
                                    >
                                      <label className="icon mr-2">
                                        {"\uf304"}
                                      </label>
                                    </button>
                                  )}
                                {this.state.role &&
                                  this.state.role.air_list[3] === 1 && (
                                    <button
                                      className="btn btn-link text-secondary"
                                      onClick={() => {
                                        // this.Login();
                                        this.setState({
                                          modal_delete: true,
                                          detail: item,
                                        })
                                      }}
                                    >
                                      <label className="icon mr-2">
                                        {"\uf2ed"}
                                      </label>
                                    </button>
                                  )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {/* pagination */}
              {this.state.data && this.state.data.data.length !== 0 && (
                <div className="d-flex justify-content-between row mx-0 w-100 px-3 my-4">
                  <div>
                    <label>
                      แสดง {this.state.data.end_index} รายการจาก{" "}
                      {this.state.data.count} รายการ
                    </label>
                  </div>
                  <div className="d-flex">
                    <button
                      className="btn-circle-white mx-1"
                      onClick={() => {
                        this.handlePageChange(1)
                      }}
                    >
                      <label className="icon">{"\uf100"}</label>
                    </button>
                    <Pagination
                      activePage={this.state.page}
                      itemsCountPerPage={10}
                      totalItemsCount={this.state.data.count}
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChange.bind(this)}
                      prevPageText={<label className="icon">{"\uf104"}</label>}
                      nextPageText={<label className="icon">{"\uf105"}</label>}
                      hideFirstLastPages={true}
                    />
                    <button
                      className="btn-circle-white mx-1"
                      onClick={() => {
                        this.handlePageChange(this.state.data.page_number)
                      }}
                    >
                      <label className="icon">{"\uf101"}</label>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* modal */}

        {/* ADD modal */}
        <Modal
          show={this.state.modal_add}
          onHide={() => {
            this.setState({ modal_add: false })
            this.clear_form()
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b>เพิ่มแอร์</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* ประเภท */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ประเภท:</label>
              </div>
              <div className="w-100">
                <select
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ air_type: e.target.value })
                  }}
                  value={this.state.air_type}
                >
                  <option value="" selected disabled>
                    -- เลือก --
                  </option>
                  <option value="wall_air">แอร์ผนัง</option>
                  <option value="hanging_air">แอร์แขวน</option>
                  <option value="4_direction_air">แอร์ 4 ทิศทาง</option>
                </select>
              </div>
            </div>
            {/* ระบบ */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ระบบ:</label>
              </div>
              <div className="w-100">
                <select
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ air_system: e.target.value })
                  }}
                  value={this.state.air_system}
                >
                  <option value="" selected disabled>
                    -- เลือก --
                  </option>
                  <option value="INVERTER">INVERTER</option>
                  <option value="NORMAL">ปกติ</option>
                </select>
              </div>
            </div>
            {/* ยี่ห้อ */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ยี่ห้อ:</label>
              </div>
              <div className="w-100">
                <select
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ brand_id: e.target.value })
                  }}
                  value={this.state.brand_id}
                >
                  <option value="" selected disabled>
                    -- เลือก --
                  </option>
                  {this.state.data_brand &&
                    this.state.data_brand.data.map((item, index) => (
                      <option value={item.brand_id}>{item.brand_name}</option>
                    ))}
                </select>
              </div>
            </div>
            {/* รุ่น */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>รุ่น:</label>
              </div>
              <div className="w-100">
                <input
                  type="text"
                  className="form-control"
                  placeholder="พิมพ์คำค้นหา..."
                  list="modelList"
                  onChange={(e) => {
                    this.setState({ model_name: e.target.value })
                  }}
                  value={this.state.model_name}
                />
                <datalist id="modelList">
                  {this.state.data_model &&
                    this.state.data_model.data.map((item, index) => (
                      <option value={item.model_name}>{item.model_name}</option>
                    ))}
                </datalist>
              </div>
            </div>
            {/* BTU */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>BTU:</label>
              </div>
              <div className="w-100">
                <select
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ btu_id: e.target.value })
                  }}
                  value={this.state.btu_id}
                >
                  <option value="" selected disabled>
                    -- เลือก --
                  </option>
                  {this.state.data_btu &&
                    this.state.data_btu.data.map((item, index) => (
                      <option value={item.btu_id}>{item.btu_name}</option>
                    ))}
                </select>
              </div>
            </div>

            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_add: false })
                    this.clear_form()
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.air_type === "") {
                      this.alert("info", "แจ้งเตือน", "กรุณาเลือกประเภท")
                      return
                    } else if (this.state.air_system === "") {
                      this.alert("info", "แจ้งเตือน", "กรุณาเลือกระบบ")
                      return
                    } else if (this.state.brand_id === "") {
                      this.alert("info", "แจ้งเตือน", "กรุณาเลือกยี่ห้อ")
                      return
                    } else if (this.state.model_name === "") {
                      this.alert("info", "แจ้งเตือน", "กรุณาเลือกรุ่น")
                      return
                    } else if (this.state.btu_id.trim() === "") {
                      this.alert("info", "แจ้งเตือน", "กรุณาเลือกขนาด BTU")
                      return
                    }

                    this.Create()
                  }}
                >
                  เพิ่ม
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* EDIT modal */}
        <Modal
          show={this.state.modal_edit}
          onHide={() => {
            this.setState({ modal_edit: false })
            this.clear_form()
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b>แก้ไขแอร์</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* ประเภท */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ประเภท:</label>
              </div>
              <div className="w-100">
                <select
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ air_type: e.target.value })
                  }}
                  value={this.state.air_type}
                >
                  <option value="" selected disabled>
                    -- เลือก --
                  </option>
                  <option value="wall_air">แอร์ผนัง</option>
                  <option value="hanging_air">แอร์แขวน</option>
                  <option value="4_direction_air">แอร์ 4 ทิศทาง</option>
                </select>
              </div>
            </div>
            {/* ระบบ */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ระบบ:</label>
              </div>
              <div className="w-100">
                <select
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ air_system: e.target.value })
                  }}
                  value={this.state.air_system}
                >
                  <option value="" selected disabled>
                    -- เลือก --
                  </option>
                  <option value="INVERTER">INVERTER</option>
                  <option value="NORMAL">ปกติ</option>
                </select>
              </div>
            </div>
            {/* ยี่ห้อ */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>ยี่ห้อ:</label>
              </div>
              <div className="w-100">
                <select
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ brand_id: e.target.value })
                  }}
                  value={this.state.brand_id}
                >
                  <option value="" selected disabled>
                    -- เลือก --
                  </option>
                  {this.state.data_brand &&
                    this.state.data_brand.data.map((item, index) => (
                      <option value={item.brand_id}>{item.brand_name}</option>
                    ))}
                </select>
              </div>
            </div>
            {/* รุ่น */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>รุ่น:</label>
              </div>
              <div className="w-100">
                <input
                  type="text"
                  className="form-control"
                  placeholder="พิมพ์คำค้นหา..."
                  list="modelList"
                  onChange={(e) => {
                    this.setState({ model_name: e.target.value })
                  }}
                  value={this.state.model_name}
                />
                <datalist id="modelList">
                  {this.state.data_model &&
                    this.state.data_model.data.map((item, index) => (
                      <option value={item.model_name}>{item.model_name}</option>
                    ))}
                </datalist>
              </div>
            </div>
            {/* BTU */}
            <div className="w-100 d-flex mb-3">
              <div className="wpx-120">
                <label>BTU:</label>
              </div>
              <div className="w-100">
                <select
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ btu_id: e.target.value })
                  }}
                  value={this.state.btu_id}
                >
                  <option value="" selected disabled>
                    -- เลือก --
                  </option>
                  {this.state.data_btu &&
                    this.state.data_btu.data.map((item, index) => (
                      <option value={item.btu_id}>{item.btu_name}</option>
                    ))}
                </select>
              </div>
            </div>

            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_edit: false })
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.air_type === "") {
                      this.alert("info", "แจ้งเตือน", "กรุณาเลือกประเภท")
                      return
                    } else if (this.state.air_system === "") {
                      this.alert("info", "แจ้งเตือน", "กรุณาเลือกระบบ")
                      return
                    } else if (this.state.brand_id === "") {
                      this.alert("info", "แจ้งเตือน", "กรุณาเลือกยี่ห้อ")
                      return
                    } else if (this.state.model_name === "") {
                      this.alert("info", "แจ้งเตือน", "กรุณาเลือกรุ่น")
                      return
                    } else if (this.state.btu_id.trim() === "") {
                      this.alert("info", "แจ้งเตือน", "กรุณาเลือกขนาด BTU")
                      return
                    }
                    this.Edit()
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* delete modal */}
        <Modal
          show={this.state.modal_delete}
          onHide={() => {
            this.setState({ modal_delete: false })
            this.clear_form()
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b>ยืนยันการลบแอร์</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 d-flex mb-3">
              <label>
                ท่านแน่ในว่าต้องการลบแอร์ “
                {this.state.detail && this.state.detail.brand_name}{" "}
                {this.state.detail && this.state.detail.model_name} {" ,"}{" "}
                {this.state.detail && this.state.detail.btu_name}”
                หลังจากท่านยืนยันการยกแล้วจะไม่สามารถย้อนคืนข้อมูลได้
              </label>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_delete: false })
                    this.clear_form()
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-danger w-100"
                  onClick={() => {
                    this.Delete()
                  }}
                >
                  ยืนยัน
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}
